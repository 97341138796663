import { FC } from 'react';
import { useIntl } from 'react-intl';

import CustomButton from 'components/CustomButton';
import AddContactDialog from 'containers/AddContactDialog';

import { useModal } from 'hooks/useModal';
import { createContact } from 'services/ContactService';

import PlusIcon from 'assets/icons/PlusIcon';
import { Contact } from 'models/contact.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { ContactFormValues } from 'utils/validation/contactFormSchema';

interface AddContactProps {
  onSuccess: (contact: Contact) => void;
}

const AddContact: FC<AddContactProps> = ({ onSuccess }) => {
  const intl = useIntl();
  const { isOpen, onOpen, onClose } = useModal();

  const handleAddContact = async (values: ContactFormValues) => {
    try {
      const createdContact = await createContact(values);
      onSuccess(createdContact?.data);
      onClose();
    } catch (e) {
      handleApiErrors(e);
    }
  };

  return (
    <>
      <CustomButton
        label={intl.formatMessage({ id: 'button.addContact' })}
        icon={<PlusIcon size={20} />}
        id="button-addContact"
        onClick={onOpen}
        variant="text"
        sx={{ py: 1 }}
      />

      {isOpen && <AddContactDialog isOpen={isOpen} onSubmit={handleAddContact} onClose={onClose} />}
    </>
  );
};

export default AddContact;
