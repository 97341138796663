import { isEqual } from 'lodash';
import {
  ComponentType, FC, Fragment, ReactElement, useState,
} from 'react';

import ResponsiveCollapsableRow from 'components/Table/ResponsiveCollapsableRow';
import ResponsiveRow from 'components/Table/ResponsiveRow';

import { Column } from 'models/column.interface';

import { TableBody } from './index.styled';

interface ResponsiveTableBodyProps {
  id: string;
  data: unknown[];
  columns: Column[];
  checkIsRowDisabled?: (rowData: unknown) => boolean;
  onRowClick?: (rowData: any) => void;
  isSelectable?: boolean;
  isCollapsable?: boolean;
  selectedRows?: unknown[];
  onSelect?: (rowData: unknown) => void;
  rowClassName?: (rowData: unknown) => string;
  isLoading?: boolean;
  loadingRowsCount: number;
  CollapsableComponent?: ComponentType<any> | null;
  groupedBy?: string[];
  groupedData?: string;
}

const ResponsiveTableBody: FC<ResponsiveTableBodyProps> = (props): ReactElement => {
  const {
    id,
    data,
    checkIsRowDisabled,
    onRowClick,
    columns,
    isSelectable,
    isCollapsable,
    selectedRows,
    onSelect,
    rowClassName,
    loadingRowsCount,
    isLoading = false,
    CollapsableComponent = null,
    groupedBy = [],
    groupedData,
  } = props;
  const [collapsedRowIds, setCollapsedRowIds] = useState<number[]>([]);
  const loadingRows = Array(loadingRowsCount).fill([]);

  const handleCollapseRow = (indexRow: number) => {
    const filteredRows = collapsedRowIds.filter((currentIndex) => indexRow !== currentIndex);

    setCollapsedRowIds(filteredRows.length === collapsedRowIds.length
      ? [...collapsedRowIds, indexRow]
      : filteredRows);
  };

  return (
    <TableBody id={`tableBody-${id}`}>
      {isLoading
        ? (loadingRows.map((loadingRow, index) => (
          <ResponsiveRow
            key={`tableRow-${id}-${index}`}
            id={id}
            rowData={loadingRow}
            indexRow={0}
            onRowClick={onRowClick}
            columns={columns}
            onSelect={onSelect}
            rowClassName={rowClassName}
            isSelectable={isSelectable}
            isCollapsable={isCollapsable}
            isLoading={isLoading}
            onRowCollapse={() => handleCollapseRow(index)}
            isDisabled
          />
        )))
        : (data?.length > 0 && data.map((rowData: any, indexRow: number): ReactElement => {
          const isDisabled = checkIsRowDisabled ? checkIsRowDisabled(rowData) : false;
          const isSelected = selectedRows && selectedRows?.length > 0
              && selectedRows.some((selectedRow: any) => isEqual(selectedRow, rowData));
          const collapsableColumn = columns[0];
          const isCollapsed = collapsedRowIds.includes(indexRow);

          if (groupedBy?.length > 0 && groupedData) {
            const [firstRow, ...groupedRows] = rowData[groupedData];
            const formattedRowData = { ...firstRow };

            groupedBy?.forEach((rule) => {
              formattedRowData[rule] = rowData[rule];
            });

            return (
              <>
                <ResponsiveRow
                  key={`tableRow-${id}-${indexRow}`}
                  id={id}
                  rowData={formattedRowData}
                  indexRow={indexRow}
                  isDisabled={isDisabled}
                  onRowClick={onRowClick}
                  columns={columns}
                  isSelected={isSelected}
                  onSelect={onSelect}
                  rowClassName={rowClassName}
                  isSelectable={isSelectable}
                  isLoading={isLoading}
                  isCollapsable={isCollapsable}
                  isCollapsed={isCollapsed}
                  onRowCollapse={() => handleCollapseRow(indexRow)}
                />
                {groupedRows && groupedRows.map((groupedDataRow: any, indexGroupedRow: any) => (
                  <ResponsiveRow
                    groupedBy={groupedBy}
                    key={`groupedTableRow-${groupedDataRow.id}-${indexGroupedRow}`}
                    id={groupedDataRow.id}
                    rowData={groupedDataRow}
                    indexRow={indexGroupedRow}
                    isDisabled={isDisabled}
                    onRowClick={onRowClick}
                    columns={columns}
                    isSelected={isSelected}
                    onSelect={onSelect}
                    rowClassName={rowClassName}
                    isSelectable={isSelectable}
                    isLoading={isLoading}
                    isCollapsable={isCollapsable}
                    isCollapsed={isCollapsed}
                    onRowCollapse={() => handleCollapseRow(indexGroupedRow)}
                  />
                ))}
              </>
            );
          }
          return (
            <Fragment key={`tableRow-${id}-${indexRow}`}>
              <ResponsiveRow
                id={id}
                rowData={rowData}
                indexRow={indexRow}
                isDisabled={isDisabled}
                onRowClick={onRowClick}
                columns={columns}
                isSelected={isSelected}
                onSelect={onSelect}
                rowClassName={rowClassName}
                isSelectable={isSelectable}
                isLoading={isLoading}
                isCollapsable={isCollapsable}
                isCollapsed={isCollapsed}
                onRowCollapse={() => handleCollapseRow(indexRow)}
              />

              {(isCollapsed && CollapsableComponent) && (
              <ResponsiveCollapsableRow
                colSpan={columns.length}
                indexRow={indexRow}
                id={id}
                column={collapsableColumn}
              >
                <CollapsableComponent item={rowData} />
              </ResponsiveCollapsableRow>
              )}
            </Fragment>
          );
        }))}
    </TableBody>
  );
};

export default ResponsiveTableBody;
