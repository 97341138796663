import { IconButton, useTheme } from '@mui/material';
import { ReactElement, FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { SettingsRoot } from 'components/_Navbar/Settings/index.styled';

import CompanyIcon from 'assets/icons/CompanyIcon';
import { COMPANY_PROFILE_ROUTE, COMPANY_TEAM_MANAGEMENT_ROUTE } from 'constants/clientRoutes';

const settingsRoutes = [
  COMPANY_PROFILE_ROUTE,
  COMPANY_TEAM_MANAGEMENT_ROUTE,
];

const Settings: FC = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isActive = settingsRoutes.includes(location.pathname);

  const openSettings = () => {
    navigate(COMPANY_PROFILE_ROUTE);
  };

  return (
    <SettingsRoot isActive={isActive}>
      <IconButton
        aria-label="Company profile"
        onClick={openSettings}
        id="iconButton-companyProfile"
      >
        <CompanyIcon color={theme.palette.common.white} />
      </IconButton>
    </SettingsRoot>
  );
};

export default Settings;
