import { styled } from '@mui/material/styles';
import MuiTabs from '@mui/material/Tabs';

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  borderBottom: 'none',

  '& .MuiTab-root': {
    padding: theme.spacing(3, 0),
    marginRight: 0,

    '&:not(:last-child)': {
      '& .MuiTypography-root': {
        borderRight: `1px solid ${theme.palette.border.main}`,
      },
    },

    '& .MuiTypography-root': {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.25,
      padding: theme.spacing(0, 9),
    },

    '&.Mui-selected .MuiTypography-root': {
      color: theme.palette.common.black,
      fontWeight: 700,
    },
  },

  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },

  '& .MuiTabs-indicatorSpan': {
    width: '80%',
    height: '4px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px 10px 0px 0px',
    left: theme.spacing(6),
  },
}));
