import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UserLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const AvatarWrapper = styled('div')<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5, 3),
  cursor: 'pointer',
  height: '100%',
  backgroundColor: isActive ? 'rgba(255, 255, 255, 0.20)' : 'transparent',
  borderRadius: '8px',

  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
  },

  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1.5),
  },

  '& svg': {
    transform: 'rotate(90deg)',
    marginLeft: theme.spacing(3),
  },
  '& svg > path': {
    fill: theme.palette.common.white,
  },
}));
