import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, ReactNode } from 'react';

import { StyledWrapper } from 'fields/CustomizableRadioButtonField/index.styled';

import { CustomTypographyVariants } from 'assets/themes/theme-declarations';

interface CustomizableRadioButtonFieldProps {
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  fontWeight?: number;
  error?: boolean;
  helperText?: string;
  id: string;
  variant?: CustomTypographyVariants;
  children: ReactNode;
  sx?: object;
}

const CustomizableRadioButtonField :FC<CustomizableRadioButtonFieldProps> = (props) => {
  const {
    label,
    fullWidth = false,
    required,
    variant = 'body1',
    fontWeight,
    error,
    helperText,
    children,
    sx,
    id,
    ...remainingProps
  } = props;

  return (
    <StyledWrapper hasError={error} sx={sx}>
      <FormControl component="div" fullWidth={fullWidth}>
        {label && (
          <FormLabel required={required} sx={{ mb: 3 }}>
            <Typography component="span" color="textPrimary" variant={variant} fontWeight={fontWeight}>
              {label}
            </Typography>
          </FormLabel>
        )}
        <RadioGroup {...remainingProps} id={id}>
          {children}
        </RadioGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </StyledWrapper>
  );
};

export default CustomizableRadioButtonField;
