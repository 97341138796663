import {
  Box, FormHelperText, Menu, MenuItem, SxProps, Typography,
} from '@mui/material';
import isEqual from 'lodash/isEqual';
import {
  ComponentType, FC, MouseEvent, ReactNode, useEffect, useState,
} from 'react';

import SubMenuItemComponent from 'fields/NestedDropdownField/SubMenuItem';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import ChevronUpIcon from 'assets/icons/ChevronUpIcon';
import { GenericOption, Option } from 'models/option.interface';

import { Button, DropdownWrapper } from './index.styled';

interface SublistComponentProps {
  options: GenericOption[];
  onSelect: (value: any) => void;
  searchPlaceholder?: string;
}

interface NestedDropdownFieldProps {
  options: Option[];
  selectedOption: any;
  onSelect: (value: any) => void;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string | ReactNode;
  SublistComponent?: ComponentType<SublistComponentProps>;
  maxWidth?: string;
  sx?: SxProps;
}

export const NestedDropdownField: FC<NestedDropdownFieldProps> = ({
  options,
  onSelect,
  selectedOption,
  disabled = false,
  fullWidth = false,
  error = false,
  placeholder = '',
  SublistComponent,
  helperText = '',
  maxWidth = '400px',
  sx = {},
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState<any>(null);

  useEffect(() => {
    if (!selectedOption) {
      setSelectedValue(null);
    }

    if (selectedOption && !isEqual(selectedOption, selectedValue)) {
      setSelectedValue(selectedOption);
    }
  }, [selectedOption]);

  const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      return;
    }

    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: any) => {
    if (value) {
      setSelectedValue(value);
      onSelect(value);
    }

    handleCloseMenu();
  };

  const renderSublist = (list: Option) => (
    list?.sublist?.options && list?.sublist?.options?.map((item, key: number) => (
      <MenuItem key={`subList-item-${key}`} onClick={() => handleSelect(item)}>
        <Typography variant="body2">
          {item?.label}
        </Typography>
      </MenuItem>
    ))
  );

  return (
    <DropdownWrapper
      hasError={error}
      display="inline-block"
      width={fullWidth ? '100%' : 'fit-content'}
      maxWidth={maxWidth}
      sx={sx}
    >
      <Button
        disabled={disabled}
        onClick={handleMenu}
        fullWidth={fullWidth}
        isActive={Boolean(anchorEl)}
        endIcon={!anchorEl ? <ChevronDownIcon size={20} /> : <ChevronUpIcon size={20} />}
      >
        <Typography
          variant="body2"
          noWrap
          color={selectedValue?.label ? 'text.primary' : 'general.lightGrey6'}
        >
          {selectedValue?.label ? selectedValue?.formattedLabel || selectedValue.label : placeholder}
        </Typography>
      </Button>
      <Menu
        id="nested-dropdown"
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        keepMounted
        slotProps={{ paper: { sx: { mt: 1 } } }}
      >
        <Box sx={{ width: anchorEl && anchorEl.offsetWidth }}>
          {options.map((list, index) => {
            const isDisabled = selectedOption?.isAllOption && selectedOption.parentValue === list.value
              ? false
              : list?.disabled;

            return (
              <SubMenuItemComponent
                key={`parent-${index}`}
                label={list.label}
                isParentOpen={Boolean(anchorEl)}
                isEmpty={!list?.sublist?.options?.length}
                disabled={isDisabled}
              >
                {SublistComponent
                  ? (
                    <SublistComponent
                      options={list?.sublist?.options || []}
                      onSelect={handleSelect}
                      searchPlaceholder={list?.sublist?.placeholder}
                    />
                  ) : renderSublist(list)}
              </SubMenuItemComponent>
            );
          })}
        </Box>
      </Menu>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </DropdownWrapper>
  );
};

export default NestedDropdownField;
