
const MuiFilledInput = {
  styleOverrides: {
    root: ({ theme: muiTheme }: any) => ({
      borderRadius: '4px',
      backgroundColor: muiTheme.palette.general.lightGrey8,
      border: `1px solid ${muiTheme.palette.general.lightGrey8}`,

      '&:hover:not(.Mui-disabled, .Mui-error)': {
        backgroundColor: muiTheme.palette.general.lightGrey8,
        border: `1px solid ${muiTheme.palette.primary.main}`,
      },

      '&:before, &:after, &:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: 'inherit',
        borderBottomStyle: 'none',
      },

      '& .MuiSelect-select': {
        borderRadius: '4px',
        paddingTop: muiTheme.spacing(2.8),
        paddingBottom: muiTheme.spacing(2.6),
        border: `1px solid ${muiTheme.palette.general.lightGrey8}`,
        backgroundColor: muiTheme.palette.general.lightGrey8,

        '&.Mui-disabled': {
          backgroundColor: muiTheme.palette.general.lightGrey9,
          border: `1px solid ${muiTheme.palette.general.lightGrey9}`,
        },
      },

      '&.Mui-focused': {
        backgroundColor: muiTheme.palette.common.white,

        '& .MuiSelect-select': {
          backgroundColor: muiTheme.palette.common.white,
          border: '1px solid transparent',
          borderRadius: '4px',
        },
      },
      '&.Mui-disabled': {
        backgroundColor: muiTheme.palette.general.lightGrey9,

        '&:before': {
          border: 'none',
        },
      },
    }),
    error: ({ theme: muiTheme }: any) => ({
      borderColor: muiTheme.palette.error.main,
    }),
    input: {
      padding: '8.5px 14px',

      '&::placeholder': {
        fontSize: '15px',
      },
    },
  },
};

export default MuiFilledInput;
