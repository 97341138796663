import {
  CriteriaCondition, RuleMatch, RuleTimeframeType, TransactionType,
} from 'constants/enums';

export const defaultRule = {
  name: '',
  identifiers: [
    {
      bankCode: '',
      bankAccountId: '',
    },
  ],
  criteria: [
    {
      key: 'memo',
      values: [''],
      condition: CriteriaCondition.equal,
      order: 0,
    },
    {
      key: 'amount',
      values: [''],
      condition: '',
      order: 1,
    },
    {
      key: 'trnType',
      values: [TransactionType.credit],
      condition: CriteriaCondition.equal,
      order: 2,
    },
  ],
  hasAmount: false,
  hasDescription: false,
  type: RuleMatch.match,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  timeframe: RuleTimeframeType.anyTime,
  timeframeStart: '',
  timeframeEnd: '',
  message: '',
  recipients: [],
};
