import { styled } from '@mui/material/styles';

export const ActionBox = styled('div')(({ theme }) => ({
  borderRadius: '4px',
  padding: theme.spacing(1, 1.5),
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  width: '28px',
  height: '20px',
}));

export const ApproveBox = styled(ActionBox)<{ selected: boolean }>(({ theme, selected }) => ({
  '& svg > path': {
    fill: selected ? theme.palette.common.white : theme.palette.general.darkGrey1,
  },
  backgroundColor: selected ? theme.palette.success.main : theme.palette.common.white,
  border: selected ? `1px solid ${theme.palette.success.main}` : `1px solid ${theme.palette.general.lightGrey3}`,
}));

export const RejectBox = styled(ActionBox)<{ selected: boolean }>(({ theme, selected }) => ({
  '& svg > path': {
    fill: selected ? theme.palette.common.white : theme.palette.general.darkGrey1,
  },
  backgroundColor: selected ? theme.palette.error.main : theme.palette.common.white,
  border: selected ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.general.lightGrey3}`,
}));
