import MuiCardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

import QuotesBg from 'assets/images/quotes-bg.svg';

export const HeaderWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(7),
  marginBottom: theme.spacing(3),
  borderRadius: '2px',
  overflow: 'hidden',
  position: 'relative',
  flexShrink: 0,
}));

export const Background = styled('div')(() => ({
  position: 'absolute',
  inset: 0,

  '&:before': {
    content: '""',
    backgroundImage: `url(${QuotesBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    width: '11rem',
    height: '12rem',
    backgroundPosition: 'right',
    bottom: '0',
    right: '-8px',
  },
}));

export const CardMedia = styled(MuiCardMedia)(() => ({
  objectFit: 'contain',
  display: 'inline-block',
  maxWidth: '200px',
  height: '54px',
})) as typeof MuiCardMedia;
