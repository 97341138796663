import { CircularProgress } from '@mui/material';
import { FC } from 'react';

import { StyledBox } from 'components/Loader/index.styled';

interface LoaderProps {
  fullwidth?: boolean;
}

const Loader:FC<LoaderProps> = ({ fullwidth }) => (
  <StyledBox fullwidth={fullwidth}>
    <CircularProgress />
  </StyledBox>
);

export default Loader;
