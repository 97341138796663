const config = {
  env: 'LOCAL',
  firebaseConfig: {
    apiKey: 'AIzaSyBitpIven3fERLYVW1fUCf6zNxc2Ktxsqc',
    authDomain: 'primaryacct-devel.firebaseapp.com',
    projectId: 'primaryacct-devel',
    storageBucket: 'primaryacct-devel.appspot.com',
    messagingSenderId: '336045187641',
    appId: '1:336045187641:web:187232495a3db8f881a3e8',
    measurementId: 'G-D1XNCH3TZK',
  },
  uiBaseUrl: 'http://localhost:3000',
  apiBaseUrl: 'https://api-dev.primaryaccount.com/',
  intercomAppId: 'qlijpicu',
  fiServEnvUrl: 'https://ais-ui-uat.fiservapps.com/alldata/',
};

export default config;
