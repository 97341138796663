import { styled } from '@mui/material/styles';
import TableRowMui from '@mui/material/TableRow';

export const TableRow = styled(TableRowMui)(({ theme }) => ({
  '&.MuiTableRow-root.italic': {
    fontStyle: 'italic',
  },

  '&.MuiTableRow-root.faded': {
    '& td:not(:last-of-type) .innerCell': {
      opacity: 0.5,
    },
  },

  '&.MuiTableRow-root.Mui-selected': {
    backgroundColor: 'transparent',
    '& .innerCell': {
      backgroundColor: theme.palette.general.lightGrey7,
    },
  },

  '&.MuiTableRow-root.MuiTableRow-hover:hover': {
    backgroundColor: 'transparent',

    '& .innerCell': {
      backgroundColor: theme.palette.general.lightGrey7,
    },
  },

  '&.MuiTableRow-root.faded.MuiTableRow-hover:hover': {
    '& td:not(:last-of-type) .innerCell': {
      backgroundColor: theme.palette.general.lightGrey12,
    },
  },

  '& .MuiTableCell-root': {
    borderBottom: 'none',
    padding: 0,
    height: `calc(100% - ${theme.spacing(2)})`,

    '&:last-of-type': {
      '& .innerCell': {
        borderTopRightRadius: '1px',
        borderBottomRightRadius: '1px',
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(5),
      },
    },

    '&:first-of-type': {
      '& .innerCell': {
        borderTopLeftRadius: '1px',
        borderBottomLeftRadius: '1px',
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(5),
      },
    },

    '& .MuiCheckbox-root.MuiCheckbox-root': {
      padding: 0,

      svg: {
        height: '20px',
      },
    },

    '& .MuiCheckbox-root:hover': {
      backgroundColor: 'transparent',
    },
  },

  '& .innerCell': {
    padding: theme.spacing('7px', 6),
    margin: theme.spacing(1, 0, 1, 0),
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  '& .alignRight': {
    alignItems: 'flex-end',
  },

  '& .collapsableColumn': {
    padding: theme.spacing('7px', 0, '7px', 2),
  },

  '& .justifyStart': {
    justifyContent: 'flex-start',
  },

  '& .justifyCenter': {
    justifyContent: 'center',
  },

  '& .icon': {
    '&:hover svg path': {
      fill: theme.palette.primary.main,
    },
  },

  '& .activeIcon': {
    '& svg': {
      transform: 'rotate(180deg)',
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
}));
