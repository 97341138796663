import ReactGA from 'react-ga4';

import { BankAccountType, DefaultType } from 'constants/enums';
import { BankAccountFilters } from 'models/bankAccount.interface';

export const sendAccountFiltersStatistics = (filters: BankAccountFilters) => {
  const { types = [], balance, institutions = [] } = filters;
  const selectedFilters : string[] = [];

  if (types.length && types?.[0] !== BankAccountType.allTypes) {
    selectedFilters.push('Type');
  }

  if (institutions.length && institutions?.[0]?.code !== DefaultType.all) {
    selectedFilters.push('Bank');
  }

  if (balance?.min || balance?.max) {
    selectedFilters.push('Balance');
  }

  if (selectedFilters.length > 0) {
    const filterNames = selectedFilters.join('_');
    ReactGA.gtag('event', `bank_accounts_filter_${filterNames}`, {
      label: filterNames,
      value: 1,
    });
  }
};
