import { useEffect, useState } from 'react';

import { LocalStorageKeys } from 'constants/enums';

export const getValueFromStorage = (key: LocalStorageKeys | string, initialValue: unknown) => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
};

export const setValueToStorage = (key: LocalStorageKeys | string, value: unknown) => {
  try {
    return window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log('localStorage error', error); // eslint-disable-line
    return null;
  }
};

const useLocalStorage = (key: LocalStorageKeys | string, initialValue?: unknown) => {
  const [storedValue, setStoredValue] = useState(getValueFromStorage(key, initialValue));

  useEffect(() => {
    if (key) {
      setStoredValue(getValueFromStorage(key, initialValue));
    }
  }, [key]);

  const setValue = (value: unknown) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log('localStorage error', error); // eslint-disable-line
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
