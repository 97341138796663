import MuiCardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const Content = styled('div')(({ theme }) => ({
  margin: theme.spacing(21, 12, 21, 15),
  height: '100%',
  maxWidth: '450px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 1,
}));

export const ImageBox = styled('div')(({ theme }) => ({
  width: '800px',
  height: '300px',

  [theme.breakpoints.down('md')]: {
    width: '450px',
    height: '200px',
  },
}));

export const CardMedia = styled(MuiCardMedia)(({ theme }) => ({
  objectFit: 'fill',
  position: 'absolute',
  top: '-90px',
  height: '400px',

  [theme.breakpoints.down('md')]: {
    height: '280px',
    top: '-70px',
  },
})) as typeof MuiCardMedia;

export const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  justifyContent: 'center',
  alignItems: 'center',
}));
