/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ChevronUpIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.09456 14.8565L6.63133 15.4042C6.69274 15.4656 6.7753 15.5 6.86128 15.5C6.94727 15.5 7.02982 15.4656 7.09123 15.4042L12.0003 10.4063L16.9094 15.4042C16.9708 15.4656 17.0534 15.5 17.1394 15.5C17.2254 15.5 17.3079 15.4656 17.3693 15.4042L17.9061 14.8565C17.9663 14.7939 18 14.7097 18 14.6219C18 14.5342 17.9663 14.45 17.9061 14.3873L12.2296 8.59581C12.1682 8.5344 12.0857 8.5 11.9997 8.5C11.9137 8.5 11.8311 8.5344 11.7697 8.59581L6.09325 14.3873C6.03323 14.4501 5.99976 14.5345 6 14.6222C6.00025 14.7099 6.0342 14.7941 6.09456 14.8565Z" fill={color} />
  </svg>
);

export default ChevronUpIcon;
