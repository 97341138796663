import {
  Box, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import ConfirmationModal from 'components/ConfirmationModal';
import SwitchField from 'fields/SwitchField';

import { useModal } from 'hooks/useModal';
import { usePermissions } from 'hooks/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  activateRule, archiveRule,
  deleteRule,
  inactivateRule, unarchiveRule,
} from 'services/RuleService';
import { fetchRules } from 'store/rule-management/ruleManagementSlice';
import {
  archivedSelector,
  filterSelector,
  paginationSelector,
  searchSelector,
  sortOptionsSelector,
} from 'store/rule-management/selectors';
import { currentWorkspaceSelector } from 'store/user/selectors';

import ArchiveIcon from 'assets/icons/ArchiveIcon';
import DeleteIcon from 'assets/icons/DeleteIcon';
import MoreIcon from 'assets/icons/MoreIcon';
import UnarchiveIcon from 'assets/icons/UnarchiveIcon';
import { ConfirmationTypes, RuleStatus } from 'constants/enums';
import { RolePermissions } from 'constants/permissionEnums';
import { RuleDetails } from 'models/rule.interface';
import { handleApiErrors } from 'utils/errorUtils';

import { IconButton, Menu } from './index.styled';

interface RuleActionsProps {
  rule: RuleDetails;
}

const RuleActions:FC<RuleActionsProps> = ({ rule }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isAllowed } = usePermissions();
  const { isOpen: isDeleteOpen, onOpen: onOpenDelete, onClose: onCloseDelete } = useModal();
  const [ruleState, setRuleState] = useState(rule.status);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const canManageRules = isAllowed({ permission: RolePermissions.ManageAlertRules });
  const { page } = useAppSelector(paginationSelector);
  const search = useAppSelector(searchSelector);
  const archived = useAppSelector(archivedSelector);
  const sortOptions = useAppSelector(sortOptionsSelector);
  const filter = useAppSelector(filterSelector);
  const IconComponent = rule?.archived ? UnarchiveIcon : ArchiveIcon;

  const handleDelete = async () => {
    if (!rule.id) {
      toast.error(intl.formatMessage({ id: 'error.unknownRule' }));
      return;
    }

    try {
      setIsSubmitting(true);
      await deleteRule(rule.id);

      refreshRules();
      onCloseDelete();
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenDialog = () => {
    onOpenDelete();
    handleMenuClose();
  };

  const toggleRule = async () => {
    if (!rule.id) {
      toast.error(intl.formatMessage({ id: 'error.unknownRule' }));
      return;
    }

    try {
      if (!currentWorkspace?.id) {
        toast.error(intl.formatMessage({ id: 'error.unknownWorkspace' }));
      } else {
        setIsSubmitting(true);
        const status = ruleState === RuleStatus.active ? RuleStatus.inactive : RuleStatus.active;
        const fn = ruleState === RuleStatus.active ? inactivateRule : activateRule;
        const payload = {
          ownerId: currentWorkspace.id,
        };

        await fn(rule.id, payload);
        setRuleState(status);
      }
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const refreshRules = () => {
    if (!currentWorkspace?.id) {
      toast.error(intl.formatMessage({ id: 'error.unknownWorkspace' }));
    } else {
      const payload = {
        workspaceId: currentWorkspace.id,
        page: page - 1,
        search,
        sort: `${sortOptions.name},${sortOptions.type}`,
        filter: canManageRules
          ? { ...filter, ...(!archived ? { archived } : {}) }
          : { status: RuleStatus.active, archived: false },
      };
      dispatch(fetchRules(payload));
    }
  };

  const handleArchive = async () => {
    if (!rule.id) {
      toast.error(intl.formatMessage({ id: 'error.unknownRule' }));
      return;
    }

    const fn = rule?.archived ? unarchiveRule : archiveRule;

    try {
      setIsSubmitting(true);
      await fn(rule?.id);
      refreshRules();

      const successMessage = rule?.archived ? 'success.unarchiveRuleSuccessfully' : 'success.archiveRuleSuccessfully';
      toast.success(intl.formatMessage({ id: successMessage }));
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box display="flex" alignItems="center" gap={4.5}>
      <Tooltip
        arrow
        id="tooltip-rule-state"
        title={intl.formatMessage({
          id: ruleState === RuleStatus.active
            ? 'label.disableRule'
            : 'label.enableRule',
        })}
        placement="top"
      >
        <div>
          <SwitchField
            disabled={isSubmitting || rule?.archived}
            checked={ruleState === RuleStatus.active}
            onChange={toggleRule}
            checkmark
          />
        </div>
      </Tooltip>

      <IconButton onClick={handleClick} aria-label="Member menu" id="iconButton-ruleMenu">
        <Box display="flex" alignItems="center">
          <MoreIcon />
        </Box>
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          {isAllowed({ permission: RolePermissions.CreateAlertRules }) && (
            <MenuItem
              onClick={handleArchive}
              id="menuItem-archive-rule"
              disabled={isSubmitting}
            >
              <ListItemIcon>
                <IconComponent />
              </ListItemIcon>
              <ListItemText>
                {intl.formatMessage({ id: rule?.archived ? 'button.unarchiveRule' : 'button.archiveRule' })}
              </ListItemText>
            </MenuItem>
          )}

          {isAllowed({ permission: RolePermissions.DeleteAlertRules }) && (
            <MenuItem
              onClick={handleOpenDialog}
              id="menuItem-delete-rule"
              className="red-menu-item"
              disabled={isSubmitting}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>
                {intl.formatMessage({ id: 'button.deleteRule' })}
              </ListItemText>
            </MenuItem>
          )}
        </div>
      </Menu>

      {isDeleteOpen && (
        <ConfirmationModal
          id="modal-removeRule"
          type={ConfirmationTypes.Error}
          title={intl.formatMessage({ id: 'modalTitle.deleteAlert' })}
          confirmLabel={intl.formatMessage({ id: 'button.confirm' })}
          onCancel={onCloseDelete}
          onSubmit={handleDelete}
          isOpen={isDeleteOpen}
          disabled={isSubmitting}
        >
          <Box textAlign="center" display="flex" justifyContent="center">
            <Typography color="textSecondary" width="300px">
              {intl.formatMessage({ id: 'modalContent.deleteRule' })}
            </Typography>
          </Box>
        </ConfirmationModal>
      )}
    </Box>
  );
};

export default RuleActions;
