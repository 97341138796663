import { Table } from '@mui/material';
import {
  ChangeEvent, ComponentType, FC, ReactElement,
} from 'react';

import { TableContainer } from 'components/Table/ResponsiveTable/index.styled';
import ResponsiveTableBody from 'components/Table/ResponsiveTableBody';
import ResponsiveTableHead from 'components/Table/ResponsiveTableHead';

import { TableHeaderColors } from 'constants/enums';
import { DEFAULT_PAGINATION_SIZE } from 'constants/general';
import { Column } from 'models/column.interface';
import { SortOptions } from 'models/sortOptions.interface';

interface TableProps {
  id: string;
  dense?: boolean;
  columns: Column[];
  onSort?: (columnName: string) => void;
  sortOptions?: SortOptions;
  isSelectable?: boolean;
  isCollapsable?: boolean;
  selectedRows?: unknown[];
  data: unknown[];
  onSelectAll?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect?: (rowData: unknown) => void;
  rowClassName?: (rowData: unknown) => string;
  checkIsRowDisabled?: (rowData: unknown) => boolean;
  onRowClick?: (rowData: any) => void;
  isRounded?: boolean;
  isLoading?: boolean;
  isFullWidth?: boolean;
  loadingRowsCount?: number;
  tableHeaderColors?: TableHeaderColors;
  CollapsableComponent?: ComponentType<any>;
  groupedBy?: string[];
  groupedData?: string;
}

const ResponsiveTable: FC<TableProps> = (props): ReactElement => {
  const {
    id,
    columns,
    sortOptions,
    isSelectable,
    isCollapsable,
    data,
    selectedRows,
    onSort,
    onSelectAll,
    onSelect,
    checkIsRowDisabled,
    onRowClick,
    rowClassName,
    CollapsableComponent = null,
    dense = false,
    isRounded = false,
    isLoading = false,
    isFullWidth = false,
    loadingRowsCount = DEFAULT_PAGINATION_SIZE,
    tableHeaderColors = TableHeaderColors.general,
    groupedBy = [],
    groupedData,
  } = props;
  const allSelected = selectedRows && selectedRows.length > 0 && selectedRows?.length === data?.length;

  return (
    <TableContainer>
      <Table
        id={`table-${id}`}
        aria-labelledby="tableTitle"
        size={dense ? 'small' : 'medium'}
        sx={{ minWidth: '650px', height: '100%' }}
        aria-label="enhanced table"
      >
        <ResponsiveTableHead
          id={id}
          columns={columns}
          sortOptions={sortOptions}
          onSort={onSort}
          isSelectable={isSelectable}
          isCollapsable={isCollapsable}
          allSelected={allSelected}
          onSelectAll={onSelectAll}
          isRounded={isRounded}
          isFullWidth={isFullWidth}
          tableHeaderColors={tableHeaderColors}
        />
        <ResponsiveTableBody
          id={id}
          data={data}
          checkIsRowDisabled={checkIsRowDisabled}
          onRowClick={onRowClick}
          rowClassName={rowClassName}
          columns={columns}
          isSelectable={isSelectable}
          isCollapsable={isCollapsable}
          selectedRows={selectedRows}
          onSelect={onSelect}
          isLoading={isLoading}
          loadingRowsCount={loadingRowsCount}
          CollapsableComponent={CollapsableComponent}
          groupedBy={groupedBy}
          groupedData={groupedData}
        />
      </Table>
    </TableContainer>
  );
};

export default ResponsiveTable;
