import { Menu, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';
import {
  FC, MouseEvent, useEffect, useState,
} from 'react';

import SearchableMenu from 'containers/SearchableMenu';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import ChevronUpIcon from 'assets/icons/ChevronUpIcon';
import { GenericOption } from 'models/option.interface';

import { Button, DropdownWrapper } from './index.styled';

interface SearchableDropdownProps {
  options: GenericOption[];
  value?: string | null;
  onSelect: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  searchPlaceholder?: string;
  fullWidth?: boolean;
  error?: boolean;
  maxWidth?: string;
  maxHeight?: string;
}

const SearchableDropdown: FC<SearchableDropdownProps> = ({
  options,
  onSelect,
  value,
  disabled = false,
  fullWidth = false,
  error = false,
  placeholder = '',
  searchPlaceholder = '',
  maxWidth = '400px',
  maxHeight = '300px',
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<GenericOption | null>(null);

  const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      return;
    }

    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!value) {
      setSelectedOption(null);
    }

    if (!selectedOption || (value && !isEqual(value, selectedOption.value))) {
      const fountOption = options?.find((option) => option.value === value);

      if (fountOption) {
        setSelectedOption(fountOption);
      }
    }
  }, [value]);

  const handleSelect = (option: GenericOption) => {
    if (option) {
      setSelectedOption(option);
      onSelect(option.value);
    }

    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <DropdownWrapper
      hasError={error}
      display="inline-block"
      width={fullWidth ? '100%' : 'fit-content'}
      maxWidth={maxWidth}
    >
      <Button
        disabled={disabled}
        onClick={handleMenu}
        fullWidth={fullWidth}
        isActive={Boolean(anchorEl)}
        endIcon={!anchorEl ? <ChevronDownIcon size={20} /> : <ChevronUpIcon size={20} />}
      >
        <Typography
          variant="body2"
          noWrap
          color={selectedOption?.label ? 'text.primary' : 'general.lightGrey6'}
        >
          {selectedOption?.label || placeholder}
        </Typography>
      </Button>
      <Menu
        id="nested-dropdown"
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        keepMounted
        slotProps={{ paper: { sx: { mt: 1 } } }}
      >
        <SearchableMenu
          maxHeight={maxHeight}
          options={options}
          searchPlaceholder={searchPlaceholder}
          onSelect={handleSelect}
        />
      </Menu>
    </DropdownWrapper>
  );
};

export default SearchableDropdown;
