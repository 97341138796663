import { styled } from '@mui/material/styles';
import TableRowMui from '@mui/material/TableRow';

export const TableRow = styled(TableRowMui)(({ theme }) => ({
  '& .MuiTableCell-root': {
    borderBottom: 'none',
    padding: 0,
    height: `calc(100% - ${theme.spacing(2)})`,
  },
}));
