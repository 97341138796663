import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const Customers = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'Customers' */ 'portal/customers/containers/Customers')
    ),
    'Customers',
  ),
);

export default Customers;
