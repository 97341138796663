import {
  Box, Divider, SelectChangeEvent, Typography,
} from '@mui/material';
import { ReactElement, FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CompanyWrapper, Select, MenuItem } from 'components/_Navbar/CompanySelector/index.styled';
import CompanyInitial from 'components/CompanyInitial';
import GenericModal from 'components/GenericModal';
import CreateDummyCompany from 'containers/CreateDummyCompany';

import { useModal } from 'hooks/useModal';
import { usePermissions } from 'hooks/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useWorkspaceAuth } from 'hooks/useWorkspaceAuth';
import { currentWorkspaceSelector, loginWorkspacesSelector, userSelector } from 'store/user/selectors';
import { setCurrentWorkspace } from 'store/user/userSlice';

import PlusIcon from 'assets/icons/PlusIcon';
import { SelectChevronIcon } from 'assets/themes/styled/SelectChevronIcon';
import { DASHBOARD_ROUTE } from 'constants/clientRoutes';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { RolePermissions } from 'constants/permissionEnums';
import { Workspace } from 'models/workspace.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { existingFlags, isRouteEnabled } from 'utils/featureFlag';

const CompanySelector: FC = (): ReactElement => {
  const { isAllowed } = usePermissions();
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const workspaces = useAppSelector(loginWorkspacesSelector);
  const loggedUser = useAppSelector(userSelector);
  const { switchWorkspace } = useWorkspaceAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useModal();
  const isMultipleCompaniesEnabled = isRouteEnabled(existingFlags.userCompanies);
  const canCreate = isMultipleCompaniesEnabled && isAllowed({ permission: RolePermissions.CreateCustomer });

  const handleChanges = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    if (value === 'createWorkspace') {
      onOpen();
    } else {
      const selectedWorkspace = workspaces.find(({ id }) => id === value);

      if (selectedWorkspace) {
        handleWorkspaceSwitch(selectedWorkspace);
      }
    }
  };

  const handleWorkspaceSwitch = async (workspace: Workspace) => {
    try {
      dispatch(setCurrentWorkspace(null));
      await switchWorkspace(workspace.id);

      setTimeout(() => {
        toast.success(
          intl.formatMessage(
            { id: 'label.successfullySwitchedWorkspace' },
            { displayName: workspace.name },
          ),
          GENERAL_TOAST_OPTIONS,
        );
      }, 800);

      if (location.pathname !== DASHBOARD_ROUTE) {
        navigate(DASHBOARD_ROUTE);
      }
    } catch (error: any) {
      handleApiErrors(error);
    }
  };

  const handleCreate = () => {
    if (location.pathname !== DASHBOARD_ROUTE) {
      navigate(DASHBOARD_ROUTE);
    }

    onClose();
  };

  return (
    <CompanyWrapper>
      <Select
        id="workspace-select"
        size="small"
        value={currentWorkspace?.id}
        onChange={handleChanges}
        IconComponent={SelectChevronIcon}
        renderValue={() => (
          <Typography noWrap variant="h2" color="general.lightGrey4">
            {currentWorkspace?.name}
          </Typography>
        )}
      >
        {workspaces.map((workspace) => (
          <MenuItem value={workspace.id} key={workspace.id}>
            <CompanyInitial
              initial={workspace.name[0]}
              size="small"
              color={currentWorkspace?.id === workspace.id ? 'primary' : 'secondary'}
            />
            <Typography color={currentWorkspace?.id === workspace.id ? 'primary' : 'default'}>
              {workspace.name}
            </Typography>
          </MenuItem>
        ))}
        {canCreate && <Divider />}
        {canCreate && (
          <MenuItem value="createWorkspace">
            <Box display="flex" alignItems="center" gap={1}>
              <PlusIcon size={18} />
              <Typography>
                {intl.formatMessage({ id: 'button.addCompany' })}
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Select>
      {isOpen && (
        <GenericModal
          id="modal-add-workspace"
          isOpen
          onClose={onClose}
          bodysx={{ p: 3 }}
        >
          <CreateDummyCompany
            currentUser={loggedUser}
            onSubmit={handleCreate}
            onClose={onClose}
          />
        </GenericModal>
      )}
    </CompanyWrapper>
  );
};

export default CompanySelector;
