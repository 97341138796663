/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const CalendarIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.33358 11.1997H7.55572V12.7997H9.33358V11.1997ZM12.8893 11.1997H11.1114V12.7997H12.8893V11.1997ZM16.445 11.1997H14.6672V12.7997H16.4443L16.445 11.1997ZM18.2229 5.6H17.3336V4H15.5557V5.6H8.44428V4H6.66642V5.6H5.77786C5.54482 5.60008 5.3141 5.64158 5.09893 5.72212C4.88377 5.80265 4.68839 5.92064 4.52402 6.0693C4.35964 6.21796 4.22951 6.39437 4.14108 6.5884C4.05266 6.78244 4.00768 6.99028 4.00873 7.2L4 18.4C3.9999 18.6101 4.04582 18.8182 4.13514 19.0124C4.22445 19.2066 4.3554 19.383 4.52051 19.5316C4.68562 19.6802 4.88165 19.798 5.09739 19.8784C5.31313 19.9588 5.54436 20.0001 5.77786 20H18.2221C18.6932 19.9988 19.1447 19.8298 19.4778 19.53C19.8109 19.2302 19.9987 18.824 20 18.4V7.2C19.9987 6.77614 19.811 6.36997 19.4781 6.0702C19.1451 5.77042 18.6938 5.60138 18.2229 5.6ZM18.2229 18.4H5.77786V9.59967H18.2221L18.2229 18.4Z" fill={color} />
  </svg>
);

export default CalendarIcon;
