import {
  Breakpoint, Button, Dialog, SxProps,
} from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import CloseIcon from 'assets/icons/CloseIcon';
import { CustomButtonVariants } from 'assets/themes/theme-declarations';

import {
  DialogContent, DialogTitle, Paper, DialogActions, Divider, IconButton, DialogTitleContent,
} from './index.styled';

interface GenericModalProps {
  hideCancelButton?: boolean;
  hideCloseButton?: boolean;
  id: string;
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: () => void | Promise<void>;
  title?: string;
  children: ReactNode | ReactNode[];
  cancelLabel?: string;
  confirmLabel?: string;
  disabled?: boolean;
  hasBottomDivider?: boolean;
  hasTopDivider?: boolean;
  hasActions?: boolean;
  maxWidth?: Breakpoint | false;
  closeVariant?: CustomButtonVariants;
  bodysx?: SxProps;
  actionsSx?: SxProps;
}

const GenericModal: FC<GenericModalProps> = (props): ReactElement => {
  const intl = useIntl();
  const {
    id,
    children,
    onClose = () => {},
    onSubmit,
    isOpen,
    title,
    disabled = false,
    hideCancelButton = false,
    hideCloseButton = false,
    hasTopDivider = false,
    hasBottomDivider = false,
    hasActions = false,
    closeVariant = 'text',
    cancelLabel = intl.formatMessage({ id: 'button.cancel' }),
    confirmLabel = intl.formatMessage({ id: 'button.confirm' }),
    bodysx = {},
    actionsSx = {},
    ...remainingProps
  } = props;

  return (
    <Dialog
      id={id}
      open={isOpen}
      onClose={onClose}
      PaperComponent={Paper}
      {...remainingProps}
    >
      {title && (
        <DialogTitle>
          <DialogTitleContent>
            {title}

            {!hideCloseButton && (
              <IconButton onClick={onClose} aria-label="close modal" id="iconButton-closeModal">
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitleContent>

          {hasTopDivider && <Divider sx={{ mt: 1 }} />}
        </DialogTitle>
      )}

      <DialogContent className="hidden-scroll" sx={bodysx}>
        {children}
      </DialogContent>

      {hasBottomDivider && <Divider />}

      {hasActions && (
        <DialogActions sx={actionsSx}>
          {!hideCancelButton && (
            <Button
              variant={closeVariant}
              onClick={onClose}
            >
              {cancelLabel}
            </Button>
          )}
          {onSubmit && (
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={disabled}
            >
              {confirmLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default GenericModal;
