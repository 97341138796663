import {
  Box, ListItemIcon, ListItemText, MenuItem, Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import ConfirmationModal from 'components/ConfirmationModal';

import { useModal } from 'hooks/useModal';
import { usePermissions } from 'hooks/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { cancelInvitation, resendInvitation } from 'services/UserService';
import { currentWorkspaceSelector } from 'store/user/selectors';
import { fetchInvitations } from 'store/users/usersSlice';

import CloseIcon from 'assets/icons/CloseIcon';
import MoreIcon from 'assets/icons/MoreIcon';
import ResendIcon from 'assets/icons/ResendIcon';
import { ConfirmationTypes } from 'constants/enums';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { RolePermissions } from 'constants/permissionEnums';
import { InvitationDetails } from 'models/invitation.interface';
import { handleApiErrors } from 'utils/errorUtils';

import { IconButton, Menu } from './index.styled';

interface InvitationActionMenuProps {
  invitation: InvitationDetails;
  isDisabled?: boolean;
}

interface ConfirmActionModal {
  title: string;
  onSubmit: () => void;
  content: string;
}

const InvitationActionMenu: FC<InvitationActionMenuProps> = ({ invitation, isDisabled = false }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isAllowed } = usePermissions();
  const { isOpen, onOpen, onClose } = useModal();

  const [modalInfo, setModalInfo] = useState<ConfirmActionModal | null>(null);
  const [isLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const canResendInvitation = isAllowed({ permission: RolePermissions.CreateInvitations });
  const canDeleteInvitation = isAllowed({ permission: RolePermissions.DeleteInvitations });

  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const workspaceId = currentWorkspace?.id || '';

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const refreshInvitations = async () => {
    const apiInfo = { params: { workspaceId } };
    dispatch(fetchInvitations(apiInfo));
  };

  const handleResendInvitation = async () => {
    try {
      await resendInvitation(invitation?.id);
      handleMenuClose();
      toast.success(intl.formatMessage({ id: 'success.resendInvitationSuccessfully' }), GENERAL_TOAST_OPTIONS);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const handleCancelInvitation = async () => {
    try {
      await cancelInvitation(invitation?.id);
      await refreshInvitations();
      handleMenuClose();
      toast.success(intl.formatMessage({ id: 'success.cancelInvitationSuccessfully' }), GENERAL_TOAST_OPTIONS);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const handleOpenCancelInvitation = () => {
    handleMenuClose();
    onOpen();
    setModalInfo({
      title: 'modalTitle.cancelInvitation',
      onSubmit: handleCancelInvitation,
      content: 'modalContent.cancelInvitation',
    });
  };

  return (
    <>
      <IconButton onClick={handleClick} aria-label="Member menu" disabled={isDisabled} id="iconButton-memberMenu">
        <Box display="flex" alignItems="center">
          <MoreIcon />
        </Box>
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          {canResendInvitation && (
            <MenuItem
              onClick={handleResendInvitation}
              id="menuItem-resend-invitation"
              disabled={isLoading}
            >
              <ListItemIcon>
                <ResendIcon />
              </ListItemIcon>
              <ListItemText>
                {intl.formatMessage({ id: 'button.resendInvitation' })}
              </ListItemText>
            </MenuItem>
          )}

          {canDeleteInvitation && (
            <MenuItem
              onClick={handleOpenCancelInvitation}
              id="menuItem-cancel-invitation"
              className="red-menu-item"
              disabled={isLoading}
            >
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <ListItemText>
                {intl.formatMessage({ id: 'button.cancelInvitation' })}
              </ListItemText>
            </MenuItem>
          )}
        </div>
      </Menu>
      {isOpen && modalInfo && (
        <ConfirmationModal
          id="modal-team-member-action"
          type={ConfirmationTypes.Error}
          title={intl.formatMessage({ id: modalInfo.title })}
          confirmLabel={intl.formatMessage({ id: 'button.confirm' })}
          onCancel={onClose}
          onSubmit={modalInfo.onSubmit}
          isOpen={isOpen}
          disabled={isLoading}
        >
          <Typography textAlign="center" color="textSecondary">
            {intl.formatMessage({ id: modalInfo.content })}
          </Typography>
        </ConfirmationModal>
      )}
    </>
  );
};

export default InvitationActionMenu;
