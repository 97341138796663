import { RootState } from 'store/index';

import { ApiStatus } from 'constants/enums';
import { Customer } from 'models/customer.interface';
import { UserAndInvitationFilter } from 'models/filter.interface';
import { InvitationDetails } from 'models/invitation.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { User } from 'models/user.interface';

export const customerDetailsSelector = (state: RootState): Customer|null => state.customerDetails.user.data;
export const isLoadingSelector = (state: RootState): boolean => state.customerDetails.user.status === ApiStatus.loading;
export const invitationsSelector = (state: RootState): InvitationDetails[] | null => (
  state.customerDetails.invitations.data
);
export const usersSelector = (state: RootState): User[] | null => state.customerDetails.users.data;
export const searchSelector = (state: RootState): string => state.customerDetails.search;
export const filterSelector = (state: RootState): UserAndInvitationFilter => state.customerDetails.filter;
export const isLoadingInvitationsSelector = (state: RootState): boolean => (
  state.customerDetails.invitations.status === ApiStatus.loading
);
export const isLoadingUsersSelector = (state: RootState): boolean => (
  state.customerDetails.users.status === ApiStatus.loading
);
export const invitationsSortOptionsSelector = (state: RootState):SortOptions | null => (
  state.customerDetails.invitations.sortOptions
);
export const usersSortOptionsSelector = (state: RootState):SortOptions | null => (
  state.customerDetails.users.sortOptions
);
