/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const PaginationDotsIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.20001 16.2864H8.91361V18H7.20001V16.2864Z" fill={color} />
    <path d="M10.6791 16.2864H12.3927V18H10.6791V16.2864Z" fill={color} />
    <path d="M14.1581 16.2864H15.8717V18H14.1581V16.2864Z" fill={color} />
  </svg>
);

export default PaginationDotsIcon;
