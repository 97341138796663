/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ScheduleIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.42857 2C4.44286 2 2 4.45455 2 7.45455C2 10.4545 4.44286 12.9091 7.42857 12.9091C10.4143 12.9091 12.8571 10.4545 12.8571 7.45455C12.8571 4.45455 10.4143 2 7.42857 2ZM7.42857 3.81818C9.41905 3.81818 11.0476 5.45455 11.0476 7.45455C11.0476 9.45455 9.41905 11.0909 7.42857 11.0909C5.4381 11.0909 3.80952 9.45455 3.80952 7.45455C3.80952 5.45455 5.4381 3.81818 7.42857 3.81818ZM16.4762 3.81818V5.63636H14.4299C14.5792 6.21818 14.6667 6.82545 14.6667 7.45455C14.6667 8.42 14.475 9.33909 14.1348 10.1818H19.1905L19.1922 20.1818H6.52381V14.6651C5.89138 14.586 5.28519 14.4256 4.71429 14.1928V20.1818C4.71429 21.1864 5.52405 22 6.52381 22H19.1905C20.1902 22 21 21.1864 21 20.1818V5.63636H18.2857V3.81818H16.4762ZM8.24321 4.90838L6.70406 7.5451L8.69559 9.54616L9.51023 8.63707L8.15309 7.36399L9.2381 5.45526L8.24321 4.90838Z" fill={color} />
  </svg>
);

export default ScheduleIcon;
