/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const UsFlagIcon = ({ size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.57364 5H21.4185C21.7265 5.00058 22.0218 5.1255 22.2396 5.34739C22.4574 5.56929 22.58 5.87007 22.5806 6.18387V18.8174C22.58 19.1312 22.4574 19.432 22.2396 19.6539C22.0218 19.8758 21.7265 20.0007 21.4185 20.0013H2.57364C2.42087 20.0018 2.26949 19.9716 2.12823 19.9123C1.98698 19.853 1.85864 19.7659 1.75061 19.6558C1.64258 19.5458 1.557 19.415 1.4988 19.2711C1.4406 19.1272 1.41093 18.973 1.41151 18.8174V6.18387C1.41208 5.87007 1.5347 5.56929 1.75252 5.34739C1.97034 5.1255 2.2656 5.00058 2.57364 5V5Z" fill="#B22234" />
    <path d="M1.41909 6.0667H22.5784C22.583 6.10707 22.5855 6.14765 22.586 6.18829V7.33679H1.4115V6.18387C1.4115 6.14407 1.4115 6.10538 1.41909 6.0667ZM22.5838 8.38802V9.65369H1.4115V8.38802H22.5838ZM22.5838 10.7093V11.975H1.4115V10.7082H22.5838V10.7093ZM22.5838 13.0307V14.2963H1.4115V13.0296L22.5838 13.0307ZM22.5838 15.352V16.6176H1.4115V15.3509L22.5838 15.352ZM22.5838 17.6733V18.8185C22.5838 18.8572 22.5838 18.9003 22.5762 18.9401H1.41909C1.41445 18.9004 1.41191 18.8606 1.4115 18.8207V17.6755H22.5838V17.6733Z" fill="white" />
    <path d="M9.3891 5V13.0804H1.4115V6.18387C1.41207 5.87007 1.5347 5.56929 1.75251 5.34739C1.97033 5.1255 2.26559 5.00058 2.57363 5H9.3891Z" fill="#3C3B6E" />
    <path d="M1.88362 6.11754L2.14838 6.95764L1.44958 6.4381H2.31766L1.6156 6.95764L1.88362 6.11754Z" fill="white" />
    <path d="M1.88362 7.74246L2.14838 8.58256L1.44958 8.06303H2.31766L1.6156 8.58256L1.88362 7.74246Z" fill="white" />
    <path d="M1.88362 9.3685L2.14838 10.2086L1.44958 9.68906H2.31766L1.6156 10.2086L1.88362 9.3685Z" fill="white" />
    <path d="M1.88362 10.9934L2.14838 11.8335L1.44958 11.314H2.31766L1.6156 11.8335L1.88362 10.9934Z" fill="white" />
    <path d="M2.81784 5.30508L3.08586 6.14518L2.38489 5.62565H3.25296L2.54765 6.14518L2.81784 5.30508Z" fill="white" />
    <path d="M2.81784 6.93001L3.08586 7.7701L2.38489 7.25057H3.25296L2.54765 7.7701L2.81784 6.93001Z" fill="white" />
    <path d="M2.81784 8.55606L3.08586 9.39616L2.38489 8.87662H3.25296L2.54765 9.39616L2.81784 8.55606Z" fill="white" />
    <path d="M2.8177 10.181L3.08572 11.0211L2.38367 10.5015H3.25174L2.54643 11.0211L2.8177 10.181Z" fill="white" />
    <path d="M2.8177 11.807L3.08572 12.6471L2.38367 12.1275H3.25174L2.54643 12.6471L2.8177 11.807Z" fill="white" />
    <path d="M3.75742 6.11754L4.02543 6.95764L3.32446 6.4381H4.19254L3.49048 6.95764L3.75742 6.11754Z" fill="white" />
    <path d="M3.75742 7.74246L4.02543 8.58256L3.32446 8.06303H4.19254L3.49048 8.58256L3.75742 7.74246Z" fill="white" />
    <path d="M3.75752 9.3685L4.02554 10.2086L3.32349 9.68906H4.19156L3.48951 10.2086L3.75752 9.3685Z" fill="white" />
    <path d="M3.75752 10.9934L4.02554 11.8335L3.32349 11.314H4.19156L3.48951 11.8335L3.75752 10.9934Z" fill="white" />
    <path d="M4.69723 5.30508L4.962 6.14518L4.26428 5.62565H5.13236L4.4303 6.14518L4.69723 5.30508Z" fill="white" />
    <path d="M4.69723 6.93001L4.962 7.7701L4.26428 7.25057H5.13236L4.4303 7.7701L4.69723 6.93001Z" fill="white" />
    <path d="M4.69723 8.55606L4.962 9.39616L4.26428 8.87662H5.13236L4.4303 9.39616L4.69723 8.55606Z" fill="white" />
    <path d="M4.69723 10.181L4.962 11.0211L4.26428 10.5015H5.13236L4.4303 11.0211L4.69723 10.181Z" fill="white" />
    <path d="M4.69723 11.807L4.962 12.6471L4.26428 12.1275H5.13236L4.4303 12.6471L4.69723 11.807Z" fill="white" />
    <path d="M5.63351 6.11754L5.90153 6.95764L5.20056 6.4381H6.06864L5.36658 6.95764L5.63351 6.11754Z" fill="white" />
    <path d="M5.63351 7.74246L5.90153 8.58256L5.20056 8.06303H6.06864L5.36658 8.58256L5.63351 7.74246Z" fill="white" />
    <path d="M5.63362 9.3685L5.90164 10.2086L5.19958 9.68906H6.06766L5.3656 10.2086L5.63362 9.3685Z" fill="white" />
    <path d="M5.63362 10.9934L5.90164 11.8335L5.19958 11.314H6.06766L5.3656 11.8335L5.63362 10.9934Z" fill="white" />
    <path d="M6.57344 5.30508L6.84146 6.14518L6.1394 5.62565H7.00748L6.30542 6.14518L6.57344 5.30508Z" fill="white" />
    <path d="M6.57344 6.93001L6.84146 7.7701L6.1394 7.25057H7.00748L6.30542 7.7701L6.57344 6.93001Z" fill="white" />
    <path d="M6.57344 8.55606L6.84146 9.39616L6.1394 8.87662H7.00748L6.30542 9.39616L6.57344 8.55606Z" fill="white" />
    <path d="M6.57344 10.181L6.84146 11.0211L6.1394 10.5015H7.00748L6.30542 11.0211L6.57344 10.181Z" fill="white" />
    <path d="M6.57344 11.807L6.84146 12.6471L6.1394 12.1275H7.00748L6.30542 12.6471L6.57344 11.807Z" fill="white" />
    <path d="M7.50972 6.11754L7.77774 6.95764L7.07568 6.4381H7.94376L7.24604 6.95764L7.50972 6.11754Z" fill="white" />
    <path d="M7.50972 7.74246L7.77774 8.58256L7.07568 8.06303H7.94376L7.24604 8.58256L7.50972 7.74246Z" fill="white" />
    <path d="M7.50972 9.3685L7.77774 10.2086L7.07568 9.68906H7.94376L7.24604 10.2086L7.50972 9.3685Z" fill="white" />
    <path d="M7.50972 10.9934L7.77774 11.8335L7.07568 11.314H7.94376L7.24604 11.8335L7.50972 10.9934Z" fill="white" />
    <path d="M8.44954 5.30508L8.71756 6.14518L8.0155 5.62565H8.88358L8.18152 6.14518L8.44954 5.30508Z" fill="white" />
    <path d="M8.44954 6.93001L8.71756 7.7701L8.0155 7.25057H8.88358L8.18152 7.7701L8.44954 6.93001Z" fill="white" />
    <path d="M8.44954 8.55606L8.71756 9.39616L8.0155 8.87662H8.88358L8.18152 9.39616L8.44954 8.55606Z" fill="white" />
    <path d="M8.44954 10.181L8.71756 11.0211L8.0155 10.5015H8.88358L8.18152 11.0211L8.44954 10.181Z" fill="white" />
    <path d="M8.44954 11.807L8.71756 12.6471L8.0155 12.1275H8.88358L8.18152 12.6471L8.44954 11.807Z" fill="white" />
  </svg>
);

export default UsFlagIcon;
