import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  backgroundColor: theme.palette.general.darkGreen5,
  color: theme.palette.common.white,

  '& .MuiSkeleton-root': {
    backgroundColor: theme.palette.general.darkGreen4,
  },
}));

export const SmallLine = styled('div')(({ theme }) => ({
  height: '2px',
  width: '12px',
  backgroundColor: theme.palette.success.secondary,
}));

export const DashedLine = styled('div')(({ theme }) => ({
  width: '12px',
  border: `1px dashed ${theme.palette.general.lightGreen5}`,
}));
