import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { DatePicker as MuiDatePicker, PickersDay as MuiPickerDay } from '@mui/x-date-pickers';

export const DatePicker = styled(MuiDatePicker)(({ theme }) => ({
  '& .MuiInputAdornment-root': {
    marginRight: theme.spacing(2),

    '& svg path': {
      fill: theme.palette.primary.main,
    },

    '& svg': {
      height: '20px',
      width: '20px',
    },
  },

  backgroundColor: theme.palette.background.main,
  borderRadius: '4px',
}));

export const PickersDay = styled(MuiPickerDay)(({ theme }) => ({
  borderRadius: '5px',
  backgroundColor: theme.palette.background.main,
  border: `1px solid ${theme.palette.background.main}`,
  color: theme.palette.general.lightBlack,

  '&.MuiPickersDay-hiddenDaySpacingFiller': {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
  },

  '&.MuiPickersDay-today': {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    color: theme.palette.primary.main,
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.white,

    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.common.white,
    },
  },

  '&.Mui-disabled': {
    opacity: 0.5,

    '&:hover': {
      backgroundColor: theme.palette.background.main,
      border: `1px solid ${theme.palette.background.main}`,
      color: theme.palette.general.lightBlack,
    },
  },

  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    border: `1px solid ${theme.palette.secondary.dark}`,
    color: theme.palette.general.lightBlack,
  },
}));

export const Paper = styled(MuiPaper)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.border.secondary}`,
  borderRadius: '6px',

  '& .MuiPickersSlideTransition-root': {
    minHeight: '204px',
  },

  '& .MuiPickersCalendarHeader-labelContainer': {
    height: '44px',
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: '8px',
    padding: theme.spacing(1, 1, 1, 3),
  },

  '& .MuiPickersCalendarHeader-root': {
    marginBottom: theme.spacing(3),
  },
}));
