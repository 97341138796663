import { styled } from '@mui/material/styles';

import { NAVBAR_HEIGHT, TABLET_WIDTH } from 'constants/general';

export const NavbarWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  height: `${NAVBAR_HEIGHT}px`,
  position: 'fixed',
  width: '100%',
  top: 0,
  zIndex: 1001,
  display: 'flex',
  minWidth: TABLET_WIDTH,
}));

export const NavbarSpacing = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1002,
  width: '100%',
  padding: theme.spacing(0, 10),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 6),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 4),
  },
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0, 2),
  },
}));

export const TabsWrapper = styled('div')(() => ({
  height: `${NAVBAR_HEIGHT}px`,
  position: 'absolute',
  width: '100%',
  justifyContent: 'center',
  display: 'flex',

  '& div:first-of-type': {
    zIndex: 1002,
  },
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(2.5),
  },

  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));
