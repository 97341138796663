/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const WorkspaceIcon = ({ color = 'currentColor', size = 22 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 22 19">
    <path fill={color} d="M0 0v16h3.652c.547-.803 1.422-1.486 2.52-2H2V2h18v12h-4.172c1.098.514 1.973 1.197 2.52 2H22V0H0Zm11 7c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3Zm0 8c-3.278 0-6 1.429-6 3v1h12v-1c0-1.571-2.722-3-6-3Z" />
  </svg>
);

export default WorkspaceIcon;
