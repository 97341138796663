/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const DoubleRightIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8273 19.8739L11.1232 19.1582C11.0442 19.0763 11 18.9663 11 18.8516C11 18.737 11.0442 18.6269 11.1232 18.545L17.549 11.9996L11.1232 5.4541C11.0442 5.37222 11 5.26214 11 5.1475C11 5.03285 11.0442 4.92277 11.1232 4.8409L11.8273 4.12521C11.9079 4.04495 12.0162 4 12.1289 4C12.2417 4 12.35 4.04495 12.4306 4.12521L19.8768 11.6938C19.9558 11.7757 20 11.8858 20 12.0004C20 12.1151 19.9558 12.2252 19.8768 12.307L12.4306 19.8757C12.3498 19.9557 12.2414 20.0003 12.1286 20C12.0158 19.9997 11.9076 19.9544 11.8273 19.8739Z" fill={color} />
    <path d="M5.8273 19.8739L5.12318 19.1582C5.04423 19.0763 5 18.9663 5 18.8516C5 18.737 5.04423 18.6269 5.12318 18.545L11.549 11.9996L5.12318 5.4541C5.04423 5.37222 5 5.26214 5 5.1475C5 5.03285 5.04423 4.92277 5.12318 4.8409L5.8273 4.12521C5.90785 4.04495 6.01615 4 6.12895 4C6.24174 4 6.35004 4.04495 6.43059 4.12521L13.8768 11.6938C13.9558 11.7757 14 11.8858 14 12.0004C14 12.1151 13.9558 12.2252 13.8768 12.307L6.43059 19.8757C6.34982 19.9557 6.24138 20.0003 6.12859 20C6.0158 19.9997 5.90762 19.9544 5.8273 19.8739Z" fill={color} />
  </svg>
);

export default DoubleRightIcon;
