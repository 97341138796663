import { captureException } from '@sentry/react';
import { ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

//  TODO: apply style and maybe add a more user friendly message
const ErrorBoundary = (): ReactElement => {
  const error = useRouteError();
  const intl = useIntl();

  useDocumentTitle({ title: 'pageTitle.errorPage' });

  useEffect(() => {
    captureException(error);
  }, []);

  const formatError = (): string => {
    if (isRouteErrorResponse(error)) {
      return `${error.status} ${error.statusText}`;
    }

    if (error instanceof Error) {
      return error.message;
    }

    return intl.formatMessage({ id: 'label.unknownError' });
  };

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>{intl.formatMessage({ id: 'label.unexpectedError' })}</p>
      <p>
        <i>{formatError()}</i>
      </p>
    </div>
  );
};

export default ErrorBoundary;
