import {
  Box, Chip, Tooltip, Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import FinancialAccountDetails from 'components/FinancialAccountDetails';
import RecipientFinancialAccountDetails from 'components/RecipientFinancialAccountDetails';
import UserName from 'components/UserName';
import UserRoles from 'components/UserRoles';
import TransactionActionMenu from 'modules/bank-account-details/containers/TransactionActionMenu';
import AcceptedAgreementColumn from 'modules/bank-accounts-overview/components/DocumentAcceptedColumn';
// import RecipientAccountDetails from 'modules/move-money-manage-recipients/components/RecipientAccountDetails';
import ContactActionMenu from 'modules/contacts/containers/ContactActionMenu';
import RecipientAccountDetails from 'modules/recipients/components/RecipientAccountDetails';
import RuleActions from 'modules/rules-management/containers/RuleActions';
import RuleInfo from 'modules/rules-management/containers/RuleInfo';
import ScheduledFor from 'modules/scheduled-transactions/components/ScheduledFor';
import InvitationActionMenu from 'modules/team-management/containers/InvitationActionMenu';
import UserActionMenu from 'modules/team-management/containers/UserActionMenu';
import Actions from 'modules/transaction-approval/components/Actions';
import ReasonStatus from 'modules/transaction-approval/components/ReasonStatus';
import TransactionSearchActionMenu from 'modules/transactions-search/containers/TransactionActionMenu';

import DepositArrowIcon from 'assets/icons/DepositArrowIcon';
import WithdrawArrowIcon from 'assets/icons/WithdrawArrowIcon';
import {
  AuthorityType,
  CurrencySymbol,
  CustomerStatus,
  DateFormats,
  InvitationStatus,
  PaymentType,
  RecipientType,
  RuleHistoryStatus,
  TransactionStatusType, TransactionType,
} from 'constants/enums';
import { currencySymbolByCurrencyLabel, NOT_APPLICABLE } from 'constants/general';
import {
  AUTHORITY_AS_LABELS,
  CUSTOMER_STATUS_LABELS,
  FINANCIAL_ACCOUNT_STATUSES_LABELS,
  FINANCIAL_ACCOUNT_SUBTYPES_LABELS,
  INVITATION_STATUS_LABELS,
  RULE_HISTORY_STATUS_LABELS,
  TRANSACTION_TYPES_AS_LABELS,
} from 'constants/labels';
import {
  classNameByCustomerStatus,
  classNameByInvitationStatus,
  classNameByRuleHistoryStatus,
  classNameByTransactionStatus,
} from 'constants/status';
import { Column, RenderParams } from 'models/column.interface';
import { Transaction, TransactionSearch } from 'models/transaction.interface';
import { TransactionBatch } from 'models/transactionBatch.interface';
import { isRecipientAccount } from 'utils/bankAccount';
import {
  formatDate,
  formatFullName,
  formatPrice,
  formatStringDate,
  formatTablePrice,
} from 'utils/formatters';
import { getTransactionPriceColor } from 'utils/transactions';

const DETAILED_BANK_NAME: Column = {
  name: 'institution',
  label: 'label.bankName',
  sx: { width: '250px' },
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (
    <div>
      {!!item?.institution?.name && (
        <Typography variant="body2" fontWeight={700}>{item?.institution?.name}</Typography>
      )}
      <Typography variant="body3" component="div" color="textSecondary">{item?.type}</Typography>
      <Typography variant="body3" component="div" color="textSecondary">{item?.name}</Typography>
    </div>
  ),
};

const ACCOUNT_INFO: Column = {
  name: 'institution',
  label: 'label.accountInfo',
  sx: { width: '250px' },
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (
    <div>
      {!!item?.name && (
        <Typography variant="body2" fontWeight={700}>{item.name}</Typography>
      )}
      <Typography variant="body3" component="div" color="textSecondary">
        {`${item?.type
          ? `${item.type} (${item.accountNumber})`
          : `(${item.accountNumber})`}`}
      </Typography>
    </div>
  ),
};

const ACCOUNT_NAME: Column = {
  name: 'institutionName',
  label: 'label.accountName',
  sx: { width: '250px' },
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (
    <Typography variant="body2" fontWeight={700}>
      {item?.nickname || item?.institution?.name || NOT_APPLICABLE}
    </Typography>
  ),
};

const ACCOUNT_NUMBER: Column = {
  name: 'accountNumber',
  label: 'label.accountNumber',
  sx: { width: '250px' },
  isClickable: true,
};

const ACCOUNT_BALANCE: Column = {
  name: 'balance',
  label: 'label.balance',
  sx: { whiteSpace: 'nowrap' },
  isClickable: true,
  cellClassName: 'alignRight justifyStart',
  render: ({ item } : RenderParams) : ReactElement | string => {
    const amount = item?.balance ? item.balance : 0;

    return (
      <Typography variant="body2">
        {formatTablePrice({ amount, currency: item.currencyCode })}
      </Typography>
    );
  },
};

const CREATED_AT: Column = {
  name: 'createdAt',
  label: 'label.dateAndTime',
  isSortable: true,
  sx: { whiteSpace: 'nowrap' },
  isClickable: true,
  render: ({ item }: RenderParams): string => (item?.createdAt ? formatDate(item?.createdAt) : NOT_APPLICABLE),
};

const POSTED_DATE: Column = {
  name: 'postedDate',
  label: 'label.createdAt',
  sx: { whiteSpace: 'nowrap' },
  isClickable: true,
  render: ({ item }: RenderParams): string => (
    item?.postedDate ? formatStringDate(item?.postedDate, DateFormats.longDate) : NOT_APPLICABLE
  ),
};

const TRANSACTION_CREATED_AT: Column = {
  name: 'createdAt',
  label: 'label.createdAt',
  sx: { whiteSpace: 'nowrap' },
  isClickable: true,
  render: ({ item }: RenderParams): string => (item?.createdAt
    ? formatDate(item?.createdAt, DateFormats.detailedDayDate)
    : NOT_APPLICABLE),
};

const SENT_AT: Column = {
  name: 'updatedAt',
  isClickable: true,
  label: 'label.dateAndTime',
  sx: { whiteSpace: 'nowrap' },
  isSortable: true,
  render: ({ item }: RenderParams): string => (item?.updatedAt ? formatDate(item?.updatedAt) : NOT_APPLICABLE),
};

const SCHEDULED_TRANSACTION_NAME: Column = {
  name: 'name',
  label: 'label.scheduleName',
  isClickable: true,
};

const TRANSACTION_MEMO: Column = {
  name: 'memo',
  label: 'label.description',
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (item.id && (
    <>
      <Typography variant="body2">
        {item.memo || NOT_APPLICABLE}
      </Typography>
      {item.idpTransactionId && (
        <Tooltip title={item.idpTransactionId} className="pointer" placement="bottom-start">
          <Typography variant="body3" fontWeight={600} color="general.darkGrey1">
            {`TXN ID: ${item.idpTransactionId}`}
          </Typography>
        </Tooltip>
      )}
    </>
  )),
};

const TRANSACTION_REASON: Column = {
  name: 'paymentReason',
  label: 'label.reason',
  isClickable: true,
};

const TRANSACTION_APPROVAL_REASON: Column = {
  name: 'paymentReasonId',
  label: 'label.reason',
  render: ({ item }: RenderParams): ReactElement => (
    <ReasonStatus paymentReasonId={item?.transaction?.paymentReasonId} note={item?.transaction?.note} />
  ),
  isClickable: true,
};

const MOVE_FROM: Column = {
  name: 'debitFinancialAccount',
  label: 'label.from',
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (
    <FinancialAccountDetails financialBankAccount={item.transactionFaFrom} />
  ),
};

const MOVE_TO: Column = {
  name: 'creditFinancialAccount',
  label: 'label.to',
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (
    isRecipientAccount(item?.transactionFaTo?.type)
      ? <RecipientFinancialAccountDetails recipient={item.transactionFaTo || {}} />
      : <FinancialAccountDetails financialBankAccount={item.transactionFaTo || {}} />
  ),
};
const REVIEW_MOVE_FROM: Column = {
  name: 'debitFinancialAccount',
  label: 'label.from',
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (
    <FinancialAccountDetails financialBankAccount={item.transaction.transactionFaFrom} />
  ),
};

const REVIEW_MOVE_TO: Column = {
  name: 'creditFinancialAccount',
  label: 'label.to',
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (
    isRecipientAccount(item?.transaction?.transactionFaTo?.type)
      ? <RecipientFinancialAccountDetails recipient={item.transaction.transactionFaTo || {}} />
      : <FinancialAccountDetails financialBankAccount={item.transaction.transactionFaTo || {}} />
  ),
};

const BANK_ACCOUNT: Column = {
  name: 'bankAccount',
  label: 'label.bankAccount',
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (
    <FinancialAccountDetails financialBankAccount={item.transactionFa} />
  ),
};

const TRANSACTION_TYPE: Column = {
  name: 'transactionType',
  label: 'label.type',
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => {
    const type = item.transactionType || item.trnType;
    return type && <FormattedMessage id={TRANSACTION_TYPES_AS_LABELS[type]} />;
  },
};

const SIMPLE_TRANSACTION_AMOUNT: Column = {
  name: 'amount',
  label: 'label.amount',
  sx: { whiteSpace: 'nowrap' },
  cellClassName: 'alignRight',
  isSortable: true,
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => {
    const transaction = item as Transaction;

    return (
      <Typography variant="body2">
        {formatTablePrice({ amount: transaction?.amount, currency: transaction.currencyCode })}
      </Typography>
    );
  },
};

const REVIEW_TRANSACTION_AMOUNT: Column = {
  name: 'amount',
  label: 'label.amount',
  sx: { whiteSpace: 'nowrap' },
  render: ({ item } : RenderParams) : ReactElement | string => (
    <Typography variant="body2">
      {formatTablePrice({ amount: item?.transaction?.amount })}
    </Typography>
  ),
};

const TRANSACTION_AMOUNT: Column = {
  name: 'amount',
  label: 'label.amount',
  sx: { whiteSpace: 'nowrap' },
  cellClassName: 'alignRight justifyStart',
  isSortable: true,
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => {
    const transaction = item as Transaction;
    const amount = transaction?.amount ? transaction?.amount : 0;

    return (
      <Typography variant="body2" color={amount > 0 ? 'success.main' : 'textPrimary'}>
        {formatTablePrice({ amount: transaction?.amount, currency: transaction.currencyCode })}
      </Typography>
    );
  },
};

const TRANSACTION_AVAILABLE_BALANCE: Column = {
  name: 'endOfDayBalance',
  label: 'label.accountBalance',
  // isSortable: true,
  cellClassName: 'alignRight justifyStart',
  isClickable: true,
  sx: { whiteSpace: 'nowrap' },
  render: ({ item }: RenderParams): string => (
    isNil(item?.endOfDayBalance)
      ? NOT_APPLICABLE
      : formatTablePrice({ amount: item?.endOfDayBalance, currency: item.currencyCode })
  ),
};

const TRANSACTION_STATUS: Column = {
  name: 'status',
  label: 'label.status',
  sx: { width: '120px' },
  isSortable: true,
  cellClassName: 'alignRight',
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => {
    const status = item?.status || item?.latestStatus?.status;
    const label = FINANCIAL_ACCOUNT_STATUSES_LABELS[status]
      ? <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[status]} />
      : status;

    return (
      status && label && (
        <Chip
          variant={classNameByTransactionStatus[status as TransactionStatusType]}
          label={label}
          className="tag"
        />
      )
    );
  },
};

const BATCH_ID: Column = {
  name: 'id',
  label: 'label.batchID',
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (
    item.id && (
      <Tooltip title={item.id} className="pointer" placement="bottom-start">
        <Typography variant="body2">
          {item.id.split('-')[0].toUpperCase()}
        </Typography>
      </Tooltip>
    )
  ),
};

const TRANSACTION_BATCH_STATUS: Column = {
  name: 'status',
  label: 'label.status',
  sx: { width: '120px' },
  isSortable: true,
  cellClassName: 'alignRight',
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => (
    item.status && (
      <Chip
        variant={classNameByTransactionStatus[item.status as TransactionStatusType]}
        label={<FormattedMessage id={`label.status.${item.status}`} />}
        className="tag"
      />
    )
  ),
};

const TRANSACTION_BATCH_TOTAL_VOLUME: Column = {
  name: 'transactionCount',
  label: 'label.totalVolume',
  isSortable: true,
  sx: { width: '150px' },
  cellClassName: 'alignRight',
  isClickable: true,
};

const TRANSACTION_BATCH_TOTAL_AMOUNT: Column = {
  name: 'totalAmount',
  label: 'label.totalAmount',
  sx: { width: '200px' },
  cellClassName: 'alignRight',
  isSortable: true,
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => {
    const transaction = item as TransactionBatch;
    const currencySymbol = transaction.currency
      ? currencySymbolByCurrencyLabel[transaction.currency]
      : CurrencySymbol.usd;

    return item.totalAmount && formatPrice({ price: +transaction.totalAmount, currencySymbol });
  },
};

export const USER_NAME = {
  name: 'name',
  label: 'label.name',
  isSortable: true,
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement => (
    <UserName user={item} />
  ),
};

export const USER_EMAIL = {
  name: 'email',
  label: 'label.email',
  isSortable: true,
  isClickable: true,
};

export const USER_ID = {
  name: 'id',
  label: 'label.id',
  isClickable: true,
  skipCapitalize: true,
};

export const USER_ROLES = {
  name: 'authority',
  label: 'label.role',
  isSortable: true,
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => (
    <UserRoles roles={item.roles} variant="body2" />
  ),
};

export const INVITATION_ROLES = {
  name: 'authority',
  label: 'label.role',
  isSortable: false,
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => {
    const formattedRoles = item?.roles ? item?.roles?.map((role: AuthorityType) => ({
      authority: role,
      name: AUTHORITY_AS_LABELS[role],
    })) : [];

    return (
      <UserRoles roles={formattedRoles} variant="body2" />
    );
  },
};

const USER_STATUS: Column = {
  name: 'status',
  label: 'label.status',
  sx: { width: '120px' },
  cellClassName: 'alignRight',
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => {
    const status = item?.status as InvitationStatus || InvitationStatus.accepted;

    return (
      <Chip
        variant={classNameByInvitationStatus[status]}
        label={<FormattedMessage id={INVITATION_STATUS_LABELS[status]} />}
        className="tag"
      />
    );
  },
};

export const USER_ACTIONS: Column = {
  name: 'actions',
  sx: { width: '50px' },
  cellClassName: 'alignRight',
  isClickable: false,
  render: ({ item, isDisabled } : RenderParams) : ReactElement | string => (
    <UserActionMenu user={item} isDisabled={isDisabled} />
  ),
};

export const INVITATION_ACTIONS: Column = {
  name: 'actions',
  sx: { width: '50px' },
  cellClassName: 'alignRight',
  isClickable: false,
  render: ({ item, isDisabled } : RenderParams) : ReactElement | string => (
    <InvitationActionMenu invitation={item} isDisabled={isDisabled} />
  ),
};

export const CONTACT_ACTIONS: Column = {
  name: 'actions',
  sx: { width: '50px' },
  cellClassName: 'alignRight',
  isClickable: false,
  render: ({ item } : RenderParams) : ReactElement | string => (
    <ContactActionMenu contact={item} />
  ),
};

export const USER_MOBILE_NUMBER = {
  name: 'phoneNumber',
  label: 'label.mobileNumber',
  isClickable: true,
};

export const BENEFICIARY_FULL_NAME = {
  name: 'name',
  label: 'label.name',
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => (
    <Typography variant="body2">
      {formatFullName(item?.firstName, item?.lastName, item?.middleName)}
    </Typography>
  ),
};

export const BENEFICIARY_EMAIL = {
  name: 'primaryEmail.value',
  label: 'label.primaryEmail',
  isClickable: true,
};

export const COMPANY_NAME = {
  name: 'businessName',
  label: 'label.legalBusinessName',
  isClickable: true,
  isSortable: true,
  render: ({ item } : RenderParams) : ReactElement | string => (
    <Typography variant="body1" fontWeight={600}>
      {item?.businessName}
    </Typography>
  ),
};

export const NO_OF_WORKSPACES = {
  name: 'noWorkspaces',
  label: 'label.workspace',
  isSortable: true,
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => (
    <Typography variant="body1" color="general.darkGreen3">
      {item?.noWorkspaces}
    </Typography>
  ),
};

const CUSTOMER_STATUS: Column = {
  name: 'status',
  label: 'label.status',
  sx: { width: '120px' },
  cellClassName: 'alignRight',
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => {
    const status = item?.status as CustomerStatus || CustomerStatus.active;

    return (
      <Chip
        variant={classNameByCustomerStatus[status]}
        label={<FormattedMessage id={CUSTOMER_STATUS_LABELS[status]} />}
        className="tag"
      />
    );
  },
};

const SCHEDULED_FOR: Column = {
  name: 'startDateTime',
  label: 'label.scheduledFor',
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => (
    <ScheduledFor transaction={item} />
  ),
};

const ALERT_HISTORY_STATUS: Column = {
  name: 'status',
  label: 'label.status',
  sx: { width: '120px' },
  cellClassName: 'alignRight',
  isClickable: true,
  isSortable: true,
  render: ({ item } : RenderParams) : ReactElement | string => {
    const status = item?.status as RuleHistoryStatus;
    const labelId = RULE_HISTORY_STATUS_LABELS[status];
    return (
      <Chip
        variant={classNameByRuleHistoryStatus[status] || 'pending'}
        label={labelId ? <FormattedMessage id={labelId} /> : status}
        className="tag"
      />
    );
  },
};

const RULE_CRITERIA: Column = {
  name: 'criteria',
  label: 'label.rules',
  render: ({ item } : RenderParams) : ReactElement => <RuleInfo rule={item} />,
};

const RULE_ACTIONS: Column = {
  name: 'actions',
  render: ({ item } : RenderParams) : ReactElement => <RuleActions rule={item} />,
  sx: { width: '150px' },
};

export const TRANSACTION_ACTIONS: Column = {
  name: 'actions',
  sx: { width: '50px' },
  cellClassName: 'alignRight',
  isClickable: false,
  render: ({ item } : RenderParams) : ReactElement => (
    <TransactionActionMenu transaction={item} />
  ),
};

export const TRANSACTION_ACTIVITY_ACTIONS: Column = {
  name: 'actions',
  sx: { width: '50px' },
  cellClassName: 'alignRight',
  isClickable: false,
  render: ({ item } : RenderParams) : ReactElement => (
    <TransactionSearchActionMenu transaction={item} />
  ),
};

const ALERT_NAME: Column = {
  name: 'name',
  label: 'label.alertName',
  isSortable: true,
};

const ALERT_HISTORY_NAME: Column = {
  name: 'ruleAlert.rule.name',
  label: 'label.alertName',
  isSortable: true,
  isClickable: true,
  render: ({ item } : RenderParams) : ReactElement | string => (
    <Typography variant="body2">
      {item.ruleName}
    </Typography>
  ),
};

const INVOICE_DATE: Column = {
  name: 'createdAt',
  isClickable: true,
  label: 'label.date',
  isSortable: true,
};

const DOCUMENT_TITLE: Column = {
  name: 'title',
  isClickable: true,
  label: 'label.documentName',
};

const DOCUMENT_ID: Column = {
  name: 'id',
  isClickable: true,
  label: 'label.documentID',
};

const DOCUMENT_VERSION: Column = {
  name: 'version',
  isClickable: true,
  label: 'label.version',
};

const DOCUMENT_ACCEPTED: Column = {
  name: 'accepted',
  isClickable: true,
  label: 'label.accepted',
  render: ({ item } : RenderParams) : ReactElement => <AcceptedAgreementColumn agreement={item} />,
};

const SUBSCRIPTION_PLAN: Column = {
  name: 'plan',
  isClickable: true,
  label: 'label.plan',
};

const SUBSCRIPTION_AMOUNT: Column = {
  name: 'amount',
  isClickable: true,
  label: 'label.amount',
  sx: { whiteSpace: 'nowrap' },
};

const SUBSCRIPTION_STATUS: Column = {
  name: 'status',
  isClickable: true,
  label: 'label.status',
};

const RECIPIENT_NAME: Column = {
  name: 'firstName',
  isClickable: true,
  isSortable: true,
  label: 'label.recipientName',
  render: ({ item } : RenderParams) : ReactElement | string => {
    const { recipient } = item || {};
    const isBusiness = recipient?.recipientType === RecipientType.business;

    return (
      <>
        <Typography variant="body2">
          {isBusiness ? recipient?.businessName : formatFullName(recipient?.firstName, recipient?.lastName)}
        </Typography>
        {isBusiness && recipient?.shortName && (
          <Typography variant="body3" mt={1} color="textSecondary">
            {recipient?.shortName}
          </Typography>
        )}
      </>
    );
  },
};

const RECIPIENT_EMAIL: Column = {
  name: 'recipient.email',
  isClickable: true,
  label: 'label.emailAddress',
};

const APPROVAL_TRANSACTION_CREATED_BY: Column = {
  name: 'createdBy',
  label: 'label.createdBy',
  isSortable: true,
  isClickable: true,
  render: ({ item }: RenderParams): ReactElement => (
    <Typography>{item?.createdByUserName || item?.transaction?.createdByUserName}</Typography>
  ),
};

const APPROVAL_TRANSACTION_APPROVED_BY: Column = {
  name: 'reviewedByUserName',
  label: 'label.approvedBy',
  isClickable: true,
};

const APPROVAL_TRANSACTION_REJECTED_BY: Column = {
  name: 'reviewedByUserName',
  label: 'label.rejectedBy',
  isClickable: true,
};

const APPROVAL_TRANSACTION_FAILED_REASON: Column = {
  name: 'korIntegrationError',
  label: 'label.reasonOfFailure',
  isClickable: true,
};

const APPROVAL_TRANSACTION_ACTIONS: Column = {
  name: 'approvalTransactionActions',
  label: 'label.action',
  render: ({ item } : RenderParams) : ReactElement | string => <Actions transactionApproval={item} />,
};

export const APPROVAL_TRANSACTION_STATUS: Column = {
  name: 'status',
  label: 'label.action',
};

const RECIPIENT_ACCOUNT: Column = {
  name: 'bankAccount',
  isClickable: true,
  label: 'label.accountDetails',
  render: ({ item } : RenderParams) : ReactElement => {
    const { financialAccount } = item || {};
    const isDebit = financialAccount?.type === PaymentType.card;
    const accountTypeLabel = financialAccount?.accountType
      ? FINANCIAL_ACCOUNT_SUBTYPES_LABELS[financialAccount?.accountType]
      : '';

    return (
      <RecipientAccountDetails
        accountTypeLabel={isDebit ? '' : accountTypeLabel}
        isDebit={isDebit}
        accountName={isDebit ? '' : financialAccount?.bankName}
        accountNumberTail={isDebit ? financialAccount?.cardNumberTail : financialAccount?.accountNumberTail}
      />
    );
  },
};

export const BANK_ACCOUNTS_COLUMNS: Column[] = [
  DETAILED_BANK_NAME,
  ACCOUNT_NUMBER,
];

export const TRANSACTION_BANK_ACCOUNTS_COLUMNS: Column[] = [
  ACCOUNT_NAME,
  ACCOUNT_INFO,
  ACCOUNT_BALANCE,
];

export const TRANSACTIONS_COLUMNS: Column[] = [
  {
    ...CREATED_AT,
    label: 'label.createdAt',
    render: ({ item }: RenderParams): string => (
      item?.createdAt
        ? formatStringDate(item?.createdAt, DateFormats.longDate)
        : NOT_APPLICABLE
    ),
  },
  TRANSACTION_TYPE,
  BANK_ACCOUNT,
  TRANSACTION_MEMO,
  {
    ...TRANSACTION_AMOUNT,
    cellClassName: 'alignRight justifyCenter',
    render: ({ item } : RenderParams) : ReactElement | string => {
      const transaction = item as TransactionSearch;
      return (
        <Typography variant="body2" color={getTransactionPriceColor(transaction?.trnType)}>
          {formatTablePrice({ amount: +transaction?.amount, currency: transaction?.currency })}
        </Typography>
      );
    },
  },
  TRANSACTION_ACTIVITY_ACTIONS,
];

export const BATCHES_COLUMNS: Column[] = [
  CREATED_AT,
  BATCH_ID,
  TRANSACTION_TYPE,
  TRANSACTION_BATCH_TOTAL_VOLUME,
  TRANSACTION_BATCH_TOTAL_AMOUNT,
  TRANSACTION_BATCH_STATUS,
];

export const USERS_COLUMNS: Column[] = [
  USER_NAME,
  USER_EMAIL,
  USER_MOBILE_NUMBER,
  USER_ID,
  USER_ROLES,
  USER_STATUS,
];

export const EXTENDED_USERS_COLUMNS: Column[] = [
  USER_NAME,
  USER_EMAIL,
  USER_MOBILE_NUMBER,
  USER_ROLES,
  USER_STATUS,
  USER_ACTIONS,
];

export const INVITATIONS_COLUMNS: Column[] = [
  USER_EMAIL,
  INVITATION_ROLES,
  USER_STATUS,
];

export const ACCOUNT_TRANSACTIONS_COLUMNS: Column[] = [
  POSTED_DATE,
  TRANSACTION_MEMO,
  {
    ...TRANSACTION_AMOUNT,
    isSortable: false,
  },
  TRANSACTION_AVAILABLE_BALANCE,
];

export const CUSTOMERS_COLUMNS: Column[] = [
  COMPANY_NAME,
  {
    ...USER_NAME,
    label: 'label.ownerName',
  },
  {
    ...USER_EMAIL,
    label: 'label.customerEmailAddress',
  },
  CUSTOMER_STATUS,
];

export const EXTENDED_CUSTOMERS_COLUMNS: Column[] = [
  COMPANY_NAME,
  {
    ...USER_NAME,
    label: 'label.ownerName',
  },
  {
    ...USER_EMAIL,
    label: 'label.customerEmailAddress',
  },
  NO_OF_WORKSPACES,
  CUSTOMER_STATUS,
];

export const SCHEDULED_TRANSACTIONS_COLUMNS: Column[] = [
  TRANSACTION_CREATED_AT,
  SCHEDULED_FOR,
  SCHEDULED_TRANSACTION_NAME,
  MOVE_FROM,
  MOVE_TO,
  SIMPLE_TRANSACTION_AMOUNT,
  TRANSACTION_STATUS,
];

export const RULES_HISTORY_COLUMNS: Column[] = [
  SENT_AT,
  ALERT_HISTORY_NAME,
  ALERT_HISTORY_STATUS,
];

export const RULES_COLUMNS: Column[] = [
  ALERT_NAME,
  RULE_CRITERIA,
  RULE_ACTIONS,
];

export const RULES_READONLY_COLUMNS: Column[] = [
  ALERT_NAME,
  RULE_CRITERIA,
];

export const COMPANY_BENEFICIARY_COLUMNS: Column[] = [
  BENEFICIARY_FULL_NAME,
  BENEFICIARY_EMAIL,
];

export const COMPANY_BENEFICIARY_CREATE_COLUMNS: Column[] = [
  BENEFICIARY_FULL_NAME,
  BENEFICIARY_EMAIL,
];

export const INVOICE_COLUMNS: Column[] = [
  INVOICE_DATE,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_AMOUNT,
  SUBSCRIPTION_STATUS,
];

export const BANK_ACCOUNT_DOCUMENTS: Column[] = [
  DOCUMENT_TITLE,
  DOCUMENT_ID,
  DOCUMENT_VERSION,
  DOCUMENT_ACCEPTED,
];

export const RECIPIENTS_COLUMNS: Column[] = [
  RECIPIENT_NAME,
  RECIPIENT_EMAIL,
  RECIPIENT_ACCOUNT,
];

export const RECIPIENTS_MOVE_MONEY_COLUMNS: Column[] = [
  {
    ...RECIPIENT_NAME,
    isSortable: false,
  },
  RECIPIENT_ACCOUNT,
  RECIPIENT_EMAIL,
];

export const RECIPIENT_TRANSACTIONS_COLUMNS: Column[] = [
  {
    ...CREATED_AT,
    label: 'label.createdAt',
    render: ({ item }: RenderParams): string => (
      item?.createdAt
        ? formatDate(item?.createdAt, DateFormats.longDate)
        : NOT_APPLICABLE
    ),
  },
  MOVE_FROM,
  TRANSACTION_REASON,
  SIMPLE_TRANSACTION_AMOUNT,
  {
    ...TRANSACTION_STATUS,
    name: 'latestStatus',
  },
];

export const COMMON_APPROVAL_TRANSACTIONS_COLUMNS: Column[] = [
  {
    ...TRANSACTION_CREATED_AT,
    isSortable: true,
  },
  APPROVAL_TRANSACTION_CREATED_BY,
  SIMPLE_TRANSACTION_AMOUNT,
  MOVE_FROM,
  MOVE_TO,
  TRANSACTION_APPROVAL_REASON,
];

export const PENDING_APPROVAL_TRANSACTIONS_COLUMNS: Column[] = [
  ...COMMON_APPROVAL_TRANSACTIONS_COLUMNS,
  APPROVAL_TRANSACTION_ACTIONS,
];

export const REJECTED_APPROVAL_TRANSACTIONS_COLUMNS: Column[] = [
  ...COMMON_APPROVAL_TRANSACTIONS_COLUMNS,
  APPROVAL_TRANSACTION_REJECTED_BY,
];

export const FAILED_APPROVAL_TRANSACTIONS_COLUMNS: Column[] = [
  ...COMMON_APPROVAL_TRANSACTIONS_COLUMNS,
  APPROVAL_TRANSACTION_FAILED_REASON,
];

export const APPROVED_APPROVAL_TRANSACTIONS_COLUMNS: Column[] = [
  ...COMMON_APPROVAL_TRANSACTIONS_COLUMNS,
  APPROVAL_TRANSACTION_APPROVED_BY,
];

export const REVIEW_APPROVAL_TRANSACTIONS_COLUMNS: Column[] = [
  APPROVAL_TRANSACTION_CREATED_BY,
  REVIEW_TRANSACTION_AMOUNT,
  REVIEW_MOVE_FROM,
  REVIEW_MOVE_TO,
];

export const CONTACTS_COLUMNS: Column[] = [
  {
    ...USER_NAME,
    name: 'firstName',
    label: 'label.contactName',
  },
  {
    ...USER_MOBILE_NUMBER,
    isSortable: true,
  },
  USER_ID,
];

export const TRANSACTIONS_ACTIVITY_COLUMNS: Column[] = [
  {
    ...CREATED_AT,
    label: 'label.createdAt',
    render: ({ item }: RenderParams): string => (
      item?.createdAt
        ? formatStringDate(item?.createdAt, DateFormats.longDate)
        : NOT_APPLICABLE
    ),
  },
  MOVE_FROM,
  MOVE_TO,
  {
    ...TRANSACTION_AMOUNT,
    cellClassName: 'alignRight justifyCenter',
    render: ({ item } : RenderParams) : ReactElement | string => {
      const transaction = item as TransactionSearch;
      return (
        <Typography variant="body2" color={getTransactionPriceColor(transaction?.trnType)}>
          {formatTablePrice({ amount: +transaction?.amount, currency: transaction?.currency })}
        </Typography>
      );
    },
  },
  {
    ...TRANSACTION_STATUS,
    name: 'latestStatus',
  },
];

export const ADVANCED_TRANSACTION_SEARCH_COLUMNS: Column[] = [
  {
    ...CREATED_AT,
    label: 'label.createdAt',
  },
  {
    ...MOVE_FROM,
    label: 'label.account',
    render: ({ item }: RenderParams): ReactElement => (
      <FinancialAccountDetails financialBankAccount={item.transactionFa} />
    ),
  },
  TRANSACTION_MEMO,
  {
    ...TRANSACTION_AMOUNT,
    render: ({ item } : RenderParams) : ReactElement | string => {
      const transaction = item as Transaction;
      const isCredit = transaction?.trnType === TransactionType.credit;
      const IconComponent = isCredit ? DepositArrowIcon : WithdrawArrowIcon;
      const color = isCredit ? 'success.main' : 'textPrimary';

      return (
        <Box display="flex" alignItems="center" color={color} gap={2.5} height="100%">
          <Typography variant="body2">
            {formatTablePrice({
              amount: isCredit ? transaction?.amount : transaction?.amount * (-1),
              currency: transaction.currencyCode,
            })}
          </Typography>
          <IconComponent size={20} />
        </Box>
      );
    },
  },
];

export const CASHFLOW_COLUMNS: Column[] = [
  {
    ...CREATED_AT,
    label: 'label.createdAt',
  },
  {
    ...MOVE_FROM,
    label: 'label.account',
    render: ({ item }: RenderParams): ReactElement => (
      <FinancialAccountDetails financialBankAccount={item.transactionFa} />
    ),
  },
  TRANSACTION_MEMO,
  TRANSACTION_AMOUNT,
];
