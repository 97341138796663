const MuiFormControlLabel = {
  styleOverrides: {
    root: {
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
  },
};

const MuiFormHelperText = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      marginLeft: 0,
      ...theme.typography.label1,
    }),
  },
};

const MuiFormLabel = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      '& .MuiTypography-root': {
        color: theme.palette.general.lightBlack,
      },
    }),
    asterisk: ({ theme }: any) => ({
      color: theme.palette.error.main,
    }),
  },
};

export {
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiFormLabel,
};
