import {
  Menu, Typography, Box,
} from '@mui/material';
import {
  FC, MouseEvent, ReactElement, ReactNode, useEffect, useState,
} from 'react';

import ChevronRightIcon from 'assets/icons/ChevronRightIcon';

import { MenuItem } from './index.styled';

interface SubMenuItemProps {
  children: ReactNode | ReactNode[];
  label: string | number | ReactElement;
  isParentOpen: boolean;
  isEmpty: boolean;
  disabled?: boolean;
}

export const SubMenuItem: FC<SubMenuItemProps> = ({
  children,
  label,
  isParentOpen,
  isEmpty,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!isParentOpen && isOpen) {
      setIsOpen(false);
      setAnchorElement(null);
    }
  }, [isParentOpen]);

  const handleClick = (event: MouseEvent<HTMLLIElement>) => {
    if (!anchorElement) {
      setAnchorElement(event.currentTarget);
    }

    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <MenuItem onClick={handleClick} isOpen={isOpen} disabled={disabled} sx={{ pr: 3 }}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="body2" noWrap>{label}</Typography>
          {!isEmpty && <ChevronRightIcon size={20} />}
        </Box>
      </MenuItem>
      {!isEmpty && (
        <Menu
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={Boolean(isOpen && isParentOpen)}
          anchorEl={anchorElement}
          onClose={handleClose}
          autoFocus={false}
          disableAutoFocus
          disableEscapeKeyDown
          disableEnforceFocus
        >
          {children}
        </Menu>
      )}
    </>
  );
};

export default SubMenuItem;
