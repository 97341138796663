import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

import EditIcon from 'assets/icons/EditIcon';

import { EditButton, TitleWrapper } from './index.styled';

interface EditHeaderWrapperProps {
  onClick: () => void;
  children: ReactNode[] | ReactNode;
  sx?: SxProps;
  canEdit?: boolean;
}

const EditHeaderWrapper: FC<EditHeaderWrapperProps> = ({
  onClick,
  children,
  sx,
  canEdit = false,
}) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" sx={sx}>
    <TitleWrapper>
      {children}
    </TitleWrapper>

    {canEdit && (
      <EditButton onClick={onClick}>
        <EditIcon />
      </EditButton>
    )}
  </Box>
);

export default EditHeaderWrapper;
