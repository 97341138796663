import { styled } from '@mui/material/styles';

import { SIDEBAR_WIDTH } from 'constants/general';

export const OutletWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(5),
  width: '100%',
  maxWidth: `calc(100% - ${theme.spacing(5)} - ${SIDEBAR_WIDTH.default}px)`,

  [theme.breakpoints.down('md')]: {
    maxWidth: `calc(100% - ${theme.spacing(5)} - ${SIDEBAR_WIDTH.small}px)`,
  },
}));
