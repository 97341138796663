import { styled } from '@mui/material/styles';

import { ACT_AS_BLOCK } from 'constants/general';

export const Root = styled('div')(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  padding: theme.spacing(4, 10),
  backgroundColor: theme.palette.general.lightBlack,
  color: theme.palette.common.white,
  height: `${ACT_AS_BLOCK}px`,
}));
