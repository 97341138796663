import { styled } from '@mui/material/styles';

import { NAVBAR_HEIGHT } from 'constants/general';

export const EmptyStateWrapper = styled('div')(() => ({
  height: `calc(100vh - ${NAVBAR_HEIGHT}px - 100px)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SearchWrapper = styled('div')(({ theme }) => ({
  width: 'fit-content',

  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
  },

  '& .MuiFormControl-root': {
    width: '900px',

    [theme.breakpoints.down('lg')]: {
      width: '600px',
    },

    [theme.breakpoints.down('md')]: {
      width: '400px',
    },
  },
}));
