import MuiCardMedia from '@mui/material/CardMedia';
import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import QuotesBg from 'assets/images/quotes-bg.svg';

export const Form = styled('form')(({ theme }) => ({
  '& > div, & > h1': {
    marginBottom: theme.spacing(5),
  },
  padding: theme.spacing(7),
}));

export const Header = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(7),
  margin: theme.spacing(-3, -3, 3),
  overflow: 'hidden',
  position: 'relative',
}));

export const Background = styled('div')(() => ({
  position: 'absolute',
  inset: 0,

  '&:before': {
    content: '""',
    backgroundImage: `url(${QuotesBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    width: '11rem',
    height: '12rem',
    backgroundPosition: 'right',
    bottom: '0',
    right: '-8px',
  },
}));

export const CardMedia = styled(MuiCardMedia)(() => ({
  objectFit: 'contain',
  display: 'inline-block',
  maxWidth: '200px',
  maxHeight: '42px',
})) as typeof MuiCardMedia;

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: theme.spacing(1),

  '& svg': {
    height: '20px',
    width: '20px',

    '& path': {
      fill: theme.palette.general.darkGrey1,
    },
  },
}));
