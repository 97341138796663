import {
  Box, Skeleton, SxProps, Typography,
} from '@mui/material';
import { FC, ReactNode } from 'react';

import { CustomTypographyVariants } from 'assets/themes/theme-declarations';

interface PageHeadingProps {
  title: string;
  isLoading?: boolean;
  mb?: number;
  sx?: SxProps;
  children?: ReactNode | ReactNode[];
  variant?: CustomTypographyVariants;
}

const PageHeading:FC<PageHeadingProps> = ({
  title,
  mb = 5,
  children,
  sx,
  isLoading = false,
  variant = 'h2',
}) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    mb={mb}
    sx={{ ml: 6, mt: 5, ...sx }}
  >
    {isLoading
      ? <Skeleton animation="wave" variant="text" height={30} width="50%" sx={{ mt: 1 }} />
      : <Typography variant={variant} fontWeight={700}>{title}</Typography>}
    {children}
  </Box>
);

export default PageHeading;
