import { FC, ReactElement } from 'react';

import { LinkWrapper } from 'components/UnstyledLink/index.styled';

interface UnstyledLinkProps {
  to: string;
  id: string;
  className?: string;
  children: string|ReactElement;
}

const UnstyledLink:FC<UnstyledLinkProps> = ({
  to,
  children,
  className = '',
  id,
}) => (
  <LinkWrapper to={to} className={className} id={id}>
    {children}
  </LinkWrapper>
);

export default UnstyledLink;
