import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { CustomTypographyVariants } from 'assets/themes/theme-declarations';
import { NOT_APPLICABLE } from 'constants/general';
import { AUTHORITY_AS_LABELS } from 'constants/labels';
import { Authority } from 'models/authority.interface';

interface UserRolesProps {
  roles: Authority[];
  fontWeight?: number;
  variant?: CustomTypographyVariants;
}

const UserRoles: FC<UserRolesProps> = ({ roles, fontWeight = 600, variant = 'body1' }) => {
  const intl = useIntl();

  const formatUserRoles = () => (
    roles
      .map((item) => {
        const labelId = AUTHORITY_AS_LABELS[item.authority];
        return labelId ? intl.formatMessage({ id: labelId }) : NOT_APPLICABLE;
      })
      .join(', ')
  );

  return (
    <Box display="flex" alignItems="center">
      <Typography variant={variant} fontWeight={fontWeight} color="primary.main">
        {formatUserRoles()}
      </Typography>
    </Box>
  );
};

export default UserRoles;
