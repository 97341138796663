import { Box, MenuItem, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import { useIntl } from 'react-intl';

import EmptyState from 'components/EmptyState';
import SearchField from 'fields/SearchField';

import { useDebounce } from 'hooks/useDebounce';

import EmptySearch from 'assets/images/empty-search.svg';
import { GenericOption } from 'models/option.interface';
import { isPartialMatch } from 'utils/sort';

import { MenuWrapper } from './index.styled';

interface SearchableMenuProps {
  options: GenericOption[];
  onSelect: (value: GenericOption) => void;
  searchPlaceholder?: string;
  placeholder?: string;
  maxHeight?: string;
  minWidth?: string;
}

const SearchableMenu: FC<SearchableMenuProps> = ({
  options,
  onSelect,
  searchPlaceholder = '',
  placeholder = '',
  maxHeight = '450px',
  minWidth = '350px',
}) => {
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<GenericOption[]>([]);
  const debounceSearch = useDebounce([search], 1000);

  const handleChangeSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    setSearch(value);
  };

  const handleClearSearch = () => setSearch('');

  useEffect(() => {
    if (options?.length > 0) {
      setFilteredOptions(options);
    }
  }, [options]);

  useEffect(() => {
    let newOptions = options;

    if (search) {
      newOptions = options.filter((option) => isPartialMatch(option.label, search));
    }

    if (!isEqual(newOptions, filteredOptions)) {
      setFilteredOptions(newOptions);
    }
  }, [debounceSearch]);

  const handleSelect = (value: any) => {
    setSearch('');
    onSelect(value);
  };

  return (
    <>
      <Box p={4} pb={2}>
        <SearchField
          name="search-searchable-menu"
          id="search-searchable-menu"
          search={search}
          onClearSearch={handleClearSearch}
          onChange={handleChangeSearch}
          onKeyDown={(e) => e.stopPropagation()}
          placeholder={searchPlaceholder ? intl.formatMessage({ id: searchPlaceholder }) : ''}
          sx={{
            backgroundColor: 'general.lightGrey8',
            borderRadius: '4px',
          }}
        />
      </Box>

      <MenuWrapper className="custom-scroll" maxHeight={maxHeight} minWidth={minWidth}>
        {placeholder && !(debounceSearch.length && !filteredOptions.length) && (
          <MenuItem disabled value="">
            {intl.formatMessage({ id: placeholder })}
          </MenuItem>
        )}

        {filteredOptions?.map((option, key: number) => (
          <MenuItem
            key={`searchable-item-${key}`}
            onClick={() => handleSelect(option)}
            disabled={option?.disabled || false}
            value={option?.value}
          >
            <Typography variant="body2">
              {option.formattedLabel || option.label}
            </Typography>
          </MenuItem>
        ))}

        {debounceSearch.length && !filteredOptions.length && (
          <Box py={5}>
            <EmptyState
              image={EmptySearch}
              subtitle={intl.formatMessage({ id: 'label.emptySearchResults' })}
              title={intl.formatMessage({ id: 'label.noResultsFound' })}
            />
          </Box>
        )}
      </MenuWrapper>
    </>
  );
};

export default SearchableMenu;
