import { SxProps } from '@mui/material';
import { FC } from 'react';

import { InitialWrapper } from 'components/CompanyInitial/index.styled';

interface CompanyInitialProps {
  initial: string;
  color?: 'primary' | 'secondary';
  size?: 'large' | 'medium' | 'small';
  sx?: SxProps;
}

const CompanyInitial:FC<CompanyInitialProps> = ({
  initial,
  color = 'primary',
  size = 'medium',
  sx = {},
}) => (
  <InitialWrapper color={color} size={size} sx={sx}>
    {initial}
  </InitialWrapper>
);

export default CompanyInitial;
