/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const MoneyBagIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 1C11.1151 1 10.3284 1.39067 9.7793 2H7L8 5H16L17 2H14.2207C13.6716 1.39067 12.8849 1 12 1ZM8 7C8 7 2 10.014 2 17C2 19.319 2.79709 20.917 4.12109 22H19.8789C21.2029 20.917 22 19.319 22 17C22 10.015 16 7 16 7H8ZM11.7383 8.71289H12.6582V10.041C12.9502 10.097 14.5898 10.4692 14.5898 12.8672H12.8652C12.8652 11.6142 12.3514 11.418 12.1094 11.418C11.9204 11.418 11.4219 11.4505 11.4219 12.4785C11.4209 14.2735 14.5762 13.7137 14.5762 16.6387C14.5762 18.7627 12.8324 19.0489 12.5234 19.0879V20.3008H11.6094V19.0879C11.3244 19.0489 9.41016 18.8382 9.41016 16.1992H11.1348C11.1348 17.7572 11.9385 17.6934 12.0605 17.6934C12.1945 17.6934 12.8535 17.6684 12.8535 16.6504C12.8535 14.6164 9.69727 15.5187 9.69727 12.4707C9.69727 10.3817 11.4293 10.0566 11.7383 10.0176V8.71289Z" fill={color} />
  </svg>
);

export default MoneyBagIcon;
