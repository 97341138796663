import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { ConfirmationTypes } from 'constants/enums';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  minWidth: '400px',
  minHeight: '200px',
  padding: theme.spacing(7, 9),
  borderRadius: 0,
  maxWidth: '900px !important',

  [theme.breakpoints.down('md')]: {
    maxWidth: '600px',
  },
}));

export const DialogTitle = styled(MuiDialogTitle)<{type: ConfirmationTypes}>(({ theme, type }) => ({
  padding: theme.spacing(0, 0, 3, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '& svg': {
    marginBottom: theme.spacing(4),
    '& path': {
      fill: type === ConfirmationTypes.Warning ? theme.palette.warning.secondary : '',
    },
  },
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
  minWidth: '23.625rem',
}));

interface DialogActionsProps {
  justifyContent: 'center' | 'space-between';
}

export const DialogActions = styled(
  MuiDialogActions,
  { shouldForwardProp: (props) => props !== 'justifyContent' },
)<DialogActionsProps>(({ theme, justifyContent }) => ({
  padding: theme.spacing(5, 0, 0),
  justifyContent,

  '& .success': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .error': {
    backgroundColor: theme.palette.error.main,
  },
  '& .warning': {
    backgroundColor: theme.palette.warning.secondary,
  },

  '& .Mui-disabled': {
    '& .error': {
      backgroundColor: theme.palette.error.main,
    },
  },
}));
