/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ResendIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.95459 9.61044L20.1364 3L16.8428 12.0589C16.5671 12.0201 16.2854 12 15.999 12C12.7975 12 10.1818 14.5072 10.0082 17.6653L8.58101 16.2386L16.5 6.63636L6.89777 14.5554L1.95459 9.61044Z" fill={color} />
    <path d="M15.999 12.75C13.1039 12.75 10.749 15.1055 10.749 18H11.9157C11.9157 15.7483 13.7479 13.9167 15.999 13.9167C17.2737 13.9167 18.4054 14.5098 19.1538 15.4285L17.749 16.8333H21.249V13.3333L19.9855 14.5968C19.0235 13.4685 17.5953 12.75 15.999 12.75ZM20.0824 18C20.0824 20.2511 18.2501 22.0833 15.999 22.0833C14.7244 22.0833 13.5927 21.4902 12.8442 20.5715L14.249 19.1667H10.749V22.6667L12.0125 21.4032C12.9745 22.5315 14.4028 23.25 15.999 23.25C18.8941 23.25 21.249 20.8951 21.249 18H20.0824Z" fill={color} />
  </svg>

);

export default ResendIcon;
