
const MuiIconButton = {
  styleOverrides: {
    root: {
      '&:disabled': {
        opacity: 0.35,
      },
    },
    colorError: ({ theme }: any) => ({
      '&:disabled': {
        color: theme.palette.error.main,
      },
    }),
  },
};

export default MuiIconButton;
