import { styled } from '@mui/material/styles';
import { ToastContainer as Toast } from 'react-toastify';

import { NAVBAR_HEIGHT } from 'constants/general';

const ToastContainer = styled(Toast)(() => ({
  '& .Toastify__toast': {
    borderRadius: '8px',
    boxShadow: '2px 4px 12px rgba(103, 132, 136, 0.1)',
    marginBottom: '8px',
    minHeight: '48px',
    width: 'auto',
  },
  '&.Toastify__toast-container--top-right': {
    top: `${NAVBAR_HEIGHT + 16}px`,
    right: '36px',
  },
  '& .Toastify__toast-theme--light': {
    color: '#000',
  },
  '& .Toastify__close-button--light': {
    opacity: 1,
    color: '#757575',
  },
}));

export default ToastContainer;
