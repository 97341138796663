/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const InsightIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 8.83343C21.9984 9.31905 21.8064 9.78433 21.4659 10.1277C21.1253 10.4711 20.6639 10.6647 20.1822 10.6663C20.0254 10.6688 19.8691 10.6472 19.7187 10.6022L16.482 13.8574C16.5264 14.0116 16.5478 14.1715 16.5456 14.3319C16.5456 14.8182 16.354 15.2845 16.013 15.6284C15.672 15.9722 15.2095 16.1654 14.7272 16.1654C14.245 16.1654 13.7825 15.9722 13.4415 15.6284C13.1005 15.2845 12.9089 14.8182 12.9089 14.3319C12.9066 14.1715 12.928 14.0116 12.9724 13.8574L10.6546 11.5204C10.3465 11.6052 10.0215 11.6052 9.7134 11.5204L5.57198 15.6998C5.61657 15.8514 5.63799 16.009 5.63551 16.1672C5.63551 16.5297 5.5289 16.884 5.32916 17.1854C5.12943 17.4868 4.84553 17.7218 4.51338 17.8605C4.18123 17.9992 3.81574 18.0355 3.46313 17.9648C3.11052 17.8941 2.78663 17.7195 2.53241 17.4632C2.27819 17.2068 2.10507 16.8803 2.03493 16.5247C1.96479 16.1692 2.00079 15.8007 2.13837 15.4658C2.27595 15.1309 2.50894 14.8446 2.80786 14.6432C3.10679 14.4418 3.45824 14.3343 3.81775 14.3343C3.9746 14.3318 4.13091 14.3534 4.28131 14.3984L8.42626 10.2273C8.38188 10.0732 8.36047 9.91329 8.36273 9.75282C8.36273 9.26656 8.5543 8.80022 8.89531 8.45638C9.23631 8.11255 9.69882 7.91938 10.1811 7.91938C10.6633 7.91938 11.1258 8.11255 11.4668 8.45638C11.8078 8.80022 11.9994 9.26656 11.9994 9.75282C12.0017 9.91329 11.9803 10.0732 11.9359 10.2273L14.2537 12.5644C14.5618 12.4796 14.8868 12.4796 15.1949 12.5644L18.4221 9.30084C18.3776 9.14919 18.3561 8.99158 18.3586 8.83343C18.3586 8.34718 18.5502 7.88083 18.8912 7.537C19.2322 7.19316 19.6947 7 20.177 7C20.6592 7 21.1217 7.19316 21.4627 7.537C21.8037 7.88083 21.9953 8.34718 21.9953 8.83343H22Z" fill={color} />
  </svg>
);

export default InsightIcon;
