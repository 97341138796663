import { z } from 'zod';

import { DateType } from 'constants/enums';

export const cashflowSchema = z.object({
  dateRange: z.object({
    type: z.string().optional(),
    from: z.number().nullable().optional(),
    to: z.number().nullable().optional(),
  })
    .refine((values) => {
      const { from, to, type } = values;

      if (type !== DateType.custom) {
        return true;
      }

      if (from && to && from > to) {
        return false;
      }

      return true;
    }, {
      message: 'error.dateRange',
    })
    .refine((values) => {
      const { from, to, type } = values;
      const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime();
      const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime();

      if (type !== DateType.custom) {
        return true;
      }

      if (from && (from < minDate || from > maxDate)) {
        return false;
      }

      if (to && (to < minDate || to > maxDate)) {
        return false;
      }

      return true;
    }, {
      message: 'error.selectedTimeframe.oneYear',
    }),
});

export type CashflowSchema = z.infer<typeof cashflowSchema>;
