import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { FC, ReactElement } from 'react';

import { Popper } from './index.styled';

interface ChartTooltipProps extends TooltipProps{
  title: ReactElement;
  children: ReactElement;
}

const ChartTooltip: FC<ChartTooltipProps> = ({
  title,
  children,
  ...remainingProps
}) => (
  <Tooltip
    {...remainingProps}
    title={title}
    PopperComponent={Popper}
  >
    {children}
  </Tooltip>
);

export default ChartTooltip;
