import MuiDivider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

export const Divider = styled(MuiDivider)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  height: '24px',
  width: '1px',
}));

export const BankImg = styled('img')(() => ({
  height: '16px',
  width: '16px',
  borderRadius: '2px',
}));
