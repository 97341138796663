
const MuiSwitch = {
  styleOverrides: {
    root: {
      width: '46px',
      height: '24px',
      padding: 0,
    },

    switchBase: ({ theme }: any) => ({
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',

      '&.Mui-checked': {
        transform: 'translateX(22px)',
        color: theme.palette.common.white,

        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 0,
        },

        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },

      '&.Mui-disabled .MuiSwitch-thumb': {
        color: 'red',
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    }),

    thumb: ({ theme }: any) => ({
      width: 20,
      height: 20,
      backgroundColor: theme.palette.general.lightGrey3,
    }),

    track: ({ theme }: any) => ({
      borderRadius: 24 / 2,
      backgroundColor: theme.palette.general.lightGrey7,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    }),
  },
};

export default MuiSwitch;
