import {
  FormControl, FormControlLabel, FormHelperText,
} from '@mui/material';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import {
  ChangeEvent, FC, MouseEvent, ReactNode,
} from 'react';

import { StyledWrapper, Checkbox } from 'fields/CheckboxField/index.styled';

interface CheckboxFieldProps {
  fullWidth?: boolean;
  label?: string;
  onChange?: (name: string, value: boolean) => unknown;
  checked?: boolean;
  name: string;
  id?: string;
  disabled?: boolean;
  sx?: object;
  size?: 'small' | 'medium';
  error?: boolean;
  helperText?: string | ReactNode;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  inputProps?: SwitchBaseProps['inputProps'];
  className?: string;
  required?: boolean;
}

const CheckboxField :FC<CheckboxFieldProps> = (props) => {
  const {
    label,
    fullWidth = false,
    checked,
    disabled,
    size,
    sx,
    name,
    error,
    helperText,
    onChange,
    labelPlacement = 'end',
    inputProps = {},
    id,
    className = '',
    required = false,
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(name, event?.target?.checked);
    }
  };

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  return (
    <StyledWrapper hasError={error} className={className}>
      <FormControl component="div" fullWidth={fullWidth}>
        <FormControlLabel
          name={name}
          label={label}
          sx={sx}
          required={required}
          labelPlacement={labelPlacement}
          control={(
            <Checkbox
              inputProps={inputProps}
              id={id}
              disabled={disabled}
              size={size}
              checked={checked}
              onChange={handleChange}
              onClick={handleClick}
            />
          )}
        />
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </StyledWrapper>
  );
};

export default CheckboxField;
