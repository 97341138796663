/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const MailIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.74976 4.00078C2.78376 4.00078 1.99976 4.78478 1.99976 5.75078L11.9998 12.0008L21.9998 5.75078C21.9998 4.78478 21.2158 4.00078 20.2498 4.00078H3.74976ZM1.99976 7.75273V18.0008C1.99976 19.1058 2.89476 20.0008 3.99976 20.0008H19.9998C21.1048 20.0008 21.9998 19.1058 21.9998 18.0008V7.75273L11.9998 14.0008L1.99976 7.75273Z" fill={color} />
  </svg>
);

export default MailIcon;
