import {
  runGetApiRequest,
  runGetBlobApiRequest,
  runPostApiRequest,
  runUpdateApiRequest,
} from 'services/Api';

import {
  GET_SUBSCRIPTION_AGREEMENT_API,
  GET_SUBSCRIPTION_API,
  SAVE_SUBSCRIPTION_API,
  UPDATE_SUBSCRIPTION_API,
} from 'constants/apiRoutes';
import { SubscriptionPayload } from 'models/subscriptionPayment.interface';

export const getSubscription = async () => (
  runGetApiRequest(GET_SUBSCRIPTION_API)
);

export const getSubscriptionAgreements = async () => (
  runGetBlobApiRequest(GET_SUBSCRIPTION_AGREEMENT_API)
);

export const saveSubscription = (data: SubscriptionPayload) => (
  runPostApiRequest(SAVE_SUBSCRIPTION_API, data)
);

export const updateSubscription = async (data: unknown) => (
  runUpdateApiRequest(UPDATE_SUBSCRIPTION_API, data)
);
