import { FC, ReactNode } from 'react';

import { ContentWrapper } from 'components/_Content/index.styled';

interface ContentProps {
  children: ReactNode;
}

const Content:FC<ContentProps> = ({ children }) => (
  <ContentWrapper>
    {children}
  </ContentWrapper>
);

export default Content;
