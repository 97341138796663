import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const Chip = styled(MuiChip)(({ theme }) => ({
  '& .MuiChip-label': {
    fontSize: '15px',
    fontWeight: 600,
    padding: theme.spacing(0, 3),
    color: theme.palette.text.secondary,
  },

  margin: theme.spacing(1),

  '& .MuiChip-deleteIcon svg path': {
    fill: theme.palette.text.secondary,
  },

  '&:hover': {
    '& .MuiChip-label': {
      color: theme.palette.text.primary,
    },

    backgroundColor: theme.palette.general.lightGrey4,

    '& .MuiChip-deleteIcon svg path': {
      fill: theme.palette.text.primary,
    },
  },
}));
