import { SxProps, TextFieldProps } from '@mui/material';
import {
  ChangeEvent, FC, KeyboardEvent, MouseEvent,
} from 'react';

import InputField from 'fields/InputField';

import OutlinedCloseIcon from 'assets/icons/OutlinedCloseIcon';
import SearchIcon from 'assets/icons/SearchIcon';

import {
  CloseAdornment,
  SearchAdornment,
} from './index.styled';

interface SearchFieldProps {
  name: string;
  search: string;
  onClearSearch?: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearch?: () => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  placeholder?: string;
  id: string;
  disabled?: boolean;
  sx?: SxProps;
}

export const SearchField: FC<TextFieldProps & SearchFieldProps> = ({
  onClearSearch,
  name,
  search,
  onChange,
  variant = 'outlined',
  onSearch = () => {},
  onClick = () => {},
  onKeyDown = () => {},
  placeholder,
  id,
  disabled = false,
  sx = {},
  InputProps,
  ...remainingProps
}) => (
  <InputField
    {...remainingProps}
    name={name}
    variant={variant}
    fullWidth
    onClick={onClick}
    InputProps={{
      startAdornment: (
        <SearchAdornment position="start" className="pointer" onClick={onSearch}>
          <SearchIcon size={24} />
        </SearchAdornment>
      ),
      endAdornment: search && onClearSearch && (
        <CloseAdornment position="start" onClick={onClearSearch} className="pointer">
          <OutlinedCloseIcon size={24} />
        </CloseAdornment>
      ),
      ...InputProps,
    }}
    value={search}
    onChange={onChange}
    placeholder={placeholder}
    onKeyDown={onKeyDown}
    id={id}
    disabled={disabled}
    sx={sx}
  />
);

export default SearchField;
