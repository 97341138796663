import {
  axisBottom, axisLeft, axisRight, axisTop, ScaleBand, ScaleLinear, select,
} from 'd3';
import { getMonth } from 'date-fns';

import { FlowActivityType, PositionType } from 'constants/enums';
import { ONE_BILLION, ONE_HUNDRED_THOUSAND, ONE_MILLION } from 'constants/general';
import { ChartData, ChartVolumeData } from 'models/charts.interface';
import { rightStringDateFormat } from 'utils/formatters';

export const getAxisBasedOnPosition = (position: PositionType) => {
  if (position === PositionType.top) {
    return axisTop;
  }

  if (position === PositionType.bottom) {
    return axisBottom;
  }

  if (position === PositionType.left) {
    return axisLeft;
  }

  return axisRight;
};

export const getRoundedBarDef = (
  value: any,
  label: any,
  scaleX: ScaleBand<any>,
  scaleY: ScaleLinear<any, any>,
  radius = 0,
  extraSpacing = 0,
  scaleXBandwidth = 0,
) => {
  if (value > 0) {
    return `
      M${(scaleX(label.toString()) || 0) + extraSpacing}, ${scaleY(Math.max(value, 0)) + radius}
      a${radius},${radius} 0 0 1 ${radius},${-radius}
      h${(scaleXBandwidth || scaleX.bandwidth()) - 2 * radius}
      a${radius},${radius} 0 0 1 ${radius},${radius}
      v${Math.abs(scaleY(value) - scaleY(0)) - radius}
      h${-(scaleXBandwidth || scaleX.bandwidth())}
      v${-(Math.abs(scaleY(value) - scaleY(0)) - radius)}
    `;
  }

  return `
    M${(scaleX(label.toString()) || 0) + extraSpacing}, ${scaleY(Math.max(value, 0))}
    a${0},${0} 0 0 1 ${0},${0}
    h${scaleXBandwidth || scaleX.bandwidth()}
    a${0},${0} 0 0 1 ${0},${0}
    v${Math.abs(scaleY(value) - scaleY(0)) - radius}
    a${radius},${radius} 0 0 1 ${-radius},${radius}
    h${-(scaleXBandwidth || scaleX.bandwidth()) + 2 * radius}
    a${radius},${radius} 0 0 1 ${-radius},${-radius}
    v${-(Math.abs(scaleY(value) - scaleY(0)) - radius)}
  `;
};

export const getNextValue = (currentValue: number): number => {
  const firstDigit = +currentValue.toString()[0];
  const digitsCount = Math.trunc(currentValue).toString().length;
  const valueToBeAdded = 10 ** (digitsCount - 1);
  const isOddNumber = firstDigit % 2 === 0;
  const flooredValue = firstDigit * 10 ** (digitsCount - 1);

  if (currentValue < 1000) {
    return isOddNumber
      ? flooredValue + valueToBeAdded * 2
      : flooredValue + valueToBeAdded;
  }

  return currentValue + (flooredValue / 4);
};

export const formatDateCharts = (chartData: ChartData[]) => chartData.map((data) => ({
  y: data?.amount,
  x: data?.date
    ? rightStringDateFormat(data?.date).getTime()
    : 0,
}));

export const formatDateVolumeCharts = (chartData: ChartVolumeData[]) => chartData.map((data) => ({
  y: data?.count,
  x: data?.date
    ? rightStringDateFormat(data?.date).getTime()
    : 0,
}));

export const getLabelBasedOnFilter = (flowType: FlowActivityType) => {
  const isInflow = flowType === FlowActivityType.inflow;

  return {
    titleLabel: isInflow ? 'label.7daysCredit' : 'label.7daysDebit',
    prevAmountLabel: 'label.vsPrevious7Days',
    legendCurrentTimeLabel: 'label.this7Days',
    legendPrevTimeLabel: 'label.previous7Days',
  };
};

export const getRightAxisMargin = (chartMaxValue: number) => {
  if (chartMaxValue > (ONE_BILLION / 10) && chartMaxValue < ONE_BILLION * 3) {
    return 85;
  }

  if (chartMaxValue > (ONE_MILLION / 10) && chartMaxValue < ONE_MILLION * 3) {
    return 70;
  }

  if (chartMaxValue > (ONE_HUNDRED_THOUSAND / 10) && chartMaxValue < ONE_HUNDRED_THOUSAND * 3) {
    return 55;
  }

  return 40;
};

export const formatChartNextMinValue = (value: number) => {
  const nextValue = getNextValue(Math.abs(value)) || 0;
  return value < 0 ? -nextValue : nextValue;
};

export const drawGradient = (chartId: string, gradientId: string, gradiant: { offset: string; color: string }[]) => {
  select(chartId)
    .append('linearGradient')
    .attr('id', gradientId)
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '0%')
    .attr('y2', '100%')
    .selectAll('stop')
    .data(gradiant)
    .enter()
    .append('stop')
    .attr('offset', (d) => d.offset)
    .attr('stop-color', (d) => d.color);
};

export const makeYLines = (scaleY: any, width: number) => (
  axisLeft(scaleY)
    .tickValues(scaleY.ticks(5))
    .tickSize(-width + 16)
    .tickPadding(10)
    .tickSizeOuter(0)
    .scale(scaleY)
);

export const drawGrid = (ref: SVGGElement, color: string, scaleY: any, width: number) => (
  select(ref)
    .attr('class', 'grid')
    .style('color', color)
    .style('stroke-dasharray', '4 4')
    .attr('stroke-width', '0.5px')
    .attr('transform', 'translate(4, 0)')
    .call(makeYLines(scaleY, width))
    .call((g) => (
      g
        .select('.domain')
        .remove()
    ))
);

export const drawHoverLine = (id: string, strokeColor: string) => {
  select(id)
    .select('.placeholder-line-bars')
    .append('line')
    .style('fill', 'none')
    .style('stroke', strokeColor)
    .style('stroke-width', 1)
    .style('stroke-dasharray', '2, 2')
    .style('opacity', 0);
};

export const getPaddingInner = (count: number) => {
  if (count <= 3) {
    return 0.8;
  }

  if (count < 5) {
    return 0.7;
  }

  return 0.5;
};

export const getUniqueMonthsFromTimestamps = (timestamps: string[]) => {
  if (!timestamps || timestamps?.length < 2) {
    return [];
  }

  let currentMonth = getMonth(+timestamps[0]);
  const months = [timestamps[0]];

  timestamps.forEach((timestamp) => {
    const month = getMonth(+timestamp);

    if (month !== currentMonth) {
      months.push(timestamp);
      currentMonth = month;
    }
  });

  return months;
};

export const getChartColor = ({
  hasData = false,
  isPositive = false,
  useSecondaryColors = false,
}) => {
  const positiveColors = useSecondaryColors ? 'success.secondary' : 'success.main';

  if (!hasData) {
    return 'general.lightGrey10';
  }

  return isPositive ? positiveColors : 'error.main';
};

export const getChartLabelColor = ({
  hasData = false,
  isPositive = false,
  useSecondaryColors = false,
}) => {
  const positiveColors = useSecondaryColors ? 'success.secondary' : 'general.darkGreen6';

  if (!hasData) {
    return 'general.lightGrey6';
  }

  return isPositive ? positiveColors : 'general.darkRed1';
};
