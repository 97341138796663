/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const TransactionIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 2C19 2.552 18.552 3 18 3C17.448 3 17 2.552 17 2H15C15 2.552 14.552 3 14 3C13.448 3 13 2.552 13 2H11C11 2.552 10.552 3 10 3C9.448 3 9 2.552 9 2H7C7 2.552 6.552 3 6 3C5.448 3 5 2.552 5 2H3V22H5C5 21.448 5.448 21 6 21C6.552 21 7 21.448 7 22H9C9 21.448 9.448 21 10 21C10.552 21 11 21.448 11 22H13C13 21.448 13.448 21 14 21C14.552 21 15 21.448 15 22H17C17 21.448 17.448 21 18 21C18.552 21 19 21.448 19 22H21V2H19ZM18 17H6V15H18V17ZM18 13H6V11H18V13ZM18 9H6V7H18V9Z" fill={color} />
  </svg>
);

export default TransactionIcon;
