import {
  ReactElement, useState, MouseEvent, FC,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { AvatarWrapper, UserLabel } from 'components/_Navbar/User/index.styled';
import UserAvatar from 'components/UserAvatar';
import UserMenu from 'components/UserMenu';

import { useLogout } from 'hooks/useFirebase';
import { useAppSelector } from 'hooks/useRedux';
import { userSelector } from 'store/user/selectors';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import { USER_PROFILE_ROUTE } from 'constants/clientRoutes';
import { formatUserInitials, formatUserName } from 'utils/formatters';

interface UserProps {
  isMini?: boolean;
}

const User:FC<UserProps> = ({ isMini = false }): ReactElement => {
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);
  const { logout } = useLogout();
  const hasUserName = !!(user?.firstName || user?.lastName);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleProfileRedirect = (): void => {
    navigate(USER_PROFILE_ROUTE);
    handleClose();
  };

  return (
    <>
      <AvatarWrapper onClick={handleOpenMenu} isActive={Boolean(anchorEl)}>
        <UserAvatar userInitials={formatUserInitials(user)} src={user?.photoUrl || ''} />

        {!isMini && (
          <>
            <UserLabel variant="body2" ml={4} className="text-ellipsis">
              {hasUserName ? formatUserName(user) : user?.email}
            </UserLabel>
            <ChevronDownIcon size={20} />
          </>
        )}
      </AvatarWrapper>
      <UserMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        onLogout={logout}
        onProfileRedirect={handleProfileRedirect}
      />
    </>
  );
};

export default User;
