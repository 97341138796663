import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';

import CheckIcon from 'assets/icons/CheckIcon';
import { NOT_APPLICABLE } from 'constants/general';
import { AccountAgreement } from 'models/accountAgreement.interface';

interface AcceptedAgreementColumnProps {
  agreement: AccountAgreement;
}

const AcceptedAgreementColumn: FC<AcceptedAgreementColumnProps> = ({
  agreement,
}) => {
  const theme = useTheme();

  return (
    agreement?.accepted
      ? <CheckIcon color={theme.palette.success.main} />
      : (
        <Typography variant="body1" color="general.lightGrey6" my={0.5}>
          {NOT_APPLICABLE}
        </Typography>
      )
  );
};

export default AcceptedAgreementColumn;
