import MuiInputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

export const InputAdornment = styled(MuiInputAdornment)(({ theme }) => ({
  '& svg path': {
    fill: theme.palette.general.darkGrey,
  },
}));

export const Root = styled('div')(({ theme }) => ({
  '& .errorSpacing': {
    paddingBottom: theme.spacing(5),
  },

  '& .checkboxLabel': {
    '& .MuiTypography-root': {
      textTransform: 'lowercase',
    },
  },

  '& .MuiFormControlLabel-label': {
    textTransform: 'lowercase',
  },

  '& .amountValue': {
    width: '183px',
    '& .MuiInputBase-input': {
      paddingLeft: 0,
    },
  },

  '& .descriptionCheckbox': {
    marginTop: theme.spacing(3.5),
    alignItems: 'flex-start',
  },
}));
