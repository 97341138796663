import { RootState } from 'store/index';

import { ApiStatus } from 'constants/enums';
import { RulesManagementFilter } from 'models/filter.interface';
import { Pagination } from 'models/pagination.interface';
import { CreateRule } from 'models/rule.interface';
import { SortOptions } from 'models/sortOptions.interface';

export const rulesSelector = (state: RootState):CreateRule[] => state.ruleManagement?.rules?.data;
export const paginationSelector = (state: RootState):Pagination => state.ruleManagement.pagination;
export const archivedSelector = (state: RootState):boolean => state.ruleManagement.archived;
export const sortOptionsSelector = (state: RootState):SortOptions => state.ruleManagement.sortOptions;
export const searchSelector = (state: RootState):string => state.ruleManagement.search;
export const filterSelector = (state: RootState):RulesManagementFilter => state.ruleManagement.filter;
export const isLoadingSelector = (state: RootState): boolean => (
  state.ruleManagement?.rules?.status === ApiStatus.loading
);
