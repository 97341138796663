import { Collapse, TableCell } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Column } from 'models/column.interface';

import { TableRow } from './index.styled';

interface ResponsiveCollapsableRowProps {
  id: string;
  indexRow: number;
  colSpan: number;
  children: ReactNode;
  column: Column;
}

export const ResponsiveCollapsableRow: FC<ResponsiveCollapsableRowProps> = ({
  id,
  indexRow,
  colSpan,
  column,
  children,
}) => (
  <TableRow id={`collapsable-tableRow-${id}-${indexRow}`} className="collapsableRow">
    <TableCell colSpan={colSpan} sx={column?.sx}>
      <Collapse in>
        {children}
      </Collapse>
    </TableCell>
  </TableRow>
);

export default ResponsiveCollapsableRow;
