import MuiButton from '@mui/material/Button';
import MuiButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';

export const ButtonGroup = styled(MuiButtonGroup)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '4px',
  boxShadow: 'none',
  backgroundColor: theme.palette.background.main,
  gap: theme.spacing(1),

  '& .MuiButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: '4px',
    border: 0,
  },

  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: '4px',
    border: 0,
  },

  '& .activeButton': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },

  '& .Mui-disabled:not(.activeButton)': {
    backgroundColor: 'transparent !important',
    color: theme.palette.text.primary,
  },

  '& .Mui-disabled.activeButton': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

export const Button = styled(MuiButton)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderRadius: '4px',
  backgroundColor: theme.palette.background.main,
  color: theme.palette.general.darkGrey1,
  fontWeight: 600,

  '&:hover': {
    backgroundColor: theme.palette.general.lightGrey4,
    color: theme.palette.text.primary,
  },
}));
