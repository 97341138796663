import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { FC, type ReactElement, useMemo } from 'react';

import { useAppSelector } from 'hooks/useRedux';
import { userSelector, viewAsSelector } from 'store/user/selectors';

import getTheme from 'assets/themes/theme';
import { isLoggedAsOperator } from 'utils/roles';

interface AppThemeProps {
  children: ReactElement[];
}

const AppTheme: FC<AppThemeProps> = ({ children }): ReactElement => {
  const user = useAppSelector(userSelector);
  const viewAs = useAppSelector(viewAsSelector);
  const isOperator = !!user && isLoggedAsOperator(user, viewAs);
  const theme = useMemo(() => getTheme(isOperator), [user, viewAs]);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default AppTheme;
