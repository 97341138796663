/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const BusinessmanIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3909 17.6632C19.5909 18.1905 19.5127 18.7829 19.1826 19.2476C18.8474 19.7189 18.2981 20 17.713 20H13.2725L12.3966 15.8095L12.8832 14.7619C12.6391 14.699 12.3966 14.6667 12.1046 14.6667C11.8127 14.6667 11.5775 14.6975 11.3261 14.7619L11.8127 15.8095L10.9368 20H6.28679C5.7013 20 5.15202 19.7185 4.81724 19.2472C4.48674 18.7825 4.40927 18.1905 4.60936 17.6629C5.62189 14.992 8.59173 13.197 11.9999 13.197C15.4081 13.1973 18.3783 14.992 19.3909 17.6632ZM12.1046 12C9.95812 12 8.21179 10.2057 8.21179 8C8.21179 5.79429 9.95812 4 12.1046 4C14.2511 4 15.9975 5.79429 15.9975 8C15.9975 10.2057 14.2511 12 12.1046 12Z" fill={color} />
  </svg>
);

export default BusinessmanIcon;
