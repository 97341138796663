import { Button, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Page } from 'components/_NotFound/index.styled';
import UnstyledLink from 'components/UnstyledLink';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { DASHBOARD_ROUTE } from 'constants/clientRoutes';

const NotFound = (): ReactElement => {
  const intl = useIntl();

  useDocumentTitle({ title: 'pageTitle.notFound' });

  return (
    <Page id="error-page">
      <Typography variant="h1">
        404
      </Typography>
      <Typography variant="body1" fontWeight={500}>
        {intl.formatMessage({ id: 'label.404title' })}
      </Typography>

      <Button variant="contained" sx={{ my: 5 }}>
        <UnstyledLink to={DASHBOARD_ROUTE} id="link-goToDashboard">
          {intl.formatMessage({ id: 'button.goToDashboard' })}
        </UnstyledLink>
      </Button>
    </Page>
  );
};

export default NotFound;
