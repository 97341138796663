import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCustomers } from 'services/CustomersService';
import { resetStore } from 'store/actions';

import { ApiStatus } from 'constants/enums';
import { Customer } from 'models/customer.interface';
import { Pagination } from 'models/pagination.interface';
import { handleApiErrors } from 'utils/errorUtils';

export interface CustomersState {
  users: {
    data: Customer[] | null;
    status: ApiStatus;
  };
  pagination: Pagination;
}

const initialState: CustomersState = {
  users: {
    data: null,
    status: ApiStatus.idle,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
    page: 1,
  },
};

// TODO: change this with the correct API
export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  async ({ page, sort, search }: { page: number; sort: string; search?: string }, thunkAPI) => {
    try {
      return await getCustomers();
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    updatePagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
      return state;
    },
    resetState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchCustomers.pending, (state) => {
        state.users = {
          ...state.users,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        const {
          content, totalPages, totalElements, pageable,
        } = action.payload || {};
        const page = pageable?.pageNumber || 0;

        state.users = {
          ...state.users,
          status: ApiStatus.idle,
          data: content,
        };
        state.pagination = {
          totalPages,
          totalElements,
          page: page + 1,
        };
      })
      .addCase(fetchCustomers.rejected, (state) => {
        state.users = {
          ...state.users,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  resetState,
  updatePagination,
} = customersSlice.actions;

export default customersSlice.reducer;
