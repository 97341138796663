import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/index';

import { ApiStatus, BalanceTrend } from 'constants/enums';
import { Pagination } from 'models/pagination.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { TransactionFilterSchema } from 'utils/validation/transactionFormSchema';

export const accountDetailsSelector = createSelector(
  (state: RootState) => state.bankAccountDetails.details,
  (bankAccountDetails) => ({
    ...bankAccountDetails,
    isLoading: bankAccountDetails.status === ApiStatus.loading,
  }),
);
export const requiresRelinkSelector = (state: RootState):boolean => state.bankAccountDetails.requiresRelink;
export const searchSelector = (state: RootState): string => state.bankAccountDetails.search;
export const transactionsSelector = createSelector(
  (state: RootState) => state.bankAccountDetails.transactions,
  (transactions) => ({
    ...transactions,
    isLoading: transactions.status === ApiStatus.loading,
  }),
);
export const activityChartSelector = createSelector(
  (state: RootState) => state.bankAccountDetails.activityChart,
  (charts) => ({
    ...charts,
    isLoading: charts.status === ApiStatus.loading,
  }),
);
export const inflowOutflowChartsSelector = createSelector(
  (state: RootState) => state.bankAccountDetails.inflowOutflowCharts,
  (charts) => ({
    ...charts,
    isLoading: charts.status === ApiStatus.loading,
  }),
);
export const transactionsFilterSelector = (state: RootState):TransactionFilterSchema => (
  state.bankAccountDetails.transactionsFilters
);
export const archivedSelector = (state: RootState):boolean => (
  state.bankAccountDetails.archived
);
export const transactionsSortSelector = (state: RootState):SortOptions => (
  state.bankAccountDetails.transactions.sortOptions
);
export const chartFilterSelector = (state: RootState):{ trend: BalanceTrend } => (
  state.bankAccountDetails.chartFilters
);
export const paginationSelector = (state: RootState):Pagination => state.bankAccountDetails.pagination;
export const accountTopUpFundingSelector = createSelector(
  (state: RootState) => state.bankAccountDetails.topUpFunding,
  (topUpFunding) => ({
    ...topUpFunding,
    isLoading: topUpFunding.status === ApiStatus.loading,
    isDialogOpen: topUpFunding.isDialogOpen,
  }),
);
export const isTopUpFundingDialogOpenSelector = (state: RootState) => (
  state.bankAccountDetails.topUpFunding?.isDialogOpen
);
