/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const NotificationIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9996 22C12.2997 21.9999 12.5969 21.9384 12.8741 21.8189C13.1513 21.6995 13.4032 21.5245 13.6154 21.3039C13.8275 21.0833 13.9958 20.8215 14.1105 20.5333C14.2253 20.2452 14.2843 19.9364 14.2842 19.6245H9.71502C9.71491 19.9364 9.77393 20.2452 9.88868 20.5333C10.0034 20.8215 10.1717 21.0833 10.3838 21.3039C10.596 21.5245 10.8479 21.6995 11.1251 21.8189C11.4023 21.9384 11.6995 21.9999 11.9996 22ZM19.692 16.4441C19.0018 15.6736 17.7107 14.515 17.7107 10.7184C17.7116 9.35666 17.2561 8.03708 16.4224 6.98637C15.5888 5.93566 14.429 5.21941 13.1423 4.96068V4.18691C13.1423 3.87212 13.0219 3.57023 12.8077 3.34764C12.5935 3.12505 12.3029 3 12 3C11.697 3 11.4065 3.12505 11.1923 3.34764C10.9781 3.57023 10.8577 3.87212 10.8577 4.18691V4.96068C9.57117 5.21958 8.41155 5.9359 7.57804 6.9866C6.74453 8.03729 6.28915 9.35677 6.29012 10.7184C6.29012 14.515 4.99897 15.6736 4.30878 16.4441C4.10941 16.6611 3.99874 16.9499 4.00001 17.2499C4.00022 17.4061 4.03005 17.5607 4.08779 17.705C4.14554 17.8492 4.23007 17.9802 4.33655 18.0904C4.44304 18.2007 4.56939 18.2881 4.70839 18.3476C4.84739 18.4071 4.99631 18.4375 5.14665 18.4372H18.8533C19.0037 18.4376 19.1527 18.4073 19.2917 18.3478C19.4308 18.2883 19.5572 18.201 19.6637 18.0907C19.7702 17.9804 19.8547 17.8494 19.9124 17.7051C19.9701 17.5608 19.9999 17.4061 20 17.2499C20.0018 16.9501 19.8917 16.6613 19.6928 16.4441H19.692Z" fill={color} />
  </svg>
);

export default NotificationIcon;
