import { Box } from '@mui/material';
import MuiFormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
  '& .MuiButtonGroup-root': {
    marginTop: theme.spacing(3),

    '& .MuiButtonBase-root': {
      padding: theme.spacing(1, 2),
      fontSize: '14px',
    },
  },
}));

export const FormHelperText = styled(
  MuiFormHelperText,
  { shouldForwardProp: (props) => props !== 'hasError' },
)<{ hasError: boolean }>(({ hasError, theme }) => (hasError ? {
  color: theme.palette.error.main,
  marginTop: theme.spacing(1),
} : {}));
