import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/index';

import {
  ApiStatus, BalanceTrend, BankAccountsGroupByType, BankAccountsSortByType,
} from 'constants/enums';
import { BankAccount, BankAccountFilters } from 'models/bankAccount.interface';

export const accountsSelector = createSelector(
  (state: RootState) => state.bankAccounts.accounts,
  (accounts) => ({
    ...accounts,
    isLoading: accounts.status === ApiStatus.loading,
  }),
);

export const filteredAccountsSelector = (state: RootState):BankAccount[] => state.bankAccounts.filteredAccounts;

export const transactionActivityWidgetSelector = createSelector(
  (state: RootState) => state.bankAccounts.transactionActivityWidget,
  (chart) => ({
    ...chart,
    isLoading: chart.status === ApiStatus.loading,
  }),
);
export const totalBalanceChartSelector = createSelector(
  (state: RootState) => state.bankAccounts.totalBalanceChart,
  (chart) => ({
    ...chart,
    isLoading: chart.status === ApiStatus.loading,
  }),
);
export const balanceRankingChartSelector = createSelector(
  (state: RootState) => state.bankAccounts.balanceRankingChart,
  (chart) => ({
    ...chart,
    isLoading: chart.status === ApiStatus.loading,
  }),
);

export const accountsFiltersSelector = (state: RootState): BankAccountFilters => state.bankAccounts.accountsFilters;
export const chartFiltersSelector = (state: RootState): Record<string, BalanceTrend> => state.bankAccounts.chartFilters;
export const searchSelector = (state: RootState): string => state.bankAccounts.search;
export const sortBySelector = (state: RootState): BankAccountsSortByType => state.bankAccounts.sortBy;
export const groupBySelector = (state: RootState): BankAccountsGroupByType => state.bankAccounts.groupBy;
export const requiresRelinkSelector = (state: RootState): boolean => state.bankAccounts.requiresRelink;
export const showOnlyLinkedAccountsSelector = (state: RootState): boolean => state.bankAccounts.showOnlyLinkedAccounts;
export const showBrokenAccountsSelector = (state: RootState): boolean => state.bankAccounts.showBrokenAccountsModal;

export const brokenAccountsSelector = createSelector(
  accountsSelector,
  ({ data: bankAccounts }) => (bankAccounts
    ? bankAccounts?.filter((account: BankAccount) => account.lastUpdateFailed)
    : []),
);
