import { Dialog } from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import CustomButton from 'components/CustomButton';

import AlertIcon from 'assets/icons/AlertIcon';
import ScheduleBubbleIcon from 'assets/icons/ScheduleBubbleIcon';
import SuccessIcon from 'assets/icons/SuccessIcon';
import { CustomButtonVariants } from 'assets/themes/theme-declarations';
import { ConfirmationTypes } from 'constants/enums';

import {
  DialogContent, DialogTitle, Paper, DialogActions,
} from './index.styled';

interface ConfirmationModalProps {
  id: string;
  isOpen: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  title: string;
  children?: ReactNode;
  cancelLabel?: string;
  confirmLabel?: string;
  type: ConfirmationTypes;
  disabled?: boolean;
  isSubmitting?: boolean;
  fullWidth?: boolean;
  cancelVariant?: CustomButtonVariants;
  buttonsAlignment?: 'center' | 'space-between';
}

const iconByType = {
  [ConfirmationTypes.Success]: SuccessIcon,
  [ConfirmationTypes.Scheduled]: ScheduleBubbleIcon,
  [ConfirmationTypes.Error]: AlertIcon,
  [ConfirmationTypes.Warning]: AlertIcon,
};

const ConfirmationModal: FC<ConfirmationModalProps> = (props): ReactElement => {
  const intl = useIntl();
  const {
    id,
    isOpen,
    onCancel,
    title,
    children,
    onSubmit,
    type,
    cancelLabel = intl.formatMessage({ id: 'button.cancel' }),
    confirmLabel = intl.formatMessage({ id: 'button.confirm' }),
    disabled = false,
    isSubmitting = false,
    cancelVariant = 'text',
    buttonsAlignment = 'center',
    fullWidth = false,
  } = props;

  const ConfirmationIcon = iconByType[type];

  return (
    <Dialog
      id={id}
      open={isOpen}
      PaperComponent={Paper}
    >
      <DialogTitle type={type}>
        <ConfirmationIcon />
        {title}
      </DialogTitle>

      <DialogContent className="hidden-scroll">
        {children}
      </DialogContent>

      <DialogActions justifyContent={buttonsAlignment}>
        {onCancel && (
          <CustomButton
            variant={cancelVariant}
            label={cancelLabel}
            onClick={onCancel}
            disabled={disabled}
            id="btn-cancel-confirmation-modal"
          />
        )}
        {!onCancel && buttonsAlignment === 'space-between' && (
          <div />
        )}
        {onSubmit && (
          <CustomButton
            className={type === ConfirmationTypes.Warning ? 'primary' : type}
            label={confirmLabel}
            onClick={onSubmit}
            isLoading={isSubmitting}
            disabled={disabled}
            fullWidth={fullWidth}
            id="btn-submit-confirmation-modal"
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
