import { Box, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  // formatPercentage,
  formatPrice,
} from 'utils/formatters';

import { ValueWrapper } from './index.styled';

interface ValueSectionProps {
  currentAmount: number;
  // variableAmount: number;
  // percentage: number;
  // isPositive?: boolean;
  isLoading?: boolean;
}

const ValueSection: FC<ValueSectionProps> = ({
  currentAmount,
  // variableAmount,
  // percentage,
  // isPositive = false,
  isLoading = false,
}) => {
  const intl = useIntl();
  // const symbol = isPositive ? '+' : '-';

  return (
    <ValueWrapper>
      <Typography variant="body2" color="general.darkGrey1">
        {intl.formatMessage({ id: 'label.value' })}
      </Typography>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" mt={1}>
        {isLoading
          ? <Skeleton animation="wave" variant="rectangular" height={33} width={125} />
          : (
            <Typography variant="h1">
              {formatPrice({ price: currentAmount })}
            </Typography>
          )}
        {/* {isLoading */}
        {/*  ? <Skeleton animation="wave" variant="rectangular" height={17} width={125} /> */}
        {/*  : ( */}
        {/*    <Typography variant="body3" color={isPositive ? 'general.darkGreen6' : 'general.darkRed1'} fontWeight={700}> */}
        {/*      {`${symbol} ${formatPrice({ price: variableAmount })} (${formatPercentage({ percentage })})`} */}
        {/*    </Typography> */}
        {/*  )} */}
      </Box>
    </ValueWrapper>
  );
};

export default ValueSection;
