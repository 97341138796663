import MuiIconButton from '@mui/material/IconButton';
import MuiMenu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

export const Menu = styled(MuiMenu)(({ theme }) => ({
  color: theme.palette.text.primary,

  '& svg > path': {
    fill: theme.palette.text.primary,
  },

  '& .red-menu-item': {
    color: theme.palette.error.main,

    '& svg path': {
      fill: theme.palette.error.main,
    },
  },
}));

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  height: '36px',
  width: '36px',

  '&.MuiIconButton-root:hover': {
    '& svg > path': {
      fill: theme.palette.primary.main,
    },
  },

  '& svg': {
    transform: 'rotate(90deg)',

    '& path': {
      fill: theme.palette.general.darkGrey,
    },
  },
}));
