import { Button, ButtonProps } from '@mui/material';
import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';

import LoadingIcon from 'assets/icons/LoadingIcon';
import { CustomButtonVariants } from 'assets/themes/theme-declarations';

interface CustomButtonProps extends ButtonProps {
  label: string | ReactElement;
  isLoading?: boolean;
  disabled?: boolean;
  loadingMessage?: string;
  id: string;
  variant?: CustomButtonVariants;
  icon?: ReactElement | null;
  iconPlacement?: 'start' | 'end';
}

const CustomButton:FC<CustomButtonProps> = (props): ReactElement => {
  const intl = useIntl();
  const {
    label,
    isLoading = false,
    loadingMessage = 'button.loading...',
    variant = 'contained',
    color = 'primary',
    icon = null,
    iconPlacement = 'start',
    disabled = false,
    id,
    ...remainingProps
  } = props;
  const btnLabel = isLoading ? intl.formatMessage({ id: loadingMessage }) : label;
  const btnIcon = icon && isLoading ? <LoadingIcon size={12} /> : icon;
  const isDisabled = disabled || isLoading;

  return (
    <Button
      {...remainingProps}
      id={id}
      disabled={isDisabled}
      variant={variant}
      color={color}
      endIcon={iconPlacement === 'end' && btnIcon}
      startIcon={iconPlacement === 'start' && btnIcon}
    >
      {btnLabel}
    </Button>
  );
};

export default CustomButton;
