import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box, Dialog, DialogActions, Divider, FormLabel, Tooltip, Typography, useTheme,
} from '@mui/material';
import { isEqual } from 'lodash';
import { Paper } from 'modules/create-rule/index.styled';
import {
  FC, ReactElement, ReactNode, useEffect, useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import ConfirmationModal from 'components/ConfirmationModal';
import CustomButton from 'components/CustomButton';
import Loader from 'components/Loader';
import InputField from 'fields/InputField';
import TextareaField from 'fields/TextareaField';
import MatchingCondition from 'modules/create-rule/components/MatchingCondition';
import CriteriaSection from 'modules/create-rule/containers/CriteriaSection';
import IdentifiersSection from 'modules/create-rule/containers/IdentifiersSection';
import RecipientsSection from 'modules/create-rule/containers/RecipientsSection';

import { useModal } from 'hooks/useModal';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { createRule, getRuleDetails } from 'services/RuleService';
import { fetchBankAccounts } from 'store/bank-accounts/bankAccountsSlice';
import { accountsSelector } from 'store/bank-accounts/selectors';
import { fetchContactsList, resetContacts } from 'store/contacts/contactsSlice';
import { contactsSelector, isLoadingSelector } from 'store/contacts/selectors';
import { currentWorkspaceSelector, userSelector } from 'store/user/selectors';

import InfoIcon from 'assets/icons/InfoIcon';
import { ConfirmationTypes, RuleTimeframeType } from 'constants/enums';
import {
  ALERT_MESSAGE,
  ALERT_NAME,
  ALERT_TIME_FROM,
  ALERT_TIME_TO,
  ALERT_TIMEFRAME,
  ALERT_TYPE,
} from 'constants/fields';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { BankAccount } from 'models/bankAccount.interface';
import { ApiFieldError } from 'models/errors.interface';
import { RuleDetails } from 'models/rule.interface';
import { CreateRuleTransaction } from 'models/transaction.interface';
import { defaultRule } from 'modules/create-rule/constants';
import { formatRuleForCreation, getInitialRule } from 'modules/create-rule/utils';
import { getTranslatedErrors, handleApiErrors } from 'utils/errorUtils';
import { ruleFormSchema, RuleFormValues } from 'utils/validation/ruleFormSchema';

interface CreateRuleProps {
  isOpen: boolean;
  onClose: () => void;
  rule?: RuleDetails | null;
  ruleId?: string;
  onSuccess?: () => void;
  transaction?: CreateRuleTransaction | null;
  isReadOnly?: boolean;
  title?: string;
  bankAccount?: BankAccount;
}

const CreateRule: FC<CreateRuleProps> = ({
  isOpen,
  onClose,
  onSuccess,
  rule = null,
  ruleId = '',
  isReadOnly = false,
  transaction = null,
  title = 'label.createAlert',
  bankAccount,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { isOpen: isErrorOpen, onOpen: onOpenError, onClose: onCloseError } = useModal();
  const loggedUser = useAppSelector(userSelector);
  const contacts = useAppSelector(contactsSelector);
  const isLoadingContacts = useAppSelector(isLoadingSelector);
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const { isLoading: areBanksLoading, data: bankAccounts } = useAppSelector(accountsSelector, isEqual);

  const [activeRuleInfo, setActiveRuleInfo] = useState<RuleDetails | null>(rule);
  const [isLoadingRule, setIsLoadingRule] = useState<boolean>(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    reset,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm<RuleFormValues>({
    resolver: zodResolver(ruleFormSchema),
    mode: 'onChange',
    defaultValues: defaultRule,
  });

  const translatedErrors = getTranslatedErrors(errors);
  const workspaceId = currentWorkspace?.id || '';
  const submitButtonLabel = rule ? 'button.save' : 'button.createAlert';
  const type = watch(ALERT_TYPE.name);
  const isLoading = areBanksLoading || isLoadingContacts || isLoadingRule;

  useEffect(() => {
    setIsLoadingRule(true);

    reset(getInitialRule({
      transaction,
      user: loggedUser,
      bankAccount,
      rule: activeRuleInfo,
      bankAccounts,
      contacts,
    }));
    setIsLoadingRule(false);
  }, [transaction, loggedUser, bankAccount, activeRuleInfo, bankAccounts, contacts]);

  useEffect(() => () => {
    dispatch(resetContacts());
  }, []);

  const handleSubmitRule = async (values: RuleFormValues) => {
    try {
      const formattedRule = formatRuleForCreation(values, workspaceId, bankAccounts);
      await createRule(formattedRule);
      onClose();

      if (onSuccess) {
        onSuccess();
      }

      toast.success(intl.formatMessage({ id: 'label.alertCreationSuccess' }), GENERAL_TOAST_OPTIONS);
    } catch (error: any) {
      const nameError = error.fieldErrors?.find((fieldError: ApiFieldError) => fieldError.field === ALERT_NAME.name);

      if (nameError?.message === 'Alert already exists.') {
        onOpenError();
        return;
      }

      handleApiErrors<RuleFormValues>(error, setError);
    }
  };

  useEffect(() => {
    if (ruleId) {
      fetchRuleDetails(ruleId);
    }
  }, [ruleId]);

  const fetchRuleDetails = async (id: string) => {
    setIsLoadingRule(true);
    try {
      const response = await getRuleDetails(id);
      setActiveRuleInfo(response.data);
    } catch (e) {
      handleApiErrors(e);
      onClose();
    } finally {
      setIsLoadingRule(false);
    }
  };

  useEffect(() => {
    setValue(ALERT_TIMEFRAME.name, RuleTimeframeType.anyTime);
    setValue(ALERT_TIME_TO.name, '');
    clearErrors(ALERT_TIME_FROM.name);
    setValue(ALERT_TIME_FROM.name, '');
  }, [type]);

  useEffect(() => {
    if (workspaceId) {
      dispatch(fetchBankAccounts({ workspaceId })).unwrap();
      dispatch(fetchContactsList());
    }
  }, [workspaceId]);

  return (
    <Dialog
      id="modal-create-rule"
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      PaperComponent={Paper}
    >
      <Typography variant="h2" mb={5}>
        {intl.formatMessage({ id: title })}
      </Typography>

      <Box className="hidden-scroll">
        <InputField
          {...ALERT_NAME}
          required
          label={intl.formatMessage({ id: ALERT_NAME.label })}
          slotProps={{
            htmlInput: register(ALERT_NAME.name),
          }}
          error={!!translatedErrors[ALERT_NAME.name]?.message}
          helperText={translatedErrors[ALERT_NAME.name]?.message as ReactNode}
          disabled={isReadOnly}
        />

        <IdentifiersSection
          onSetValue={setValue}
          onClearErrors={clearErrors}
          watch={watch}
          isReadOnly={isReadOnly}
        />

        <Box mt={5}>
          <CriteriaSection
            onSetValue={setValue}
            watch={watch}
            onClearErrors={clearErrors}
            control={control}
            register={register}
            errors={errors}
            isReadOnly={isReadOnly}
          />
        </Box>

        <Divider sx={{ my: 5 }} />

        <MatchingCondition
          control={control}
          onSetValue={setValue}
          watch={watch}
          translatedErrors={translatedErrors}
          register={register}
          isReadOnly={isReadOnly}
        />

        <Divider sx={{ my: 5 }} />

        <Box display="flex" mb={3}>
          <FormLabel required sx={{ display: 'flex', mr: 2 }}>
            <Typography variant="subtitle1">
              {intl.formatMessage({ id: 'label.alertMessage' })}
            </Typography>
          </FormLabel>
          <Tooltip
            arrow
            id="tooltip-matching-condition"
            title={intl.formatMessage({ id: 'label.aMessageThatWillBeShown' })}
            placement="top-start"
          >
            <Box><InfoIcon size={20} color={theme.palette.general.darkGrey1} /></Box>
          </Tooltip>
        </Box>

        <Controller
          name={ALERT_MESSAGE.name}
          control={control}
          render={({ field }): ReactElement => (
            <TextareaField
              {...ALERT_MESSAGE}
              inputProps={register(ALERT_MESSAGE.name)}
              value={field.value}
              error={!!translatedErrors[ALERT_MESSAGE.name]?.message}
              helperText={translatedErrors[ALERT_MESSAGE.name]?.message as ReactNode}
              count={field.value ? field.value.toString().length : 0}
              disabled={isReadOnly}
              placeholder={intl.formatMessage({ id: 'placeholder.enterAMessage' })}
            />
          )}
        />

        <Divider sx={{ my: 5 }} />

        <RecipientsSection
          watch={watch}
          onSetValue={setValue}
          errors={errors}
          isReadOnly={isReadOnly}
          control={control}
        />
      </Box>

      <DialogActions sx={{ p: 0 }}>
        <Box display="flex" justifyContent="flex-end" width="100%" mt={5} gap={6}>
          <CustomButton
            variant="text"
            onClick={onClose}
            label={intl.formatMessage({ id: isReadOnly ? 'button.close' : 'button.cancel' })}
            id="button-cancelRule"
            disabled={isSubmitting}
          />
          {!isReadOnly && (
            <CustomButton
              variant="contained"
              onClick={handleSubmit(handleSubmitRule)}
              label={intl.formatMessage({ id: submitButtonLabel })}
              id="button-saveRule"
              disabled={isSubmitting || !isValid}
            />
          )}
        </Box>
      </DialogActions>

      {isErrorOpen && (
        <ConfirmationModal
          id="modal-alert-already-exists"
          type={ConfirmationTypes.Error}
          title={intl.formatMessage({ id: 'modalTitle.alertAlreadyExists' })}
          confirmLabel={intl.formatMessage({ id: 'button.confirm' })}
          onCancel={onCloseError}
          onSubmit={onCloseError}
          isOpen={isErrorOpen}
        >
          <Box textAlign="center" display="flex" justifyContent="center">
            <Typography color="textSecondary" width="325px">
              {intl.formatMessage({ id: 'modalContent.alertAlreadyExists' })}
            </Typography>
          </Box>
        </ConfirmationModal>
      )}

      {isLoading && <Loader />}
    </Dialog>
  );
};

export default CreateRule;
