/* eslint max-len: 0 */
import { CustomIconInterface } from 'models/icon.interface';

const KeyIcon = ({ color = 'currentColor', size = 20 }: CustomIconInterface) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 20 12">
    <path fill={color} d="M5.833.167a5.833 5.833 0 1 0 5.59 7.5H15v2.5h3.333v-2.5H20V4.332h-8.578A5.834 5.834 0 0 0 5.833.167Zm0 3.333a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z" />
  </svg>
);

export default KeyIcon;
