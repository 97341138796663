/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const MonitorIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 2C1.895 2 1 2.895 1 4V16C1 17.105 1.895 18 3 18H10V20H9C8.448 20 8 20.448 8 21C8 21.552 8.448 22 9 22H15C15.552 22 16 21.552 16 21C16 20.448 15.552 20 15 20H14V18H21C22.105 18 23 17.105 23 16V4C23 2.895 22.105 2 21 2H3ZM3 4H21V16H3V4Z" fill={color} />
  </svg>
);

export default MonitorIcon;
