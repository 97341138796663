import { FC } from 'react';

import { IOSSwitch } from 'fields/SwitchField/index.styled';

import { ExtendedSwitchProps } from 'models/switch.interface';

const SwitchField:FC<ExtendedSwitchProps> = ({ size = 'medium', ...props }) => (
  <IOSSwitch {...props} size={size} />
);

export default SwitchField;
