import { z } from 'zod';

import {
  CriteriaCondition, RuleMatch, RuleStatus, RuleTimeframeType,
} from 'constants/enums';
import {
  ALERT_CRITERIA,
  ALERT_CRITERIA_HAS_AMOUNT,
  ALERT_IDENTIFIERS,
  ALERT_MESSAGE,
  ALERT_NAME,
  ALERT_RECIPIENTS,
  ALERT_TIME_FROM,
  ALERT_TIME_TO,
  ALERT_TIMEFRAME,
  ALERT_TYPE,
  ALERT_CRITERIA_HAS_DESCRIPTION, ALERT_STATUS, ALERT_TIMEZONE,
} from 'constants/fields';

const identifierSchema = z.object({
  bankCode: z.string().nullable().optional(),
  bankAccountId: z.string().nullable().optional(),
  label: z.string().nullable().optional(),
}).refine((values) => {
  const { bankCode, bankAccountId } = values;

  if (!bankCode && !bankAccountId) {
    return false;
  }

  return true;
}, {
  message: 'error.mandatoryField',
});

const criteriaConditionRefine = ({ criteria = [], hasAmount = false }) => {
  const criteriaArray = criteria as { condition: string }[];
  return hasAmount ? criteria && criteriaArray?.[1]?.condition : true;
};

const criteriaAmountPositiveRefine = ({ criteria = [], hasAmount = false }) => {
  const criteriaArray = criteria as { values: string[] }[];
  const value = criteriaArray?.[1]?.values[0]?.replaceAll(',', '');
  return hasAmount ? criteria && +value > 0 : true;
};

const criteriaAmountRefine = ({ criteria = [], hasAmount = false }) => {
  const criteriaArray = criteria as { values: string[] }[];
  return hasAmount ? criteria && criteriaArray?.[1]?.values[0] : true;
};

const secondaryCriteriaAmountRefine = ({ criteria = [], hasAmount = false }) => {
  const criteriaArray = criteria as { values: string[]; condition: CriteriaCondition }[];

  if (criteriaArray?.[1]?.condition !== CriteriaCondition.between) {
    return true;
  }

  return hasAmount ? criteria && criteriaArray?.[1]?.values[1] : true;
};

const secondaryCriteriaAmountPositiveRefine = ({ criteria = [], hasAmount = false }) => {
  const criteriaArray = criteria as { values: string[]; condition: CriteriaCondition }[];

  if (criteriaArray?.[1]?.condition !== CriteriaCondition.between) {
    return true;
  }

  const value = criteriaArray?.[1]?.values[1]?.replaceAll(',', '');
  return hasAmount ? criteria && +value > 0 : true;
};

const criteriaAmountRangeRefine = ({ criteria = [], hasAmount = false }) => {
  const criteriaArray = criteria as { values: string[]; condition: CriteriaCondition }[];
  const min = criteriaArray?.[1]?.values[0]?.replaceAll(',', '');
  const max = criteriaArray?.[1]?.values[1]?.replaceAll(',', '');

  if (criteriaArray?.[1]?.condition !== CriteriaCondition.between || !min || !max) {
    return true;
  }

  return hasAmount ? +min < +max : true;
};

const criteriaDescriptionRefine = ({ criteria = [], hasDescription = false }) => {
  const criteriaArray = criteria as { values: string[] }[];

  if (hasDescription && criteriaArray && criteriaArray.length > 0) {
    const { values } = criteriaArray[0];

    if (values && values.length > 0) {
      return !values.some((description: string) => !description);
    }

    return true;
  }

  return true;
};

const criteriaSchema = z.object({
  key: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    }),
  order: z.number().nullable().optional(),
  values: z.array(z.string().nullable().optional()),
  condition: z.string().nullable().optional(),
  id: z.string().nullable().optional(),
});

export const ruleFormSchema = z.object({
  [ALERT_NAME.name]: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    }),
  [ALERT_STATUS.name]: z
    .enum([
      RuleStatus.active,
      RuleStatus.inactive,
    ])
    .nullable()
    .optional(),
  [ALERT_IDENTIFIERS.name]: z.array(identifierSchema),
  [ALERT_CRITERIA.name]: z.array(criteriaSchema),
  [ALERT_CRITERIA_HAS_AMOUNT.name]: z.boolean().nullable().optional(),
  [ALERT_CRITERIA_HAS_DESCRIPTION.name]: z.boolean().nullable().optional(),
  [ALERT_MESSAGE.name]: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    }),
  [ALERT_TYPE.name]: z
    .enum([
      RuleMatch.match,
      RuleMatch.noMatch,
    ], {
      required_error: 'error.mandatoryField',
    }),
  [ALERT_TIME_TO.name]: z.union([z.number(), z.string()]).nullable().optional(),
  [ALERT_TIME_FROM.name]: z.union([z.number(), z.string()]).nullable().optional(),
  [ALERT_TIMEZONE.name]: z.string().optional(),
  [ALERT_TIMEFRAME.name]: z.string().nullable().optional(),
  [ALERT_RECIPIENTS.name]: z
    .array(z.any())
    .refine((recipients) => recipients.length >= 1, {
      message: 'error.mandatoryField',
    })
    .refine((recipients) => recipients.length <= 10, {
      message: 'error.ruleMaxRecipients',
    }),
})
  .refine(
    ({
      timeframeEnd,
      timeframeStart,
      timeframe,
      timezone,
      type,
    }) => {
      const isCustomTime = timeframe === RuleTimeframeType.customTime;
      const isNoMatch = type === RuleMatch.noMatch;

      return !((isCustomTime || isNoMatch) && (!timeframeStart || !timeframeEnd || !timezone));
    },
    {
      message: 'error.mandatoryField',
      path: [ALERT_TIME_FROM.name],
    },
  )
  .refine(
    ({
      timeframeEnd,
      timeframeStart,
      timeframe,
      type,
    }) => {
      const isCustomTime = timeframe === RuleTimeframeType.customTime;
      const isNoMatch = type === RuleMatch.noMatch;

      if (!isCustomTime && !isNoMatch) {
        return true;
      }

      return timeframeStart && timeframeEnd && +timeframeStart < +timeframeEnd;
    },
    {
      message: 'error.rule.timeframeHours',
      path: [ALERT_TIME_FROM.name],
    },
  )
  .refine(criteriaAmountRefine, {
    message: 'error.mandatoryField',
    path: ['criteria[1].values[0]'],
  })
  .refine(criteriaAmountPositiveRefine, {
    message: 'error.positiveValue',
    path: ['criteria[1].values[0]'],
  })
  .refine(criteriaAmountRangeRefine, {
    message: 'error.minAmountRange',
    path: ['criteria[1].values[0]'],
  })
  .refine(secondaryCriteriaAmountRefine, {
    message: 'error.mandatoryField',
    path: ['criteria[1].values[1]'],
  })
  .refine(secondaryCriteriaAmountPositiveRefine, {
    message: 'error.positiveValue',
    path: ['criteria[1].values[1]'],
  })
  .refine(criteriaAmountRangeRefine, {
    message: 'error.amountRange',
    path: ['criteria[1].values[1]'],
  })
  .refine(criteriaConditionRefine, {
    message: 'error.mandatoryField',
    path: ['criteria[1].condition'],
  })
  .refine(criteriaDescriptionRefine, {
    message: 'error.mandatoryField',
    path: ['criteria[0].values'],
  });

export type RuleFormValues = z.infer<typeof ruleFormSchema>
