import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import ReactGA from 'react-ga4';
import { useIntl } from 'react-intl';

import MoveMoneySelector from 'components/_Navbar/MoveMoneySelector';
import {
  ItemRoot, NavbarLink, NavbarWrapper, NavElement,
} from 'components/_Navbar/NavigationTabs/index.styled';

import { usePermissions } from 'hooks/usePermissions';

import DashboardIcon from 'assets/icons/DashboardIcon';
import InsightIcon from 'assets/icons/InsightIcon';
import NotificationIcon from 'assets/icons/NotificationIcon';
import {
  ALERTS_RULES_ROUTE,
  DASHBOARD_ROUTE,
  INSIGHTS_ROUTE,
} from 'constants/clientRoutes';
import { RolePermissions } from 'constants/permissionEnums';
import { existingFlags, isFeatureEnabled } from 'utils/featureFlag';

const NavigationTabs = (): ReactElement => {
  const intl = useIntl();
  const { canSeeMoveMoney, isAllowed } = usePermissions();

  const handleClickItem = (name: string) => {
    ReactGA.gtag('event', `navbar_item_${name}`, {
      label: name,
      value: 1,
    });
  };

  return (
    <NavbarWrapper>
      <NavbarLink to={DASHBOARD_ROUTE}>
        {({ isActive }): ReactElement => (
          <ItemRoot onClick={() => handleClickItem('Dashboard')}>
            <NavElement isActive={isActive}>
              <DashboardIcon />
              <Typography variant="body2">
                {intl.formatMessage({ id: 'navigation.dashboard' })}
              </Typography>
            </NavElement>
          </ItemRoot>
        )}
      </NavbarLink>

      {canSeeMoveMoney() && (
        <MoveMoneySelector onItemClick={() => handleClickItem('Move_Money')} />
      )}

      {isFeatureEnabled(existingFlags.insights) && (
        <NavbarLink to={INSIGHTS_ROUTE}>
          {({ isActive }): ReactElement => (
            <ItemRoot onClick={() => handleClickItem('Insights')}>
              <NavElement isActive={isActive}>
                <InsightIcon />
                <Typography variant="body2">
                  {intl.formatMessage({ id: 'navigation.insights' })}
                </Typography>
              </NavElement>
            </ItemRoot>
          )}
        </NavbarLink>
      )}

      {isAllowed({ permission: RolePermissions.ViewAlertRules }) && (
        <NavbarLink to={ALERTS_RULES_ROUTE}>
          {({ isActive }): ReactElement => (
            <ItemRoot onClick={() => handleClickItem('Alert_Center')}>
              <NavElement isActive={isActive}>
                <NotificationIcon />
                <Typography variant="body2">
                  {intl.formatMessage({ id: 'navigation.alertCenter' })}
                </Typography>
              </NavElement>
            </ItemRoot>
          )}
        </NavbarLink>
      )}
    </NavbarWrapper>
  );
};

export default NavigationTabs;
