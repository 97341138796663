/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const MembershipCardIcon = ({ color = 'currentColor', width = 18, height = 14 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <path
      fill={color}
      d="M16.334.333H1.667C.93.333.333.93.333 1.667v10.666c0 .737.597 1.334 1.334 1.334h14.666c.737 0 1.334-.597 1.334-1.333V1.667c0-.737-.597-1.334-1.334-1.334Zm-6.3 5.974L8.1 7.748l.719 2.303a.338.338 0 0 1-.518.375L6.333 9.031l-1.967 1.394a.338.338 0 0 1-.518-.376l.719-2.301-1.935-1.44a.338.338 0 0 1 .198-.61l2.412-.027.771-2.285a.338.338 0 0 1 .64 0l.773 2.285 2.41.028a.338.338 0 0 1 .199.609Zm2.3 5.36a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334Zm2.666 0a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334Z"
    />
  </svg>
);

export default MembershipCardIcon;
