import {
  DefaultType, InvitationStatus, SortOrder, UsersSortByType,
} from 'constants/enums';
import { UserAndInvitationFilter } from 'models/filter.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { User } from 'models/user.interface';
import { formatUserName } from 'utils/formatters';
import { isPartialMatch } from 'utils/sort';

export const filterSupportUsers = (users: User[]): User[] => {
  if (!users || !users.length) {
    return [];
  }

  return users.filter((user) => !user.email.includes('@service.primaryaccount.com'));
};

export const getSearchedMembers = (users: User[], search: string) => users.filter((user) => {
  const {
    id, email, phoneNumber,
  } = user;
  const name = formatUserName(user);

  if (!id && !name && !email && !phoneNumber && search) {
    return false;
  }

  if (!search) {
    return true;
  }

  return isPartialMatch(`${id} ${name} ${email} ${phoneNumber}`, search);
});

const getFilteredMembers = (
  users: User[],
  filter: UserAndInvitationFilter,
) => {
  if (filter.role !== DefaultType.all) {
    return users.filter((user) => user.roles.some((role) => role.authority === filter.role));
  }

  if (!filter.statuses[DefaultType.all]) {
    return filter.statuses[InvitationStatus.accepted]
      ? users
      : [];
  }

  return users;
};

const getSortedMembers = (
  users: User[],
  sort: SortOptions | null,
) => {
  const sortBy = sort?.name as UsersSortByType;
  const isAsc = sort?.type === SortOrder.asc;

  switch (sortBy) {
    case UsersSortByType.name:
      return users.sort((a, b) => {
        const firstUserName = formatUserName(a);
        const secondUserName = formatUserName(b);

        if (firstUserName && secondUserName) {
          return isAsc ? secondUserName.localeCompare(firstUserName) : firstUserName.localeCompare(secondUserName);
        }

        return 0;
      });
    case UsersSortByType.email:
      return users.sort((a, b) => (isAsc ? b.email.localeCompare(a?.email) : a.email.localeCompare(b?.email)));
    case UsersSortByType.phoneNumber:
      return users.sort((a, b) => {
        if (a?.phoneNumber && b?.phoneNumber) {
          return isAsc ? b.phoneNumber.localeCompare(a?.phoneNumber) : a.phoneNumber.localeCompare(b?.phoneNumber);
        }

        return 0;
      });
    case UsersSortByType.id:
      return users.sort((a, b) => {
        if (a?.id && b?.id) {
          return isAsc ? b.id.localeCompare(a?.id) : a.id.localeCompare(b?.id);
        }

        return 0;
      });
    case UsersSortByType.authority:
      return users.sort((a, b) => {
        const firstUserRole = a?.roles?.[0]?.name;
        const secondUserRole = b?.roles?.[0]?.name;

        if (firstUserRole && secondUserRole) {
          return isAsc ? secondUserRole.localeCompare(firstUserRole) : firstUserRole.localeCompare(secondUserRole);
        }

        return 0;
      });
    default:
      return users;
  }
};

export const getManipulatedMembers = (
  users: User[],
  sort: SortOptions | null,
  search: string,
  filter: UserAndInvitationFilter,
) => {
  const foundUsers = getSearchedMembers(users, search);
  const filteredUsers = getFilteredMembers(foundUsers, filter);
  return getSortedMembers(filteredUsers, sort);
};

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0;
    // eslint-disable-next-line no-mixed-operators,no-bitwise
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
