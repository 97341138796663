
const MuiPaginationItem = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      '&.Mui-selected, &:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
      },
    }),
  },
};

const MuiPagination = {
  styleOverrides: {
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
};

export {
  MuiPaginationItem,
  MuiPagination,
};
