import { type ReactElement, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';

import { customerRouter, operatorRouter, sentryCreateBrowserRouter } from 'routes/main';

import { useAppSelector } from 'hooks/useRedux';
import { userSelector, viewAsSelector } from 'store/user/selectors';

import { isLoggedAsOperator } from 'utils/roles';

const AppRouter = (): ReactElement => {
  const user = useAppSelector(userSelector);
  const viewAs = useAppSelector(viewAsSelector);
  const router = useMemo(
    () => (user && isLoggedAsOperator(user, viewAs) ? operatorRouter : customerRouter),
    [user, viewAs],
  );

  return (
    <RouterProvider router={sentryCreateBrowserRouter(router)} />
  );
};

export default AppRouter;
