import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const CreateKyb = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'CreateKyb' */ 'modules/kyb/containers/CreateKyb')
    ),
    'CreateKyb',
  ),
);

export default CreateKyb;
