import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const RecipientDetails = lazy(
  () => CustomLazyLoader(
    () => (
      import(
        /* webpackChunkName: 'RecipientDetails' */
        'modules/recipient-details/containers/RecipientDetails'
      )
    ),
    'RecipientDetails',
  ),
);

export default RecipientDetails;
