import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const ForgotPassword = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'ForgotPassword' */ 'modules/forgot-password/containers/ForgotPassword')
    ),
    'ForgotPassword',
  ),
);

export default ForgotPassword;
