import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTypography = styled(
  Typography,
  { shouldForwardProp: (props) => props !== 'full' },
)<{ full: boolean }>(({ theme, full }) => ({
  maxWidth: '800px',
  lineHeight: '23px',
  cursor: 'pointer',

  [theme.breakpoints.down('lg')]: {
    maxWidth: '600px',
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '400px',
  },

  ...(full ? {
    marginBottom: theme.spacing(4),
  } : {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
}));

export const ExpandMore = styled('div')<{ expand: boolean }>(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  cursor: 'pointer',
  height: '25px',
  display: 'flex',
  alignItems: 'center',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
