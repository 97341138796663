import { styled } from '@mui/material/styles';

import Icon from 'assets/icons/ChevronDownIcon';

export const SelectChevronIcon = styled(Icon)(({ theme }) => ({
  userSelect: 'none',
  display: 'inline-block',
  fill: theme.palette.general.darkGrey1,
  transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  fontSize: '1.5rem',
  position: 'absolute',
  pointerEvents: 'none',
  height: '20px',
  width: '20px',
  marginTop: theme.spacing(0.5),
  '& path': {
    fill: theme.palette.general.darkGrey1,
  },
}));
