import {
  Box, ListItemIcon, ListItemText, MenuItem, Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import ConfirmationModal from 'components/ConfirmationModal';
import EditUserDialog from 'containers/EditUserDialog';

import { useActAs } from 'hooks/useActAs';
import { useModal } from 'hooks/useModal';
import { usePermissions } from 'hooks/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { deleteUser } from 'services/UsersService';
import { customerDetailsSelector } from 'store/customer-details/selectors';
import { currentWorkspaceSelector, userSelector } from 'store/user/selectors';
import { fetchUsers, refreshUsers } from 'store/users/usersSlice';

import DeleteIcon from 'assets/icons/DeleteIcon';
import EditIcon from 'assets/icons/EditIcon';
import MoreIcon from 'assets/icons/MoreIcon';
import ProfileIcon from 'assets/icons/ProfileIcon';
import { ConfirmationTypes } from 'constants/enums';
import { RolePermissions } from 'constants/permissionEnums';
import { User } from 'models/user.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { isOperator } from 'utils/roles';

import { IconButton, Menu } from './index.styled';

interface UserActionMenuProps {
  user: User;
  isDisabled?: boolean;
}

interface ConfirmActionModal {
  title: string;
  onSubmit: () => void;
  content: string;
}

const UserActionMenu: FC<UserActionMenuProps> = ({ user, isDisabled = false }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useModal();
  const { isAllowed } = usePermissions();
  const loggedUser = useAppSelector(userSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const isMe = Boolean(loggedUser && loggedUser?.id === user?.id);
  const isLoggedUserAnOperator = loggedUser && isOperator(loggedUser);
  const { loginAsCustomer, isSubmitting } = useActAs();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useModal();
  const [modalInfo, setModalInfo] = useState<ConfirmActionModal | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const canEditMember = isAllowed({ permission: RolePermissions.UpdateUsers });
  const canDeleteMember = isAllowed({ permission: RolePermissions.DeleteUsers });

  const workspaceId = currentWorkspace?.id || '';

  const getTeamMembers = async () => {
    const apiInfo = { params: { workspaceId } };
    dispatch(fetchUsers(apiInfo));
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenRemoveMember = () => {
    handleMenuClose();
    onOpen();
    setModalInfo({
      title: 'modalTitle.removeMember',
      onSubmit: handleRemoveMember,
      content: 'modalContent.removeMember',
    });
  };

  const handleOpenEdit = () => {
    handleMenuClose();
    onEditOpen();
  };

  const handleOnEditSuccessfully = async () => {
    const apiInfo = { params: { workspaceId } };
    dispatch(refreshUsers(apiInfo));
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleRemoveMember = async () => {
    if (!workspaceId || !user?.id) {
      return;
    }

    try {
      setIsLoading(true);
      await deleteUser(workspaceId, user?.id);
      await getTeamMembers();
      toast.success(intl.formatMessage({ id: 'label.successfullyRemoved' }));
    } catch (error: any) {
      handleApiErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewAs = () => {
    if (customer?.workspaces?.length) {
      loginAsCustomer(user, customer?.workspaces);
    }
  };

  return (
    <>
      <IconButton onClick={handleClick} aria-label="Member menu" disabled={isDisabled} id="iconButton-memberMenu">
        <Box display="flex" alignItems="center">
          <MoreIcon />
        </Box>
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          {isLoggedUserAnOperator ? (
            <MenuItem
              onClick={handleViewAs}
              id="menuItem-act-as-user"
              disabled={isSubmitting}
            >
              <ListItemIcon>
                <ProfileIcon />
              </ListItemIcon>
              <ListItemText>
                {intl.formatMessage({ id: 'button.actAsUser' })}
              </ListItemText>
            </MenuItem>
          ) : (
            <>
              {canEditMember && (
                <MenuItem
                  onClick={handleOpenEdit}
                  id="menuItem-edit-team-member"
                  disabled={isLoading}
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {intl.formatMessage({ id: 'button.editTeamMember' })}
                  </ListItemText>
                </MenuItem>
              )}
              {!isMe && canDeleteMember && (
                <MenuItem
                  onClick={handleOpenRemoveMember}
                  id="menuItem-delete-team-member"
                  className="red-menu-item"
                  disabled={isLoading}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {intl.formatMessage({ id: 'button.deleteTeamMember' })}
                  </ListItemText>
                </MenuItem>
              )}
            </>
          )}
        </div>
      </Menu>
      {(isOpen && modalInfo) && (
        <ConfirmationModal
          id="modal-team-member-action"
          type={ConfirmationTypes.Error}
          title={intl.formatMessage({ id: modalInfo.title })}
          confirmLabel={intl.formatMessage({ id: 'button.confirm' })}
          onCancel={onClose}
          onSubmit={modalInfo.onSubmit}
          isOpen={isOpen}
          disabled={isLoading}
        >
          <Typography textAlign="center" color="textSecondary">
            {intl.formatMessage({ id: modalInfo.content })}
          </Typography>
        </ConfirmationModal>
      )}

      {isEditOpen && (
        <EditUserDialog
          isOpen={isEditOpen}
          onClose={onEditClose}
          onSuccess={handleOnEditSuccessfully}
          userDetails={user}
        />
      )}
    </>
  );
};

export default UserActionMenu;
