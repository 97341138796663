import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const UserPreferences = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'UserPreferences' */ 'modules/user-preference/containers/Preferences')
    ),
    'UserPreferences',
  ),
);

export default UserPreferences;
