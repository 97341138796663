import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getRuleHistory } from 'services/RuleService';
import { resetStore } from 'store/actions';

import { ApiStatus } from 'constants/enums';
import { RULE_HISTORY_PAGINATION_SIZE } from 'constants/general';
import { RuleHistoryFilter } from 'models/filter.interface';
import { Pagination } from 'models/pagination.interface';
import { RuleHistory } from 'models/rule.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { RuleHistoryFilterSchema } from 'utils/validation/ruleHistoryFilterSchema';

export interface RuleHistoryState {
  rules: {
    data: RuleHistory[];
    status: ApiStatus;
  };
  pagination: Pagination;
  search: string;
  filter: RuleHistoryFilterSchema;
}

const initialState: RuleHistoryState = {
  rules: {
    data: [],
    status: ApiStatus.idle,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
    page: 1,
  },
  search: '',
  filter: {
    dateRange: {
      from: null,
      to: null,
    },
  },
};

export const fetchRules = createAsyncThunk(
  'ruleHistory/fetchRules',
  async (
    params: { page: number; sort: string; workspaceId: string; search: string; filter: RuleHistoryFilterSchema },
    thunkAPI,
  ) => {
    try {
      const filters: RuleHistoryFilter = {};

      if (params.filter?.dateRange?.from) {
        filters['startEndDates.startDate'] = params.filter?.dateRange?.from;
      }

      if (params.filter?.dateRange?.to) {
        filters['startEndDates.endDate'] = params.filter?.dateRange?.to;
      }

      const queryParams = {
        page: params.page,
        size: RULE_HISTORY_PAGINATION_SIZE,
        sort: params.sort,
        name: params.search,
        filter: filters,
        ownerId: params.workspaceId,
      };

      const response = await getRuleHistory(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const ruleHistorySlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      state.pagination.page = 1;
      return state;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload;
      return state;
    },
    updateFilter: (state, action: PayloadAction<RuleHistoryFilterSchema>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
      return state;
    },
    resetState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchRules.pending, (state) => {
        state.rules = {
          ...state.rules,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchRules.fulfilled, (state, action) => {
        const { content, totalPages, totalElements } = action.payload || {};

        state.rules = {
          ...state.rules,
          status: ApiStatus.idle,
          data: content,
        };
        state.pagination = {
          ...state.pagination,
          totalPages,
          totalElements,
        };
      })
      .addCase(fetchRules.rejected, (state) => {
        state.rules = {
          ...state.rules,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  resetState,
  updateSearch,
  updatePage,
  updateFilter,
} = ruleHistorySlice.actions;

export default ruleHistorySlice.reducer;
