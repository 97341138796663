import {
  TransactionFilterAmount,
  FilterDate,
  TransactionFilterTypes,
  DateFormats,
  TransactionFilterDirections,
  DefaultType,
  LimitType,
} from 'constants/enums';

import { MAX_ALERT_MESSAGE, MAX_MEMO_MESSAGE } from './general';

export const TRANSACTION_NOTES = {
  name: 'note',
  label: 'label.note',
  fullWidth: true,
  multiline: true,
  rows: 5,
  id: 'textArea-transactionNotes',
};

export const FIRST_NAME = {
  name: 'firstName',
  label: 'label.firstName',
  fullWidth: true,
  id: 'input-firstName',
};

export const MIDDLE_NAME = {
  name: 'middleName',
  label: 'label.middleName',
  fullWidth: true,
  id: 'input-middleName',
};

export const LAST_NAME = {
  name: 'lastName',
  label: 'label.lastName',
  fullWidth: true,
  id: 'input-lastName',
};

export const BUSINESS_NAME = {
  name: 'businessName',
  label: 'label.businessName',
  fullWidth: true,
  required: true,
  id: 'input-businessName',
};

export const JOB_TITLE = {
  name: 'title',
  label: 'label.jobTitle',
  fullWidth: true,
  id: 'input-jobTitle',
};

export const OWNERSHIP_PERCENTAGE = {
  name: 'ownershipPercentage',
  label: 'label.ownershipPercentage',
  fullWidth: true,
  id: 'input-ownershipPercentage',
};

export const EMAIL_ADDRESS = {
  label: 'label.emailAddress',
  name: 'email',
  fullWidth: true,
  required: true,
  id: 'input-email',
};

export const ADDRESS_LINE_1 = {
  name: 'addressLine1',
  label: 'label.addressStreet',
  fullWidth: true,
  placeholder: '',
  id: 'input-addressLine1',
};

export const CITY = {
  name: 'city',
  label: 'label.city',
  fullWidth: true,
  id: 'input-city',
};

export const STATE = {
  name: 'state',
  label: 'label.stateRegion',
  fullWidth: true,
  id: 'input-state',
};

export const ZIP_CODE = {
  name: 'zipCode',
  label: 'label.postalCode',
  fullWidth: true,
  id: 'input-zipCode',
};

export const COUNTRY_CODE = {
  name: 'countryCode',
  label: 'label.country',
  fullWidth: true,
  id: 'select-countryCode',
};

export const COMPANY_ADDRESS_TYPE = {
  name: 'type',
  label: 'label.type',
  fullWidth: true,
  id: 'select-addressType',
};

export const WORKSPACE_OPTIONS = {
  name: 'workspace',
  label: 'label.chooseCompanyToLogin',
  displayEmpty: true,
  fullWidth: true,
  required: true,
  id: 'select-workspaces',
};

export const WORKSPACE_NAME = {
  label: 'label.workspaceName',
  tooltip: 'label.workspaceName.tooltip',
  name: 'displayName',
  fullWidth: true,
  required: true,
  id: 'input-workspaceName',
};

export const PHONE_NUMBER = {
  name: 'phoneNumber',
  label: 'label.mobileNumber',
  fullWidth: true,
  id: 'phone-phoneNumber',
};

export const USER_TRANSACTION_LIMIT = {
  name: 'transactionLimit',
  label: 'label.transactionLimit',
  fullWidth: true,
  id: 'input-transactionLimit',
};

export const REFERRAL_CODE = {
  name: 'referralCode',
  label: 'label.referralCode',
  fullWidth: true,
  id: 'phone-phoneNumber',
};

export const GROUP_BY = {
  name: 'groupBy',
  required: true,
  fullWidth: true,
  id: 'radio-groupBy',
};

export const TRANSACTION_LATEST_STATUS = {
  name: 'latestStatus',
  required: true,
  fullWidth: true,
  id: 'radio-latestStatus',
};

export const TRANSACTION_ALL_TYPES = {
  name: `types.${DefaultType.all}`,
  label: 'label.allTypes',
  id: 'checkbox-allTransactionTypes',
};

export const TRANSACTION_INFLOW = {
  name: `types.${TransactionFilterTypes.inflow}`,
  label: 'label.credit',
  id: 'checkbox-inflowTransactions',
};

export const TRANSACTION_OUTFLOW = {
  name: `types.${TransactionFilterTypes.outflow}`,
  label: 'label.debit',
  id: 'checkbox-outflowTransactions',
};

export const TRANSACTION_SEND = {
  name: `types.${TransactionFilterDirections.send}`,
  label: 'label.send',
  id: 'checkbox-debitTransactions',
};

export const TRANSACTION_REQUEST = {
  name: `types.${TransactionFilterDirections.request}`,
  label: 'label.request',
  id: 'checkbox-creditTransactions',
};

export const TRANSACTION_AMOUNT_RANGE = {
  name: 'amountRange.root',
  id: 'input-amountRange',
};

export const TRANSACTION_AMOUNT_MIN = {
  name: TransactionFilterAmount.min,
  label: 'label.min',
  fullWidth: true,
  type: 'number',
  id: 'input-minAmountRange',
};

export const TRANSACTION_AMOUNT_MAX = {
  name: TransactionFilterAmount.max,
  label: 'label.max',
  fullWidth: true,
  type: 'number',
  id: 'input-maxAmountRange',
};

export const COMPANY_NAME = {
  label: 'label.companyName',
  tooltip: 'label.companyName.tooltip',
  name: 'name',
  fullWidth: true,
  id: 'input-companyName',
  required: true,
};

export const BUSINESS_LEGAL_NAME = {
  label: 'label.businessLegalName',
  name: 'legalName',
  fullWidth: true,
  id: 'input-legalName',
};

export const OLD_TAX_ID = {
  name: 'oldTaxId',
  id: 'input-oldTaxId',
};

export const DATE = {
  name: 'date',
  id: 'input-date',
};

export const EMPLOYER_IDENTIFICATION_NUMBER = {
  label: 'label.employerIdentificationNumber',
  name: 'taxId',
  fullWidth: true,
  required: true,
  id: 'input-taxId',
  placeholder: 'xx-xxxxxxx',
  format: '##-#######',
  mask: 'x',
};

export const EMPLOYER_IDENTIFICATION_NUMBER_COUNTRY_CODE = {
  label: 'label.issuedCountry',
  name: 'taxIdCountryCode',
  fullWidth: true,
  required: true,
  id: 'select-taxIdCountryCode',
};

export const ISSUING_COUNTRY_CODE = {
  name: 'issuingCountryCode',
  label: 'label.issuingCountry',
  fullWidth: true,
  id: 'select-issuingCountry',
};

export const ISSUING_STATE_CODE = {
  name: 'issuingStateCode',
  label: 'label.issuingState',
  fullWidth: true,
  id: 'select-issuingState',
};

export const DOING_BUSINESS_AS = {
  label: 'label.doingBusinessAs',
  name: 'doingBusinessAsName',
  fullWidth: true,
  id: 'input-doingBusinessAsName',
};

export const NEW_PASSWORD = {
  label: 'label.newPassword',
  name: 'password',
  fullWidth: true,
  required: true,
  type: 'password',
  id: 'input-password',
};

export const PASSWORD = {
  label: 'label.password',
  name: 'password',
  fullWidth: true,
  type: 'password',
  id: 'input-password',
};

export const CONFIRM_PASSWORD = {
  label: 'label.confirmPassword',
  name: 'confirmPassword',
  fullWidth: true,
  required: true,
  type: 'password',
  id: 'input-confirmPassword',
};

export const COMPANY_PHONE_NUMBER = {
  label: 'label.companyPhoneNumber',
  name: 'phoneNumber',
  fullWidth: true,
  id: 'input-companyPhoneNumber',
  required: true,
};

export const COMPANY_PRIMARY_PHONE_NUMBER = {
  label: 'label.companyPhoneNumber',
  name: 'primaryPhone',
  fullWidth: true,
  id: 'input-primaryPhone',
  required: true,
};

export const ALERT_NAME = {
  name: 'name',
  label: 'label.alertName',
  fullWidth: true,
  required: true,
  id: 'input-alertName',
};

export const ALERT_IDENTIFIERS = {
  name: 'identifiers',
  fullWidth: true,
  id: 'input-identifiers',
};

export const ALERT_STATUS = {
  name: 'status',
  id: 'input-alertStatus',
};

export const ALERT_TIME_FROM = {
  name: 'timeframeStart',
  id: 'input-alertTimeframeStart',
};

export const ALERT_TIMEZONE = {
  name: 'timezone',
  id: 'input-alertTimezone',
};

export const ALERT_TIME_TO = {
  name: 'timeframeEnd',
  id: 'input-alertTimeframeEnd',
};

export const ALERT_TIMEFRAME = {
  name: 'timeframe',
  id: 'input-alertTimeframe',
};

export const ALERT_RECIPIENTS = {
  name: 'recipients',
  id: 'input-alertRecipients',
};

export const ALERT_MESSAGE = {
  name: 'message',
  id: 'input-alertMessage',
  fullWidth: true,
  multiline: true,
  rows: 3,
  maxCount: MAX_ALERT_MESSAGE,
};

export const ALERT_TYPE = {
  name: 'type',
  id: 'input-alertType',
};

export const ALERT_CRITERIA = {
  name: 'criteria',
  id: 'input-alertCriteria',
};

export const ALERT_CRITERIA_HAS_AMOUNT = {
  name: 'hasAmount',
  id: 'input-alertCriteriaHasAmount',
  label: 'label.amount',
};

export const ALERT_CRITERIA_HAS_DESCRIPTION = {
  name: 'hasDescription',
  id: 'input-alertCriteriaHasDescription',
  label: 'label.includes',
};

export const FILTER_DATE_FROM = {
  name: FilterDate.from,
  label: 'label.from',
  fullWidth: true,
  id: 'input-fromDateRange',
};

export const DATE_RANGE = {
  name: 'dateRange.root',
  label: 'label.to',
  id: 'input-toDateRange',
};

export const FILTER_DATE_TO = {
  name: FilterDate.to,
  label: 'label.to',
  fullWidth: true,
  id: 'input-toDateRange',
};

export const TRANSACTION_MEMO = {
  name: 'memo',
  id: 'input-memo',
  fullWidth: true,
  multiline: true,
  rows: 3,
  maxCount: MAX_MEMO_MESSAGE,
};

export const TRANSACTION_DESCRIPTION = {
  name: 'description',
  id: 'input-description',
  fullWidth: true,
  multiline: true,
  rows: 3,
  maxCount: MAX_MEMO_MESSAGE,
};

export const WEBSITE = {
  label: 'label.websiteOptional',
  name: 'website',
  id: 'input-website',
  fullWidth: true,
};

export const DOCUMENT_TYPE = {
  label: 'label.document',
  name: 'documentType',
  id: 'select-documentType',
  fullWidth: true,
  sx: { mb: 6 },
};

export const DOCUMENT_NAME = {
  label: 'label.name',
  name: 'documentName',
  id: 'input-documentName',
  fullWidth: true,
  sx: { mb: 4 },
};

export const CITIZENSHIP_COUNTRY_CODE = {
  name: 'citizenshipCountryCode',
  label: 'label.citizenshipCountry',
  fullWidth: true,
  id: 'select-citizenshipCountryCode',
};

export const DATE_OF_BIRTH = {
  label: 'label.dateOfBirth',
  name: 'dateOfBirth',
  id: 'input-dateOfBirth',
  fullWidth: true,
  disableFuture: true,
};

export const SOCIAL_SECURITY_NUMBER = {
  label: 'label.socialSecurityNumber',
  name: 'socialId',
  id: 'input-socialId',
  fullWidth: true,
  required: true,
  format: '###-##-####',
  placeholder: 'xxx-xx-xxxx',
  mask: 'x',
};

export const SOCIAL_ID_COUNTRY_CODE = {
  name: 'socialIdCountryCode',
  label: 'label.issuedCountry',
  fullWidth: true,
  required: true,
  disabled: true,
  id: 'select-socialIdCountryCode',
};

export const SINGLE_LIMIT = {
  name: LimitType.single,
  label: 'label.newSingleLimit',
  fullWidth: true,
  id: 'input-singleLimit',
};

export const DAY_LIMIT = {
  name: LimitType.daily,
  label: 'label.new24HLimit',
  fullWidth: true,
  id: 'input-dayLimit',
};

export const WEEK_LIMIT = {
  name: LimitType.weekly,
  label: 'label.new7DayLimit',
  fullWidth: true,
  id: 'input-weekLimit',
};

export const MONTH_LIMIT = {
  name: LimitType.monthly,
  label: 'label.new30DayLimit',
  fullWidth: true,
  id: 'input-monthLimit',
};

export const NICKNAME = {
  name: 'nickname',
  label: 'label.accountNickname',
  fullWidth: true,
  id: 'input-nickname',
};

export const DL_NUMBER = {
  name: 'number',
  label: 'label.driverLicenseNumber',
  fullWidth: true,
  id: 'input-driverLicenseNumber',
};

export const PASSPORT_NUMBER = {
  name: 'number',
  label: 'label.passportNumber',
  fullWidth: true,
  id: 'input-passportNumber',
};

export const ISSUED_DATE = {
  label: 'label.issuedDate',
  name: 'issuingDate',
  id: 'input-issuedDate',
  fullWidth: true,
  disableFuture: true,
};

export const EXPIRY_DATE = {
  label: 'label.expiryDate',
  name: 'expiryDate',
  id: 'input-expiryDate',
  fullWidth: true,
  disablePast: true,
};

export const CARD_NUMBER = {
  name: 'cardNumber',
  label: 'label.cardNumber',
  fullWidth: true,
  required: true,
  id: 'input-cardNumber',
  placeholder: '0000 0000 0000 0000',
};

export const CVV = {
  name: 'cvv',
  label: 'label.securityCode',
  fullWidth: true,
  required: true,
  id: 'input-cvv',
  placeholder: 'placeholder.cvv',
};

export const EXPIRE_DATE = {
  name: 'expiry',
  label: 'label.expireDate',
  fullWidth: true,
  required: true,
  id: 'input-expiry',
  views: ['month', 'year'],
  format: DateFormats.monthAndYear,
};

export const BANK_NAME = {
  name: 'bankName',
  label: 'label.bankName',
  required: true,
  fullWidth: true,
  id: 'input-bankName',
};

export const BANK_ACCOUNT_TYPE_SELECTOR = {
  name: 'accountType',
  label: 'label.accountType',
  required: true,
  fullWidth: true,
  displayEmpty: true,
  id: 'select-accountType',
};

export const ROUTING_NUMBER = {
  name: 'routingNumber',
  label: 'label.routingNumber',
  required: true,
  fullWidth: true,
  id: 'input-routingNumber',
  placeholder: '123456789',
};

export const ACCOUNT_NUMBER = {
  name: 'accountNumber',
  label: 'label.accountNumber',
  required: true,
  fullWidth: true,
  id: 'input-accountNumber',
  placeholder: '01234567890',
};

export const REVERSAL_REASONS = {
  name: 'reversalReason',
  label: 'label.reversalReason',
  fullWidth: true,
  required: true,
  id: 'select-reversalReason',
};

export const CORRECTION_RECIPIENT = {
  name: 'correctionBankAccountId',
  label: 'label.bankAccount',
  fullWidth: true,
  id: 'select-correctionFinancialAccountId',
};

export const CORRECTION_AMOUNT = {
  name: 'correctionAmount',
  label: 'label.amount',
  fullWidth: true,
  id: 'input-correctionAmount',
};

export const ALLOWED_BANK_ACCOUNTS = {
  name: 'bankAccounts',
  label: 'label.bankAccount',
  fullWidth: true,
  id: 'select-bankAccounts',
};

export const USER_ROLE = {
  name: 'authority',
  label: 'label.role',
  fullWidth: true,
  id: 'select-authority',
};

export const TRANSACTION_REVIEW_NOTE = {
  name: 'note',
  label: 'label.note',
  multiline: true,
  rows: 5,
  id: 'textArea-transactionReviewNote',
  sx: { width: '50%' },
};

export const AMOUNT_RANGE_MIN = {
  name: 'amountRange.min',
  id: 'input-amountRange-min',
  fullWidth: true,
};

export const AMOUNT_RANGE_MAX = {
  name: 'amountRange.max',
  id: 'input-amountRange-max',
  fullWidth: true,
};

export const FUNDING_MIN_THRESHOLD = {
  name: 'minimumThreshold',
  label: 'label.minimumThresholdAmount',
  fullWidth: true,
  id: 'input-minimumThreshold',
  required: true,
};

export const FUNDING_AMOUNT = {
  name: 'amount',
  label: 'label.topUpAmount',
  fullWidth: true,
  id: 'input-amount',
  required: true,
};

export const FUNDING_BANK_ACCOUNT_TO = {
  name: 'bankAccountToId',
  id: 'input-bankAccountToId',
};

export const DATE_RANGE_TYPE = {
  name: 'dateRange.type',
  fullWidth: true,
  id: 'input-dateRangeType',
};

export const FUNDING_BANK_ACCOUNT_FROM = {
  name: 'bankAccountFromId',
  id: 'input-bankAccountFromId',
  label: 'label.fundingAccount',
  fullWidth: true,
  required: true,
  displayEmpty: true,
};

export const KEYWORDS = {
  name: 'keywords',
  fullWidth: true,
  id: 'input-keywords',
  multiline: true,
  rows: 3,
};

export const TRANSACTION_TYPE = {
  name: 'transactionType',
  fullWidth: true,
  displayEmpty: true,
  id: 'input-transactionType',
};

export const AMOUNT_CONDITION = {
  name: 'amountCondition',
  fullWidth: true,
  id: 'input-amountCondition',
  displayEmpty: true,
};
