import { styled } from '@mui/material/styles';

export const AutocompleteWrapper = styled('div')(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
  },

  '& .MuiAutocomplete-endAdornment': {
    '& svg path': {
      fill: theme.palette.text.primary,
    },

    '& .Mui-disabled': {
      opacity: 1,
    },
  },

  '& .MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-sizeSmall': {
    padding: '3px 14px',
  },

  '& .Mui-disabled fieldset': {
    borderColor: `${theme.palette.general.lightGrey9} !important`,
  },
}));

export const PaperComponent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  border: `1px solid ${theme.palette.border.secondary}`,
  borderRadius: '4px',
  boxShadow: 'none',
  backgroundColor: theme.palette.common.white,

  '& .MuiAutocomplete-listbox': {
    padding: 0,
    '& li': {
      padding: 0,
    },
  },
}));
