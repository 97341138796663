import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const UserDetails = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'UserDetails' */ 'modules/user-details/containers/UserDetails')
    ),
    'UserDetails',
  ),
);

export default UserDetails;
