import {
  Checkbox, TableCell, TableRow, Tooltip, Typography,
} from '@mui/material';
import { ChangeEvent, FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { TableHead, TableSortLabel } from 'components/Table/ResponsiveTableHead/index.styled';

import { SortOrder, TableHeaderColors } from 'constants/enums';
import { Column } from 'models/column.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { capitalize } from 'utils/text';

interface ResponsiveTableHeadProps {
  id: string;
  columns: Column[];
  onSort?: (columnName: string) => void;
  sortOptions?: SortOptions;
  isSelectable?: boolean;
  isCollapsable?: boolean;
  isRounded?: boolean;
  isFullWidth?: boolean;
  allSelected?: boolean;
  onSelectAll?: (e: ChangeEvent<HTMLInputElement>) => void;
  tableHeaderColors?: TableHeaderColors;
}

const ResponsiveTableHead: FC<ResponsiveTableHeadProps> = (props): ReactElement => {
  const {
    id,
    columns,
    onSort,
    sortOptions,
    onSelectAll,
    allSelected = false,
    isSelectable = false,
    isCollapsable = false,
    isRounded = false,
    isFullWidth = false,
    tableHeaderColors = TableHeaderColors.general,
  } = props;
  const intl = useIntl();

  const createSortHandler = (columnName: string): void => {
    if (onSort) {
      onSort(columnName);
    }
  };

  const renderCell = (column: Column): ReactElement => {
    const hasSortOption = sortOptions?.name === column.name;
    const labelMessage = column?.label
      ? intl.formatMessage({ id: column.label })
      : '';
    const formattingLabel = column?.skipCapitalize ? labelMessage : capitalize(labelMessage);

    if (column?.isSortable && column?.label) {
      return (
        <TableSortLabel
          active={hasSortOption}
          direction={hasSortOption ? sortOptions?.type : SortOrder.desc}
          onClick={(): void => createSortHandler(column.name)}
        >
          <Typography variant="body2" className="text-ellipsis">
            {formattingLabel}
          </Typography>
        </TableSortLabel>
      );
    }

    if (formattingLabel) {
      return (
        <Typography variant="body2" className="text-ellipsis">
          {formattingLabel}
        </Typography>
      );
    }

    return <div />;
  };

  return (
    <TableHead
      id={`tableHead-${id}`}
      isRounded={isRounded}
      isFullWidth={isFullWidth}
      tableHeaderColors={tableHeaderColors}
    >
      <TableRow>
        {isCollapsable && <TableCell id={`tableHead-collapsable-${id}`}><div className="innerCell" /></TableCell>}
        {isSelectable && (
          <TableCell padding="checkbox" id={`tableHead-checkAll-${id}`}>
            <div className="innerCell">
              <Tooltip
                arrow
                id="tooltip-info"
                title={intl.formatMessage({ id: 'label.selectAll' })}
                placement="right"
              >
                <Checkbox
                  id={`tableHead-checkbox-${id}`}
                  checked={allSelected}
                  onChange={onSelectAll}
                />
              </Tooltip>
            </div>
          </TableCell>
        )}
        {columns.map((column) => (
          <TableCell
            id={`tableHead-column-${column.name}`}
            key={column.name}
            sx={column?.sx}
          >
            <div className={`innerCell ${column.cellClassName || ''}`}>
              {renderCell(column)}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ResponsiveTableHead;
