import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const BankAccountsOverview = lazy(
  () => CustomLazyLoader(
    () => import(/* webpackChunkName: 'BankAccountsOverview' */ 'modules/bank-accounts-overview/containers/Overview'),
    'BankAccountsOverview',
  ),
);

export default BankAccountsOverview;
