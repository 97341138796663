import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import InputField from 'fields/InputField';

export const Textarea = styled(InputField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    padding: theme.spacing(3, 4.5, 7.5),
    borderRadius: '4px',

  },
  '& textarea': {
    padding: theme.spacing(0, 1, 0, 0),

    '&::-webkit-scrollbar': {
      width: '6px',
    },

    '&::-webkit-scrollbar-track': {
      background: theme.palette.common.white,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.general.lightGrey3,
      borderRadius: '8px',
    },

    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.general.lightGrey3,
    },
  },
}));

export const Text = styled(
  Typography,
  { shouldForwardProp: (props) => props !== 'hasError' },
)<{ hasError: boolean }>(({ hasError }) => ({
  position: 'absolute',
  bottom: hasError ? 30 : 8,
  right: 16,
}));
