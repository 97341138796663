/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const CardIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.19998 4C2.31598 4 1.59998 4.716 1.59998 5.6V6.4H22.4V5.6C22.4 4.716 21.684 4 20.8 4H3.19998ZM1.59998 8.8V18.4C1.59998 19.284 2.31598 20 3.19998 20H20.8C21.684 20 22.4 19.284 22.4 18.4V8.8H1.59998ZM3.99998 10.4H10.4V11.2H3.99998V10.4Z" fill={color} />
  </svg>
);

export default CardIcon;
