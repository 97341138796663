import {
  FormControl, FormLabel, InputBaseComponentProps, TextFieldVariants, Typography,
} from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers';
import { FC, ReactNode } from 'react';

import CalendarIcon from 'assets/icons/CalendarIcon';
import { DateFormats } from 'constants/enums';

import { DatePicker, Paper, PickersDay } from './index.styled';

interface DatePickerFieldProps {
  id?:string;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  size?: 'small' | 'medium' | undefined;
  value?: number | Date | null;
  name: string;
  error?: boolean;
  helperText?: string | ReactNode;
  inputProps?: InputBaseComponentProps;
  format?: DateFormats;
  label?: string;
  onChange: (date: number | null) => void;
  minDate?: number | null;
  maxDate?: number | null;
  variant?: TextFieldVariants;
}

const DatePickerField:FC<DatePickerFieldProps> = (props) => {
  const {
    label,
    id,
    onChange,
    format = DateFormats.defaultDate,
    fullWidth = false,
    disabled = false,
    required,
    name,
    error,
    value,
    size = 'small',
    variant = 'filled',
    helperText,
    minDate,
    maxDate,
    inputProps,
    ...remainingProps
  } = props;
  const formattedValue = value ? new Date(value) : null;

  const handleChange = (date: unknown, context: { validationError: DateValidationError }) => {
    const newDate = context?.validationError === 'invalidDate' ? null : new Date(date as Date).getTime();
    onChange(newDate);
  };

  return (
    <FormControl component="div" fullWidth={fullWidth}>
      {label && (
        <FormLabel required={required}>
          <Typography variant="label1" color="textPrimary">
            {label}
          </Typography>
        </FormLabel>
      )}
      <DatePicker
        {...remainingProps}
        format={format}
        onChange={handleChange}
        value={formattedValue}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate ? new Date(maxDate) : undefined}
        slotProps={{
          textField: {
            id,
            size,
            error,
            helperText,
            name,
            inputProps,
            variant,
          },
        }}
        disabled={disabled}
        slots={{
          openPickerIcon: CalendarIcon,
          day: PickersDay,
          desktopPaper: Paper,
        }}
      />
    </FormControl>
  );
};

export default DatePickerField;
