/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const DeleteIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.00018 19C6.00184 19.5299 6.21313 20.0377 6.58791 20.4124C6.96269 20.7871 7.47052 20.9983 8.00054 21H16.002C16.532 20.9983 17.0398 20.7871 17.4146 20.4124C17.7894 20.0377 18.0007 19.5299 18.0023 19V7H6.00018V19ZM19 4H15.5013L14.5011 3H9.50018L8.5 4H5V6H19V4Z" fill={color} />
  </svg>
);

export default DeleteIcon;
