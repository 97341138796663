import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

export const Signup = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'Signup' */ 'modules/signup/containers/Signup')
    ),
    'Signup',
  ),
);

export const CompleteProfile = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'CompleteProfile' */ 'modules/signup/containers/CompleteProfile'
    ),
    'CompleteProfile',
  ),
);

export const ChooseWorkspace = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'ChooseWorkspace' */'modules/signup/containers/ChooseWorkspace'
    ),
    'ChooseWorkspace',
  ),
);
