import {
  FormControl, FormHelperText, FormLabel, InputProps, SxProps, Typography,
} from '@mui/material';
import { FC, ChangeEvent, ReactNode } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { PhoneWrapper } from 'fields/PhoneField/index.styled';

interface PhoneFieldProps {
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  name: string;
  id: string;
  phoneNumber: string;
  error?: boolean;
  helperText?: string | ReactNode;
  inputProps: InputProps;
  sx?: SxProps;
  onlyCountries?: string[];
  country?: string;
}

const PhoneField :FC<PhoneFieldProps> = (props) => {
  const {
    label,
    fullWidth = false,
    required,
    name,
    phoneNumber,
    inputProps,
    error,
    helperText,
    id,
    sx,
    country = 'us',
    ...remainingProps
  } = props;

  const handleChanges = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ) => {
    if (inputProps.onChange) {
      inputProps.onChange({
        target: {
          name,
          value: formattedValue,
        },
      } as ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <PhoneWrapper hasError={error}>
      <FormControl component="div" fullWidth={fullWidth} sx={sx}>
        {label && (
          <FormLabel required={required}>
            <Typography variant="label1">
              {label}
            </Typography>
          </FormLabel>
        )}
        <PhoneInput
          {...remainingProps}
          inputProps={{
            name,
            id,
          }}
          country={country}
          specialLabel=""
          value={phoneNumber}
          onChange={handleChanges}
        />
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </PhoneWrapper>
  );
};

export default PhoneField;
