import { AuthorityType } from 'constants/enums';
import { User } from 'models/user.interface';
import { Workspace } from 'models/workspace.interface';
import { operatorsEmails } from 'utils/featureFlag';

export const isWorkspaceOwner = (user: User | Workspace): boolean => (
  !!user?.roles?.find(({ authority }) => authority === AuthorityType.userOwner)
);

export const isWorkspaceAdmin = (user: User): boolean => (
  !!user?.roles?.find(({ authority }) => authority === AuthorityType.userAdmin)
);

// TODO: [PORTAL] update this after having the proper support from BE
export const isLoggedAsOperator = (user: User, viewAs: User|null): boolean => (
  !viewAs && operatorsEmails.includes(user.email)
);

export const isOperator = (user: User): boolean => (
  user.roles.some((role) => role.authority === AuthorityType.opsAdmin)
);
