import {
  Box, Grid2 as Grid, Skeleton, Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl';

import DonutChart from 'charts/DonutChart';
import TotalVolume from 'modules/insights-advanced-search/components/TotalVolume';
import ValueSection from 'modules/insights-advanced-search/components/ValueSection';
// import VolumeChart from 'modules/insights-advanced-search/components/VolumeChart';

import { useAppSelector } from 'hooks/useRedux';
import { advancedSearchSelector, isLoadingAdvancedSearchSelector } from 'store/cash-flow/selectors';

import TransactionIcon from 'assets/icons/TransactionIcon';

import { IconWrapper, ValueWrapper, VolumeWrapper } from './index.styled';

const TotalTransactionsVolume = () => {
  const intl = useIntl();
  const isLoading = useAppSelector(isLoadingAdvancedSearchSelector);
  const data = useAppSelector(advancedSearchSelector, isEqual);

  return (
    <Grid container>
      <VolumeWrapper size={{ xs: 12, lg: 4.5, xl: 5 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" color="common.white">
            {intl.formatMessage({ id: 'label.totalTransactionsVolume' })}
          </Typography>
          <IconWrapper>
            <TransactionIcon color="currentColor" />
          </IconWrapper>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={3}>
            <DonutChart
              data={[data?.totalCreditVolume, data?.totalDebitVolume]}
              colors={['success.secondary', 'error.main']}
            />
            {isLoading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={33}
                width={86}
                sx={{ backgroundColor: 'general.darkGreen2' }}
              />
            ) : (
              <Typography variant="h1" color="common.white" fontSize="48px">
                {data?.totalVolume}
              </Typography>
            )}
          </Box>
          {/* <VolumeChart */}
          {/*  data={[{ x: 10, y: 12 }, { x: 15, y: 40 }, { x: 20, y: 25 }, { x: 100, y: 99 }]} */}
          {/*  percentage={10} */}
          {/*  balance="+100" */}
          {/*  isLoading={isLoading} */}
          {/*  useSecondaryColors */}
          {/*  isPositive={isPositive} */}
          {/*  isSkeletonDark */}
          {/* /> */}
        </Box>
      </VolumeWrapper>
      <ValueWrapper size={{ xs: 6, lg: 3.75, xl: 3.5 }}>
        <TotalVolume
          currentVolume={data?.totalCreditVolume}
          isLoading={isLoading}
          isCredits
          // variableVolume="-131"
          // percentage={10}
          // data={[{ x: 10, y: 12 }, { x: 15, y: 40 }, { x: 20, y: 99 }, { x: 100, y: 5 }]}
        />
        <ValueSection
          currentAmount={data?.totalCreditValue}
          isLoading={isLoading}
          // variableAmount={192938.31}
          // percentage={14}
          // isPositive
        />
      </ValueWrapper>
      <ValueWrapper size={{ xs: 6, lg: 3.75, xl: 3.5 }} hideBorder>
        <TotalVolume
          currentVolume={data?.totalDebitVolume}
          isLoading={isLoading}
          // variableVolume="+31"
          // percentage={10}
          // isPositive={isPositive}
          // data={[{ x: 10, y: 12 }, { x: 15, y: 40 }, { x: 20, y: 25 }, { x: 100, y: 99 }]}
        />
        <ValueSection
          currentAmount={data?.totalDebitValue}
          isLoading={isLoading}
          // variableAmount={20837.02}
          // percentage={52}
        />
      </ValueWrapper>
    </Grid>
  );
};

export default TotalTransactionsVolume;
