/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const MoveMoneyIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 24 24">
    <path fill={color} d="M18.667 3v2.077h-4v.568c1.042.481 1.829 1.248 2.28 2.201h1.72v2.077L22 6.462 18.667 3Zm-8 2.077v1.55c-1.67.404-2.703 1.585-2.703 3.235 0 1.653.954 2.703 2.806 3.067l1.332.276c1.157.24 1.598.554 1.598 1.137 0 .692-.659 1.172-1.62 1.172-1.065 0-1.802-.487-1.865-1.252H7.76c.034 1.65 1.124 2.771 2.907 3.134v1.527h2.666v-1.539c1.826-.387 2.907-1.58 2.907-3.363 0-1.667-.898-2.607-2.896-3.008l-1.305-.262c-1.066-.22-1.516-.554-1.516-1.13 0-.699.597-1.143 1.502-1.143.904 0 1.584.517 1.64 1.26h2.378c-.034-1.547-1.087-2.698-2.71-3.105V5.077h-2.666Zm-5.334 9L2 17.538 5.333 21v-2.077h4v-.487c-1.166-.465-2.017-1.258-2.487-2.282H5.333v-2.077Z" />
  </svg>
);

export default MoveMoneyIcon;
