import { Typography } from '@mui/material';
import { FC } from 'react';

import { BusinessAddress } from 'models/address.interface';

interface BusinessAddressInformationProps {
  address: BusinessAddress;
}

const BusinessAddressInformation: FC<BusinessAddressInformationProps> = ({ address }) => (
  <Typography variant="body1" fontWeight={600} mt={2}>
    {address.streetLine1}
    <br />
    {`${address.cityName}, ${address.stateCode}`}
    <br />
    {`${address.zipCode}, ${address.countryCode}`}
  </Typography>
);

export default BusinessAddressInformation;
