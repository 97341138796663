import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import { SIDEBAR_WIDTH } from 'constants/general';

export const Drawer = styled(
  MuiDrawer,
  { shouldForwardProp: (props) => props !== 'isMini' },
)<{ isMini?: boolean }>(({ theme, isMini }) => ({
  minHeight: '100vh',
  width: isMini ? `${SIDEBAR_WIDTH.mini}px` : `${SIDEBAR_WIDTH.default}px`,
  '& .MuiDrawer-paper': {
    borderRadius: 0,
    // TODO: improve this by using a burger menu?
    [theme.breakpoints.down('md')]: {
      position: 'unset',
    },
  },
}));

export const DrawerContent = styled('div')<{ isMini?: boolean }>(({ theme, isMini }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: isMini ? 'center' : 'unset',
  backgroundColor: theme.palette.primary.secondary,
  width: isMini ? `${SIDEBAR_WIDTH.mini}px` : `${SIDEBAR_WIDTH.default}px`,
}));

export const LinkWrapper = styled('div')<{ isActive: boolean }>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.common.white : theme.palette.general.lightGrey6,
  padding: theme.spacing(2, 4),
  borderRadius: '8px',
  transition: 'all 0.25s ease-out',
  fontWeight: isActive ? 700 : 600,
  display: 'flex',
  alignItems: 'center',

  backgroundColor: isActive
    ? 'rgba(255, 255, 255, 0.20)'
    : 'transparent',

  '& svg': {
    flex: '0 0 24px',
    marginRight: theme.spacing(3),
    '& > path': {
      fill: isActive ? theme.palette.common.white : theme.palette.general.lightGrey6,
      transition: 'all 0.25s ease-out',
    },
  },

  '&:hover': {
    backgroundColor: 'rgba(167, 191, 190, 0.30)',
    color: theme.palette.common.white,

    '& svg > path': {
      fill: theme.palette.common.white,
      transition: 'all 0.25s ease-out',
    },
  },
}));

export const NavbarLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));

export const PortalBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.general.darkGreen2,
  textAlign: 'center',
  color: theme.palette.common.white,
  marginBottom: theme.spacing(3),
  padding: theme.spacing(0, 2),
}));
