/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const BarChartIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 24 24">
    <path fill={color} d="M18 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-3Zm-7 4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-3Zm-7 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4Z" />
  </svg>
);

export default BarChartIcon;
