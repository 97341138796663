import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const CompanyDetails = lazy(
  () => CustomLazyLoader(
    () => import(/* webpackChunkName: 'CompanyDetails' */ 'modules/company-details/containers/CompanyDetails'),
    'CompanyDetails',
  ),
);

export default CompanyDetails;
