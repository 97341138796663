import { Typography, Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import CardType from 'components/CardType';

import FilledUserIcon from 'assets/icons/FilledUserIcon';
import { FinancialAccountType } from 'constants/enums';
import { NOT_APPLICABLE } from 'constants/general';
import { FaAccountTransaction } from 'models/faTransaction.interface';

interface RecipientFinancialAccountDetailsProps {
  recipient: FaAccountTransaction;
}

const RecipientFinancialAccountDetails:FC<RecipientFinancialAccountDetailsProps> = ({ recipient }) => {
  const {
    bankAccountName, accountNumber, faType: accountType,
  } = recipient;
  const intl = useIntl();

  const accountDetails = useMemo(() => {
    if (!accountNumber) {
      return {
        name: bankAccountName || NOT_APPLICABLE,
      };
    }

    if (accountType === FinancialAccountType.bank) {
      return {
        name: `${bankAccountName} *${accountNumber}`,
        accountInfo: intl.formatMessage({ id: 'label.bankPreview' }, { number: accountNumber }),
      };
    }

    return {
      name: `${bankAccountName} *${accountNumber}`,
      accountInfo: intl.formatMessage({ id: 'label.cardPreview' }, { number: accountNumber }),
    };
  }, [recipient]);

  return (
    <Box display="flex" alignItems="center" gap={3}>
      <FilledUserIcon />
      <div>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography fontWeight={600}>
            {accountDetails.name}
          </Typography>
          {accountType === FinancialAccountType.card && (
            <CardType label={intl.formatMessage({ id: 'label.debit' })} />
          )}
        </Box>
        {accountDetails.accountInfo && (
          <Typography variant="body3" color="textSecondary">
            {accountDetails.accountInfo}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default RecipientFinancialAccountDetails;
