import { KybSteps } from 'constants/enums';
import { Beneficiary, Company, KybDetails } from 'models/company.interface';
import { Document } from 'models/document.interface';

export const removeTaxIdFormatting = (value?: string|null) => value?.replace(/x|-/g, '')?.trim();
export const formatBusinessTaxId = (value?: string|null) => {
  const newTaxId = removeTaxIdFormatting(value) || '';

  if (newTaxId.length === 4) {
    return newTaxId;
  }

  return `${newTaxId.slice(0, 2)}-${newTaxId.slice(2)}`;
};

export const removeSocialIdFormatting = (value?: string|null) => value?.replace(/x|-/g, '')?.trim();
export const formatSocialSecurityNumber = (socialId?: string) => {
  const newSocialId = removeSocialIdFormatting(socialId);
  if (!newSocialId) return undefined;

  if (newSocialId.length === 4) {
    return newSocialId;
  }

  return `${newSocialId.slice(0, 3)}-${newSocialId.slice(3, 5)}-${newSocialId.slice(5, 9)}`;
};

interface MissingInfoProps {
  business: string[];
  beneficiary: boolean;
}

export const checkMissingInfo = ({ beneficiaries, company }: KybDetails): MissingInfoProps => {
  const missingInfo: MissingInfoProps = {
    business: [],
    beneficiary: false,
  };

  if (!company?.customer?.taxId) {
    missingInfo.business.push('label.employerIdentificationNumber');
  }
  if (!company?.customer?.primaryPhone) {
    missingInfo.business.push('label.primaryPhoneNumber');
  }
  if (!company?.customer?.addresses?.length) {
    missingInfo.business.push('label.address');
  }

  if (!beneficiaries?.length) {
    missingInfo.beneficiary = true;
  }

  return missingInfo;
};

export const isStepCompleted = (
  step: KybSteps,
  company: Company|null,
  documents: Document[],
  beneficiaries?: Beneficiary[],
) => {
  switch (step) {
    case KybSteps.companyDetails:
      return Boolean(company?.taxId && company?.primaryPhone);
    case KybSteps.companyAddress:
      return Boolean(company?.addresses?.length);
    case KybSteps.documents:
      return Boolean(documents?.length);
    case KybSteps.beneficiaries:
      return Boolean(beneficiaries?.length);
    default:
      return false;
  }
};

export const getKybProgress = (company: Company|null, documents: Document[], beneficiaries: Beneficiary[]) => {
  let completedSteps: number = 0;
  let incompleteStep: KybSteps|null = null;

  [
    KybSteps.companyDetails,
    KybSteps.companyAddress,
    KybSteps.documents,
    KybSteps.beneficiaries,
  ].forEach((step) => {
    if (isStepCompleted(step, company, documents, beneficiaries)) {
      completedSteps += 1;
    } else if (!incompleteStep) {
      incompleteStep = step;
    }
  });

  return {
    progress: (completedSteps * 100) / 4,
    incompleteStep: incompleteStep || KybSteps.review,
  };
};
