/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ReverseIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.10556 3L2.62767 8.47702L2 9.13312L2.62767 9.78922L8.10556 15.2662L9.41797 13.954L5.50927 10.046H15.6091C18.1234 10.046 20.174 12.0963 20.174 14.6101C20.174 17.124 18.1234 19.1743 15.6091 19.1743H13.7832V21H15.6091C19.1184 21 22 18.1189 22 14.6101C22 11.1014 19.1184 8.22029 15.6091 8.22029H5.50927L9.41797 4.3122L8.10556 3ZM2.82739 19.1743V21H4.65335V19.1743H2.82739ZM6.47932 19.1743V21H8.30528V19.1743H6.47932ZM10.1312 19.1743V21H11.9572V19.1743H10.1312Z" fill={color} />
  </svg>
);

export default ReverseIcon;
