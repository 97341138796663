import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { BANK_ACCOUNT_TYPES_AS_LABELS } from 'constants/labels';
import { FaAccountTransaction } from 'models/faTransaction.interface';
import { getBankLogo } from 'utils/bank';
import { formatAcctNumberAndName } from 'utils/formatters';

import { BankImg, InstitutionWrapper } from './index.styled';

interface FinancialAccountDetailsProps {
  financialBankAccount: FaAccountTransaction;
}

const FinancialAccountDetails: FC<FinancialAccountDetailsProps> = ({ financialBankAccount }) => {
  const {
    institution, accountNumber, type, nickname, bankAccountName,
  } = financialBankAccount || {};
  const intl = useIntl();
  const typeLabel = type ? BANK_ACCOUNT_TYPES_AS_LABELS[type] : null;

  return (
    <Box display="flex" alignItems="center">
      <BankImg src={getBankLogo(institution)} alt={nickname || bankAccountName} />

      <InstitutionWrapper>
        <Typography
          variant="body3"
          fontWeight={700}
          className="text-ellipsis"
        >
          {nickname || bankAccountName}
        </Typography>

        <Typography variant="body3" fontWeight={600} color="textSecondary" className="text-ellipsis">
          {formatAcctNumberAndName({
            accountNumber,
            accountName: typeLabel ? intl.formatMessage({ id: typeLabel }) : '',
            isAccountWrapped: true,
          })}
        </Typography>
      </InstitutionWrapper>
    </Box>
  );
};

export default FinancialAccountDetails;
