import { merge } from 'lodash';

const FLAVOUR = process.env.REACT_APP_FLAVOUR || 'primaryacct';
const TARGET_ENV = process.env.REACT_APP_TARGET_ENV || 'prod';

// eslint-disable-next-line
console.log(`loading ${FLAVOUR}/${TARGET_ENV} in ${process.env.NODE_ENV} mode`);

/* eslint import/no-dynamic-require: "off" */
const {
  default: {
    [TARGET_ENV]: { default: envConfig },
  },
// eslint-disable-next-line @typescript-eslint/no-var-requires
} = require(`../../flavours/${FLAVOUR}/config`);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { default: commonConfig } = require(`../../flavours/${FLAVOUR}/config/config`);

export default merge(commonConfig, envConfig);
