import { TextFieldProps, Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Text, Textarea } from './index.styled';

interface TextareaFieldProps {
  maxCount: number;
  count: number;
  error?: boolean;
}

const TextareaField: FC<TextareaFieldProps & TextFieldProps> = ({
  maxCount, count, error = false, ...remainingProps
}) => {
  const intl = useIntl();

  const renderBold = (chunks: ReactNode[]) => <b>{chunks}</b>;

  return (
    <Box position="relative">
      <Textarea {...remainingProps} error={error} className="custom-scroll" />
      <Text variant="body3" color="textSecondary" hasError={error}>
        {intl.formatMessage({ id: 'label.maxCountCharacters' }, {
          count,
          maxCount,
          b: renderBold,
        })}
      </Text>
    </Box>
  );
};

export default TextareaField;
