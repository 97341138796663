import { Box } from '@mui/material';
import { ReactElement, FC, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import Content from 'components/_Content';
import CompanyNameAsTitle from 'components/CompanyNameAsTitle';
import Sidebar from 'layouts/PortalLayout/Sidebar';
import SidebarSettings from 'layouts/SettingsLayout/SidebarSettings';
import CustomSuspense from 'routes/CustomSuspense';

import { SidebarContext, useSidebar } from 'hooks/useSidebar';

import { TABLET_WIDTH } from 'constants/general';

import { OutletWrapper } from './index.styled';

interface PortalSettingsLayoutProps {
  context: SidebarContext;
  hasTitle?: boolean;
}
const PortalSettingsLayout:FC<PortalSettingsLayoutProps> = ({ hasTitle = false, context }): ReactElement => {
  const sidebarItems = useSidebar(context);
  const items = useMemo(() => sidebarItems.filter(({ isVisible }) => isVisible), [sidebarItems]);

  return (
    <Box minWidth={TABLET_WIDTH} width="100%" display="flex">
      <Sidebar sidebarItems={sidebarItems} />
      <Content>
        {hasTitle && <CompanyNameAsTitle />}

        <Box display="flex" mt={6}>
          <SidebarSettings sidebarItems={items} hasTitle={hasTitle} />

          <OutletWrapper>
            <CustomSuspense>
              <Outlet />
            </CustomSuspense>
          </OutletWrapper>
        </Box>
      </Content>
    </Box>
  );
};

export default PortalSettingsLayout;
