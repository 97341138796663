const MuiTooltip = {
  styleOverrides: {
    tooltip: ({ theme }: any) => ({
      backgroundColor: theme.palette.general.lightBlack,
      borderRadius: '2px',
      fontSize: '12px',
      lineHeight: '17px',
      padding: theme.spacing(1.5, 2),
      border: `1px solid ${theme.palette.border.main}`,
    }),
    arrow: ({ theme }: any) => ({
      color: theme.palette.general.lightBlack,
      '&::before': {
        border: `1px solid ${theme.palette.border.main}`,
      },
    }),
  },
};

export default MuiTooltip;
