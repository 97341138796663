import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import { NAVBAR_HEIGHT, SIDEBAR_WIDTH } from 'constants/general';

export const ContentWrapper = styled(
  Paper,
  { shouldForwardProp: (props) => props !== 'hasTitle' },
)<{ hasTitle: boolean }>(({ theme, hasTitle }) => ({
  minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px - 75px - ${hasTitle ? '90px' : '0px'})`,
  flex: `0 0 ${SIDEBAR_WIDTH.default}px`,
  padding: theme.spacing(5, 0),
  position: 'sticky',
  top: '94px',

  [theme.breakpoints.down('md')]: {
    flex: `0 0 ${SIDEBAR_WIDTH.small}px`,
  },
}));

interface LinkWrapperProps {
  isActive: boolean;
  collapsable?: boolean;
}

export const LinkWrapper = styled('div')<LinkWrapperProps>(({ theme, isActive, collapsable }) => ({
  transition: 'all 0.25s ease-out',
  width: '100%',
  borderRight: `4px solid ${isActive && !collapsable ? theme.palette.primary.main : theme.palette.common.white}`,
}));

export const LinkContent = styled('div')<LinkWrapperProps>(({ theme, isActive, collapsable }) => ({
  transition: 'all 0.25s ease-out',
  display: 'flex',
  alignItems: 'center',
  justifyContent: collapsable ? 'space-between' : 'flex-start',
  padding: theme.spacing(2, 4, 2, 4),
  margin: theme.spacing(0, 3),

  '& svg': {
    flex: '0 0 24px',
    marginRight: theme.spacing(3),
    '& > path': {
      fill: isActive
        ? theme.palette.primary.main
        : theme.palette.general.lightGrey6,
      transition: 'all 0.25s ease-out',
    },
  },

  '& .MuiTypography-root': {
    color: isActive ? theme.palette.text.primary : theme.palette.text.disabled,
  },

  '&:hover': {
    backgroundColor: theme.palette.general.lightGrey4,
    borderRadius: '4px',

    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      transition: 'all 0.25s ease-out',
    },

    '& svg > path': {
      fill: theme.palette.text.primary,
      transition: 'all 0.25s ease-out',
    },
  },
}));

export const NavbarLink = styled(NavLink)(() => ({
  textDecoration: 'none',
}));
