/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ArrowRightIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 13.0007H16.1695L10.5798 18.5901L11.9994 20L20 12L11.9994 4L10.5894 5.40993L16.1695 11.0004H4V13.0007Z" fill={color} />
  </svg>
);

export default ArrowRightIcon;
