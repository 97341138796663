/* eslint max-len: 0 */
import { CustomIconInterface } from 'models/icon.interface';

const UnlinkIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3125 3C15.1113 3 13.8984 3.46875 12.9844 4.38281L11.8828 5.48438C11.1211 6.24609 10.6699 7.21289 10.5469 8.20312L12.0469 8.39062C12.1318 7.71094 12.4189 7.05762 12.9375 6.53906L14.0391 5.4375C15.293 4.18359 17.3086 4.18359 18.5625 5.4375C19.8164 6.69141 19.8164 8.70703 18.5625 9.96094L17.4609 11.0625H17.4375C16.9189 11.584 16.2861 11.8711 15.6094 11.9531L15.7969 13.4531C16.7871 13.3301 17.7539 12.8789 18.5156 12.1172L19.6172 11.0156C21.4424 9.19043 21.4424 6.20801 19.6172 4.38281C18.7031 3.46875 17.5137 3 16.3125 3ZM5.78906 4.71094L4.71094 5.78906L18.2109 19.2891L19.2891 18.2109L5.78906 4.71094ZM8.20312 10.5469C7.21289 10.6699 6.24609 11.1211 5.48438 11.8828L4.38281 12.9844C2.55762 14.8096 2.55762 17.792 4.38281 19.6172C6.20801 21.4424 9.19043 21.4424 11.0156 19.6172L12.1172 18.5156C12.8789 17.7539 13.3301 16.7871 13.4531 15.7969L11.9531 15.6094C11.8682 16.2891 11.5811 16.9424 11.0625 17.4609L9.96094 18.5625C8.70703 19.8164 6.69141 19.8164 5.4375 18.5625C4.18359 17.3086 4.18359 15.293 5.4375 14.0391L6.53906 12.9375H6.5625C7.08105 12.416 7.71387 12.1289 8.39062 12.0469L8.20312 10.5469Z" fill={color} />
  </svg>
);

export default UnlinkIcon;
