import { Box, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';

import CollapsableSection from 'layouts/SettingsLayout/SidebarSettings/CollapsableSection';
import {
  ContentWrapper, LinkContent,
  LinkWrapper,
  NavbarLink,
} from 'layouts/SettingsLayout/SidebarSettings/index.styled';

import { SidebarInterface } from 'models/sidebar.interface';

interface SidebarSettingsProps {
  sidebarItems: SidebarInterface[];
  hasTitle?: boolean;
}

const SidebarSettings: FC<SidebarSettingsProps> = ({ sidebarItems, hasTitle = false }): ReactElement => {
  const intl = useIntl();

  return (
    <ContentWrapper hasTitle={hasTitle} elevation={0}>
      <Box display="flex" gap={3} flexDirection="column">
        {sidebarItems.map((item): ReactElement => {
          const {
            route, key, label, Icon,
          } = item;
          return (
            route ? (
              <NavbarLink to={route} key={key}>
                {({ isActive }): ReactElement => (
                  <LinkWrapper isActive={isActive}>
                    <LinkContent isActive={isActive}>
                      {Icon && <Icon size={24} />}
                      <Typography variant="body2" fontWeight={700}>
                        {intl.formatMessage({ id: label })}
                      </Typography>
                    </LinkContent>
                  </LinkWrapper>
                )}
              </NavbarLink>
            ) : (
              <CollapsableSection
                key={key}
                item={item}
              />
            )
          );
        })}
      </Box>
    </ContentWrapper>
  );
};

export default SidebarSettings;
