import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import { FC, ReactNode } from 'react';

import { Accordion } from 'modules/insights-cashflow/components/TransactionAccordion/index.styled';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';

interface TransactionAccordionProps {
  title: string;
  totalAmount: string;
  children: ReactNode;
}

const TransactionAccordion:FC<TransactionAccordionProps> = ({ title, totalAmount, children }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ChevronDownIcon />}
      aria-controls="panel-content"
      id="panel-header"
    >
      <Box display="flex" justifyContent="space-between" width="100%" gap={2} pr={5}>
        <Typography variant="subtitle1" fontWeight={700}>{title}</Typography>
        <Typography variant="subtitle1" fontWeight={700}>{totalAmount}</Typography>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>
);

export default TransactionAccordion;
