import { Typography, Grid2 as Grid, Box } from '@mui/material';
import { Country } from 'country-state-city';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import BusinessAddressInformation from 'components/BusinessAddressInformation';
import { DocumentWrapper } from 'components/CompanyBeneficiary/index.styled';
import GridInformationLabel from 'components/GridInformationLabel';

import DocumentIcon from 'assets/icons/DocumentIcon';
import { DateFormats } from 'constants/enums';
import { NOT_APPLICABLE } from 'constants/general';
import { DOCUMENT_TYPE_LABELS } from 'constants/labels';
import { Beneficiary } from 'models/company.interface';
import { Document } from 'models/document.interface';
import {
  formatDate, formatFullName, formatPercentage, formatPhoneNumber,
} from 'utils/formatters';

interface CompanyBeneficiaryProps {
  beneficiary: Beneficiary;
  documents?: Document[];
  detailed?: boolean;
  isLoading?: boolean;
}

const CompanyBeneficiary: FC<CompanyBeneficiaryProps> = ({
  beneficiary,
  documents = [],
  detailed = false,
  isLoading = false,
}) => {
  const intl = useIntl();
  const { firstName, lastName, middleName } = beneficiary;
  const fullName = formatFullName(firstName, lastName, middleName);
  const dob = beneficiary?.dateOfBirth
    ? formatDate(new Date(beneficiary?.dateOfBirth), DateFormats.defaultDate)
    : NOT_APPLICABLE;
  const beneficiaryCountry = useMemo(() => (
    Country
      .getAllCountries()
      .find((country) => country?.isoCode === beneficiary?.citizenshipCountryCode)
  ), [beneficiary?.citizenshipCountryCode]);

  return (
    <Grid container gap={4}>
      <GridInformationLabel
        value={fullName || NOT_APPLICABLE}
        isPlaceholder={!fullName}
        label={intl.formatMessage({ id: 'label.legalName' })}
        isLoading={isLoading}
      />
      <GridInformationLabel
        value={beneficiary?.primaryEmail?.value || NOT_APPLICABLE}
        label={intl.formatMessage({ id: 'label.primaryEmail' })}
        isLoading={isLoading}
      />
      <GridInformationLabel
        value={beneficiary?.title || NOT_APPLICABLE}
        isPlaceholder={!beneficiary?.title}
        label={intl.formatMessage({ id: 'label.jobTitle' })}
        isLoading={isLoading}
      />
      <GridInformationLabel
        value={formatPercentage({ percentage: beneficiary?.ownershipPercentage })}
        isPlaceholder={!beneficiary?.ownershipPercentage}
        label={intl.formatMessage({ id: 'label.ownershipPercentage' })}
        isLoading={isLoading}
      />
      <GridInformationLabel
        value={beneficiaryCountry?.name || NOT_APPLICABLE}
        isPlaceholder={!beneficiary?.citizenshipCountryCode}
        label={intl.formatMessage({ id: 'label.citizenshipCountry' })}
        isLoading={isLoading}
      />
      <GridInformationLabel
        value={dob}
        isPlaceholder={!beneficiary?.dateOfBirth}
        label={intl.formatMessage({ id: 'label.dateOfBirth' })}
        isLoading={isLoading}
      />
      <GridInformationLabel
        value={beneficiary?.socialId || NOT_APPLICABLE}
        isPlaceholder={!beneficiary?.socialId}
        label={intl.formatMessage({ id: 'label.socialSecurityNumber' })}
        isLoading={isLoading}
      />
      <GridInformationLabel
        value={formatPhoneNumber(beneficiary?.primaryPhoneNumber?.number)}
        isPlaceholder={!beneficiary?.primaryPhoneNumber?.number}
        label={intl.formatMessage({ id: 'label.primaryPhoneNumber' })}
        isLoading={isLoading}
      />
      <GridInformationLabel label={intl.formatMessage({ id: 'label.address' })} isLoading={isLoading}>
        {beneficiary.addresses?.[0]
          ? <BusinessAddressInformation address={beneficiary.addresses?.[0]} />
          : <Typography variant="body1" fontWeight={600} color="general.lightGrey6">{NOT_APPLICABLE}</Typography>}
      </GridInformationLabel>
      <GridInformationLabel label={intl.formatMessage({ id: 'label.document' })} isLoading={isLoading}>
        <Box display="flex" flexDirection="column" gap={2}>
          {documents.map((document) => {
            const documentLabel = DOCUMENT_TYPE_LABELS?.[document.documentType]
              ? intl.formatMessage({ id: DOCUMENT_TYPE_LABELS?.[document.documentType] })
              : NOT_APPLICABLE;

            return (
              <Box key={document.id} mb={4}>
                <Typography variant="body1" fontWeight={600} className="text-ellipsis">
                  {documentLabel}
                </Typography>
                {detailed ? (
                  <DocumentWrapper>
                    <DocumentIcon />
                    <Typography variant="body1" color="textSecondary" className="text-ellipsis">
                      {document.name}
                    </Typography>
                  </DocumentWrapper>
                ) : (
                  <Typography variant="body1" fontWeight={600} color="general.darkGrey1" className="text-ellipsis">
                    {`(${document.name})`}
                  </Typography>
                )}
              </Box>
            );
          })}
          {!documents.length && (
            <Typography variant="body1" fontWeight={600} color="general.lightGrey6">
              {NOT_APPLICABLE}
            </Typography>
          )}
        </Box>
      </GridInformationLabel>
    </Grid>
  );
};

export default CompanyBeneficiary;
