import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import ConfirmationModal from 'components/ConfirmationModal';
import GenericModal from 'components/GenericModal';
import MemberForm from 'components/MemberForm';
import TransferOwnership from 'components/TransferOwnership';
import AccountAccessField from 'fields/AccountAccessField';

import { useModal } from 'hooks/useModal';
import { usePermissions } from 'hooks/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getWorkspaceBankAccounts } from 'services/BankAccountService';
import { updateUser, updateUserRole } from 'services/UsersService';
import { fetchUserDetails } from 'store/user-details/userDetailsSlice';
import { currentWorkspaceSelector, userSelector } from 'store/user/selectors';
import { setUser } from 'store/user/userSlice';

import { AuthorityType, ConfirmationTypes, DefaultType } from 'constants/enums';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { RolePermissions } from 'constants/permissionEnums';
import { BankAccount } from 'models/bankAccount.interface';
import { User } from 'models/user.interface';
import { getTranslatedErrors, handleApiErrors } from 'utils/errorUtils';
import { formatUserName } from 'utils/formatters';
import { extractNumberFromNumericFormat } from 'utils/numbers';
import { isWorkspaceOwner } from 'utils/roles';
import { memberFormSchema, MemberFormValues } from 'utils/validation/memberFormSchema';

interface EditUserDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (user: User) => void;
  userDetails: User;
}

const EditUserDialog: FC<EditUserDialogProps> = ({
  isOpen,
  onClose,
  userDetails,
  onSuccess,
}) => {
  const { isAllowed } = usePermissions();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(userSelector);
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const isMe = Boolean(loggedUser && loggedUser?.id === userDetails?.id);
  const amOwner = Boolean(loggedUser && isWorkspaceOwner(loggedUser) && isMe);
  const [transferInfo, setTransferInfo] = useState<MemberFormValues|null>(null);
  const disableRoleField = !isAllowed({ permission: RolePermissions.ManageUserRoles });
  const [userBankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [, setIsBanksLoading] = useState<boolean>(false);

  const {
    register,
    reset,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: {
      errors, isSubmitting,
    },
  } = useForm<MemberFormValues>({
    resolver: zodResolver(memberFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      firstName: userDetails?.firstName || '',
      lastName: userDetails?.lastName || '',
      email: userDetails?.email || '',
      authority: userDetails?.roles?.[0]?.authority !== AuthorityType.opsAdmin
        ? userDetails?.roles?.[0]?.authority
        : undefined,
      phoneNumber: userDetails?.phoneNumber || '',
      amOwner,
      isMe,
      transferOwnerTo: '',
      transactionLimit: userDetails?.transactionLimit ? userDetails.transactionLimit.toString() : '',
      bankAccounts: userDetails?.bankAccounts || [],
    },
  });

  const { isOpen: isTransferOpen, onOpen: openTransfer, onClose: closeTransfer } = useModal();
  const { isOpen: isEditOpen, onOpen: openEdit, onClose: closeEdit } = useModal();

  const newRole = watch('authority');

  const showTransferOwnership = amOwner && newRole !== AuthorityType.userOwner;
  const workspaceId = currentWorkspace?.id || '';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const translatedErrors = getTranslatedErrors(errors);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        setIsBanksLoading(true);
        const { data } = await getWorkspaceBankAccounts(workspaceId);
        setBankAccounts(data);

        if (userDetails?.bankAccounts?.length && data.length === userDetails?.bankAccounts?.length) {
          setValue(
            'bankAccounts',
            [{ id: DefaultType.all, label: intl.formatMessage({ id: 'label.all' }) }, ...userDetails?.bankAccounts],
          );
        }
      } catch (e) {
        handleApiErrors(e);
      } finally {
        setIsBanksLoading(false);
      }
    };

    fetchAccounts();
  }, []);

  useEffect(() => {
    if (isOpen) {
      openEdit();
    }
  }, [isOpen]);

  const handleCloseEdit = (): void => {
    reset();
    closeEdit();
    onClose();
  };

  const refreshUser = async () => {
    try {
      const updatePayload = { workspaceId, userId: userDetails.id };
      const updatedUser = await dispatch(fetchUserDetails(updatePayload)).unwrap();

      if (isMe) {
        dispatch(setUser(updatedUser));
      }

      if (onSuccess) {
        onSuccess(updatedUser);
      }
    } catch (e) {
      console.log('unable to refresh the user', e); // eslint-disable-line
    }
  };

  const handleCloseTransfer = async () => {
    await refreshUser();

    reset({});
    setTransferInfo(null);
    closeTransfer();
    onClose();
  };

  const handleTransferOwnership = async () => {
    if (!transferInfo) {
      await handleCloseTransfer();
    } else {
      try {
        await updateUserRole(
          { workspaceId, userId: transferInfo.transferOwnerTo },
          [AuthorityType.userOwner],
        );
        await updateUserRole(
          { workspaceId, userId: userDetails.id },
          [transferInfo.authority],
        );
        await handleCloseTransfer();

        toast.success(
          intl.formatMessage({ id: 'label.memberChangesSaved' }, { name: formatUserName(userDetails) }),
          GENERAL_TOAST_OPTIONS,
        );
      } catch (e) {
        handleApiErrors(e);
      }
    }
  };

  const handleSubmitChanges = async (teamMemberDetails: MemberFormValues) => {
    const {
      authority, transferOwnerTo, transactionLimit, bankAccounts, ...remainingProps
    } = teamMemberDetails;
    const payload: Partial<User> = {
      ...userDetails,
      ...remainingProps,
      transactionLimit: extractNumberFromNumericFormat(transactionLimit),
      name: formatUserName(remainingProps),
      bankAccounts: bankAccounts?.[0]?.id === DefaultType.all ? userBankAccounts : bankAccounts as BankAccount[],
    };

    if (!userDetails.id || !workspaceId) {
      toast.warning(intl.formatMessage({ id: 'label.unableToEditTeamMember' }), GENERAL_TOAST_OPTIONS);
      return;
    }

    if (!transferOwnerTo) {
      payload.roles = [{ authority }];
    } else {
      payload.roles = userDetails.roles;
    }

    try {
      await updateUser({ workspaceId }, payload);

      if (!amOwner && authority !== userDetails?.roles?.[0]?.authority) {
        await updateUserRole(
          { workspaceId, userId: userDetails.id },
          [authority],
        );
      }

      if (transferOwnerTo) {
        setTransferInfo(teamMemberDetails);
        openTransfer();
      } else {
        await refreshUser();
        onClose();

        toast.success(
          intl.formatMessage({ id: 'label.memberChangesSaved' }, { name: formatUserName(payload) }),
          GENERAL_TOAST_OPTIONS,
        );
      }

      reset();
      closeEdit();
    } catch (e: any) {
      handleApiErrors(e);
    }
  };

  return (
    <>
      {isEditOpen && (
        <GenericModal
          id="modal-edit-member"
          isOpen={isEditOpen}
          onClose={handleCloseEdit}
          title={intl.formatMessage({ id: 'label.editTeamMember' })}
          hasActions
          confirmLabel={intl.formatMessage({ id: 'button.save' })}
          onSubmit={handleSubmit(handleSubmitChanges)}
          disabled={isSubmitting}
        >
          <MemberForm
            disableRole={disableRoleField}
            register={register}
            errors={errors}
            control={control}
            onSubmit={handleSubmit(handleSubmitChanges)}
          >
            {showTransferOwnership ? (
              <>
                <Divider sx={{ mt: 5, mb: 4 }} />
                <TransferOwnership
                  control={control}
                  register={register}
                  errors={errors}
                />
              </>
            ) : null}
            {!isWorkspaceOwner(userDetails) && (
              <>
                <Divider sx={{ mt: 5, mb: 4 }} />
                <AccountAccessField
                  <MemberFormValues>
                  control={control}
                  errors={translatedErrors}
                  bankAccounts={userBankAccounts}
                  onSetValue={setValue}
                />
              </>
            )}
          </MemberForm>
        </GenericModal>
      )}

      {isTransferOpen && (
        <ConfirmationModal
          id="modal-transfer-role"
          type={ConfirmationTypes.Warning}
          title={intl.formatMessage({ id: 'modalTitle.transferOwnerRole' })}
          confirmLabel={intl.formatMessage({ id: 'button.confirm' })}
          onCancel={handleCloseTransfer}
          onSubmit={handleTransferOwnership}
          isOpen={isTransferOpen}
          disabled={isSubmitting}
        >
          <Typography textAlign="center" color="textSecondary">
            {intl.formatMessage({ id: 'modalContent.transferOwnerRole' })}
          </Typography>
        </ConfirmationModal>
      )}
    </>
  );
};

export default EditUserDialog;
