import { ReactElement, FC } from 'react';
import { Outlet } from 'react-router-dom';

import CustomSuspense from 'routes/CustomSuspense';

const SuspenseLayout:FC = (): ReactElement => (
  <CustomSuspense>
    <Outlet />
  </CustomSuspense>
);

export default SuspenseLayout;
