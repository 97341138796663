import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const Contacts = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'Contacts' */ 'modules/contacts/containers/Contacts'
    ),
    'Contacts',
  ),
);

export default Contacts;
