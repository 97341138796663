import SubcontractIcon from 'assets/icons/SubcontractIcon';
import { DASHBOARD_ROUTE } from 'constants/clientRoutes';

export const sidebarItems = [
  {
    key: 'customers',
    Icon: SubcontractIcon,
    label: 'navigation.customerLookup',
    route: DASHBOARD_ROUTE,
  },
];
