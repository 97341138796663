/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const WithdrawArrowIcon = ({ color = '#FF6E6E', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12ZM11 17L11 10.414L8.707 12.707L7.293 11.293L12 6.586L16.707 11.293L15.293 12.707L13 10.414L13 17L11 17Z" fill={color} />
  </svg>
);

export default WithdrawArrowIcon;
