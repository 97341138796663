import { runGetApiRequest, runGetBlobApiRequest } from 'services/Api';

import {
  CASH_FLOW_ADVANCED_SEARCH_API,
  CASH_FLOW_ADVANCED_SEARCH_DOWNLOAD_API,
  CASH_FLOW_DEBIT_SEARCH_API,
  CASH_FLOW_CREDIT_SEARCH_API,
  CASH_FLOW_CONSOLIDATED_SEARCH_API,
  CASH_FLOW_CONSOLIDATED_CHART_API,
} from 'constants/apiRoutes';
import { AdvancedSearchQueryParams, CashFlowSearchQueryParams } from 'models/cashFlow.interface';

export const getAdvancedTransactionSearch = (queryParams: AdvancedSearchQueryParams) => (
  runGetApiRequest(CASH_FLOW_ADVANCED_SEARCH_API, queryParams)
);

export const getAdvancedTransactionSearchDocument = (queryParams: CashFlowSearchQueryParams) => (
  runGetBlobApiRequest(CASH_FLOW_ADVANCED_SEARCH_DOWNLOAD_API, queryParams)
);

export const getCashFlowDebitTransactions = (queryParams: CashFlowSearchQueryParams) => {
  const requestParams = {
    ...queryParams,
    bankAccountIds: queryParams?.bankAccountIds?.join(',') || '',
  };
  return runGetApiRequest(CASH_FLOW_DEBIT_SEARCH_API, requestParams);
};

export const getCashFlowCreditTransactions = (queryParams: CashFlowSearchQueryParams) => {
  const requestParams = {
    ...queryParams,
    bankAccountIds: queryParams?.bankAccountIds?.join(',') || '',
  };
  return runGetApiRequest(CASH_FLOW_CREDIT_SEARCH_API, requestParams);
};

export const getCashFlowInfo = (queryParams: CashFlowSearchQueryParams) => {
  const requestParams = {
    ...queryParams,
    bankAccountIds: queryParams?.bankAccountIds?.join(',') || '',
  };
  return runGetApiRequest(CASH_FLOW_CONSOLIDATED_SEARCH_API, requestParams);
};

export const getCashFlowChartInfo = (queryParams: CashFlowSearchQueryParams) => {
  const requestParams = {
    ...queryParams,
    bankAccountIds: queryParams?.bankAccountIds?.join(',') || '',
  };
  return runGetApiRequest(CASH_FLOW_CONSOLIDATED_CHART_API, requestParams);
};
