/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ChevronLeftIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8565 17.9054L15.4042 17.3687C15.4656 17.3073 15.5 17.2247 15.5 17.1387C15.5 17.0527 15.4656 16.9702 15.4042 16.9088L10.4063 11.9997L15.4042 7.09057C15.4656 7.02917 15.5 6.94661 15.5 6.86062C15.5 6.77464 15.4656 6.69208 15.4042 6.63067L14.8565 6.0939C14.7939 6.03371 14.7097 6 14.6219 6C14.5342 6 14.45 6.03371 14.3873 6.0939L8.59581 11.7704C8.5344 11.8318 8.5 11.9143 8.5 12.0003C8.5 12.0863 8.5344 12.1689 8.59581 12.2303L14.3873 17.9068C14.4501 17.9668 14.5345 18.0002 14.6222 18C14.7099 17.9998 14.7941 17.9658 14.8565 17.9054Z" fill={color} />
  </svg>
);

export default ChevronLeftIcon;
