import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLogout } from 'hooks/useFirebase';
import { useAppDispatch } from 'hooks/useRedux';
import { getLoggedUserWorkspaces } from 'services/WorkspaceService';
import { resetStore } from 'store/actions';
import { setUserWorkspaces, setViewAs, setViewAsWorkspaces } from 'store/user/userSlice';

import { DASHBOARD_ROUTE } from 'constants/clientRoutes';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { User } from 'models/user.interface';
import { Workspace } from 'models/workspace.interface';

export const useActAs = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { logout } = useLogout();

  const loginAsCustomer = async (user: User, workspaces: Workspace[]) => {
    try {
      setIsSubmitting(true);
      dispatch(setViewAsWorkspaces(workspaces));
      dispatch(setViewAs(user));
      navigate(DASHBOARD_ROUTE);
    } catch (e) {
      toast.error(intl.formatMessage({ id: 'error.loginViewAs' }), GENERAL_TOAST_OPTIONS);
    } finally {
      setIsSubmitting(false);
    }
  };

  const logoutCustomerView = async () => {
    try {
      setIsSubmitting(true);
      const { data } = await getLoggedUserWorkspaces();
      const { content: workspaces = [] } = data || {};
      navigate(DASHBOARD_ROUTE);
      dispatch(setUserWorkspaces(workspaces));
      dispatch(setViewAsWorkspaces([]));
      dispatch(setViewAs(null));
      dispatch(resetStore());
    } catch (e) {
      logout();
      toast.error(intl.formatMessage({ id: 'error.logoutViewAs' }), GENERAL_TOAST_OPTIONS);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    loginAsCustomer,
    logoutCustomerView,
    isSubmitting,
  };
};
