import { DateType, DefaultType } from 'constants/enums';
import { AMOUNT_CONDITION, KEYWORDS, TRANSACTION_TYPE } from 'constants/fields';

export const DEFAULT_FILTERS = {
  dateRange: {
    type: DateType.sevenDays,
    from: null,
    to: null,
  },
  amountRange: {
    min: null,
    max: null,
  },
  [AMOUNT_CONDITION.name]: '',
  [TRANSACTION_TYPE.name]: DefaultType.all,
  [KEYWORDS.name]: '',
};
