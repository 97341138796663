/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const RemoveStarIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4749 3.94227C12.3241 3.48412 11.676 3.48412 11.5251 3.94228L10.5993 6.75376C10.5417 6.92875 10.585 7.12125 10.712 7.25474L11.8588 8.46047L12.2908 8.91462L14.7952 11.5476L15.5196 12.3092L16.428 13.263C16.6079 13.4518 16.9028 13.4707 17.1053 13.3063L20.4061 10.6263C20.7716 10.3295 20.5618 9.7381 20.0909 9.7381H14.7452C14.5293 9.7381 14.3378 9.59953 14.2703 9.39448L12.4749 3.94227ZM5.65581 4.5031C5.45873 4.29589 5.12832 4.29589 4.93124 4.5031L4.40438 5.057C4.22076 5.25004 4.22076 5.55315 4.40438 5.7462L7.39799 8.8935C7.70085 9.21191 7.47515 9.7381 7.0357 9.7381H3.90919C3.43835 9.7381 3.2285 10.3295 3.59405 10.6263L7.88917 14.1132C8.04398 14.2389 8.10931 14.4445 8.05542 14.6365L6.51557 20.1224C6.38768 20.578 6.9056 20.9399 7.28943 20.6631L11.7075 17.4769C11.8822 17.351 12.1178 17.351 12.2925 17.4769L17.079 20.9288C17.341 21.1177 17.6945 20.8707 17.6072 20.5597C17.511 20.2171 17.9378 19.9744 18.1831 20.2323L18.402 20.4625C18.5676 20.6366 18.8453 20.6366 19.0109 20.4625L19.5956 19.8478C19.7792 19.6547 19.7792 19.3516 19.5956 19.1586L16.2197 15.6093C16.218 15.6076 16.2169 15.6055 16.2163 15.6031C16.2158 15.6008 16.2146 15.5987 16.213 15.597L9.88015 8.93901C9.8795 8.93833 9.87837 8.93851 9.87796 8.93936C9.87756 8.94021 9.87642 8.94039 9.87577 8.93971L5.65581 4.5031Z" fill={color} />
  </svg>
);

export default RemoveStarIcon;
