import { Box } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  width: '575px',
  padding: theme.spacing(7, 9),
}));

export const AddWrapper = styled(Box)<{ disabled?: boolean }>(({ theme, disabled = false }) => ({
  alignSelf: 'center',
  display: 'flex',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? 0.5 : 1,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),

  '& svg': {
    '& circle': {
      fill: theme.palette.primary.main,
    },
  },
}));

export const TrashIconButton = styled(MuiIconButton)(({ theme }) => ({
  '&:hover svg path': {
    fill: theme.palette.error.main,
  },
}));
