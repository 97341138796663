/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const BrandIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.586 3.586L2.58599 12.586C1.80499 13.367 1.80499 14.633 2.58599 15.414L8.586 21.414C9.367 22.195 10.633 22.195 11.414 21.414L20.414 12.414C20.789 12.039 21 11.53 21 11V5C21 3.895 20.105 3 19 3L13 3C12.47 3 11.961 3.211 11.586 3.586ZM17.5 8C16.672 8 16 7.328 16 6.5C16 5.672 16.672 5 17.5 5C18.328 5 19 5.672 19 6.5C19 7.328 18.328 8 17.5 8Z" fill={color} />
  </svg>
);

export default BrandIcon;
