/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SecurityIcon = ({ color = 'currentColor', size = 16 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 16 20">
    <path fill={color} d="M8 0 0 3.636v5.455C0 16.209 5.724 19.533 8 20c2.276-.467 8-3.79 8-10.91V3.637L8 0Zm-.889 14.013-3.72-3.805 1.257-1.285 2.463 2.519 5.149-5.266 1.256 1.286-6.405 6.55Z" />
  </svg>
);

export default SecurityIcon;
