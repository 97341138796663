import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getRecipientDetails, getRecipientTransactions } from 'services/RecipientService';
import { resetStore } from 'store/actions';

import {
  ApiStatus, DefaultType, SortOrder, TransactionFilterDirections,
} from 'constants/enums';
import { Pagination } from 'models/pagination.interface';
import { Recipient } from 'models/recipient.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { TransactionDTO } from 'models/transaction.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { RecipientTransactionFilterSchema } from 'utils/validation/transactionFormSchema';

export interface RecipientDetailsState {
  details: {
    data: Recipient;
    status: ApiStatus;
  };
  transactions: {
    data: TransactionDTO[];
    status: ApiStatus;
  };
  sortOptions: SortOptions;
  pagination: Pagination;
  filters: RecipientTransactionFilterSchema;
}

const initialState: RecipientDetailsState = {
  details: {
    data: {} as Recipient,
    status: ApiStatus.idle,
  },
  transactions: {
    data: [],
    status: ApiStatus.idle,
  },
  sortOptions: {
    name: 'createdAt',
    type: SortOrder.desc,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
    page: 1,
  },
  filters: {
    dateRange: {
      from: null,
      to: null,
    },
    amountRange: {
      min: null,
      max: null,
    },
    types: {
      [DefaultType.all]: true,
      [TransactionFilterDirections.send]: false,
      [TransactionFilterDirections.request]: false,
    },
    latestStatus: DefaultType.all,
  },
};

export const fetchRecipient = createAsyncThunk(
  'recipient/fetchRecipient',
  async (recipientId: string, thunkAPI) => {
    try {
      const response = await getRecipientDetails(recipientId);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchRecipientTransactions = createAsyncThunk(
  'recipient/fetchRecipientTransactions',
  async (params: { recipientId: string; page: number; sort: string; showError?: boolean }, thunkAPI) => {
    const { recipientId, showError, ...queryParams } = params;

    try {
      const response = await getRecipientTransactions(recipientId, queryParams);
      return response.data;
    } catch (e) {
      if (showError) {
        handleApiErrors(e);
      }

      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const recipientDetailsSlice = createSlice({
  name: 'recipient',
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
      return state;
    },
    setSort: (state, action: PayloadAction<SortOptions>) => {
      state.sortOptions = action.payload;
      return state;
    },
    setFilters: (state, action: PayloadAction<RecipientTransactionFilterSchema>) => {
      state.filters = action.payload;
      return state;
    },
    resetRecipient: () => initialState,
    resetFilters: (state) => {
      state.filters = initialState.filters;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchRecipient.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchRecipient.fulfilled, (state, action) => {
        state.details = {
          data: action?.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchRecipient.rejected, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchRecipientTransactions.pending, (state) => {
        state.transactions = {
          ...state.transactions,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchRecipientTransactions.fulfilled, (state, action) => {
        const {
          totalElements, totalPages, content,
        } = action?.payload || {};

        state.transactions = {
          data: content,
          status: ApiStatus.idle,
        };
        state.pagination = {
          ...state.pagination,
          totalPages,
          totalElements,
        };
      })
      .addCase(fetchRecipientTransactions.rejected, (state) => {
        state.transactions = {
          ...state.transactions,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  setPagination,
  setSort,
  setFilters,
  resetFilters,
  resetRecipient,
} = recipientDetailsSlice.actions;

export default recipientDetailsSlice.reducer;
