import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { Grid2 as Grid, Box } from '@mui/material';
import { isEqual } from 'lodash';
import {
  ChangeEvent, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';
import AdvancedAccountFilter from 'containers/AdvancedAccountFilter';
import AccountsFilter from 'containers/AdvancedAccountFilter/AccountsFilter';
import SearchField from 'fields/SearchField';
import TotalTransactionsVolume from 'modules/insights-advanced-search/components/TotalTransactionsVolume';
import FilterContent from 'modules/insights-advanced-search/containers/FilterContent';
import Transactions from 'modules/insights-advanced-search/containers/Transactions';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getAdvancedTransactionSearchDocument } from 'services/CashFlowService';
import { accountsSelector } from 'store/bank-accounts/selectors';
import { setAdvancedSearchFilters, setAdvancedSearchSearch } from 'store/cash-flow/cashFlowSlice';
import {
  advancedSearchFiltersSelector,
  advancedSearchSearchSelector,
} from 'store/cash-flow/selectors';

import DownloadIcon from 'assets/icons/DownloadIcon';
import EmptyBankAccounts from 'assets/images/empty-bank-accounts.svg';
import { DEFAULT_FILTERS } from 'constants/cashflow';
import { DASHBOARD_ROUTE } from 'constants/clientRoutes';
import { DefaultType } from 'constants/enums';
import { GenericOption } from 'models/option.interface';
import { formatFiltersForServer } from 'modules/insights-advanced-search/utils';
import { forceDownload } from 'utils/downloadFile';
import { handleApiErrors } from 'utils/errorUtils';
import {
  advancedTransactionFilterSchema,
  AdvancedTransactionFilterSchema,
} from 'utils/validation/transactionFormSchema';

import { EmptyStateWrapper, SearchWrapper } from './index.styled';

const AdvancedTransactionSearch = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const filters = useAppSelector(advancedSearchFiltersSelector);
  const search = useAppSelector(advancedSearchSearchSelector);
  const { data: bankAccounts, isLoading } = useAppSelector(accountsSelector, isEqual);

  const [tempAccounts, setTempAccounts] = useState<GenericOption[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<GenericOption[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<AdvancedTransactionFilterSchema>({
    resolver: zodResolver(advancedTransactionFilterSchema),
    mode: 'onSubmit',
    defaultValues: DEFAULT_FILTERS,
  });

  const onSubmit = (newFilters: AdvancedTransactionFilterSchema) => {
    if (!isEqual(newFilters, filters)) {
      dispatch(setAdvancedSearchFilters(newFilters));
      reset(newFilters);
    }

    if (!isEqual(selectedAccounts, tempAccounts)) {
      setSelectedAccounts([...tempAccounts]);
    }

    setExpanded(false);
  };

  const isFormDirty = useMemo(() => {
    if (isDirty) {
      return true;
    }

    const newIds = tempAccounts?.map((acc) => acc?.value).filter((value) => value);
    return !isEqual(newIds, selectedAccounts);
  }, [isDirty, tempAccounts, selectedAccounts]);

  const onCancel = () => {
    reset(filters);
    setTempAccounts(selectedAccounts);
    setExpanded(false);
  };

  const handleDownloadCSV = async () => {
    try {
      const hasAllOptionSelected = selectedAccounts.find(({ value }) => value === DefaultType.all);
      const payload = {
        bankAccountIds: hasAllOptionSelected
          ? bankAccounts?.map(({ id }) => id) || []
          : selectedAccounts.map(({ value }) => value),
        ...(search ? { search } : {}),
        ...formatFiltersForServer(filters),
      };

      const response = await getAdvancedTransactionSearchDocument(payload);
      forceDownload(window.URL.createObjectURL(response?.data), 'transactions.csv');
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const handleChangeSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setAdvancedSearchSearch(value));
  };

  const handleClearSearch = () => {
    dispatch(setAdvancedSearchSearch(''));
  };

  if (!bankAccounts?.length) {
    return (
      <>
        <EmptyStateWrapper>
          <EmptyState
            image={EmptyBankAccounts}
            title={intl.formatMessage({ id: 'label.startByAddingAccounts' })}
            subtitle={intl.formatMessage({ id: 'label.onceAccountsCreatedLinked' })}
            width="350px"
            height="180px"
          >
            <CustomButton
              id="btn-redirect-dashboard"
              label={intl.formatMessage({ id: 'button.goToDashboard' })}
              onClick={() => navigate(DASHBOARD_ROUTE)}
              sx={{ mt: 4 }}
            />
          </EmptyState>
        </EmptyStateWrapper>

        {isLoading && <Loader />}
      </>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <AdvancedAccountFilter
        accounts={selectedAccounts}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onCancel}
        isDirty={isFormDirty}
        isExpanded={expanded}
        onSetIsExpanded={setExpanded}
      >
        <Grid container>
          <Grid size={{ xs: 8 }}>
            <AccountsFilter
              selectedAccounts={tempAccounts}
              setSelectedAccounts={setTempAccounts}
            />
          </Grid>
          <Grid size={{ xs: 4 }}>
            <FilterContent
              register={register}
              errors={errors}
              control={control}
              watch={watch}
            />
          </Grid>
        </Grid>
      </AdvancedAccountFilter>
      <TotalTransactionsVolume />

      <Box display="flex" alignItems="cente" justifyContent="space-between" gap={4}>
        <SearchWrapper>
          <SearchField
            name="search-transactions"
            id="search-transactions"
            search={search}
            onClearSearch={handleClearSearch}
            onChange={handleChangeSearch}
            placeholder={intl.formatMessage({ id: 'placeholder.searchByDescriptionAndAmount' })}
          />
        </SearchWrapper>

        <CustomButton
          label={intl.formatMessage({ id: 'button.downloadCSV' })}
          id="btn-download-csv"
          variant="text"
          onClick={handleDownloadCSV}
          icon={<DownloadIcon size={20} />}
          sx={{ color: 'general.lightBlack' }}
        />
      </Box>

      <Transactions selectedAccounts={selectedAccounts} />
    </Box>
  );
};

export default AdvancedTransactionSearch;
