import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Circle } from 'components/_ComingSoon/index.styled';
import Logo from 'components/Logo';

import { ElementSize } from 'constants/enums';

interface ComingSoonProps {
  minHeight?: string;
  description?: string;
}

const ComingSoon: FC<ComingSoonProps> = ({
  minHeight = '70vh',
  description = 'label.workingOnImplementingThisFeaturesStayTuned',
}) => {
  const intl = useIntl();

  return (
    <Box minHeight={minHeight} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box mb={4} maxWidth="300px" textAlign="center">
        <Typography variant="h1" gutterBottom>
          {intl.formatMessage({ id: 'label.comingSoon' })}
        </Typography>
        <Typography variant="h2" color="textSecondary" fontWeight={500} gutterBottom>
          {intl.formatMessage({ id: description })}
        </Typography>
      </Box>

      <Circle>
        <Logo size={ElementSize.large} />
      </Circle>
    </Box>
  );
};

export default ComingSoon;
