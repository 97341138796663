import { useTheme } from '@mui/material';
import {
  ScaleBand, ScaleLinear, select, Axis,
} from 'd3';
import { FC, useEffect, useRef } from 'react';

export interface XYAxisProps {
  scale: ScaleLinear<number, number, never> | ScaleBand<string>;
  hideAxe?: boolean;
  color?: string;
  transform?: string;
  maxTicks?: number;
  tickPadding?: number;
  onTickFormat?: (tick: string, index?: number) => any;
  axisHandler: (scale: any) => Axis<any>;
  tickValues?: any;
}

const XYAxis:FC<XYAxisProps> = ({
  scale,
  hideAxe,
  color,
  transform,
  maxTicks = 3,
  tickPadding = 0,
  onTickFormat,
  axisHandler,
  tickValues = null,
}) => {
  const ref = useRef<SVGGElement>(null);
  const theme = useTheme();

  useEffect(() => {
    if (ref.current) {
      select(ref.current)
        .style('color', color || theme.palette.text.secondary)
        .style('font-size', 13)
        .style('font-weight', 400)
        .style('line-height', 17.73)
        .style('font-family', 'Nunito Sans, sans-serif')
        .call(axisHandler(scale)
          .tickSize(0)
          .tickPadding(tickPadding)
          .ticks(maxTicks)
          .tickValues(tickValues)
          .tickFormat((d, index) => (onTickFormat ? onTickFormat(d, index) : d)));

      select(ref.current)
        .select('path')
        .style('stroke', theme.palette.general.darkGrey1);

      if (hideAxe) {
        select(ref.current)
          .call((g) => g.select('path').remove());
      }
    }
  }, [scale, tickValues]);

  return <g ref={ref} transform={transform} />;
};

export default XYAxis;
