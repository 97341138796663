import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDivider from '@mui/material/Divider';
import MuiIconButton from '@mui/material/IconButton';
import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  minWidth: '400px',
  minHeight: '200px',
  padding: theme.spacing(0),
  borderRadius: 0,
  border: 'none',
}));

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: theme.spacing(7, 9, 3, 9),
}));

export const DialogTitleContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(0, 9, 0, 9),
}));

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: theme.spacing(5, 9, 7),
}));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  marginTop: theme.spacing(5),
  color: theme.palette.general.lightGrey3,
}));

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: theme.spacing(1),

  '& svg': {
    height: '20px',
    width: '20px',

    '& path': {
      fill: theme.palette.general.darkGrey1,
    },
  },
}));
