import { Typography, useTheme } from '@mui/material';
import { ScaleBand, ScaleLinear } from 'd3';
import { FC, ReactElement, useState } from 'react';

import ChartTooltip from 'components/ChartTooltip';

import { formatPrice } from 'utils/formatters';

interface LineChartTooltipProps {
  line: { x: number; y: number };
  scaleX: ScaleLinear<number, number> | ScaleBand<string>;
  scaleY: ScaleLinear<number, number>;
  circleColor: string;
  title?: ReactElement;
  isVisible?: boolean;
  radius?: number;
  open?: boolean;
}

const LineChartTooltip: FC<LineChartTooltipProps> = ({
  line,
  scaleX,
  scaleY,
  circleColor,
  title = (
    <Typography variant="body2" color="textSecondary">
      {formatPrice({ price: line.y })}
    </Typography>
  ),
  isVisible = false,
  open = false,
  radius = 5,
}) => {
  const theme = useTheme();
  const [hasHovered, setHasHovered] = useState<boolean>(false);
  const defaultColor = isVisible ? circleColor : 'transparent';

  const cx = 'bandwidth' in scaleX
    ? (scaleX(line.x.toString()) || 0) + scaleX.bandwidth() / 2
    : scaleX(line.x || 0);

  return (
    <ChartTooltip
      open={open || hasHovered}
      key={`${line.x}-${line.y}`}
      arrow
      placement="left"
      title={title}
    >
      <g
        onMouseEnter={() => setHasHovered(true)}
        onMouseLeave={() => setHasHovered(false)}
      >
        <circle
          cx={cx}
          cy={scaleY(line.y)}
          r={radius}
          fill={(open || hasHovered) ? circleColor : defaultColor}
        />

        {(open || hasHovered) && (
          <circle
            cx={cx}
            cy={scaleY(line.y)}
            r={Math.floor(radius / 2)}
            fill={theme.palette.common.white}
          />
        )}
      </g>
    </ChartTooltip>
  );
};

export default LineChartTooltip;
