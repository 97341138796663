/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const UnarchiveIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.95898 4H11.7236C11.2735 4.71263 10.9692 5.52662 10.8529 6.4H10.3996H1.59961V4C1.59961 3.116 2.31561 2.4 3.19961 2.4H7.09336C7.65576 2.4 8.17643 2.69496 8.46523 3.17656L8.95898 4ZM2.39961 8H10.8529C11.244 10.9355 13.7575 13.2 16.8 13.2C19.3524 13.2 21.5326 11.6062 22.3996 9.35957V18.4C22.3996 19.284 21.6836 20 20.7996 20H3.19961C2.31561 20 1.59961 19.284 1.59961 18.4V8.8C1.59961 8.3584 1.95801 8 2.39961 8ZM12 7.2C12 4.5492 14.1492 2.4 16.8 2.4C19.4508 2.4 21.6 4.5492 21.6 7.2C21.6 9.8508 19.4508 12 16.8 12C14.1492 12 12 9.8508 12 7.2ZM18.1172 6.6828C18.2736 6.8392 18.5264 6.8392 18.6828 6.6828C18.8392 6.5264 18.8392 6.2736 18.6828 6.1172L17.0828 4.5172C17.0048 4.4392 16.9024 4.4 16.8 4.4C16.6976 4.4 16.5952 4.4392 16.5172 4.5172L14.9172 6.1172C14.7608 6.2736 14.7608 6.5264 14.9172 6.6828C15.0736 6.8392 15.3264 6.8392 15.4828 6.6828L16.4 5.7656V9.6C16.4 9.8208 16.5788 10 16.8 10C17.0212 10 17.2 9.8208 17.2 9.6V5.7656L18.1172 6.6828Z"
      fill={color}
    />
  </svg>
);

export default UnarchiveIcon;
