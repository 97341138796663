import { RootState } from 'store/index';
import { SelectedTransactionProps } from 'store/transaction-approval/transactionApprovalSlice';

import { ApiStatus } from 'constants/enums';
import { Pagination } from 'models/pagination.interface';
import { PaymentReasonInterface } from 'models/paymentReason.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { TransactionApproval } from 'models/transactionApproval.interface';
import { ApprovalTransactionFilterSchema } from 'utils/validation/transactionFormSchema';

export const pendingTransactionsSelector = (state: RootState): TransactionApproval[] => (
  state.transactionApproval?.transactionsPending?.data
);
export const isPendingTransactionLoadingSelector = (state: RootState): boolean => (
  state.transactionApproval?.transactionsPending?.status === ApiStatus.loading
);
export const pendingTransactionPaginationSelector = (state: RootState): Pagination => (
  state.transactionApproval?.transactionsPending?.pagination
);
export const pendingSortOptionsSelector = (state: RootState):SortOptions => (
  state.transactionApproval?.transactionsPending?.sortOptions
);
export const pendingSearchSelector = (state: RootState):string => (
  state.transactionApproval?.transactionsPending?.search
);
export const pendingFiltersSelector = (state: RootState):ApprovalTransactionFilterSchema => (
  state.transactionApproval?.transactionsPending?.filters
);

export const approvedTransactionsSelector = (state: RootState): TransactionApproval[] => (
  state.transactionApproval?.transactionsApproved?.data
);
export const isApprovedTransactionLoadingSelector = (state: RootState): boolean => (
  state.transactionApproval?.transactionsApproved?.status === ApiStatus.loading
);
export const approvedTransactionPaginationSelector = (state: RootState): Pagination => (
  state.transactionApproval?.transactionsApproved?.pagination
);
export const approvedSortOptionsSelector = (state: RootState):SortOptions => (
  state.transactionApproval?.transactionsApproved?.sortOptions
);
export const approvedSearchSelector = (state: RootState):string => (
  state.transactionApproval?.transactionsApproved?.search
);
export const approvedFiltersSelector = (state: RootState):ApprovalTransactionFilterSchema => (
  state.transactionApproval?.transactionsApproved?.filters
);

export const rejectedTransactionsSelector = (state: RootState): TransactionApproval[] => (
  state.transactionApproval?.transactionsRejected?.data
);
export const isRejectedTransactionLoadingSelector = (state: RootState): boolean => (
  state.transactionApproval?.transactionsRejected?.status === ApiStatus.loading
);
export const rejectedTransactionPaginationSelector = (state: RootState): Pagination => (
  state.transactionApproval?.transactionsRejected?.pagination
);
export const rejectedSortOptionsSelector = (state: RootState):SortOptions => (
  state.transactionApproval?.transactionsRejected?.sortOptions
);
export const rejectedSearchSelector = (state: RootState):string => (
  state.transactionApproval?.transactionsRejected?.search
);
export const rejectedFiltersSelector = (state: RootState):ApprovalTransactionFilterSchema => (
  state.transactionApproval?.transactionsRejected?.filters
);

export const failedTransactionsSelector = (state: RootState): TransactionApproval[] => (
  state.transactionApproval?.transactionsFailed?.data
);
export const isFailedTransactionLoadingSelector = (state: RootState): boolean => (
  state.transactionApproval?.transactionsFailed?.status === ApiStatus.loading
);
export const failedTransactionPaginationSelector = (state: RootState): Pagination => (
  state.transactionApproval?.transactionsFailed?.pagination
);
export const failedSortOptionsSelector = (state: RootState):SortOptions => (
  state.transactionApproval?.transactionsFailed?.sortOptions
);
export const failedSearchSelector = (state: RootState):string => (
  state.transactionApproval?.transactionsFailed?.search
);
export const failedFiltersSelector = (state: RootState):ApprovalTransactionFilterSchema => (
  state.transactionApproval?.transactionsFailed?.filters
);

export const reasonsSelector = (state: RootState): PaymentReasonInterface[] => (
  state.transactionApproval?.reasons
);

export const selectedTransactionsSelector = (state: RootState): Record<string, SelectedTransactionProps>|undefined => (
  state.transactionApproval?.selectedTransactions
);
