/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const OutlinedPlusIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 40 40">
    <circle cx="20" cy="20" r="20" fill={color} />
    <path fill="#fff" d="M27.333 21.333h-6v6a.667.667 0 0 1-.666.667h-1.334a.667.667 0 0 1-.666-.667v-6h-6a.667.667 0 0 1-.667-.666v-1.334a.667.667 0 0 1 .667-.666h6v-6a.667.667 0 0 1 .666-.667h1.334a.667.667 0 0 1 .666.667v6h6a.667.667 0 0 1 .667.666v1.334a.667.667 0 0 1-.667.666Z" />
  </svg>
);

export default OutlinedPlusIcon;
