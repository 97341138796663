import { Divider, Grid2 as Grid } from '@mui/material';
import {
  FC, FormEvent, ReactElement, ReactNode,
} from 'react';
import {
  Controller, FieldErrors, UseFormRegister, Control,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import DropdownField from 'fields/DropdownField';
import InputField from 'fields/InputField';
import NumberFormatField from 'fields/NumberFormatField';
import PhoneField from 'fields/PhoneField';
import { InputAdornment } from 'modules/bank-account-details/components/TransactionLimitsForm/index.styled';

import DollarIcon from 'assets/icons/DollarIcon';
import {
  FIRST_NAME, LAST_NAME, PHONE_NUMBER, USER_TRANSACTION_LIMIT,
} from 'constants/fields';
import { USER_AUTHORITY_OPTIONS } from 'constants/options';
import { getTranslatedErrors } from 'utils/errorUtils';
import { MemberFormValues } from 'utils/validation/memberFormSchema';

interface MemberFormProps {
  control: Control<MemberFormValues>;
  register: UseFormRegister<MemberFormValues>;
  errors: Partial<FieldErrors<MemberFormValues>>;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  children?: ReactNode;
  disableRole: boolean;
}

const MemberForm:FC<MemberFormProps> = ({
  register,
  errors,
  control,
  onSubmit,
  children = null,
  disableRole,
}): ReactElement => {
  const intl = useIntl();
  const translatedErrors = getTranslatedErrors(errors);

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 6 }}>
          <InputField
            {...FIRST_NAME}
            label={intl.formatMessage({ id: FIRST_NAME.label })}
            required
            slotProps={{
              htmlInput: register('firstName'),
            }}
            error={!!errors.firstName?.message}
            helperText={translatedErrors.firstName?.message}
            placeholder={intl.formatMessage({ id: 'placeholder.enterFirstName' })}
          />
        </Grid>

        <Grid size={{ xs: 6 }}>
          <InputField
            {...LAST_NAME}
            label={intl.formatMessage({ id: LAST_NAME.label })}
            required
            slotProps={{
              htmlInput: register('lastName'),
            }}
            error={!!errors.lastName?.message}
            helperText={translatedErrors.lastName?.message}
            placeholder={intl.formatMessage({ id: 'placeholder.enterLastName' })}
          />
        </Grid>

        <Grid size={{ xs: 6 }}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }): ReactElement => (
              <PhoneField
                {...PHONE_NUMBER}
                label={intl.formatMessage({ id: PHONE_NUMBER.label })}
                required
                inputProps={register('phoneNumber')}
                phoneNumber={field.value || ''}
                error={!!errors?.phoneNumber?.message}
                helperText={translatedErrors?.phoneNumber?.message}
                id="phone-phoneNumber"
              />
            )}
          />
        </Grid>

        <Grid size={{ xs: 6 }}>
          <InputField
            name="email"
            label={intl.formatMessage({ id: 'label.email' })}
            fullWidth
            required
            disabled
            slotProps={{
              htmlInput: register('email'),
            }}
            error={!!errors.email?.message}
            helperText={translatedErrors.email?.message}
            placeholder={intl.formatMessage({ id: 'placeholder.enterYourEmail' })}
            id="input-email"
          />
        </Grid>

        <Grid size={{ xs: 6 }}>
          <Controller
            name="authority"
            control={control}
            render={({ field }): ReactElement => (
              <DropdownField
                name="authority"
                label={intl.formatMessage({ id: 'label.role' })}
                fullWidth
                required
                disabled={disableRole}
                value={field?.value}
                options={USER_AUTHORITY_OPTIONS}
                inputProps={register('authority')}
                error={!!errors.authority?.message}
                helperText={translatedErrors.authority?.message}
                id="select-authority"
              />
            )}
          />
        </Grid>
      </Grid>

      <Divider sx={{ mt: 5, mb: 4 }} />

      <Grid container spacing={3}>
        <Grid size={{ xs: 6 }}>
          <Controller
            name="transactionLimit"
            control={control}
            render={({ field }): ReactElement => (
              <NumberFormatField
                {...USER_TRANSACTION_LIMIT}
                value={field.value}
                label={intl.formatMessage({ id: USER_TRANSACTION_LIMIT.label })}
                fullWidth
                error={!!errors?.transactionLimit?.message}
                helperText={translatedErrors?.transactionLimit?.message}
                InputProps={{
                  ...register('transactionLimit'),
                  startAdornment: (
                    <InputAdornment position="start">
                      <DollarIcon size={18} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {children}
      <input type="submit" hidden />
    </form>
  );
};

export default MemberForm;
