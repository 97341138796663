/* eslint max-len: 0 */
const TermsAndConditions = () => (
  <div>
    <h4>Last Updated: June 6, 2023</h4>

    <p>Rocket Financial Inc.,</p>

    <p><b>Websites covered by these terms include:</b></p>

    <ol>
      <li>
        <span>
          <a href="http://www.rocketfncl.com" target="_blank" rel="noopener noreferrer">www.rocketfncl.com</a>
          , and
        </span>
      </li>
      <li>
        <span>
          <a href="http://www.rocketcoms.com" target="_blank" rel="noopener noreferrer">www.rocketcoms.com</a>
          , and
        </span>
      </li>
      <li><a href="http://www.primaryaccount.com" target="_blank" rel="noopener noreferrer"><span>www.primaryaccount.com</span></a></li>
    </ol>

    <p>Welcome to our website. We hope the information and our demo services are helpful to you. If you have any questions, please email us at info@rocketfncl.com.</p>

    <h3>By visiting this site, you accept our terms</h3>

    <p>
      The use of this website is subject to the following terms of use and the conditions of our privacy policy.
      By visiting this website: &nbsp;
      <a href="https://rocketfncl.com/legal" target="_blank" rel="noopener noreferrer">https://rocketfncl.com/legal</a>
      &nbsp;you acknowledge that you have read, understood and agree to these.
      We may update these policies from time to time and it is your responsibility to check for updates.
    </p>

    <p>
      By registering or creating a sandbox or production account on this website or our dashboard, providing information or
      otherwise using any demo, sandbox or API services offered on our website, or otherwise using our banking and other
      software services, you are be subject to additional terms found at &nbsp;
      <a href="https://rocketfncl.com/legal" target="_blank" rel="noopener noreferrer">https://rocketfncl.com/legal</a>
      .
    </p>

    <h3>Demo and Sandbox Services</h3>

    <p>
      If you attempt to or otherwise use our demo services or sandbox services, you agree to use the test credentials and
      data provided by us, or otherwise provide fake information; and you will not provide any real personally identifiable
      information (e.g. SSN or photo identification), sensitive financial information (e.g. real username, password or
      authorizations credentials to your financial institution), or any information which you do not own or you are authorized
      to use. You agree to defend and indemnify us of any unauthorized use of information provided to us.
    </p>

    <h3>We will protect our intellectual property rights</h3>

    <p>
      The contents of this website, including the text, graphics, software, and multi-media content is protected under both
      United States and foreign laws. Unauthorized use of our content may violate copyright, trademark and other laws.
      You have no rights to use this content except as permitted by this agreement without prior written consent from us.
      The trademarks, service marks and logos used on the website are registered marks of Rocket Financial Inc., our affiliates,
      and subsidiaries (“Rocketfncl” or “RocketCOMS” or “Primary Account”). Nothing on this site shall be construed as granting,
      by implication, or otherwise, any license or right to use these marks without our prior written permission specific for
      each such use. All goodwill generated from the use of our marks must be for the benefit of Rocket.
    </p>

    <h3>NO WARRANTIES / LIMITATION OF LIABILITY</h3>

    <p>
      THE WEBSITE AND ITS CONTENT (INCLUDING, BUT NOT LIMITED TO THE DEMO AND SANDBOX SERVICES) ARE PROVIDED ON AN “AS IS”
      AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND. WE DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED
      TO, THE WARRANTY OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND FITNESS FOR PARTICULAR
      PURPOSE. IN NO EVENT SHALL WE BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, INCIDENTAL
      AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM
      THE USE OR INABILITY TO USE THE WEBSITE OR ITS CONTENT WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
      OR ANY OTHER LEGAL THEORY, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME STATES DO NOT ALLOW
      EXCLUSION OF IMPLIED WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
      LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH STATES, OUR LIABILITY SHALL BE LIMITED TO THE GREATEST EXTENT
      PERMITTED BY LAW.
    </p>

    <h3>We are not responsible for external sites</h3>

    <p>
      Using advanced hypertext document linking technology, we may, from time to time, link to external websites.
      These links do not represent our endorsement of external content. We are not responsible for any external content.
      If you are concerned about content on external sites, please get in touch with the administrators of those sites.
    </p>

    <h3>Digital Millennium Copyright Act</h3>

    <p>
      Rocket Financial respects the intellectual property rights of others.
      We will review all claims that we receive of copyright infringement.
      If we deem that any content is in violation of applicable laws, we will remove it.
    </p>

    <p>
      Please notify Rocket of any claimed infringement by emailing us at legal@Rocketfncl.com with the following:
    </p>

    <ol>
      <li>A description of the copyrighted work and the URL on which it appears;</li>
      <li>Your contact details, including email address;</li>
      <li>A good faith statement that you believe our use of the work is not authorized;</li>
      <li>
        A statement, made under the penalty of perjury, that the information in the notice is accurate and that
        you are the copyright owner or authorized to act on the owner’s behalf; and
      </li>
      <li>An electronic or physical signature of the copyright owner or a person authorized to act on their behalf.</li>
    </ol>

    <h3>This is an American web site</h3>

    <p>
      This website is based in the United States of America. We make no claims whether this site can be downloaded or
      appropriately used outside of the Unites States. If you access it from outside of the United States, you do so at
      your own risk. You are solely responsible for ensuring compliance with local laws.
    </p>

    <h3>We can terminate this agreement at will</h3>

    <p>
      We reserve the right, at our sole discretion, to restrict, suspend, or terminate this Agreement and your access to all
      or any part of the Website, at any time and for any reason without prior notice or liability. We reserve the right to
      change, suspend, or discontinue all or any part of the Website at any time without prior notice or liability.
    </p>

    <h3>Miscellaneous legal provisions</h3>

    <p>
      This agreement is governed by the internal substantive laws of the State of Florida without regards to conflict of laws.
      You agree to submit to the exclusive personal jurisdiction of the state and federal courts in the State of Florida.
      If any provision of this agreement is found to be invalid by a competent court, the invalidity of such provisions shall
      not affect the validity of the remaining provisions.
    </p>

    <p>
      Additionally, if we choose to terminate any provision in accordance with the termination provision, that termination shall
      not affect the termination of other provisions. If we fail to act on, or enforce, any provision in this agreement, this
      shall not be construed as a waiver of that, or any other, provision. We will only grant waivers to specific provisions,
      for specific instances, in writing. This agreement constitutes the entire agreement between you and us with respect to
      this website and supersedes all previous or contemporaneous agreements. The section headings are merely provided for
      convenience and shall not be given any legal import. This agreement will inure to the benefit of our successors, assignees,
      licensees and sublicensees.
    </p>
  </div>
);

export default TermsAndConditions;
