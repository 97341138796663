import { Tooltip } from '@mui/material';
import {
  Bubble, BubbleWrapper, MoreBubble, UnorderList,
} from 'modules/rules-management/index.styled';
import { FC, useMemo } from 'react';

interface MemosProps {
  memos: string[];
}

const Memos:FC<MemosProps> = ({ memos }) => {
  const [fistMemo, secondMemo, thirdMemo, ...remainingMemos] = memos;
  const visibleMemos = useMemo(
    () => [fistMemo, secondMemo, thirdMemo].filter((memo) => memo),
    [memos],
  );

  const renderRemainingIdentifiers = useMemo(() => (
    <UnorderList>
      {remainingMemos?.map((memo, index) => (
        <li key={index}>{memo}</li>
      ))}
    </UnorderList>
  ), [memos]);

  return (
    <>
      {visibleMemos.map((memo, index) => (
        <BubbleWrapper key={index}>
          <Bubble>{memo}</Bubble>
        </BubbleWrapper>
      ))}

      {remainingMemos?.length > 0 && (
        <Tooltip
          arrow
          title={renderRemainingIdentifiers}
          className="pointer"
          placement="right"
        >
          <MoreBubble>{`+ ${remainingMemos?.length}`}</MoreBubble>
        </Tooltip>
      )}
    </>
  );
};

export default Memos;
