import { SortOrder } from 'constants/enums';
import { SortOptions } from 'models/sortOptions.interface';

export function getRequestSortOptions(name: string, sort: SortOptions | null) {
  if (sort && sort?.name === name) {
    const type = sort.type === SortOrder.asc ? SortOrder.desc : SortOrder.asc;

    return {
      name,
      type,
    };
  }

  return {
    name,
    type: SortOrder.desc,
  };
}

export const isPartialMatch = (name: string, search: string) => {
  if (!name) {
    return false;
  }

  const nameLower = name.toLowerCase();
  const searchLower = search.toLowerCase();

  if (nameLower.includes(searchLower)) {
    return true;
  }

  const nameWords = nameLower.split(' ');

  return nameWords.some((word) => word.includes(searchLower));
};
