import {
  Typography, MenuItem, SxProps,
} from '@mui/material';
import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { LogoutWrapper, Menu } from 'components/UserMenu/index.styled';

import LogoutIcon from 'assets/icons/LogoutIcon';

interface UserMenuProps {
  anchorEl: null | HTMLElement;
  onProfileRedirect: () => void;
  onLogout: () => void;
  onClose: () => void;
  sx?: SxProps;
}

const UserMenu:FC<UserMenuProps> = (props): ReactElement => {
  const {
    anchorEl, sx = {}, onClose, onProfileRedirect, onLogout,
  } = props;
  const open = Boolean(anchorEl);
  const intl = useIntl();

  return (
    <Menu
      id="user-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={sx}
      keepMounted
      open={open}
      onClose={onClose}
    >
      <MenuItem onClick={onProfileRedirect}>
        <Typography variant="body1">
          {intl.formatMessage({ id: 'label.accountSettings' })}
        </Typography>
      </MenuItem>
      <MenuItem onClick={onLogout}>
        <LogoutWrapper>
          <LogoutIcon size={20} />

          <Typography variant="body1" ml={2} pt={0.5}>
            {intl.formatMessage({ id: 'button.logout' })}
          </Typography>
        </LogoutWrapper>
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
