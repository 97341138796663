
import {
  runDeleteApiRequest, runGetApiRequest, runPostApiRequest, runUpdateApiRequest,
} from 'services/Api';

import { CONTACT_DETAILS_API, GET_CONTACTS_API, GET_CONTACTS_LIST_API } from 'constants/apiRoutes';
import { User } from 'models/user.interface';
import { formatRoute } from 'utils/formatters';

export const getContacts = (queryParams: { [key: string]: string | number } | URLSearchParams) => (
  runGetApiRequest(GET_CONTACTS_API, queryParams)
);

export const getContactsList = () => runGetApiRequest(GET_CONTACTS_LIST_API);

export const createContact = (contact: Partial<User>) => (
  runPostApiRequest(GET_CONTACTS_API, contact)
);

export const updateContact = (contact: Partial<User>, contactId: string) => (
  runUpdateApiRequest(formatRoute(CONTACT_DETAILS_API, { contactId }), contact)
);

export const deleteContact = (contactId: string) => (
  runDeleteApiRequest(formatRoute(CONTACT_DETAILS_API, { contactId }))
);
