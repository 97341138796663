import { useIntl } from 'react-intl';

import EmptyState from 'components/EmptyState';

import EmptySearch from 'assets/images/empty-search.svg';

import { EmptyStateWrapper } from './index.styled';

export const EmptySearchResults = () => {
  const intl = useIntl();

  return (
    <EmptyStateWrapper>
      <EmptyState
        image={EmptySearch}
        subtitle={intl.formatMessage({ id: 'label.emptySearchResults' })}
        title={intl.formatMessage({ id: 'label.noResultsFound' })}
        width="60%"
      />
    </EmptyStateWrapper>
  );
};

export default EmptySearchResults;
