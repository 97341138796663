/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ResumeIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.68182 4C5.50099 4 5.32757 4.07024 5.1997 4.19526C5.07183 4.32029 5 4.48986 5 4.66667V4.66927V12V19.3307V19.3333C5 19.5101 5.07183 19.6797 5.1997 19.8047C5.32757 19.9298 5.50099 20 5.68182 20C5.82375 19.9996 5.96201 19.9559 6.07733 19.875L6.07866 19.8763L19.6018 12.6055C19.7205 12.5524 19.8211 12.4671 19.8917 12.3598C19.9623 12.2525 19.9998 12.1276 20 12C20.0001 11.8688 19.9606 11.7405 19.8864 11.6312C19.8123 11.5219 19.7068 11.4364 19.5832 11.3854L6.07866 4.1237H6.07733C5.96185 4.04326 5.8236 4.00002 5.68182 4Z" fill={color} />
  </svg>
);

export default ResumeIcon;
