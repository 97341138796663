import { styled } from '@mui/material/styles';

export const Circle = styled('div')(({ theme }) => ({
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.dark,
  display: 'flex',
  justifyContent: 'center',
}));
