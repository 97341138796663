/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const BatchesIcon = ({ color = '#BABABA', size = 18 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 18 18">
    <path fill={color} d="M9 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM2.25 3.75a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm13.5 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM9 6a2.262 2.262 0 0 0-2.25 2.25c0 .522.256.96.56 1.343C6.539 10.138 6 10.991 6 12h1.5c0-.838.662-1.5 1.5-1.5s1.5.662 1.5 1.5H12c0-1.009-.539-1.862-1.31-2.407.303-.383.56-.821.56-1.343A2.262 2.262 0 0 0 9 6Zm0 1.5c.424 0 .75.326.75.75S9.424 9 9 9a.738.738 0 0 1-.75-.75c0-.424.326-.75.75-.75Zm-6.75 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm13.5 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM9 15a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z" />
  </svg>
);

export default BatchesIcon;
