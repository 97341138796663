import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { fetchIdentityApplication } from 'store/kyb/kybSlice';
import { fetchSubscription } from 'store/subscription/subscriptionSlice';
import { currentWorkspaceSelector } from 'store/user/selectors';

const AppProviders = (): null => {
  const dispatch = useAppDispatch();
  const customer = useAppSelector(currentWorkspaceSelector);

  useEffect(() => {
    if (customer?.id) {
      dispatch(fetchIdentityApplication(customer?.id));
      dispatch(fetchSubscription());
    }
  }, [customer?.id]);

  return null;
};

export default AppProviders;
