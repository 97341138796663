import MuiMenuItem from '@mui/material/MenuItem';
import MuiSelector from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export const CompanyWrapper = styled('div')(({ theme }) => ({
  maxWidth: '300px',
  marginLeft: theme.spacing(2),

  [theme.breakpoints.down(1300)]: {
    maxWidth: '250px',
  },

  [theme.breakpoints.down('lg')]: {
    maxWidth: '140px',
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '150px',
    marginLeft: theme.spacing(2.5),
  },
}));

export const MenuItem = styled(MuiMenuItem)(() => ({
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
  display: 'flex',
  alignItems: 'center',
  gap: 1,
}));

export const Select = styled(MuiSelector)(({ theme }) => ({
  color: theme.palette.common.white,
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.general.darkGreen,
  },
  '&.Mui-focused': {
    backgroundColor: theme.palette.general.darkGreen2,
  },
  '&:hover:not(&.Mui-focused, &.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& svg path': {
    fill: theme.palette.common.white,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
}));
