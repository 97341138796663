/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ArrowUpIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9993 20L10.9993 7.8305L5.40993 13.4202L4 12.0006L12 4L20 12.0006L18.5901 13.4106L12.9996 7.8305L12.9996 20L10.9993 20Z" fill={color} />
  </svg>
);

export default ArrowUpIcon;
