import { Grid2 as Grid } from '@mui/material';
import {
  FC, ReactElement, useState, useEffect,
} from 'react';
import {
  Controller, FieldErrors, UseFormRegister, Control,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import DropdownField from 'fields/DropdownField';
import InputField from 'fields/InputField';

import { useAppSelector } from 'hooks/useRedux';
import { getWorkspaceMembers } from 'services/UsersService';
import { currentWorkspaceSelector, userSelector } from 'store/user/selectors';

import { Option } from 'models/option.interface';
import { User } from 'models/user.interface';
import { getTranslatedErrors } from 'utils/errorUtils';
import { formatUserName } from 'utils/formatters';
import { filterSupportUsers } from 'utils/users';
import { MemberFormValues, TransferOwnershipFormValues } from 'utils/validation/memberFormSchema';

interface TransferOwnershipProps {
  control: Control<any>;
  register: UseFormRegister<any>;
  errors: Partial<FieldErrors<MemberFormValues | TransferOwnershipFormValues>>;
}

const TransferOwnership:FC<TransferOwnershipProps> = ({
  register,
  errors,
  control,
}): ReactElement => {
  const [members, setMembers] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const user = useAppSelector(userSelector);
  const translatedErrors = getTranslatedErrors(errors);
  const workspaceId = currentWorkspace?.id || '';

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setIsLoading(true);
        const { data } = await getWorkspaceMembers({ workspaceId });
        const filteredMembers = filterSupportUsers(data?.content);
        const options = filteredMembers
          ?.filter((member: User) => member.id !== user?.id)
          ?.map((member: User): Option => ({
            label: formatUserName(member),
            value: member.id,
          }));

        setMembers(options);
      } catch (e) {
        setMembers([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMembers();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 6 }}>
        <InputField
          name="currentOwner"
          label={intl.formatMessage({ id: 'label.transferOwnerFrom' })}
          value={formatUserName(user)}
          fullWidth
          disabled
          id="input-currentOwner"
        />
      </Grid>
      <Grid size={{ xs: 6 }}>
        <Controller
          name="transferOwnerTo"
          control={control}
          render={({ field }): ReactElement => (
            <DropdownField
              name="transferOwnerTo"
              label={intl.formatMessage({ id: 'label.transferOwnerTo' })}
              fullWidth
              required
              isLoading={isLoading}
              value={field?.value}
              options={members}
              inputProps={register('transferOwnerTo')}
              error={!!errors.transferOwnerTo?.message}
              helperText={translatedErrors.transferOwnerTo?.message}
              id="select-transferOwnerTo"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default TransferOwnership;
