import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPaymentReasons, getScheduledTransactionDetails } from 'services/TransactionService';
import { getUserDetails } from 'services/UserService';
import { resetStore } from 'store/actions';
import { RootState } from 'store/index';

import { ApiStatus } from 'constants/enums';
import { PaymentReasonInterface } from 'models/paymentReason.interface';
import { ScheduledTransaction } from 'models/transaction.interface';
import { User } from 'models/user.interface';
import { handleApiErrors } from 'utils/errorUtils';

export interface ScheduledTransactionState {
  details: {
    data?: ScheduledTransaction;
    status: ApiStatus;
  };
  createdBy: {
    data?: User;
    status: ApiStatus;
  };
  updatedBy: {
    data?: User;
    status: ApiStatus;
  };
  reasons: {
    data: PaymentReasonInterface[];
    status: ApiStatus;
  };
}

const initialState: ScheduledTransactionState = {
  details: {
    data: undefined,
    status: ApiStatus.loading,
  },
  createdBy: {
    data: undefined,
    status: ApiStatus.idle,
  },
  updatedBy: {
    data: undefined,
    status: ApiStatus.idle,
  },
  reasons: {
    data: [],
    status: ApiStatus.idle,
  },
};

export const fetchScheduledTransactionDetails = createAsyncThunk(
  'scheduledTransactionsDetails/fetchTransactionDetails',
  async ({ transactionId, type }: { transactionId: string; type: string }, thunkAPI) => {
    try {
      const response = await getScheduledTransactionDetails(transactionId, type);
      const state = thunkAPI.getState() as RootState;
      const { loggedUser: { user } } = state;
      const { createdBy, updatedBy, customer } = response?.data?.metadata || {};

      if (createdBy) {
        if (user && user?.id === createdBy) {
          thunkAPI.dispatch(setCreatedBy(user));
        } else {
          thunkAPI.dispatch(fetchCreatedBy({ workspaceId: customer, userId: createdBy }));
        }
      }

      if (updatedBy) {
        if (user && user?.id === updatedBy) {
          thunkAPI.dispatch(setUpdatedBy(user));
        } else {
          thunkAPI.dispatch(fetchUpdatedBy({ workspaceId: customer, userId: updatedBy }));
        }
      }

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchPaymentReasons = createAsyncThunk(
  'scheduledTransactionsDetails/paymentReasons',
  async (_, thunkAPI) => {
    try {
      const { data } = await getPaymentReasons();
      return data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchCreatedBy = createAsyncThunk(
  'scheduledTransactionsDetails/createdBy',
  async ({ workspaceId, userId }: { workspaceId: string; userId: string }, thunkAPI) => {
    try {
      const { data } = await getUserDetails(workspaceId, userId);
      return data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);
export const fetchUpdatedBy = createAsyncThunk(
  'scheduledTransactionsDetails/updatedBy',
  async ({ workspaceId, userId }: { workspaceId: string; userId: string }, thunkAPI) => {
    try {
      const { data } = await getUserDetails(workspaceId, userId);
      return data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const scheduledTransactionDetailsSlice = createSlice({
  name: 'scheduledTransactionDetails',
  initialState,
  reducers: {
    setCreatedBy: (state, action: PayloadAction<User>) => {
      state.createdBy = {
        ...state.createdBy,
        data: action.payload,
      };
    },
    setUpdatedBy: (state, action: PayloadAction<User>) => {
      state.updatedBy = {
        ...state.updatedBy,
        data: action.payload,
      };
    },
    resetState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchScheduledTransactionDetails.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchScheduledTransactionDetails.fulfilled, (state, action) => {
        state.details = {
          ...state.details,
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchScheduledTransactionDetails.rejected, (state) => {
        state.details = {
          data: undefined,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchPaymentReasons.pending, (state) => {
        state.reasons.status = ApiStatus.loading;
      })
      .addCase(fetchPaymentReasons.fulfilled, (state, action) => {
        state.reasons = {
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchPaymentReasons.rejected, (state) => {
        state.reasons = {
          data: [],
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchCreatedBy.pending, (state) => {
        state.reasons.status = ApiStatus.loading;
      })
      .addCase(fetchCreatedBy.fulfilled, (state, action) => {
        state.reasons = {
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchCreatedBy.rejected, (state) => {
        state.reasons = {
          data: [],
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchUpdatedBy.pending, (state) => {
        state.reasons.status = ApiStatus.loading;
      })
      .addCase(fetchUpdatedBy.fulfilled, (state, action) => {
        state.reasons = {
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchUpdatedBy.rejected, (state) => {
        state.reasons = {
          data: [],
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  resetState,
  setCreatedBy,
  setUpdatedBy,
} = scheduledTransactionDetailsSlice.actions;

export default scheduledTransactionDetailsSlice.reducer;
