/* eslint max-len: 0 */
import { CustomIconInterface } from 'models/icon.interface';

const LinkAccountIcon = ({ color = 'currentColor', stroke = 'currentColor', size = 70 }: CustomIconInterface) => (
  <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.3095 5.45958C30.035 5.32716 29.715 5.32716 29.4405 5.45958L7.56553 16.0117C7.21976 16.1785 7 16.5285 7 16.9124V22.181C7 22.7333 7.44772 23.181 8 23.181H11.0833C11.6356 23.181 12.0833 23.6287 12.0833 24.181V44.25C12.0833 44.8023 12.531 45.25 13.0833 45.25H16.1667C16.719 45.25 17.1667 44.8023 17.1667 44.25V24.181C17.1667 23.6287 17.6144 23.181 18.1667 23.181H21.25C21.8023 23.181 22.25 23.6287 22.25 24.181V44.25C22.25 44.8023 22.6977 45.25 23.25 45.25H26.3333C26.8856 45.25 27.3333 44.8023 27.3333 44.25V24.181C27.3333 23.6287 27.781 23.181 28.3333 23.181H31.4167C31.969 23.181 32.4167 23.6287 32.4167 24.181V44.25C32.4167 44.8023 32.8644 45.25 33.4167 45.25H36.5C37.0523 45.25 37.5 44.8023 37.5 44.25V24.181C37.5 23.6287 37.9477 23.181 38.5 23.181H41.5833C42.1356 23.181 42.5833 23.6287 42.5833 24.181V44.25C42.5833 44.8023 43.0311 45.25 43.5833 45.25H46.6667C47.219 45.25 47.6667 44.8023 47.6667 44.25V24.181C47.6667 23.6287 48.1144 23.181 48.6667 23.181H51.75C52.3023 23.181 52.75 22.7333 52.75 22.181V16.9124C52.75 16.5285 52.5302 16.1785 52.1845 16.0117L30.3095 5.45958Z"
      fill={color}
    />
    <path
      d="M53.1667 54.8796H6.58333C4.74238 54.8796 3.25 53.3872 3.25 51.5463C3.25 49.7053 4.74239 48.213 6.58334 48.213H53.1667C55.0076 48.213 56.5 49.7053 56.5 51.5463C56.5 53.3872 55.0076 54.8796 53.1667 54.8796Z"
      fill={color}
    />
    <path
      d="M38.6 52C38.6 44.5999 44.5999 38.6 52 38.6C59.4001 38.6 65.4 44.5999 65.4 52C65.4 59.4001 59.4001 65.4 52 65.4C44.5999 65.4 38.6 59.4001 38.6 52Z"
      fill="#A7BFBE"
      stroke={stroke}
      strokeWidth="2"
      id="circle"
    />
    <path
      d="M47.6638 54.5598L47.2329 54.9805L47.2327 54.9807C46.7677 55.4343 46.7673 56.1656 47.2331 56.6207C47.7114 57.088 48.5021 57.0883 48.9812 56.6205L49.3513 56.2592C49.31 56.2629 49.2685 56.2649 49.2269 56.2653C49.0232 56.2674 48.8206 56.2297 48.6308 56.1541L48.9844 55.267L48.6308 56.1541C48.4409 56.0784 48.2669 55.9659 48.1194 55.8219L47.6638 54.5598ZM47.6638 54.5598C47.6586 54.6084 47.6557 54.6574 47.6552 54.7065C47.653 54.9152 47.6935 55.1216 47.7738 55.3135C47.854 55.5053 47.9719 55.6779 48.1194 55.8219L47.6638 54.5598ZM52.8352 45.1801L52.8349 45.1804L51.7227 46.2663L51.7224 46.2666C50.9029 47.0674 50.4437 48.1411 50.4437 49.2851C50.4437 49.7796 50.5303 50.2602 50.6945 50.7112C50.2274 50.5445 49.7299 50.4569 49.2193 50.4569C48.0697 50.4569 46.9812 50.8962 46.1619 51.6955L46.1616 51.6958L45.0493 52.7817L45.049 52.782C44.2296 53.5827 43.7704 54.6565 43.7704 55.8005C43.7704 56.9445 44.2296 58.0183 45.049 58.819C45.8685 59.6198 46.9581 60.0582 48.1071 60.0582C49.2567 60.0582 50.3451 59.6189 51.1645 58.8196L51.1648 58.8193L52.277 57.7334L52.2773 57.7331C53.0967 56.9324 53.5559 55.8586 53.5559 54.7146C53.5559 54.3172 53.4977 53.9343 53.3958 53.5721L53.3211 53.3064L53.6286 53.3889C53.9945 53.487 54.3805 53.5428 54.7804 53.5428C55.93 53.5428 57.0184 53.1035 57.8378 52.3042L57.8381 52.3039L58.9503 51.218L58.9506 51.2177C59.7701 50.417 60.2293 49.3432 60.2293 48.1992C60.2293 47.0552 59.7701 45.9815 58.9506 45.1807L58.95 45.1801C58.1307 44.3808 57.0422 43.9415 55.8926 43.9415C54.743 43.9415 53.6546 44.3808 52.8352 45.1801ZM54.7404 47.7373C54.7088 47.7382 54.6773 47.7401 54.6459 47.7429L55.0184 47.3792C55.2542 47.149 55.5676 47.0295 55.8926 47.0295C56.2169 47.0295 56.5306 47.1491 56.767 47.3794C57.2326 47.8342 57.2322 48.5652 56.767 49.019L56.7668 49.0192L56.3334 49.4423C56.3615 49.1796 56.3211 48.9137 56.2156 48.6703C56.0904 48.3813 55.8804 48.1401 55.6172 47.9752C55.3542 47.8104 55.0488 47.7284 54.7407 47.7373L54.7404 47.7373Z"
      fill="white"
      stroke={stroke}
      strokeWidth="2"
    />
  </svg>
);

export default LinkAccountIcon;
