import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const CustomerDetails = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'CustomerDetails' */ 'portal/customer-details/containers/CustomerDetails')
    ),
    'CustomerDetails',
  ),
);

export default CustomerDetails;
