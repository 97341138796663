import { Tooltip } from '@mui/material';
import {
  Bubble, BubbleWrapper, MoreBubble, UnorderList,
} from 'modules/rules-management/index.styled';
import { FC, useMemo } from 'react';

interface BanksIdentifiersProps {
  identifiers: Record<string, string>;
}

const BanksIdentifiers:FC<BanksIdentifiersProps> = ({ identifiers }) => {
  const identifiersAsAccounts = useMemo(() => Object.values(identifiers), [identifiers]);

  const [identifier1, identifier2, identifier3, ...remainingIdentifiers] = identifiersAsAccounts;
  const visibleIdentifiers = useMemo(
    () => [identifier1, identifier2, identifier3].filter((identifier) => identifier),
    [identifiers],
  );

  const renderRemainingIdentifiers = useMemo(() => (
    <UnorderList>
      {remainingIdentifiers.map((identifierId) => (
        <li key={identifierId}>{identifierId}</li>
      ))}
    </UnorderList>
  ), [remainingIdentifiers]);

  return (
    <>
      {visibleIdentifiers.map((identifierId) => (
        <BubbleWrapper key={identifierId}>
          <Bubble>{identifierId}</Bubble>
        </BubbleWrapper>
      ))}
      {remainingIdentifiers?.length > 0 && (
        <Tooltip
          arrow
          title={renderRemainingIdentifiers}
          className="pointer"
          placement="right"
        >
          <MoreBubble>{`+ ${remainingIdentifiers?.length}`}</MoreBubble>
        </Tooltip>
      )}
    </>
  );
};

export default BanksIdentifiers;
