const config = {
  env: 'STAGE',
  firebaseConfig: {
    apiKey: 'AIzaSyBZnfOc7BSrj6WFx2tr82BPjCNgeQvvVQc',
    authDomain: 'primaryacct-staging.firebaseapp.com',
    projectId: 'primaryacct-staging',
    storageBucket: 'primaryacct-staging.appspot.com',
    messagingSenderId: '907515703759',
    appId: '1:907515703759:web:24a89c38d62ef3191a22ba',
    measurementId: 'G-XV02PLF8RS',
  },
  siteId: 'primaryacct-staging',
  sentryConfiguration: {
    dsn: 'https://05c054ee7e1e4886b6f68dd7b6dcd077@o4504578189557760.ingest.sentry.io/4504578249392128',
  },
  uiBaseUrl: 'https://primaryacct-staging.web.app/',
  apiBaseUrl: 'https://api-stage.primaryaccount.com/',
  fiServEnvUrl: 'https://ais-ui-uat.fiservapps.com/alldata/',
  intercomAppId: 'qlijpicu',
};

export default config;
