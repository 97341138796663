import MuiPopper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

export const Popper = styled(MuiPopper)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0px 6px 24px rgba(141, 141, 141, 0.35)',
    border: 0,
    padding: theme.spacing(3, 4),
  },

  '& .MuiTooltip-arrow:before': {
    backgroundColor: theme.palette.common.white,
    border: 0,
  },
}));
