import MuiIconButton from '@mui/material/IconButton';
import MuiMenu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  height: '36px',
  width: '36px',

  '& svg': {
    transform: 'rotate(90deg)',

    '& path': {
      fill: theme.palette.general.darkGrey,
    },
  },
}));

export const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .red-menu-item': {
    color: theme.palette.error.main,

    '& svg path': {
      fill: theme.palette.error.main,
    },
  },
}));
