import {
  Checkbox, IconButton, Skeleton, TableCell, Typography,
} from '@mui/material';
import { get } from 'lodash';
import { FC, ReactElement, useMemo } from 'react';

import { TableRow } from 'components/Table/ResponsiveRow/index.styled';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import { NOT_APPLICABLE } from 'constants/general';
import { Column } from 'models/column.interface';

interface ResponsiveRowProps {
  id: string;
  rowData: unknown;
  indexRow: number;
  columns: Column[];
  onRowCollapse?: () => void;
  isDisabled?: boolean;
  onRowClick?: (rowData: any) => void;
  isSelected?: boolean;
  isCollapsable?: boolean;
  isCollapsed?: boolean;
  isSelectable?: boolean;
  onSelect?: (rowData: any) => void;
  rowClassName?: (rowData: any) => string;
  isLoading?: boolean;
  groupedBy?: string[];
}

const ResponsiveRow: FC<ResponsiveRowProps> = (props): ReactElement => {
  const {
    id,
    rowData,
    indexRow,
    onRowCollapse,
    onRowClick,
    columns,
    isSelected,
    onSelect,
    rowClassName,
    isSelectable,
    isCollapsable,
    isCollapsed = false,
    isDisabled = false,
    isLoading = false,
    groupedBy = [],
  } = props;

  const className = useMemo(() => {
    if (rowClassName) return rowClassName(rowData);
    return '';
  }, [rowData]);

  const handleSelect = (): void => onSelect && onSelect(rowData);

  const handleRowClick = (): void => {
    if (isDisabled) {
      return;
    }

    if (onRowClick) {
      onRowClick(rowData);
    }
  };

  const renderCellContent = (column: Column): ReactElement | string => {
    const value = get(rowData, column.name);

    if (column.render) {
      const content = column.render({
        item: rowData,
        rowIndex: indexRow,
        isDisabled,
        name: column.name,
      });

      if (typeof content === 'string') {
        return (
          <Typography variant="body2">
            {content}
          </Typography>
        );
      }

      return content;
    }

    return (
      <Typography variant="body2">
        {value || NOT_APPLICABLE}
      </Typography>
    );
  };

  return (
    <TableRow
      className={className}
      selected={isSelected}
      id={`tableRow-${id}-${indexRow}`}
      hover={!isDisabled}
      sx={{
        opacity: isDisabled ? 0.5 : 1,
        cursor: onRowClick && !isDisabled ? 'pointer' : 'inherit',
      }}
    >
      {(isCollapsable && onRowCollapse) && (
        <TableCell id={`tableRow-collapsable-${id}`} width="50px">
          <div className={`innerCell collapsableColumn icon ${isCollapsed ? 'activeIcon' : ''}`}>
            <IconButton
              onClick={onRowCollapse}
              size="small"
              aria-label="collapse table column"
              id="iconButton-collapseTableColumn"
            >
              <ChevronDownIcon size={20} />
            </IconButton>
          </div>
        </TableCell>
      )}
      {isSelectable && (
        <TableCell padding="checkbox" id={`tableRow-checkAll-${id}`}>
          <div className="innerCell">
            <Checkbox
              id={`tableHead-checkbox-${id}`}
              checked={isSelected}
              onChange={handleSelect}
            />
          </div>
        </TableCell>
      )}
      {columns.map((column) => {
        const cellClassName = (groupedBy.includes(column.name) || (column.name === 'actions' && groupedBy?.length > 0))
          ? 'empty'
          : '';

        return (
          <TableCell
            className={cellClassName}
            key={`${indexRow}-${column.name}`}
            id={`tableRow-${id}-${indexRow}-${column.name}`}
            sx={column?.sx}
            {...(column?.isClickable ? { onClick: handleRowClick } : {})}
          >
            {!groupedBy.includes(column.name) ? (
              <div className={`innerCell ${column.cellClassName || ''}`}>
                {isLoading
                  ? (
                    <Skeleton
                      height="20px"
                      width="100%"
                      sx={{ minWidth: '40px' }}
                      variant="rectangular"
                      animation="wave"
                    />
                  ) : renderCellContent(column)}
              </div>
            ) : <div className="innerCell" />}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default ResponsiveRow;
