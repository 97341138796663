/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SuspendIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.55556 4C5.69611 4 5 4.65091 5 5.45455V18.5455C5 19.3491 5.69611 20 6.55556 20H8.11111C8.97056 20 9.66667 19.3491 9.66667 18.5455V5.45455C9.66667 4.65091 8.97056 4 8.11111 4H6.55556ZM15.8889 4C15.0294 4 14.3333 4.65091 14.3333 5.45455V18.5455C14.3333 19.3491 15.0294 20 15.8889 20H17.4444C18.3039 20 19 19.3491 19 18.5455V5.45455C19 4.65091 18.3039 4 17.4444 4H15.8889Z" fill={color} />
  </svg>
);

export default SuspendIcon;
