import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getTransactionBatches } from 'services/TransactionBatchesService';
import { resetStore } from 'store/actions';

import { ApiStatus } from 'constants/enums';
import { DEFAULT_PAGINATION_SIZE } from 'constants/general';
import { TransactionBatch } from 'models/transactionBatch.interface';
import { handleApiErrors } from 'utils/errorUtils';

export interface TransactionActivityState {
  details: {
    data: TransactionBatch[];
    status: ApiStatus;
  };
  totalPages: number;
  totalElements: number;
}

const initialState: TransactionActivityState = {
  details: {
    data: [],
    status: ApiStatus.idle,
  },
  totalPages: 0,
  totalElements: 0,
};

export const fetchTransactionBatches = createAsyncThunk(
  'transactions/fetchTransactions',
  async ({ id, page, sort }: { id: string; page: number; sort: string }, thunkAPI) => {
    try {
      const params = {
        page,
        size: DEFAULT_PAGINATION_SIZE,
        sort,
      };

      const response = await getTransactionBatches(id, params);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const transactionBatchesSlice = createSlice({
  name: 'transactionBatches',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchTransactionBatches.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchTransactionBatches.fulfilled, (state, action) => {
        state.details = {
          ...state.details,
          data: action.payload.content,
          status: ApiStatus.idle,
        };
        state.totalPages = action.payload.totalPages;
        state.totalElements = action.payload.totalElements;
      })
      .addCase(fetchTransactionBatches.rejected, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.idle,
        };
      });
  },
});

export default transactionBatchesSlice.reducer;
