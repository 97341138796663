/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const BeneficiaryIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 1C6.46957 1 5.96086 1.21071 5.58579 1.58579C5.21071 1.96086 5 2.46957 5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5C7.53043 5 8.03914 4.78929 8.41421 4.41421C8.78929 4.03914 9 3.53043 9 3C9 2.46957 8.78929 1.96086 8.41421 1.58579C8.03914 1.21071 7.53043 1 7 1ZM17 5C16.4696 5 15.9609 5.21071 15.5858 5.58579C15.2107 5.96086 15 6.46957 15 7C15 7.53043 15.2107 8.03914 15.5858 8.41421C15.9609 8.78929 16.4696 9 17 9C17.5304 9 18.0391 8.78929 18.4142 8.41421C18.7893 8.03914 19 7.53043 19 7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5ZM5 6C3.897 6 3 6.897 3 8V15H4V23H6V15H8V23H10V15H11V8C11 6.897 10.103 6 9 6H5ZM15 10C13.897 10 13 10.897 13 12V18H14V23H16V18H18V23H20V18H21V12C21 10.897 20.103 10 19 10H15Z" fill={color} />
  </svg>
);

export default BeneficiaryIcon;
