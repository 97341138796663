import { Typography, Box, Grid2 as Grid } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import BusinessAddressInformation from 'components/BusinessAddressInformation';
import { DocumentWrapper } from 'components/BusinessDetails/index.styled';
import GridInformationLabel from 'components/GridInformationLabel';

import DocumentIcon from 'assets/icons/DocumentIcon';
import { NOT_APPLICABLE } from 'constants/general';
import { DOCUMENT_TYPE_LABELS } from 'constants/labels';
import { Company } from 'models/company.interface';
import { Document } from 'models/document.interface';
import { formatPhoneNumber } from 'utils/formatters';

interface BusinessDetailsProps {
  companyDetails?: Company | null;
  documents: Document[];
  detailed?: boolean;
  isLoading?: boolean;
}

const BusinessDetails: FC<BusinessDetailsProps> = ({
  companyDetails,
  documents,
  detailed = false,
  isLoading = false,
}) => {
  const intl = useIntl();

  return (
    <Grid container gap={4}>
      <GridInformationLabel
        value={companyDetails?.legalName || NOT_APPLICABLE}
        isPlaceholder={!companyDetails?.legalName}
        label={intl.formatMessage({ id: 'label.businessLegalName' })}
        isLoading={isLoading}
      />
      <GridInformationLabel
        value={companyDetails?.doingBusinessAsName || NOT_APPLICABLE}
        isPlaceholder={!companyDetails?.doingBusinessAsName}
        label={intl.formatMessage({ id: 'label.doingBusinessAs' })}
        isLoading={isLoading}
      />
      <GridInformationLabel
        value={companyDetails?.businessClassification || NOT_APPLICABLE}
        isPlaceholder={!companyDetails?.businessClassification}
        label={intl.formatMessage({ id: 'label.businessClassification' })}
        isLoading={isLoading}
      />
      <GridInformationLabel label={intl.formatMessage({ id: 'label.document' })} isLoading={isLoading}>
        <Box display="flex" flexDirection="column" gap={2}>
          {documents?.map((document) => {
            const documentLabel = DOCUMENT_TYPE_LABELS?.[document.documentType]
              ? intl.formatMessage({ id: DOCUMENT_TYPE_LABELS?.[document.documentType] })
              : NOT_APPLICABLE;

            return (
              <Box key={document.id} mb={4}>
                <Typography variant="body1" fontWeight={600} className="text-ellipsis">
                  {documentLabel}
                </Typography>
                {detailed ? (
                  <DocumentWrapper>
                    <DocumentIcon />
                    <Typography variant="body1" color="textSecondary" className="text-ellipsis">
                      {document.name}
                    </Typography>
                  </DocumentWrapper>
                ) : (
                  <Typography variant="body1" fontWeight={600} color="general.darkGrey1" className="text-ellipsis">
                    {`(${document.name})`}
                  </Typography>
                )}
              </Box>
            );
          })}
          {!documents?.length && (
            <Typography variant="body1" fontWeight={600} color="general.lightGrey6">
              {NOT_APPLICABLE}
            </Typography>
          )}
        </Box>
      </GridInformationLabel>
      <GridInformationLabel label={intl.formatMessage({ id: 'label.address' })} isLoading={isLoading}>
        <Box display="flex" flexDirection="column" gap={2}>
          {!companyDetails?.addresses?.length ? (
            <Typography variant="body1" fontWeight={600} color="general.lightGrey6">
              {NOT_APPLICABLE}
            </Typography>
          ) : (
            companyDetails?.addresses?.map((address) => (
              <BusinessAddressInformation address={address} key={address.id} />
            ))
          )}
        </Box>
      </GridInformationLabel>
      <GridInformationLabel
        value={formatPhoneNumber(companyDetails?.primaryPhone)}
        isPlaceholder={!companyDetails?.primaryPhone}
        label={intl.formatMessage({ id: 'label.primaryPhoneNumber' })}
        isLoading={isLoading}
      />
      <GridInformationLabel
        value={companyDetails?.website || NOT_APPLICABLE}
        isPlaceholder={!companyDetails?.website}
        label={intl.formatMessage({ id: 'label.website' })}
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default BusinessDetails;
