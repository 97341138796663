import { Avatar, SxProps } from '@mui/material';
import { FC, ReactElement } from 'react';

interface UserAvatarProps {
  src?: string;
  userInitials?: string;
  height?: string | number;
  width?: string | number;
  variant?: 'rounded' | 'circular' | 'square';
  sx?: SxProps;
}

const UserAvatar: FC<UserAvatarProps> = (props): ReactElement => {
  const {
    src = '',
    userInitials,
    height = '30px',
    width = '30px',
    variant = 'circular',
    sx = {},
  } = props;

  return (
    <Avatar
      sx={{
        height, width, fontSize: '14px', ...sx,
      }}
      variant={variant}
      src={src}
    >
      {userInitials}
    </Avatar>
  );
};

export default UserAvatar;
