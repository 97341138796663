/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const StarIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5245 4.46353C11.6742 4.00287 12.3259 4.00287 12.4756 4.46353L14.1329 9.56434C14.1999 9.77035 14.3919 9.90983 14.6085 9.90983H19.9718C20.4562 9.90983 20.6575 10.5296 20.2657 10.8143L15.9267 13.9668C15.7514 14.0941 15.6781 14.3198 15.745 14.5258L17.4024 19.6266C17.5521 20.0873 17.0248 20.4704 16.633 20.1857L12.294 17.0332C12.1187 16.9059 11.8814 16.9059 11.7062 17.0332L7.36716 20.1857C6.97531 20.4704 6.44807 20.0873 6.59774 19.6266L8.2551 14.5258C8.32203 14.3198 8.2487 14.0941 8.07346 13.9668L3.73445 10.8143C3.34259 10.5296 3.54398 9.90983 4.02834 9.90983H9.39166C9.60827 9.90983 9.80025 9.77035 9.86718 9.56434L11.5245 4.46353Z" fill={color} />
  </svg>
);

export default StarIcon;
