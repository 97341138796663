/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const InfoIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.34313 6.34298C5.22432 7.46182 4.46239 8.8873 4.15372 10.4392C3.84504 11.991 4.00347 13.5996 4.60897 15.0614C5.21447 16.5232 6.23985 17.7727 7.55545 18.6517C8.87104 19.5308 10.4178 20 12 20C13.5822 20 15.129 19.5308 16.4446 18.6517C17.7601 17.7727 18.7855 16.5232 19.391 15.0614C19.9965 13.5996 20.155 11.991 19.8463 10.4392C19.5376 8.8873 18.7757 7.46182 17.6569 6.34298C16.1565 4.84279 14.1217 4 12 4C9.8783 4 7.84349 4.84279 6.34313 6.34298ZM12.3208 7.35617C12.6478 7.35617 12.9613 7.48604 13.1925 7.71722C13.4237 7.9484 13.5535 8.26194 13.5535 8.58888C13.5535 8.91581 13.4237 9.22936 13.1925 9.46053C12.9613 9.69171 12.6478 9.82159 12.3208 9.82159C11.9918 9.81877 11.6774 9.68568 11.4463 9.45146C11.2153 9.21724 11.0865 8.90099 11.0881 8.57199C11.0906 8.24738 11.2219 7.93702 11.453 7.70907C11.6841 7.48111 11.9962 7.35419 12.3208 7.35617ZM14.0601 15.6136C14.0244 15.72 13.9669 15.8178 13.8913 15.9007C13.6863 16.1277 13.4363 16.3095 13.1572 16.4345C12.8781 16.5595 12.576 16.625 12.2702 16.6268C11.971 16.6352 11.6716 16.6183 11.3752 16.5761C10.8855 16.5086 10.2607 15.9007 10.3452 15.259L10.5478 13.9419C10.6829 13.182 10.818 12.4052 10.9531 11.6453C10.9531 11.5947 10.9699 11.544 10.9699 11.4933C10.9699 11.1725 10.8686 11.0543 10.5478 11.0205C10.4115 11.0062 10.2761 10.9837 10.1425 10.953C10.0766 10.9353 10.0187 10.8954 9.97874 10.84C9.93876 10.7846 9.91907 10.7172 9.923 10.649C9.93989 10.5139 10.0243 10.4295 10.1932 10.3957C10.2881 10.3812 10.3842 10.3755 10.4802 10.3788H12.8105C13.0976 10.3788 13.2665 10.5139 13.2665 10.801C13.2634 11.0397 13.2351 11.2774 13.182 11.5102C13.0301 12.3883 12.8612 13.2495 12.7092 14.1276C12.6586 14.4147 12.591 14.7018 12.5572 14.9888C12.5432 15.1304 12.5547 15.2734 12.591 15.411C12.6068 15.4949 12.654 15.5696 12.7231 15.6199C12.7922 15.6701 12.8778 15.692 12.9625 15.6812C13.1205 15.6597 13.2743 15.6141 13.4184 15.5461C13.5275 15.4831 13.6403 15.4267 13.7562 15.3772C13.9588 15.3097 14.1108 15.4279 14.0601 15.6136Z" fill={color} />
  </svg>
);

export default InfoIcon;
