/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const UnlockedIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.087 1C9.91925 1 8.17391 2.78413 8.17391 5V5.66667H2.25849C1.56458 5.66667 1 6.26467 1 7V13.6667C1 14.402 1.56458 15 2.25849 15H9.52412C10.218 15 10.7826 14.402 10.7826 13.6667V7C10.7826 6.26467 10.2187 5.66667 9.52539 5.66667H9.47826V5C9.47826 3.48254 10.6025 2.33333 12.087 2.33333C13.5714 2.33333 14.6957 3.48254 14.6957 5V6.33333H16V5C16 2.78413 14.2547 1 12.087 1ZM5.8964 9C6.61379 9 7.20075 9.6 7.20075 10.3333C7.20075 11.0667 6.61379 11.6667 5.8964 11.6667C5.17901 11.6667 4.59205 11.0667 4.59205 10.3333C4.59205 9.6 5.17901 9 5.8964 9Z"
      fill={color}
    />
  </svg>
);

export default UnlockedIcon;
