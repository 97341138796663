import { Box } from '@mui/material';
import { ReactElement, FC, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import Content from 'components/_Content';
import Navbar from 'components/_Navbar';
import ActAsCustomerBanner from 'components/ActAsCustomerBanner';
import CompanyNameAsTitle from 'components/CompanyNameAsTitle';
import SidebarSettings from 'layouts/SettingsLayout/SidebarSettings';
import CustomSuspense from 'routes/CustomSuspense';

import useLocalStorage from 'hooks/useLocalStorage';
import { SidebarContext, useSidebar } from 'hooks/useSidebar';

import { LocalStorageKeys } from 'constants/enums';
import { NAVBAR_HEIGHT, TABLET_WIDTH } from 'constants/general';

import { OutletWrapper } from './index.styled';

interface SettingsLayoutProps {
  context: SidebarContext;
  hasTitle?: boolean;
}
const SettingsLayout:FC<SettingsLayoutProps> = ({ hasTitle = false, context }): ReactElement => {
  const sidebarItems = useSidebar(context);
  const items = useMemo(() => sidebarItems.filter(({ isVisible }) => isVisible), [sidebarItems]);
  const [storedViewAs] = useLocalStorage(LocalStorageKeys.viewAs);

  return (
    <>
      <Navbar />
      <Box mt={`${NAVBAR_HEIGHT}px`} minWidth={TABLET_WIDTH}>
        <Content>
          {hasTitle && <CompanyNameAsTitle />}

          <Box display="flex">
            <SidebarSettings sidebarItems={items} hasTitle={hasTitle} />

            <OutletWrapper>
              <CustomSuspense>
                <Outlet />
              </CustomSuspense>
            </OutletWrapper>
          </Box>
        </Content>
        {storedViewAs && (
          <ActAsCustomerBanner />
        )}
      </Box>
    </>
  );
};

export default SettingsLayout;
