import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  boxShadow: 'none',

  '& .MuiAccordionSummary-root': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0, 7),

    '& svg path': {
      fill: theme.palette.common.white,
    },
  },

  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(2),
  },
}));
