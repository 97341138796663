/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const EditIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.0852 6.91386L17.0853 10.914L8.39922 19.6018L4.83245 19.9955C4.72068 20.0078 4.60756 19.9949 4.50146 19.9576C4.39537 19.9204 4.29901 19.8597 4.21952 19.7802C4.14003 19.7006 4.07944 19.6042 4.04223 19.4981C4.00503 19.392 3.99217 19.2789 4.00459 19.1671L4.40161 15.5976L13.0852 6.91403V6.91386ZM19.5602 6.31817L17.6823 4.43978C17.543 4.30036 17.3776 4.18976 17.1955 4.1143C17.0135 4.03884 16.8183 4 16.6212 4C16.4241 4 16.229 4.03884 16.0469 4.1143C15.8648 4.18976 15.6994 4.30036 15.5601 4.43978L13.7934 6.2065L17.7935 10.2066L19.5602 8.43991C19.6996 8.3006 19.8102 8.13518 19.8857 7.95311C19.9612 7.77104 20 7.57588 20 7.37879C20 7.1817 19.9612 6.98654 19.8857 6.80447C19.8102 6.6224 19.6996 6.45698 19.5602 6.31767V6.31817Z" fill={color} />
  </svg>
);

export default EditIcon;
