import type { Metric } from 'web-vitals/src/types/base';

const reportWebVitals = (onPerfEntry?: (metrics: Metric) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({
      onCLS, onINP, onFCP, onLCP, onTTFB,
    }) => {
      onCLS(onPerfEntry);
      onINP(onPerfEntry);
      onFCP(onPerfEntry);
      onLCP(onPerfEntry);
      onTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
