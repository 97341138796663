import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { Root } from 'components/ActAsCustomerBanner/index.styled';
import CustomButton from 'components/CustomButton';

import { useActAs } from 'hooks/useActAs';
import useLocalStorage from 'hooks/useLocalStorage';

import LogoutIcon from 'assets/icons/LogoutIcon';
import { LocalStorageKeys } from 'constants/enums';
import { formatUserName } from 'utils/formatters';

const ActAsCustomerBanner = () => {
  const intl = useIntl();
  const [storedViewAs] = useLocalStorage(LocalStorageKeys.viewAs);
  const { logoutCustomerView, isSubmitting } = useActAs();

  const existUser = () => {
    logoutCustomerView();
  };

  return (
    <Root>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1">
          {intl.formatMessage({ id: 'label.loggedInAs' }, { name: formatUserName(storedViewAs) })}
        </Typography>
        <CustomButton
          color="inherit"
          variant="outlined"
          label={intl.formatMessage({ id: 'label.existActAsUser' })}
          icon={<LogoutIcon size={20} />}
          onClick={existUser}
          isLoading={isSubmitting}
          id="button-exitActAsUser"
        />
      </Box>
    </Root>
  );
};

export default ActAsCustomerBanner;
