import { Box, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

// import VolumeChart from 'modules/insights-advanced-search/components/VolumeChart';

// import RoundedArrowDownIcon from 'assets/icons/RoundedArrowDownIcon';
// import RoundedArrowUpIcon from 'assets/icons/RoundedArrowUpIcon';

interface TotalVolumeProps {
  currentVolume: number;
  // variableVolume: string;
  // percentage: number;
  // data: { x: number; y: number }[];
  isCredits?: boolean;
  isLoading?: boolean;
  // isPositive?: boolean;
}

const TotalVolume: FC<TotalVolumeProps> = ({
  currentVolume,
  // variableVolume,
  // percentage,
  // data,
  isCredits = false,
  isLoading = false,
  // isPositive = false,
}) => {
  const intl = useIntl();
  // const IconComponent = isPositive ? RoundedArrowUpIcon : RoundedArrowDownIcon;

  return (
    <Box display="flex" justifyContent="space-between" px={6} py={3.5}>
      <div>
        <Typography variant="body2" color="general.darkGrey1" mb={5}>
          {intl.formatMessage({ id: isCredits ? 'label.totalCreditsVolume' : 'label.totalDebitsVolume' })}
        </Typography>
        {isLoading
          ? <Skeleton animation="wave" variant="rectangular" height={36} width={65} />
          : (
            <Typography variant="h1" fontSize="36px">
              {currentVolume}
            </Typography>
          )}
      </div>
      {/* <Box display="flex" flexDirection="column" alignItems="flex-end"> */}
      {/*  <IconComponent /> */}
      {/*  <VolumeChart */}
      {/*    data={data} */}
      {/*    percentage={percentage} */}
      {/*    balance={variableVolume} */}
      {/*    isLoading={isLoading} */}
      {/*    isPositive={isPositive} */}
      {/*  /> */}
      {/* </Box> */}
    </Box>
  );
};

export default TotalVolume;
