import { styled } from '@mui/material/styles';

export const BankImg = styled('img')(() => ({
  height: '28px',
  width: '28px',
  borderRadius: '2px',
}));

export const InstitutionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
