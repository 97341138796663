/* eslint max-len: 0 */
const DashboardIcon = ({ color = 'currentColor', size = 24 }: any) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.89362 3H4.31361C3.59162 3 3.00769 3.6493 3.00769 4.44718V8.16884C3.00769 8.9675 3.59162 9.61679 4.31361 9.61679H9.89362C10.6119 9.61679 11.1995 8.9675 11.1995 8.16961V4.44718C11.1958 3.6493 10.6119 3 9.89362 3Z" fill={color} />
    <path d="M9.89347 10.1717H4.31346C3.92555 10.2105 3.57149 10.3741 3.32731 10.6272C3.08314 10.8802 2.96833 11.2027 3.00754 11.5252V19.6464C2.96833 19.969 3.08314 20.2914 3.32731 20.5445C3.57149 20.7976 3.92555 20.9611 4.31346 21H9.89347C10.2814 20.9611 10.6354 20.7976 10.8796 20.5445C11.1238 20.2914 11.2386 19.969 11.1994 19.6464V11.5252C11.2386 11.2027 11.1238 10.8802 10.8796 10.6272C10.6354 10.3741 10.2814 10.2105 9.89347 10.1717Z" fill={color} />
    <path d="M19.6933 14.3833H14.117C13.3997 14.3833 12.8158 15.0326 12.8158 15.8304V19.5521C12.8158 20.35 13.3997 20.9993 14.117 20.9993H19.6933C20.4107 20.9993 20.9946 20.35 20.9946 19.5521V15.8289C20.9993 15.0326 20.4153 14.3833 19.6933 14.3833Z" fill={color} />
    <path d="M19.6933 3H14.117C13.7366 3.03366 13.3872 3.18972 13.1439 3.43468C12.9006 3.67964 12.7828 3.99397 12.8157 4.3102V12.1699C12.7828 12.4861 12.9006 12.8004 13.1439 13.0454C13.3872 13.2903 13.7366 13.4464 14.117 13.4801H19.6933C20.0737 13.4464 20.423 13.2903 20.6663 13.0454C20.9096 12.8004 21.0274 12.4861 20.9945 12.1699V4.3102C21.0274 3.99397 20.9096 3.67964 20.6663 3.43468C20.423 3.18972 20.0737 3.03366 19.6933 3V3Z" fill={color} />
  </svg>
);

export default DashboardIcon;
