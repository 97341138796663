import {
  Box, ListItemIcon, ListItemText, MenuItem, Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ConfirmationModal from 'components/ConfirmationModal';
import AddContactDialog from 'containers/AddContactDialog';

import { useModal } from 'hooks/useModal';
import { usePermissions } from 'hooks/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { deleteContact, updateContact } from 'services/ContactService';
import { fetchContacts } from 'store/contacts/contactsSlice';
import { searchSelector, sortOptionsSelector } from 'store/contacts/selectors';

import DeleteIcon from 'assets/icons/DeleteIcon';
import EditIcon from 'assets/icons/EditIcon';
import MoreIcon from 'assets/icons/MoreIcon';
import { ConfirmationTypes } from 'constants/enums';
import { DEFAULT_PAGINATION_SIZE } from 'constants/general';
import { RolePermissions } from 'constants/permissionEnums';
import { Contact } from 'models/contact.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { ContactFormValues } from 'utils/validation/contactFormSchema';

import { IconButton, Menu } from './index.styled';

interface ContactActionMenuProps {
  contact: Contact;
  isDisabled?: boolean;
}

const ContactActionMenu: FC<ContactActionMenuProps> = ({ contact, isDisabled = false }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useModal();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useModal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const sortOptions = useAppSelector(sortOptionsSelector);
  const search = useSelector(searchSelector);
  const { isAllowed } = usePermissions();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenRemoveContact = () => {
    handleMenuClose();
    onOpen();
  };

  const handleOpenEdit = () => {
    handleMenuClose();
    onEditOpen();
  };

  const handleSaveContact = async (values: ContactFormValues) => {
    try {
      await updateContact(values, contact?.id);
      await getContacts();
      onEditClose();
      toast.success(intl.formatMessage({ id: 'success.updateContactSuccessfully' }));
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const handleRemoveMember = async () => {
    try {
      setIsLoading(true);
      await deleteContact(contact?.id);
      await getContacts();
      toast.success(intl.formatMessage({ id: 'success.deleteContactSuccessfully' }));
    } catch (error: any) {
      handleApiErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getContacts = async () => {
    const payload = {
      page: 0,
      size: DEFAULT_PAGINATION_SIZE,
      ...(search ? { search, page: 0 } : {}),
      sort: `${sortOptions.name},${sortOptions.type}`,
      sortOptions,
    };

    await dispatch(fetchContacts(payload));
  };

  return (
    <>
      <IconButton onClick={handleClick} aria-label="Member menu" disabled={isDisabled} id="iconButton-memberMenu">
        <Box display="flex" alignItems="center">
          <MoreIcon />
        </Box>
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isAllowed({ permission: RolePermissions.CreateContact }) && (
          <MenuItem
            onClick={handleOpenEdit}
            id="menuItem-edit-contact"
            disabled={isLoading}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>
              {intl.formatMessage({ id: 'button.editContact' })}
            </ListItemText>
          </MenuItem>
        )}
        {isAllowed({ permission: RolePermissions.DeleteContact }) && (
          <MenuItem
            onClick={handleOpenRemoveContact}
            id="menuItem-delete-contact"
            className="red-menu-item"
            disabled={isLoading}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>
              {intl.formatMessage({ id: 'button.deleteContact' })}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>

      {isOpen && (
        <ConfirmationModal
          id="modal-contact-action"
          type={ConfirmationTypes.Error}
          title={intl.formatMessage({ id: 'modalTitle.deleteContact' })}
          confirmLabel={intl.formatMessage({ id: 'button.delete' })}
          onCancel={onClose}
          onSubmit={handleRemoveMember}
          isOpen={isOpen}
          disabled={isLoading}
        >
          <Typography margin="auto" textAlign="center" color="textSecondary" maxWidth="320px">
            {intl.formatMessage({ id: 'modalContent.deleteContact' })}
          </Typography>
        </ConfirmationModal>
      )}

      {isEditOpen && (
        <AddContactDialog
          isOpen={isEditOpen}
          onSubmit={handleSaveContact}
          onClose={onEditClose}
          contact={contact}
        />
      )}
    </>
  );
};

export default ContactActionMenu;
