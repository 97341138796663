const MuiMenu = {
  styleOverrides: {
    paper: ({ theme }: any) => ({
      boxShadow: 'none',
      border: `1px solid ${theme.palette.border.secondary}`,

      '& ul': {
        padding: 0,
      },
    }),
  },
};

const MuiMenuItem = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      '&:hover': {
        backgroundColor: theme.palette.general.lightGrey7,
      },
      padding: theme.spacing(3, 4),
    }),
  },
};

export {
  MuiMenu,
  MuiMenuItem,
};
