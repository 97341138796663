/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SadCloudIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4C9.709 4 7.56081 5.13791 6.25781 7.00391C2.78481 7.13191 0 9.997 0 13.5C0 17.084 2.916 20 6.5 20H18.5C21.532 20 24 17.533 24 14.5C24 11.536 21.6431 9.11191 18.7051 9.00391C17.8391 6.05591 15.149 4 12 4ZM12 6C14.472 6 16.5446 7.77284 16.9316 10.2148L17.082 11.1758L18.1816 11.0312C18.2866 11.0173 18.391 11 18.5 11C20.43 11 22 12.57 22 14.5C22 16.43 20.43 18 18.5 18H6.5C4.019 18 2 15.981 2 13.5C2 11.019 4.019 9 6.5 9L7.35938 9.04688L7.66797 8.51367C8.55997 6.96267 10.22 6 12 6ZM10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11C9 11.2652 9.10536 11.5196 9.29289 11.7071C9.48043 11.8946 9.73478 12 10 12C10.2652 12 10.5196 11.8946 10.7071 11.7071C10.8946 11.5196 11 11.2652 11 11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10ZM14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11C13 11.2652 13.1054 11.5196 13.2929 11.7071C13.4804 11.8946 13.7348 12 14 12C14.2652 12 14.5196 11.8946 14.7071 11.7071C14.8946 11.5196 15 11.2652 15 11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10ZM12 13C10.8333 13 9.92199 13.2958 9.30273 13.6055C8.68348 13.9151 8.29297 14.293 8.29297 14.293L9.70703 15.707C9.70703 15.707 9.81652 15.5849 10.1973 15.3945C10.578 15.2042 11.1667 15 12 15C12.8333 15 13.422 15.2042 13.8027 15.3945C14.1835 15.5849 14.293 15.707 14.293 15.707L15.707 14.293C15.707 14.293 15.3165 13.9151 14.6973 13.6055C14.078 13.2958 13.1667 13 12 13Z" fill={color} />
  </svg>
);

export default SadCloudIcon;
