/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const TransactionApprovalIcon = ({ color = 'currentColor', height = 14, width = 13 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <path
      fill={color}
      fillRule="evenodd"
      d="M10.667.333a.667.667 0 0 1-1.334 0H8a.667.667 0 0 1-1.333 0H5.333A.667.667 0 0 1 4 .333H2.667a.667.667 0 0 1-1.334 0H0v13.334h1.333a.667.667 0 0 1 1.334 0H4a.667.667 0 0 1 1.333 0h1.334c0-.12.031-.233.087-.33a3.981 3.981 0 0 1-.699-3.004H2V9h4.535a4.02 4.02 0 0 1 1.253-1.333H2V6.333h8V7c.729 0 1.412.195 2 .535V.333h-1.333ZM10 5H2V3.667h8V5Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M6.8 11a3.2 3.2 0 1 1 6.4 0 3.2 3.2 0 0 1-6.4 0Zm2.973 1.405 2.016-2.016a.266.266 0 1 0-.377-.378L9.584 11.84l-.732-.732a.266.266 0 1 0-.377.377l.92.92a.266.266 0 0 0 .378 0Z"
      clipRule="evenodd"
    />
  </svg>
);

export default TransactionApprovalIcon;
