/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const MyAccountIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6364 2L2 7.45455V9.27273H19.2727V7.45455L10.6364 2ZM3.81818 10.1818V16.5455H6.54545V10.1818H3.81818ZM9.27273 10.1818V12.9091V14.7273H9.28338C9.34247 13.2427 10.5273 12.0534 12 12.0089V10.1818H9.27273ZM14.7273 10.1818V12H17.4545V10.1818H14.7273ZM12.0835 13.8182C11.5353 13.8182 11.0909 14.2764 11.0909 14.8409V15.6364H22V14.8409C22 14.2764 21.5556 13.8182 21.0075 13.8182H12.0835ZM2 17.4545V20.1818H9.27273V17.4545H2ZM11.0909 17.4545V20.9773C11.0909 21.5418 11.5353 22 12.0835 22H21.0075C21.5556 22 22 21.5418 22 20.9773V17.4545H11.0909Z" fill={color} />
  </svg>
);

export default MyAccountIcon;
