/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SearchIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.8793 19.9917L16.409 15.5493C16.3306 15.4713 16.5177 15.5493 16.409 15.5493H15.5149C16.6418 14.3383 17.2787 12.7562 17.3031 11.1069C17.2848 9.22748 16.5254 7.43018 15.1879 6.10126C13.8505 4.77234 12.0418 4.01787 10.1506 4C8.25953 4.01836 6.45118 4.77318 5.11412 6.10225C3.77705 7.43132 3.01798 9.22858 3 11.1078C3.01799 12.9874 3.77732 14.785 5.11479 16.1141C6.45226 17.4432 8.26109 18.1978 10.1525 18.2157C11.8121 18.1914 13.4041 17.5586 14.6228 16.4387C14.8939 16.7604 15.1931 17.0577 15.5168 17.3272L19.0931 20.8811C19.229 20.959 19.3832 21 19.5401 21C19.697 21 19.8512 20.959 19.9871 20.8811H20.8812C20.9594 20.7457 21.0003 20.5923 21 20.4361C20.9997 20.28 20.958 20.1268 20.8793 19.9917ZM10.1506 16.4387C9.44452 16.4446 8.74434 16.3107 8.09089 16.0449C7.43743 15.7791 6.84378 15.3866 6.34454 14.8904C5.84531 14.3942 5.45049 13.8042 5.1831 13.1547C4.91572 12.5053 4.78113 11.8095 4.78717 11.1078C4.78113 10.4062 4.91572 9.71035 5.1831 9.06093C5.45049 8.4115 5.84531 7.82148 6.34454 7.32528C6.84378 6.82907 7.43743 6.43661 8.09089 6.17078C8.74434 5.90495 9.44452 5.77107 10.1506 5.77696C10.8567 5.77095 11.557 5.90473 12.2106 6.1705C12.8641 6.43628 13.4579 6.82872 13.9572 7.32494C14.4566 7.82116 14.8515 8.41122 15.1189 9.06071C15.3864 9.71019 15.521 10.4061 15.5149 11.1078C15.521 11.8096 15.3864 12.5055 15.1189 13.155C14.8515 13.8045 14.4566 14.3945 13.9572 14.8907C13.4579 15.387 12.8641 15.7794 12.2106 16.0452C11.557 16.3109 10.8567 16.4447 10.1506 16.4387Z" fill={color} />
  </svg>
);

export default SearchIcon;
