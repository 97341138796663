/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const DialogIcon = ({ color = 'currentColor', size = 16 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none">
    <path
      fill={color}
      d="M2.167.5C1.246.5.5 1.246.5 2.167v11.666c0 .911.756 1.667 1.667 1.667h11.666a1.68 1.68 0 0 0 1.667-1.667V2.167C15.5 1.246 14.754.5 13.833.5H2.167Zm0 3.333h11.666v10H2.167v-10Z"
    />
  </svg>
);

export default DialogIcon;
