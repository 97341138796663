import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTransactionApproval } from 'services/TransactionApprovalService';
import { getPaymentReasons } from 'services/TransactionService';
import { resetStore } from 'store/actions';

import {
  ApiStatus,
  SortOrder,
  TransactionApprovalStatus,
} from 'constants/enums';
import { DEFAULT_PAGINATION_SIZE } from 'constants/general';
import { Pagination } from 'models/pagination.interface';
import { PaymentReasonInterface } from 'models/paymentReason.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { TransactionApproval } from 'models/transactionApproval.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { ApprovalTransactionFilterSchema } from 'utils/validation/transactionFormSchema';

import { getActiveFilters } from './utils';

export interface ApprovalTransactionQueryParams {
  page: number;
  sortOptions: SortOptions;
  search: string;
  filters: ApprovalTransactionFilterSchema;
}

export interface SelectedTransactionProps {
  transaction: TransactionApproval;
  status: TransactionApprovalStatus;
}

interface SortOptionsState {
  name: string;
  type: SortOrder;
}

export interface TransactionSection {
  data: TransactionApproval[];
  status: ApiStatus;
  pagination: Pagination;
  search: string;
  sortOptions: SortOptionsState;
  filters: ApprovalTransactionFilterSchema;
}

export interface TransactionSearchState {
  transactionsPending: TransactionSection;
  transactionsApproved: TransactionSection;
  transactionsRejected: TransactionSection;
  transactionsFailed: TransactionSection;
  reasons: PaymentReasonInterface[];
  selectedTransactions?: Record<string, SelectedTransactionProps>;
}

const defaultTransactionState = {
  data: [],
  status: ApiStatus.idle,
  pagination: {
    totalPages: 0,
    totalElements: 0,
    page: 1,
  },
  search: '',
  sortOptions: {
    name: 'createdAt',
    type: SortOrder.desc,
  },
  filters: {
    dateRange: {
      from: null,
      to: null,
    },
    amountRange: {
      min: null,
      max: null,
    },
  },
};

const initialState: TransactionSearchState = {
  transactionsPending: defaultTransactionState,
  transactionsApproved: defaultTransactionState,
  transactionsRejected: defaultTransactionState,
  transactionsFailed: defaultTransactionState,
  reasons: [],
  selectedTransactions: undefined,
};

export const fetchPendingTransactions = createAsyncThunk(
  'transactionApproval/fetchPendingTransactions',
  async (payload: ApprovalTransactionQueryParams, thunkAPI) => {
    const {
      page, sortOptions, search, filters,
    } = payload;

    try {
      const queryParams: Record<string, string|number> = {
        status: TransactionApprovalStatus.pending,
        page,
        sort: `${sortOptions.name},${sortOptions.type}`,
        size: DEFAULT_PAGINATION_SIZE,
        ...getActiveFilters(filters),
      };

      if (search) {
        queryParams.search = search;
      }

      const response = await getTransactionApproval(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchApprovedTransactions = createAsyncThunk(
  'transactionApproval/fetchApprovedTransactions',
  async (payload: ApprovalTransactionQueryParams, thunkAPI) => {
    const {
      page, sortOptions, search, filters,
    } = payload;

    try {
      const queryParams: Record<string, string|number> = {
        status: TransactionApprovalStatus.approved,
        page,
        sort: `${sortOptions.name},${sortOptions.type}`,
        size: DEFAULT_PAGINATION_SIZE,
        ...getActiveFilters(filters),
      };

      if (search) {
        queryParams.search = search;
      }

      const response = await getTransactionApproval(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchRejectedTransactions = createAsyncThunk(
  'transactionApproval/fetchRejectedTransactions',
  async (payload: ApprovalTransactionQueryParams, thunkAPI) => {
    const {
      page, sortOptions, search, filters,
    } = payload;

    try {
      const queryParams: Record<string, string|number> = {
        status: TransactionApprovalStatus.rejected,
        page,
        sort: `${sortOptions.name},${sortOptions.type}`,
        size: DEFAULT_PAGINATION_SIZE,
        ...getActiveFilters(filters),
      };

      if (search) {
        queryParams.search = search;
      }

      const response = await getTransactionApproval(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchFailedTransactions = createAsyncThunk(
  'transactionApproval/fetchFailedTransactions',
  async (payload: ApprovalTransactionQueryParams, thunkAPI) => {
    const {
      page, sortOptions, search, filters,
    } = payload;

    try {
      const queryParams: Record<string, string|number> = {
        status: TransactionApprovalStatus.failed,
        page,
        sort: `${sortOptions.name},${sortOptions.type}`,
        size: DEFAULT_PAGINATION_SIZE,
        ...getActiveFilters(filters),
      };

      if (search) {
        queryParams.search = search;
      }

      const response = await getTransactionApproval(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchPaymentReasons = createAsyncThunk(
  'transactionApproval/paymentReasons',
  async (_, thunkAPI) => {
    try {
      const { data } = await getPaymentReasons();
      return data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const transactionApprovalSlice = createSlice({
  name: 'transactionApproval',
  initialState,
  reducers: {
    setSelectedTransaction: (state, action: PayloadAction<SelectedTransactionProps>) => {
      state.selectedTransactions = {
        ...state.selectedTransactions,
        [action.payload.transaction.id]: action.payload,
      };
      return state;
    },
    removeSelectedTransaction: (state, action: PayloadAction<string>) => {
      const newState = JSON.parse(JSON.stringify(state));
      if (newState?.selectedTransactions?.[action.payload]) {
        delete newState.selectedTransactions[action.payload];
      }
      return newState;
    },
    resetSelectedTransaction: (state) => {
      state.selectedTransactions = undefined;
      return state;
    },
    setPendingPagination: (state, action: PayloadAction<number>) => {
      state.transactionsPending.pagination.page = action.payload;
      return state;
    },
    updatePendingSearch: (state, action: PayloadAction<string>) => {
      state.transactionsPending.search = action.payload;
      state.transactionsPending.pagination.page = 0;
      return state;
    },
    updatePendingSortOptions: (state, action: PayloadAction<SortOptionsState>) => {
      state.transactionsPending.sortOptions = action.payload;
      return state;
    },
    setPendingFilters: (state, action: PayloadAction<ApprovalTransactionFilterSchema>) => {
      state.transactionsPending.filters = action.payload;
      state.transactionsPending.pagination.page = 0;
      return state;
    },
    setApprovedPagination: (state, action: PayloadAction<number>) => {
      state.transactionsApproved.pagination.page = action.payload;
      return state;
    },
    updateApprovedSearch: (state, action: PayloadAction<string>) => {
      state.transactionsApproved.search = action.payload;
      state.transactionsApproved.pagination.page = 0;
      return state;
    },
    updateApprovedSortOptions: (state, action: PayloadAction<SortOptionsState>) => {
      state.transactionsApproved.sortOptions = action.payload;
      return state;
    },
    setApprovedFilters: (state, action: PayloadAction<ApprovalTransactionFilterSchema>) => {
      state.transactionsApproved.filters = action.payload;
      state.transactionsApproved.pagination.page = 0;
      return state;
    },
    setRejectedPagination: (state, action: PayloadAction<number>) => {
      state.transactionsRejected.pagination.page = action.payload;
      return state;
    },
    setFailedPagination: (state, action: PayloadAction<number>) => {
      state.transactionsFailed.pagination.page = action.payload;
      return state;
    },
    updateRejectedSearch: (state, action: PayloadAction<string>) => {
      state.transactionsRejected.search = action.payload;
      state.transactionsRejected.pagination.page = 0;
      return state;
    },
    updateRejectedSortOptions: (state, action: PayloadAction<SortOptionsState>) => {
      state.transactionsRejected.sortOptions = action.payload;
      return state;
    },
    setRejectedFilters: (state, action: PayloadAction<ApprovalTransactionFilterSchema>) => {
      state.transactionsRejected.filters = action.payload;
      state.transactionsRejected.pagination.page = 0;
      return state;
    },
    updateFailedSearch: (state, action: PayloadAction<string>) => {
      state.transactionsFailed.search = action.payload;
      state.transactionsFailed.pagination.page = 0;
      return state;
    },
    updateFailedSortOptions: (state, action: PayloadAction<SortOptionsState>) => {
      state.transactionsFailed.sortOptions = action.payload;
      return state;
    },
    setFailedFilters: (state, action: PayloadAction<ApprovalTransactionFilterSchema>) => {
      state.transactionsFailed.filters = action.payload;
      state.transactionsFailed.pagination.page = 0;
      return state;
    },
    resetTransactionApprovalStore: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchPendingTransactions.pending, (state) => {
        state.transactionsPending = {
          ...state.transactionsPending,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchPendingTransactions.fulfilled, (state, action) => {
        const {
          totalElements, totalPages, content,
        } = action.payload;

        state.transactionsPending = {
          ...state.transactionsPending,
          data: content,
          status: ApiStatus.idle,
          pagination: {
            ...state.transactionsPending.pagination,
            totalPages,
            totalElements,
          },
        };
      })
      .addCase(fetchPendingTransactions.rejected, (state) => {
        state.transactionsPending = {
          ...state.transactionsPending,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchApprovedTransactions.pending, (state) => {
        state.transactionsApproved = {
          ...state.transactionsApproved,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchApprovedTransactions.fulfilled, (state, action) => {
        const {
          totalElements, totalPages, content,
        } = action.payload;

        state.transactionsApproved = {
          ...state.transactionsApproved,
          data: content,
          status: ApiStatus.idle,
          pagination: {
            ...state.transactionsApproved.pagination,
            totalPages,
            totalElements,
          },
        };
      })
      .addCase(fetchApprovedTransactions.rejected, (state) => {
        state.transactionsApproved = {
          ...state.transactionsApproved,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchRejectedTransactions.pending, (state) => {
        state.transactionsRejected = {
          ...state.transactionsRejected,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchRejectedTransactions.fulfilled, (state, action) => {
        const {
          totalElements, totalPages, content,
        } = action.payload;

        state.transactionsRejected = {
          ...state.transactionsRejected,
          data: content,
          status: ApiStatus.idle,
          pagination: {
            ...state.transactionsRejected.pagination,
            totalPages,
            totalElements,
          },
        };
      })
      .addCase(fetchRejectedTransactions.rejected, (state) => {
        state.transactionsRejected = {
          ...state.transactionsRejected,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchFailedTransactions.pending, (state) => {
        state.transactionsFailed = {
          ...state.transactionsFailed,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchFailedTransactions.fulfilled, (state, action) => {
        const {
          totalElements, totalPages, content,
        } = action.payload;

        state.transactionsFailed = {
          ...state.transactionsFailed,
          data: content,
          status: ApiStatus.idle,
          pagination: {
            ...state.transactionsFailed.pagination,
            totalPages,
            totalElements,
          },
        };
      })
      .addCase(fetchFailedTransactions.rejected, (state) => {
        state.transactionsFailed = {
          ...state.transactionsFailed,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchPaymentReasons.fulfilled, (state, action) => {
        state.reasons = action.payload;
      });
  },
});

export const {
  setPendingPagination,
  setApprovedPagination,
  setRejectedPagination,
  setFailedPagination,
  resetTransactionApprovalStore,
  setSelectedTransaction,
  removeSelectedTransaction,
  resetSelectedTransaction,
  updatePendingSearch,
  updatePendingSortOptions,
  setPendingFilters,
  updateApprovedSearch,
  updateApprovedSortOptions,
  setApprovedFilters,
  updateRejectedSearch,
  updateRejectedSortOptions,
  setRejectedFilters,
  updateFailedSearch,
  updateFailedSortOptions,
  setFailedFilters,
} = transactionApprovalSlice.actions;

export default transactionApprovalSlice.reducer;
