import { Box } from '@mui/material';
import { ReactElement, FC } from 'react';

import WorkspaceSelector from 'components/_Navbar/CompanySelector';
import {
  TabsWrapper,
  NavbarWrapper,
  LogoWrapper,
  NavbarSpacing,
} from 'components/_Navbar/index.styled';
import NavigationTabs from 'components/_Navbar/NavigationTabs';
import Settings from 'components/_Navbar/Settings';
import User from 'components/_Navbar/User';
import Logo from 'components/Logo';

import { useAppSelector } from 'hooks/useRedux';
import { currentWorkspaceSelector } from 'store/user/selectors';

import { isRouteEnabled } from 'utils/featureFlag';

const Navbar: FC = (): ReactElement => {
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const isMultipleCompaniesEnabled = isRouteEnabled('user-companies');
  return (
    <NavbarWrapper>
      <NavbarSpacing>
        <LogoWrapper>
          <Logo />
          {isMultipleCompaniesEnabled && currentWorkspace?.displayName && <WorkspaceSelector />}
        </LogoWrapper>

        <Box display="flex" alignItems="center">
          <Settings />
          <Box ml={3}>
            <User />
          </Box>
        </Box>
      </NavbarSpacing>

      <TabsWrapper>
        <NavigationTabs />
      </TabsWrapper>
    </NavbarWrapper>
  );
};

export default Navbar;
