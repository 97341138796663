/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SameDayIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4C9.5907 4 7.44298 5.08453 5.97656 6.77656L4 4.8V9.6H8.8L7.10156 7.90156C8.27604 6.49964 10.0317 5.6 12 5.6C15.5288 5.6 18.4 8.4712 18.4 12H20C20 7.5888 16.4112 4 12 4ZM4 12C4 15.2728 5.9792 18.0858 8.8 19.3234V17.5281C6.8904 16.4209 5.6 14.3616 5.6 12H4ZM12.4 13.6C11.3047 13.6 10.4 14.5047 10.4 15.6V16H12V15.6C12 15.3705 12.1705 15.2 12.4 15.2C12.6295 15.2 12.8 15.3705 12.8 15.6C12.8 15.8063 12.5221 16.4458 12.0656 17.0391C11.6092 17.6324 11.0331 18.2101 10.6516 18.5688L10.4 18.8063V20H14.4V18.4H12.9781C13.105 18.2516 13.2124 18.1727 13.3344 18.0141C13.8779 17.3076 14.4 16.5465 14.4 15.6C14.4 14.5047 13.4953 13.6 12.4 13.6ZM16 13.6V18.4H18.4V20H20V13.6H18.4V16.8H17.6V13.6H16Z" fill={color} />
  </svg>
);

export default SameDayIcon;
