import { Box, Collapse, Typography } from '@mui/material';
import {
  FC, ReactElement, useEffect, useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation, matchRoutes } from 'react-router-dom';

import {
  ChildTypography,
  CollapsableButton,
  CollapsableContent,
} from 'layouts/SettingsLayout/SidebarSettings/CollapsableSection/index.styled';
import {
  LinkContent,
  LinkWrapper,
  NavbarLink,
} from 'layouts/SettingsLayout/SidebarSettings/index.styled';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import ChevronUpIcon from 'assets/icons/ChevronUpIcon';
import { SidebarInterface } from 'models/sidebar.interface';

interface CollapsableSectionProps {
  item: SidebarInterface;
}

const CollapsableSection: FC<CollapsableSectionProps> = ({ item }): ReactElement => {
  const intl = useIntl();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    const isActive = isSectionActive();

    if (isActive !== open) {
      setOpen(isActive);
    }

    if (isActive !== active) {
      setActive(isActive);
    }
  }, [location]);

  const isSectionActive = () => {
    const routes = item.children?.map(({ route }) => ({ path: route }));
    const matches = routes ? matchRoutes(routes, location) : null;

    return matches ? Boolean(matches.length) : false;
  };

  const { Icon, label, children } = item;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <CollapsableButton onClick={handleClick}>
        <LinkWrapper isActive={active} collapsable>
          <LinkContent isActive={active} collapsable>
            <Box display="flex" alignItems="center">
              {Icon && <Icon size={24} />}

              <Typography variant="body2" fontWeight={700}>
                {intl.formatMessage({ id: label })}
              </Typography>
            </Box>
            {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </LinkContent>
        </LinkWrapper>
      </CollapsableButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CollapsableContent>
          {children?.map(({ label: childLabel, key, route }) => (
            <NavbarLink to={route} key={key}>
              {({ isActive }): ReactElement => (
                <ChildTypography variant="body2" fontWeight={600} isActive={isActive}>
                  {intl.formatMessage({ id: childLabel })}
                </ChildTypography>
              )}
            </NavbarLink>
          ))}
        </CollapsableContent>
      </Collapse>
    </>
  );
};

export default CollapsableSection;
