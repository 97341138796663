import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const EmailActions = lazy(
  () => CustomLazyLoader(
    () => import(/* webpackChunkName: 'EmailActions' */ 'modules/firebase-actions/containers/EmailActions'),
    'EmailActions',
  ),
);

export default EmailActions;
