import { CustomChipVariants } from 'assets/themes/theme-declarations';
import {
  InvitationStatus,
  TransactionStatusType,
  CustomerStatus,
  RuleHistoryStatus,
  MappedKybStatus,
} from 'constants/enums';

export const classNameByTransactionStatus: Record<TransactionStatusType, CustomChipVariants> = {
  [TransactionStatusType.approved]: 'active',
  [TransactionStatusType.active]: 'active',
  [TransactionStatusType.finished]: 'active',
  [TransactionStatusType.recurrence]: 'recurrence',
  [TransactionStatusType.pending]: 'pending',
  [TransactionStatusType.declined]: 'error',
  [TransactionStatusType.error]: 'error',
  [TransactionStatusType.cancelled]: 'error',
  [TransactionStatusType.rejected]: 'error',
  [TransactionStatusType.depositWithheld]: 'lightPending',
  [TransactionStatusType.settled]: 'active',
  [TransactionStatusType.new]: 'lightPending',
  [TransactionStatusType.cleared]: 'active',
  [TransactionStatusType.reversed]: 'lightPending',
  [TransactionStatusType.processing]: 'active',
  [TransactionStatusType.scheduled]: 'pending',
  [TransactionStatusType.deleted]: 'error',
  [TransactionStatusType.suspended]: 'error',
  [TransactionStatusType.failed]: 'error',
};

export const classNameByInvitationStatus: Record<InvitationStatus, CustomChipVariants> = {
  [InvitationStatus.new]: 'new',
  [InvitationStatus.accepted]: 'active',
  [InvitationStatus.visited]: 'pending',
};

export const classNameByCustomerStatus: Record<CustomerStatus, CustomChipVariants> = {
  [CustomerStatus.active]: 'active',
  [CustomerStatus.inactive]: 'inactive',
  [CustomerStatus.suspended]: 'error',
  [CustomerStatus.closed]: 'disabled',
};

export const classNameByRuleHistoryStatus: Record<RuleHistoryStatus, CustomChipVariants> = {
  [RuleHistoryStatus.pending]: 'pending',
  [RuleHistoryStatus.sent]: 'active',
  [RuleHistoryStatus.delivered]: 'active',
  [RuleHistoryStatus.failed]: 'error',
  [RuleHistoryStatus.submitted]: 'pending',
};

export const classNameByMappedKybStatus: Record<MappedKybStatus, CustomChipVariants> = {
  [MappedKybStatus.draft]: 'inactive',
  [MappedKybStatus.inProgress]: 'new',
  [MappedKybStatus.inReview]: 'pending',
  [MappedKybStatus.accepted]: 'active',
  [MappedKybStatus.rejected]: 'error',
};
