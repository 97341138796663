import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import AuthLayout from 'layouts/AuthLayout';

import { useAppSelector } from 'hooks/useRedux';
import { jwtTokenSelector } from 'store/user/selectors';

import { DASHBOARD_ROUTE } from 'constants/clientRoutes';

const UnauthenticatedRoute = (): ReactElement => {
  const jwtToken = useAppSelector(jwtTokenSelector);

  if (jwtToken?.idToken) {
    return <Navigate to={DASHBOARD_ROUTE} replace />;
  }

  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
};

export default UnauthenticatedRoute;
