import { FormattedMessage } from 'react-intl';
import timezones from 'timezones-list';

import {
  CriteriaAccount,
  CriteriaCondition,
  DefaultType,
  RuleMatch, RuleStatus,
  RuleTimeframeType,
  TransactionType,
} from 'constants/enums';
import { BankAccount } from 'models/bankAccount.interface';
import { Contact, RuleContact } from 'models/contact.interface';
import {
  Criteria, CreateRule, RuleIdentifier, RuleDetails,
} from 'models/rule.interface';
// import { getInstitutionsAsOptions } from 'utils/bank';
import { CreateRuleTransaction } from 'models/transaction.interface';
import { User } from 'models/user.interface';
import {
  formatAcctDisplayedName,
  formatAcctNumberNameAndType, formatFullName,
  formatTablePrice,
  formatTimezone,
} from 'utils/formatters';
import { RuleFormValues } from 'utils/validation/ruleFormSchema';

import { defaultRule } from './constants';

interface GetInitialRule {
  transaction?: CreateRuleTransaction | null;
  user?: User | null;
  bankAccount?: BankAccount | null;
  rule?: RuleDetails | null;
  bankAccounts?: BankAccount[] | null;
  contacts?: Contact[] | null;
}

export const getAccountsAsOptions = (bankAccounts: BankAccount[], identifiers: RuleIdentifier[]) => {
  // const institutions = getInstitutionsAsOptions(bankAccounts);
  const {
    hasAllBankAccounts,
    // hasAllBanks
  } = hasAllOptionsChecked(identifiers);

  return [
    // {
    //   label: <FormattedMessage id="label.bank" />,
    //   value: CriteriaAccount.bank,
    //   sublist: {
    //     options: [
    //       {
    //         label: 'label.allBanks',
    //         formattedLabel: <FormattedMessage id="label.allbanks" />,
    //         value: {
    //           bankCode: DefaultType.all,
    //         },
    //       },
    //       ...institutions.map((institution) => {
    //         const isDisabled = identifiers.some((identifier) => identifier.bankCode === institution.code);
    //
    //         return {
    //           label: institution.name,
    //           value: {
    //             bankCode: institution.code,
    //           },
    //           disabled: isDisabled,
    //         };
    //       }),
    //     ],
    //     placeholder: 'placeholder.searchByBankName',
    //   },
    //   disabled: hasAllBanks,
    // },
    {
      label: <FormattedMessage id="label.bankAccount" />,
      value: CriteriaAccount.bankAccount,
      sublist: {
        options: [
          {
            label: 'label.allaccounts',
            formattedLabel: <FormattedMessage id="label.allaccounts" />,
            value: {
              bankAccountId: DefaultType.all,
            },
          },
          ...bankAccounts.map((acc) => {
            const isDisabled = identifiers.some((identifier) => identifier.bankAccountId === acc.id);

            return {
              label: formatAcctDisplayedName(acc),
              formattedLabel: formatAcctNumberNameAndType(acc),
              value: {
                bankAccountId: acc.id,
              },
              disabled: isDisabled,
            };
          }),
        ],
        placeholder: 'placeholder.searchAccountByName',
      },
      disabled: hasAllBankAccounts,
    },
  ];
};

export const hasAllOptionsChecked = (identifiers: RuleIdentifier[]) => {
  let hasAllBankAccounts = false;

  identifiers.forEach((identifier) => {
    if (identifier.bankAccountId === DefaultType.all) {
      hasAllBankAccounts = true;
    }
  });

  return { hasAllBankAccounts };
};

export const hasIdentifierDisabled = (
  identifiers: RuleIdentifier,
  hasAllBankAccounts: boolean,
) => {
  const { bankAccountId } = identifiers;

  if (bankAccountId && bankAccountId !== DefaultType.all && hasAllBankAccounts) {
    return true;
  }

  return false;
};

export const formatRuleHour = (hour: string) => (+hour < 10 ? `0${hour}:00` : `${hour}:00`);

export const formatRuleForCreation = (
  ruleForm: RuleFormValues,
  ownerId: string,
  bankAccounts: BankAccount[] | null,
): CreateRule => {
  const ruleCriteria = ruleForm.criteria as Criteria[];
  let formattedRule: Partial<CreateRule> = {
    name: ruleForm.name as string,
    message: ruleForm.message as string,
    type: ruleForm.type as RuleMatch,
    ...(ruleForm.status ? { status: ruleForm.status as RuleStatus } : {}),
    ownerId,
    criteria: [ruleCriteria[2]],
  };

  if (ruleForm.timeframe === RuleTimeframeType.customTime || ruleForm.type === RuleMatch.noMatch) {
    formattedRule = {
      ...formattedRule,
      timeframeEnd: ruleForm.timeframeEnd ? formatRuleHour(ruleForm.timeframeEnd as string) : '',
      timeframeStart: ruleForm.timeframeStart ? formatRuleHour(ruleForm.timeframeStart as string) : '',
      timezone: ruleForm?.timezone as string,
    };
  }

  if (ruleForm.recipients) {
    const formRecipients = ruleForm.recipients as User[];
    formattedRule.recipients = formRecipients.map(({ id, phoneNumber }) => id || phoneNumber || '');
  }

  if (ruleForm.identifiers) {
    let identifiers = {};
    const ruleIdentifiers = ruleForm.identifiers as RuleIdentifier[];

    const hasAllBankAccounts = ruleIdentifiers.find((identifier) => {
      if (identifier.bankAccountId === DefaultType.all && bankAccounts) {
        bankAccounts.forEach((bankAccount) => {
          identifiers = {
            ...identifiers,
            [bankAccount.id]: bankAccount.name,
          };
        });

        return true;
      }

      return false;
    });

    ruleIdentifiers.forEach((identifier) => {
      const { bankCode, bankAccountId, label } = identifier;
      const key = identifier?.bankCode ? 'bankCode' : 'bankAccountId';

      if (key === 'bankAccountId' && hasAllBankAccounts) {
        return;
      }

      identifiers = {
        ...identifiers,
        [bankCode || bankAccountId]: label,
      };
    });

    formattedRule.identifiers = identifiers;
  }

  if (ruleForm.hasDescription) {
    formattedRule.criteria?.push(ruleCriteria[0]);
  }

  if (ruleForm.hasAmount) {
    const criteria = {
      ...ruleCriteria[1],
      values: ruleCriteria[1]?.condition === CriteriaCondition.between
        ? [`${ruleCriteria[1]?.values?.[0]}:${ruleCriteria[1]?.values?.[1]}`]
        : [ruleCriteria[1]?.values?.[0]],
    };

    formattedRule.criteria?.push(criteria);
  }

  return formattedRule as CreateRule;
};

const getUsersFromRule = (rule: RuleDetails, contacts: Contact[]) => {
  const ruleContacts: RuleContact[] = [];

  rule?.recipients?.forEach(({ id, phoneNumber }) => {
    const foundContact = contacts?.find((u) => u?.id === id);

    const formattedContact = foundContact
      ? extractInfoFromContact(foundContact)
      : { name: '', phoneNumber };

    ruleContacts.push(formattedContact as RuleContact);
  });

  return ruleContacts;
};

const getBankAccountsFromRule = (rule: RuleDetails, bankAccounts: BankAccount[]) => {
  const ruleBankAccounts: BankAccount[] = [];

  Object.keys(rule?.identifiers).forEach((bankAccountId) => {
    const foundAccount = bankAccounts?.find((acc) => acc?.id === bankAccountId);

    if (foundAccount) {
      ruleBankAccounts.push(foundAccount);
    }
  });

  return ruleBankAccounts;
};

export const getInitialRule = ({
  transaction, user, bankAccount, rule, bankAccounts, contacts,
} : GetInitialRule) => {
  if (transaction) {
    return getInitialRuleFromTransaction(transaction, user, bankAccount);
  }

  if (rule) {
    const ruleUsers = contacts ? getUsersFromRule(rule, contacts) : [];
    const ruleBankAccounts = bankAccounts ? getBankAccountsFromRule(rule, bankAccounts) : [];

    return getInitialRuleFromServerRule(rule, ruleUsers, ruleBankAccounts);
  }
  return {
    ...defaultRule,
    recipients: user ? [extractInfoFromUser(user)] : [],
  };
};

const getIdentifiersFromBankAccounts = (bankAccounts: BankAccount[]) => bankAccounts?.map((bankAccount) => {
  const { id } = bankAccount;

  return {
    label: formatAcctDisplayedName(bankAccount),
    formattedLabel: formatAcctNumberNameAndType(bankAccount),
    bankAccountId: id,
  };
});

const getHourFromRuleTimeframe = (timeframe?: string) => {
  if (!timeframe) {
    return '';
  }

  return +timeframe.split(':')?.[0];
};

const getInitialRuleFromServerRule = (rule: RuleDetails, recipients: Partial<User>[], bankAccounts: BankAccount[]) => {
  const isCustomTime = !!(rule?.timeframeStart || rule?.timeframeEnd);
  const criteria = [...defaultRule.criteria];

  rule?.criteria?.forEach((c) => {
    if (criteria[c.order]) {
      criteria[c.order] = c?.key === 'amount' && c?.condition === CriteriaCondition.between
        ? {
          ...c,
          values: c?.values?.[0]?.split(':'),
        } : c;
    }
  });

  return {
    name: rule?.name,
    identifiers: getIdentifiersFromBankAccounts(bankAccounts),
    criteria,
    hasDescription: criteria?.[0]?.values?.length > 0 && !!criteria?.[0]?.values?.[0],
    hasAmount: criteria?.[1]?.values?.length > 0 && !!criteria?.[1]?.values?.[0],
    type: rule?.type,
    status: rule?.status,
    timezone: rule?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeframe: isCustomTime ? RuleTimeframeType.customTime : RuleTimeframeType.anyTime,
    timeframeStart: getHourFromRuleTimeframe(rule?.timeframeStart),
    timeframeEnd: getHourFromRuleTimeframe(rule?.timeframeEnd),
    message: rule?.message,
    recipients,
  };
};

export const getInitialRuleFromTransaction = (
  transaction: CreateRuleTransaction,
  user?: User | null,
  bankAccount?: BankAccount | null,
) => {
  const recipients = user && user?.phoneNumber ? [extractInfoFromUser(user)] : [];
  const messageType = transaction.trnType === TransactionType.credit ? 'deposited to' : 'withdrawn from';
  const amount = Math.abs(transaction.amount);
  const messageAmount = formatTablePrice({ amount });

  const identifiers = bankAccount
    ? getIdentifiersFromBankAccounts([bankAccount])
    : [{ bankAccountId: '' }];

  return {
    name: '',
    identifiers,
    criteria: [
      {
        key: 'memo',
        values: [transaction.memo],
        condition: CriteriaCondition.equal,
        order: 0,
      },
      {
        key: 'amount',
        values: [amount.toFixed(2).toString()],
        condition: '',
        order: 1,
      },
      {
        key: 'trnType',
        values: [transaction.trnType],
        condition: CriteriaCondition.equal,
        order: 2,
      },
    ],
    hasAmount: true,
    hasDescription: true,
    type: RuleMatch.match,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeframe: RuleTimeframeType.anyTime,
    timeframeStart: '',
    timeframeEnd: '',
    message: `Amount of ${messageAmount} or more has been ${messageType} your account.`,
    recipients,
  };
};

export const extractInfoFromContact = (contact: Contact): RuleContact => ({
  name: formatFullName(contact?.firstName, contact?.lastName),
  phoneNumber: contact?.phoneNumber,
  id: contact?.id,
});

export const extractInfoFromUser = (user: User): RuleContact => ({
  name: user?.name,
  phoneNumber: user?.phoneNumber,
  id: user?.contactId,
});

export const getManipulatedTimezones = () => timezones
  ?.sort((a, b) => {
    if (a.tzCode < b.tzCode) {
      return -1;
    }
    if (a.tzCode > b.tzCode) {
      return 1;
    }
    return 0;
  })
  ?.map((t) => formatTimezone(t));
