/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SpeakerIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6387 9.0286C18.0739 5.34431 15.5553 2.539 14.2812 3.06313C12.1169 3.95297 15.5705 8.22294 4.94398 12.5961C4.02601 12.9736 3.79338 14.4844 4.17722 15.3878C4.56105 16.2913 5.81633 17.1901 6.7343 16.8127C6.89266 16.7475 7.47601 16.5574 7.47601 16.5574C8.13184 17.4472 8.81808 16.9195 9.06055 17.4853C9.35312 18.1651 9.98926 19.6424 10.2049 20.1448C10.4205 20.6472 10.9117 21.1125 11.2678 20.9758L13.2952 20.1991C13.3873 20.1729 13.4727 20.1269 13.5457 20.0644C13.6187 20.0019 13.6777 19.9243 13.7185 19.8368C13.7594 19.7493 13.7813 19.6539 13.7826 19.5572C13.784 19.4604 13.7648 19.3644 13.7265 19.2758C13.5744 18.9219 12.949 18.8178 12.7709 18.4041C12.5929 17.9904 12.0104 16.6651 11.8431 16.2469C11.6149 15.6784 12.099 15.2167 12.8022 15.1425C17.6381 14.6329 18.5427 17.6545 20.189 16.9774C21.463 16.4479 21.2036 12.7129 19.6387 9.0286ZM19.0938 15.0031C18.8111 15.1199 16.9072 13.6009 15.6939 10.7368C14.4807 7.87262 14.631 5.2556 14.9137 5.13882C15.1965 5.02205 17.053 6.85876 18.2689 9.71838C19.4848 12.578 19.3775 14.8891 19.0938 15.0049V15.0031Z" fill={color} />
  </svg>
);

export default SpeakerIcon;
