import { initializeApp } from 'firebase/app';
import { getAuth, AuthErrorCodes } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

import envConfig from 'configuration/env';
import setupAxiosInterceptors from 'utils/interceptors';

const { firebaseConfig } = envConfig;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

setupAxiosInterceptors();

export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export default firebaseApp;

export const getIntlMessageFromFirebaseError = (errorCode: any) => {
  if (errorCode === AuthErrorCodes.USER_DELETED) {
    return 'error.emailNotFound';
  }

  if (errorCode === AuthErrorCodes.INVALID_PASSWORD) {
    return 'error.wrongPasswordOrEmail';
  }

  if (errorCode === AuthErrorCodes.INVALID_LOGIN_CREDENTIALS) {
    return 'error.wrongPasswordOrEmail';
  }

  if (errorCode === AuthErrorCodes.EMAIL_EXISTS) {
    return 'error.emailExists';
  }

  if (errorCode === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
    return 'error.emailTooManyRequests';
  }

  return '';
};
