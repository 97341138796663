import { usePermissions } from 'hooks/usePermissions';

import BillingIcon from 'assets/icons/BillingIcon';
import CompanyIcon from 'assets/icons/CompanyIcon';
import TeamIcon from 'assets/icons/TeamIcon';
import {
  COMPANY_BILLING_ROUTE,
  COMPANY_PROFILE_ROUTE,
  COMPANY_TEAM_MANAGEMENT_ROUTE,
} from 'constants/clientRoutes';
import { RolePermissions } from 'constants/permissionEnums';
import { alertsItems, moveMoneyItems, userSettingsItems } from 'constants/sidebar';

export enum SidebarContext {
  UserProfile,
  WorkspaceSettings,
  MoveMoney,
  Alerts,
}

const useWorkspaceSettings = () => {
  const { isAllowed } = usePermissions();

  return () => (
    [
      {
        key: 'company-profile',
        Icon: CompanyIcon,
        label: 'navigation.companyProfile',
        route: COMPANY_PROFILE_ROUTE,
        isVisible: true,
      },
      {
        key: 'team-management',
        Icon: TeamIcon,
        label: 'navigation.teamManagement',
        route: COMPANY_TEAM_MANAGEMENT_ROUTE,
        isVisible: isAllowed({ permission: RolePermissions.ViewUsers }),
      },
      {
        key: 'billing',
        Icon: BillingIcon,
        label: 'navigation.billingInformation',
        route: COMPANY_BILLING_ROUTE,
        isVisible: isAllowed({ permission: RolePermissions.ViewSubscription }),
      },
    ]
  );
};

export const useSidebar = (context: SidebarContext) => {
  const workspaceSettingsItems = useWorkspaceSettings();

  switch (context) {
    case SidebarContext.UserProfile:
      return userSettingsItems;
    case SidebarContext.WorkspaceSettings:
      return workspaceSettingsItems();
    case SidebarContext.MoveMoney:
      return moveMoneyItems;
    case SidebarContext.Alerts:
      return alertsItems;
    default:
      return [];
  }
};
