/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const FilledUserIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4C9.78906 4 8 5.78906 8 8C8 10.2109 9.78906 12 12 12C14.2109 12 16 10.2109 16 8C16 5.78906 14.2109 4 12 4ZM12 14C5.89844 14 4 18 4 18V20H20V18C20 18 18.1016 14 12 14Z"
      fill={color}
    />
  </svg>

);

export default FilledUserIcon;
