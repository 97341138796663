import * as Sentry from '@sentry/react';
import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import {
  createRoutesFromChildren, matchRoutes, useLocation, useNavigationType,
} from 'react-router-dom';

import envConfig from 'configuration/env';

import 'assets/css/index.css';

import packageJson from '../package.json';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_NODE_ENV !== 'local') {
  Sentry.init({
    ...envConfig.sentryConfiguration,
    release: `${packageJson.name}@${packageJson.version}`,
    environment: process.env.REACT_APP_NODE_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
  ReactGA.initialize(envConfig?.firebaseConfig?.measurementId);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
