import { styled } from '@mui/material/styles';

export const BankImg = styled('img')(() => ({
  height: '24px',
  width: '24px',
  borderRadius: '2px',
}));

export const InstitutionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(3),
  maxWidth: '250px',
}));
