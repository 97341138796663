import {
  Box, Checkbox, FormLabel, Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import {
  Controller, FieldErrors, Control, FieldValues, Path, UseFormSetValue, PathValue,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import AutocompleteMultipleField from 'fields/AutocompleteMultipleField';

import { DefaultType } from 'constants/enums';
import { ALLOWED_BANK_ACCOUNTS } from 'constants/fields';
import { AllBanksOption } from 'models/allOption.interface';
import { BankAccount } from 'models/bankAccount.interface';
import { formatBankName } from 'utils/formatters';

interface AccountAccessFieldProps<TAccess extends FieldValues> {
  control: Control<TAccess>;
  errors: Partial<FieldErrors<TAccess>>;
  bankAccounts: BankAccount[];
  onSetValue: UseFormSetValue<TAccess>;
}

const AccountAccessField = <TAccess extends FieldValues>({
  errors,
  control,
  bankAccounts,
  onSetValue,
}: AccountAccessFieldProps<TAccess>): ReactElement => {
  const intl = useIntl();
  const options = [
    { id: DefaultType.all, label: intl.formatMessage({ id: 'label.all' }) },
    ...bankAccounts,
  ];

  const handleChangeAutocomplete = (
    newAccounts: (BankAccount|AllBanksOption)[],
    newValue: BankAccount|AllBanksOption,
  ) => {
    const isAllSelected = Boolean(newAccounts?.find(({ id }) => id === DefaultType.all));

    if (newValue?.id === DefaultType.all) {
      if (isAllSelected) {
        onSetValue(
          ALLOWED_BANK_ACCOUNTS.name as Path<TAccess>,
          [newValue, ...bankAccounts] as PathValue<TAccess, any>,
          { shouldValidate: true },
        );
      } else {
        onSetValue(
          ALLOWED_BANK_ACCOUNTS.name as Path<TAccess>,
          [] as PathValue<TAccess, any>,
          { shouldValidate: true },
        );
      }
    } else if (isAllSelected) {
      onSetValue(
          ALLOWED_BANK_ACCOUNTS.name as Path<TAccess>,
          newAccounts?.filter(({ id }) => id !== DefaultType.all) as PathValue<TAccess, any>,
          { shouldValidate: true },
      );
    } else {
      const accounts = newAccounts?.length === bankAccounts?.length ? [options[0], ...newAccounts] : newAccounts;
      onSetValue(
        ALLOWED_BANK_ACCOUNTS.name as Path<TAccess>,
        accounts as PathValue<TAccess, any>,
        { shouldValidate: true },
      );
    }
  };

  const getOptionName = (option: BankAccount|AllBanksOption) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    option.id === DefaultType.all ? option.label : formatBankName(option as BankAccount)
  );
  const getTagName = (option: BankAccount|AllBanksOption) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    option.id === DefaultType.all ? option.label : `${formatBankName(option as BankAccount)},`
  );

  return (
    <>
      <Typography variant="subtitle1" color="textPrimary" sx={{ mb: 3 }}>
        {intl.formatMessage({ id: 'label.accountAccess' })}
      </Typography>
      <FormLabel required>
        <Typography variant="label1" color="textPrimary">
          {intl.formatMessage({ id: ALLOWED_BANK_ACCOUNTS.label })}
        </Typography>
      </FormLabel>
      <Controller
        name={ALLOWED_BANK_ACCOUNTS.name as Path<TAccess>}
        control={control}
        render={({ field }): ReactElement => (
          <AutocompleteMultipleField
            name={ALLOWED_BANK_ACCOUNTS.name}
            values={field?.value as string[]}
            options={options}
            onChange={handleChangeAutocomplete}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderTagsComponent={(option: BankAccount|AllBanksOption) => {
              const isAllSelected = Boolean(field.value?.find(({ id }: any) => id === DefaultType.all));

              if ((isAllSelected && option.id === DefaultType.all) || !isAllSelected) {
                return (
                  <Typography key={`chip-${option.id}`} pr={1}>
                    {getTagName(option)}
                  </Typography>
                );
              }

              return null;
            }}
            renderOptionComponent={(option: BankAccount|AllBanksOption) => (
              <Box key={`opt-${option.id}`} px={4} py={3} display="flex" alignItems="center" gap={1} width="100%">
                <Checkbox
                  checked={!!field?.value?.find(({ id }: BankAccount|AllBanksOption) => id === option.id)}
                />
                <Typography variant="body1">
                  {getOptionName(option)}
                </Typography>
              </Box>
            )}
            getOptionLabel={(option: BankAccount|AllBanksOption) => getOptionName(option)}
            errorMessage={errors?.[ALLOWED_BANK_ACCOUNTS.name]?.message as string}
            id="select-allowedBanks"
          />
        )}
      />
    </>
  );
};

export default AccountAccessField;
