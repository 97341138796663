export enum RolePermissions {
  ViewTransactions = 'VIEW_TRANSACTIONS',
  SyncFirebase = 'SYNC_FIREBASE',
  InviteUser = 'INVITE_USER',
  UpdateNotifications = 'UPDATE_NOTIFICATIONS',
  DeleteUsers = 'DELETE_USERS',
  CancelTransactions = 'CANCEL_TRANSACTIONS',
  DeleteScheduledTransaction = 'DELETE_SCHEDULED_TRANSACTION',
  UpdateCustomer = 'UPDATE_CUSTOMER',
  UpdateUsers = 'UPDATE_USERS',
  ManageUserRoles = 'MANAGE_USER_ROLES',
  CreateInvitations = 'CREATE_INVITATIONS',
  SuspendScheduledTransaction = 'SUSPEND_SCHEDULED_TRANSACTION',
  UpdateScheduledTransaction = 'UPDATE_SCHEDULED_TRANSACTION',
  UpdateTransactions = 'UPDATE_TRANSACTIONS',
  ViewKybDetails = 'VIEW_KYB_DETAILS',
  ManageKybDetails = 'MANAGE_KYB_DETAILS',
  AccessAllCustomer = 'ACCESS_ALL_CUSTOMER',
  AccessAllUser = 'ACCESS_ALL_USER',
  ViewAllCustomers = 'VIEW_ALL_CUSTOMERS',
  ViewCustomer = 'VIEW_CUSTOMER',
  ViewUsers = 'VIEW_USERS',
  CustomerSecurity = 'CUSTOMER_SECURITY',
  ViewAccounts = 'VIEW_ACCOUNTS',
  ViewDashboard = 'VIEW_DASHBOARD',
  ViewInvitations = 'VIEW_INVITATIONS',
  UseSession = 'USE_SESSION',
  ViewAudits = 'VIEW_AUDITS',
  ViewAllTransactions = 'VIEW_ALL_TRANSACTIONS',
  ViewScheduledTransaction = 'VIEW_SCHEDULED_TRANSACTION',
  RefreshAccounts = 'REFRESH_ACCOUNTS',
  ViewUserAgreement = 'VIEW_USER_AGREEMENT',
  DeleteCustomer = 'DELETE_CUSTOMER',
  LinkAccounts = 'LINK_ACCOUNTS',
  CreateCustomer = 'CREATE_CUSTOMER',
  CreateAccounts = 'CREATE_ACCOUNTS',
  CloseAccounts = 'CLOSE_ACCOUNTS',
  DeleteInvitations = 'DELETE_INVITATIONS',
  SubmitKybApplication = 'SUBMIT_KYB_APPLICATION',
  UpdateAccounts = 'UPDATE_ACCOUNTS',
  CreateTransactions = 'CREATE_TRANSACTIONS',
  ManageUserAgreement = 'MANAGE_USER_AGREEMENT',
  ViewAlertRules = 'VIEW_ALERT_RULES',
  ViewLimits = 'VIEW_LIMITS',
  ViewRecipients = 'VIEW_RECIPIENTS',
  ViewSubscription = 'VIEW_SUBSCRIPTION',
  CreateAlertRules = 'CREATE_ALERT_RULES',
  ManageAlertRules = 'MANAGE_ALERT_RULES',
  CreateRecipient = 'CREATE_RECIPIENT',
  DeleteAlertRules = 'DELETE_ALERT_RULES',
  ManageLimits = 'MANAGE_LIMITS',
  DeleteRecipient = 'DELETE_RECIPIENT',
  ManageSubscription = 'MANAGE_SUBSCRIPTION',
  ReviewTransactionApproval = 'REVIEW_TRANSACTION_APPROVAL',
  ViewContact = 'VIEW_CONTACT',
  CreateContact = 'CREATE_CONTACT',
  DeleteContact = 'DELETE_CONTACT',
  UpdateAlerts = 'UPDATE_ALERTS'
}
