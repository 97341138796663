
const MuiChip = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      border: `1px solid ${theme.palette.border.secondary}`,
      backgroundColor: theme.palette.general.lightGrey7,

      '&.tag': {
        fontWeight: 700,
        fontSize: '10px',
        height: '26px',
        padding: theme.spacing(1, 1.5),
        lineHeight: '14px',
      },

      '& svg': {
        padding: theme.spacing(0.5),
        marginRight: theme.spacing(1),

        '& path': {
          fill: theme.palette.general.lightBlack,
        },
      },
    }),
    label: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

export default MuiChip;
