import {
  ScaleLinear, ScaleBand, select, line, curveMonotoneX,
} from 'd3';
import { useRef, useEffect, FC } from 'react';

interface LineProps {
  data: { x: number; y: number }[];
  scaleX: ScaleLinear<number, number> | ScaleBand<string>;
  scaleY: ScaleLinear<number, number>;
  color: string;
  boxShadow?: string | null;
  isCurved?: boolean;
  isDashed?: boolean;
}

const Line: FC<LineProps> = ({
  data,
  scaleX,
  scaleY,
  color,
  boxShadow = null,
  isCurved = false,
  isDashed = false,
}) => {
  const gRef = useRef<SVGGElement>(null);

  const handleX = (d: { x: number; y: number }) => {
    if ('bandwidth' in scaleX) {
      return (scaleX(d.x.toString()) || 0) + scaleX.bandwidth() / 2;
    }

    return scaleX(d.x || 0);
  };

  useEffect(() => {
    if (gRef.current) {
      let lineGenerator = line<{ x: number; y: number }>()
        .x(handleX)
        .y((d) => scaleY(d.y));

      if (isCurved) {
        lineGenerator = lineGenerator.curve(curveMonotoneX);
      }

      const hasPath = Array.from(gRef.current.children)
        .some((element) => element.tagName === 'path');
      let pathElement = select(gRef.current).append('path');

      if (hasPath) {
        pathElement = select(gRef.current).select('path');
      }

      pathElement
        .datum(data)
        .attr('d', lineGenerator)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('filter', boxShadow ? `drop-shadow(0px 4px 4px ${boxShadow}` : '')
        .attr('stroke-width', '2px')
        .attr('stroke-dasharray', isDashed ? '5 5' : '0');
    }
  }, [data, scaleX, scaleY, color, boxShadow]);

  return <g ref={gRef} />;
};

export default Line;
