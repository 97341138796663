
const MuiCheckbox = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      color: theme.palette.general.lightGrey6,
      padding: theme.spacing(1),
      '& svg': {
        width: '18px',
        height: '18px',
      },
    }),
  },
};

export default MuiCheckbox;
