import {
  ACTIVATE_ACCOUNT_TOP_UP_FUNDING_API,
  CREATE_TOP_UP_FUNDING_API,
  GET_ACCOUNT_FUNDING_COUNT_API,
  GET_ACCOUNT_TOP_UP_FUNDING_API,
  GET_TOP_UP_FUNDING_API,
  INACTIVATE_ACCOUNT_TOP_UP_FUNDING_API,
} from 'constants/apiRoutes';
import { formatRoute } from 'utils/formatters';
import { TopUpFormValues } from 'utils/validation/topUpThresholdSchema';

import { runGetApiRequest, runPatchApiRequest, runPostApiRequest } from './Api';

export const getAccountTopUpFunding = async (accountId: string) => {
  const endpoint = formatRoute(GET_ACCOUNT_TOP_UP_FUNDING_API, { accountId });
  return runGetApiRequest(endpoint);
};

export const activateTopUpFunding = async (fundingId: string) => {
  const endpoint = formatRoute(ACTIVATE_ACCOUNT_TOP_UP_FUNDING_API, { fundingId });
  return runPostApiRequest(endpoint);
};

export const inactivateTopUpFunding = async (fundingId: string) => {
  const endpoint = formatRoute(INACTIVATE_ACCOUNT_TOP_UP_FUNDING_API, { fundingId });
  return runPostApiRequest(endpoint);
};

export const createTopUpFunding = async (payload: TopUpFormValues) => (
  runPostApiRequest(CREATE_TOP_UP_FUNDING_API, payload)
);

export const editTopUpFunding = async (fundingId: string, payload: TopUpFormValues) => {
  const endpoint = formatRoute(GET_TOP_UP_FUNDING_API, { fundingId });
  return runPatchApiRequest(endpoint, payload);
};

export const getAccountFundingCount = async (accountId: string) => {
  const endpoint = formatRoute(GET_ACCOUNT_FUNDING_COUNT_API, { accountId });
  return runGetApiRequest(endpoint);
};
