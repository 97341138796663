import { Tab, Typography } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import ComingSoon from 'components/_ComingSoon';
import Content from 'components/_Content';
import { Tabs } from 'layouts/InsightsLayout/index.styled';
import AdvancedTransactionSearch from 'modules/insights-advanced-search/containers/AdvancedTransactionSearch';
import CashflowAnalysis from 'modules/insights-cashflow/containers/CashFlowAnalysis';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { resetAccountStore } from 'store/bank-account-details/bankAccountDetailsSlice';
import { fetchBankAccounts } from 'store/bank-accounts/bankAccountsSlice';
import { resetCashFlow } from 'store/cash-flow/cashFlowSlice';
import { currentWorkspaceSelector } from 'store/user/selectors';

function a11yProps(index: string) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-${index}`,
  };
}

const insightsComponents: Record<string, any> = {
  moneyMovement: ComingSoon,
  cashflow: CashflowAnalysis,
  advanceSearch: AdvancedTransactionSearch,
};

const InsightsLayout = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const initialTab = searchParams?.get('tab') || 'moneyMovement';
  const [tab, setTab] = useState(initialTab);
  const workspaceId = currentWorkspace?.id || '';
  const Component = insightsComponents[tab];

  useDocumentTitle({ title: 'pageTitle.insights' });

  useEffect(() => () => {
    dispatch(resetCashFlow());
    dispatch(resetAccountStore());
  }, []);

  useEffect(() => {
    if (workspaceId) {
      dispatch(fetchBankAccounts({ workspaceId, showError: true }));
    }
  }, [workspaceId]);

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTab(newValue);
    navigate(`${location.pathname}?tab=${newValue}`);
  };

  return (
    <Content>
      <Tabs
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        value={tab}
        onChange={handleTabChange}
        aria-label="tab"
        sx={{ mb: 3 }}
      >
        <Tab
          value="moneyMovement"
          label={(
            <Typography>
              {intl.formatMessage({ id: 'label.moveMovementSummary' })}
            </Typography>
          )}
          {...a11yProps('moneyMovement')}
        />
        <Tab
          value="cashflow"
          label={(
            <Typography>
              {intl.formatMessage({ id: 'label.cashflowAnalysis' })}
            </Typography>
          )}
          {...a11yProps('cashflow')}
        />
        <Tab
          value="advanceSearch"
          label={(
            <Typography>
              {intl.formatMessage({ id: 'label.advancedTransactionSearch' })}
            </Typography>
          )}
          {...a11yProps('advanceSearch')}
        />
      </Tabs>
      {Component && <Component />}
    </Content>
  );
};

export default InsightsLayout;
