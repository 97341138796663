import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import FilterIcon from 'assets/icons/FilterIcon';
import { BANK_ACCOUNT_TYPES_AS_LABELS } from 'constants/labels';
import { BankAccount } from 'models/bankAccount.interface';
import { getBankLogo } from 'utils/bank';
import { getAccountLast4Numbers } from 'utils/formatters';
import { capitalize } from 'utils/text';

import { BankImg, Divider } from './index.styled';

interface FilterHeaderProps {
  accounts: BankAccount[];
}

const FilterHeader: FC<FilterHeaderProps> = ({
  accounts,
}) => {
  const intl = useIntl();

  return (
    <>
      <Box display="flex">
        <FilterIcon size={24} />
      </Box>
      <Typography variant="body2" fontWeight={600} className="text-ellipsis">
        Last 7 days
      </Typography>

      {accounts?.length > 0 && (
        <>
          <Divider orientation="vertical" />
          <Box display="flex" alignItems="center" gap={3}>
            <Typography variant="body2" fontWeight={600}>
              {capitalize(intl.formatMessage({ id: 'label.account' }))}
              :
            </Typography>
            {accounts?.map(({
              accountNumber, type, institution, id, nickname, name,
            }) => {
              const accountType = type ? BANK_ACCOUNT_TYPES_AS_LABELS[type] : '';

              return (
                <Box display="flex" alignItems="center" key={id} gap={2}>
                  <BankImg src={getBankLogo(institution)} alt={nickname || name} />
                  <Typography variant="body2" className="text-ellipsis">
                    {`${intl.formatMessage({ id: accountType })} (${getAccountLast4Numbers(accountNumber)})`}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </>
      )}

      <Divider orientation="vertical" />

      <Typography variant="body2" fontWeight={600} className="text-ellipsis">
        Debits & Credits
      </Typography>

      <Divider orientation="vertical" />

      <Typography variant="body2" fontWeight={600} className="text-ellipsis">
        Greater than $2,000.00
      </Typography>

      <Divider orientation="vertical" />

      <Typography variant="body2" fontWeight={600} className="text-ellipsis">
        Keyword - ABC inc, invoice, retainer, payment, Lobortis tellus ut in justo sit.
      </Typography>
    </>
  );
};

export default FilterHeader;
