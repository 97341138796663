import {
  Box, FormControlLabel, Radio, Tooltip, Typography, useTheme,
} from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';
import {
  Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import CustomizableRadioButtonField from 'fields/CustomizableRadioButtonField';
import RuleTimeframe from 'modules/create-rule/components/RuleTimeframe';

import InfoIcon from 'assets/icons/InfoIcon';
import { RuleMatch } from 'constants/enums';
import { RuleFormValues } from 'utils/validation/ruleFormSchema';

interface MatchingConditionProps {
  control: Control<RuleFormValues>;
  onSetValue: UseFormSetValue<RuleFormValues>;
  watch: UseFormWatch<RuleFormValues>;
  translatedErrors: Partial<FieldErrors<RuleFormValues>>;
  register: UseFormRegister<RuleFormValues>;
  isReadOnly: boolean;
}

const MatchingCondition: FC<MatchingConditionProps> = ({
  control,
  onSetValue,
  watch,
  register,
  translatedErrors,
  isReadOnly,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const renderBold = (chunks: ReactNode[]) => <b>{chunks}</b>;

  return (
    <>
      <Box display="flex" mb={3}>
        <Typography variant="subtitle1" mr={2}>
          {intl.formatMessage({ id: 'label.matchingCondition' })}
        </Typography>
        <Tooltip
          arrow
          id="tooltip-matching-condition"
          title={intl.formatMessage({ id: 'label.chooseAConditionThat' })}
          placement="top-start"
        >
          <Box><InfoIcon size={20} color={theme.palette.general.darkGrey1} /></Box>
        </Tooltip>
      </Box>

      <Controller
        name="type"
        control={control}
        render={({ field }): ReactElement => (
          <CustomizableRadioButtonField
            fullWidth
            onChange={field.onChange}
            id="rule-match-type"
            value={field.value as string}
          >
            <Box display="flex" flexDirection="column" gap={3} ml={3}>
              <FormControlLabel
                disabled={isReadOnly}
                value={RuleMatch.match}
                sx={{ alignItems: 'flex-start', width: 'fit-content' }}
                label={(
                  <Box pl={1} mt={0.5}>
                    <Typography variant="body1">
                      {intl.formatMessage(
                        { id: 'label.aboveCriteriaMatched' },
                        { b: renderBold },
                      )}
                    </Typography>
                    <RuleTimeframe
                      isVisible={field.value === RuleMatch.match}
                      onSetValue={onSetValue}
                      watch={watch}
                      ruleType={RuleMatch.match}
                      register={register}
                      translatedErrors={translatedErrors}
                      control={control}
                      isReadOnly={isReadOnly}
                    />
                  </Box>
                )}
                control={<Radio id="radio-type-match" />}
              />
              <FormControlLabel
                disabled={isReadOnly}
                value={RuleMatch.noMatch}
                sx={{ alignItems: 'flex-start', width: 'fit-content' }}
                label={(
                  <Box pl={1} mt={0.5}>
                    <Typography variant="body1">
                      {intl.formatMessage(
                        { id: 'label.aboveCriteriaDidNotOccur' },
                        { b: renderBold },
                      )}
                    </Typography>
                    <RuleTimeframe
                      isVisible={field.value === RuleMatch.noMatch}
                      onSetValue={onSetValue}
                      watch={watch}
                      ruleType={RuleMatch.noMatch}
                      register={register}
                      translatedErrors={translatedErrors}
                      control={control}
                      isReadOnly={isReadOnly}
                    />
                  </Box>
                )}
                control={<Radio id="radio-type-noMatch" />}
              />
            </Box>
          </CustomizableRadioButtonField>
        )}
      />
    </>
  );
};

export default MatchingCondition;
