import { styled } from '@mui/material/styles';

export const PhoneWrapper = styled('div')<{ hasError?: boolean }>(({ theme, hasError }) => {
  const borderColor = hasError ? theme.palette.error.main : theme.palette.general.lightGrey8;
  const focusBorderColor = hasError ? theme.palette.error.main : theme.palette.primary.main;
  const errorCss = hasError ? {
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '& .react-tel-input .flag-dropdown .selected-flag': {
      borderRight: 'none',
      borderColor: theme.palette.error.main,
    },
  } : {};

  return {
    width: '100%',
    '& .react-tel-input .flag-dropdown.open .selected-flag': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      backgroundColor: `${theme.palette.general.lightGrey8}`,
    },

    '& .react-tel-input': {
      '& .flag-dropdown': {
        border: 'none',
        backgroundColor: theme.palette.common.white,

        '& .selected-flag': {
          width: '60px',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          border: `1px solid ${theme.palette.general.lightGrey8}`,
          paddingLeft: theme.spacing(5),
          backgroundColor: `${theme.palette.general.lightGrey8}`,

          '&:hover': {
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            backgroundColor: theme.palette.general.lightGrey8,
          },
        },
      },

      '& .form-control': {
        paddingLeft: theme.spacing(15),
        height: '45px',
        width: '100%',
        borderRadius: '8px',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '15px',
        lineHeight: '21px',
        backgroundColor: theme.palette.general.lightGrey8,
        color: theme.palette.text.primary,
        borderColor,
      },

      '& .country-list': {
        boxShadow: 'none',
        border: `1px solid ${theme.palette.general.lightGrey8}`,
        margin: theme.spacing(2.5, 0, 2.5, 0.25),
        position: 'fixed',
      },

      '&:hover': {
        '& .form-control': {
          borderColor: hasError ? theme.palette.error.main : theme.palette.border.main,
        },

        '& .flag-dropdown .selected-flag': {
          borderColor: hasError ? theme.palette.error.main : theme.palette.border.main,
          borderRight: 'none',
        },
      },

      '&:focus-within': {
        '& .form-control': {
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${focusBorderColor}`,
        },

        '& .flag-dropdown .selected-flag': {
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${focusBorderColor}`,
          borderRight: 'none',
        },
      },
    },

    ...errorCss,
  };
});
