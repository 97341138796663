import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import ReactGA from 'react-ga4';

import {
  deleteWorkspaceBankAccount,
  getAccountBalanceRankingWidget,
  getActivityWidget,
  getTransactionActivityWidget,
  getWorkspaceBankAccounts,
  updateWorkspaceBankAccount,
} from 'services/BankAccountService';
import { resetStore } from 'store/actions';

import {
  ApiStatus,
  BalanceTrend,
  BankAccountsGroupByType,
  BankAccountsSortByType,
  BankAccountType,
} from 'constants/enums';
import { BankAccount, BankAccountFilters } from 'models/bankAccount.interface';
import { AccountActivityRanked, AccountBalanceRanked, TotalBalanceChartInfo } from 'models/charts.interface';
import { isInternalAccount } from 'utils/bankAccount';
import { handleApiErrors } from 'utils/errorUtils';
import { sendAccountFiltersStatistics } from 'utils/googleAnalytics';

export interface BankAccountsState {
  accounts: {
    data: BankAccount[] | null;
    status: ApiStatus;
  };
  filteredAccounts: BankAccount[];
  chartFilters: {
    totalBalanceTrend: BalanceTrend;
    rankedActivityTrend: BalanceTrend;
  };
  transactionActivityWidget: {
    data: AccountActivityRanked[];
    status: ApiStatus;
  };
  totalBalanceChart: {
    data: TotalBalanceChartInfo;
    status: ApiStatus;
  };
  balanceRankingChart: {
    data: AccountBalanceRanked[];
    status: ApiStatus;
  };
  accountsFilters: BankAccountFilters;
  search: string;
  sortBy: BankAccountsSortByType;
  groupBy: BankAccountsGroupByType;
  showOnlyLinkedAccounts: boolean;
  requiresRelink: boolean;
  showBrokenAccountsModal: boolean;
}

const initialState: BankAccountsState = {
  accounts: {
    data: null,
    status: ApiStatus.idle,
  },
  filteredAccounts: [],
  transactionActivityWidget: {
    data: [],
    status: ApiStatus.loading,
  },
  totalBalanceChart: {
    data: {
      balances: [],
      thirtyDaysMovingAverage: [],
      sixtyDaysMovingAverage: [],
      ninetyDaysMovingAverage: [],
    },
    status: ApiStatus.loading,
  },
  balanceRankingChart: {
    data: [],
    status: ApiStatus.loading,
  },
  chartFilters: {
    totalBalanceTrend: BalanceTrend.LastFourWeeks,
    rankedActivityTrend: BalanceTrend.LastFourWeeks,
  },
  accountsFilters: {
    types: [BankAccountType.allTypes],
    institutions: [],
    balance: {
      min: '',
      max: '',
    },
  },
  search: '',
  sortBy: BankAccountsSortByType.balanceHighLow,
  groupBy: BankAccountsGroupByType.accountType,
  requiresRelink: false,
  showOnlyLinkedAccounts: false,
  showBrokenAccountsModal: false,
};

export const fetchBankAccounts = createAsyncThunk(
  'bankAccounts/fetchBankAccounts',
  async ({ workspaceId, showError }: { workspaceId: string; showError?: boolean }, thunkAPI) => {
    try {
      const response = await getWorkspaceBankAccounts(workspaceId);
      return response.data;
    } catch (e) {
      if (showError) {
        handleApiErrors(e);
      }

      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const updateBankAccount = createAsyncThunk(
  'bankAccounts/updateBankAccount',
  async ({
    workspaceId, accountId, payload, position,
  }: {
    workspaceId: string;
    accountId: string;
    payload: { favorite?: boolean; nickname?: string };
    position: number;
  }, thunkAPI) => {
    try {
      const response = await updateWorkspaceBankAccount(workspaceId, accountId, payload);
      thunkAPI.dispatch(updateAccount({ position, bankAccount: response.data }));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const deleteBankAccount = createAsyncThunk(
  'bankAccounts/deleteBankAccount',
  async (
    { workspaceId, accountId, position }: { workspaceId: string; accountId: string; position: number},
    thunkAPI,
  ) => {
    try {
      const response = await deleteWorkspaceBankAccount(workspaceId, accountId);
      thunkAPI.dispatch(removeAccount({ position }));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchTransactionActivityWidget = createAsyncThunk(
  'bankAccounts/fetchTransactionActivityWidget',
  async ({ params, queryParams } : {
    params: { workspaceId: string };
    queryParams: { period: BalanceTrend };
  }, thunkAPI) => {
    try {
      const response = await getTransactionActivityWidget(queryParams, params);

      return response.data?.transactionActivityDTOList;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchTotalBalanceWidget = createAsyncThunk(
  'bankAccounts/fetchTotalBalanceWidget',
  async ({ params, queryParams } : {
    params: { workspaceId: string };
    queryParams: { period: BalanceTrend; 'bank-account-id': string };
  }, thunkAPI) => {
    try {
      const response = await getActivityWidget(queryParams, params);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchBalanceRankingWidget = createAsyncThunk(
  'bankAccounts/fetchBalanceRankingWidget',
  async ({ params } : {
    params: { workspaceId: string };
  }, thunkAPI) => {
    try {
      const response = await getAccountBalanceRankingWidget(params);
      return response.data?.accountBalanceRankingDTOList;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const bankAccountsSlice = createSlice({
  name: 'bankAccount',
  initialState,
  reducers: {
    removeAccount: (state, action: PayloadAction<{ position: number }>) => {
      const { position } = action.payload;
      const accounts: BankAccount[] = JSON.parse(JSON.stringify(state.accounts?.data));
      accounts.splice(position, 1);
      state.accounts = {
        ...state.accounts,
        data: accounts,
      };
      state.requiresRelink = accounts.some((account: BankAccount) => (
        !isInternalAccount(account.category) && account.lastUpdateFailed
      ));
      return state;
    },
    updateAccount: (state, action: PayloadAction<{ bankAccount: BankAccount; position: number }>) => {
      const { position, bankAccount } = action.payload;
      const accounts = JSON.parse(JSON.stringify(state?.accounts?.data));
      const filteredAccounts = JSON.parse(JSON.stringify(state.filteredAccounts));
      const filteredAccountsPosition = filteredAccounts.findIndex((account: BankAccount) => (
        account?.id === bankAccount?.id
      ));

      if (filteredAccountsPosition !== -1) {
        filteredAccounts[filteredAccountsPosition] = bankAccount;
        state.filteredAccounts = filteredAccounts;
      }

      accounts[position] = bankAccount;
      state.accounts.data = accounts;
      return state;
    },
    updateChartFilters: (state, action: PayloadAction<{ name: string; value: BalanceTrend }>) => {
      const { name, value } = action.payload;
      state.chartFilters = {
        ...state.chartFilters,
        [name]: value,
      };

      ReactGA.gtag('event', `chart_date_filter_${value}`, {
        label: value,
        value: 1,
      });

      return state;
    },
    updateShowOnlyLinkedAccounts: (state, action: PayloadAction<boolean>) => {
      state.showOnlyLinkedAccounts = action.payload;
      return state;
    },
    updateFilteredAccounts: (state, action: PayloadAction<BankAccount[]>) => {
      state.filteredAccounts = action.payload;
      return state;
    },
    updateAccountsFilters: (
      state,
      action: PayloadAction<{ filters: BankAccountFilters }>,
    ) => {
      const { filters } = action.payload;
      state.accountsFilters = filters;

      sendAccountFiltersStatistics(filters);
      return state;
    },
    updateSortBy: (state, action: PayloadAction<BankAccountsSortByType>) => {
      state.sortBy = action.payload;
      return state;
    },
    updateGroupBy: (state, action: PayloadAction<BankAccountsGroupByType>) => {
      state.groupBy = action.payload;
      return state;
    },

    resetAccountsFilters: (state) => {
      state.accountsFilters = {
        types: [BankAccountType.allTypes],
        institutions: [],
        balance: {
          min: '',
          max: '',
        },
      };

      return state;
    },
    handleSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      return state;
    },
    triggerBrokenAccountsModal: (state, action: PayloadAction<boolean>) => {
      state.showBrokenAccountsModal = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchBankAccounts.pending, (state) => {
        state.accounts = {
          ...state.accounts,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchBankAccounts.fulfilled, (state, action) => {
        if (!isEqual(state.accounts.data, action.payload)) {
          state.accounts = {
            ...state.accounts,
            status: ApiStatus.idle,
            data: action.payload,
          };
          state.requiresRelink = action.payload.some((account: BankAccount) => (
            !isInternalAccount(account.category) && account.lastUpdateFailed
          ));
          return;
        }

        state.accounts.status = ApiStatus.idle;
      })
      .addCase(fetchBankAccounts.rejected, (state) => {
        state.accounts = {
          ...state.accounts,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchTotalBalanceWidget.pending, (state) => {
        state.totalBalanceChart = {
          ...state.totalBalanceChart,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchTotalBalanceWidget.fulfilled, (state, action) => {
        state.totalBalanceChart = {
          ...state.totalBalanceChart,
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchTotalBalanceWidget.rejected, (state) => {
        state.totalBalanceChart = {
          ...state.totalBalanceChart,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchTransactionActivityWidget.pending, (state) => {
        state.transactionActivityWidget = {
          ...state.transactionActivityWidget,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchTransactionActivityWidget.fulfilled, (state, action) => {
        state.transactionActivityWidget = {
          ...state.transactionActivityWidget,
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchTransactionActivityWidget.rejected, (state) => {
        state.transactionActivityWidget = {
          ...state.transactionActivityWidget,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchBalanceRankingWidget.pending, (state) => {
        state.balanceRankingChart = {
          ...state.balanceRankingChart,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchBalanceRankingWidget.fulfilled, (state, action) => {
        state.balanceRankingChart = {
          ...state.balanceRankingChart,
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchBalanceRankingWidget.rejected, (state) => {
        state.balanceRankingChart = {
          ...state.balanceRankingChart,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  removeAccount,
  updateAccount,
  updateShowOnlyLinkedAccounts,
  updateFilteredAccounts,
  updateChartFilters,
  updateAccountsFilters,
  updateSortBy,
  updateGroupBy,
  resetAccountsFilters,
  handleSearch,
  triggerBrokenAccountsModal,
} = bankAccountsSlice.actions;

export default bankAccountsSlice.reducer;
