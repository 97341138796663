import { Box } from '@mui/material';
import { ReactElement, FC } from 'react';
import { Outlet } from 'react-router-dom';

import Content from 'components/_Content';
import { SimpleContent } from 'layouts/PortalLayout/index.styled';
import Sidebar from 'layouts/PortalLayout/Sidebar';
import { sidebarItems } from 'layouts/PortalLayout/Sidebar/utils';
import CustomSuspense from 'routes/CustomSuspense';

import { TABLET_WIDTH } from 'constants/general';

interface PortalLayoutProps {
  isMini?: boolean;
}

const PortalLayout:FC<PortalLayoutProps> = ({ isMini = false }): ReactElement => {
  const ContentWrapper = isMini ? SimpleContent : Content;
  return (
    <Box minWidth={TABLET_WIDTH} display="flex">
      <Sidebar sidebarItems={sidebarItems} isMini={isMini} />
      <ContentWrapper>
        <CustomSuspense>
          <Outlet />
        </CustomSuspense>
      </ContentWrapper>
    </Box>
  );
};

export default PortalLayout;
