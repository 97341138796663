import { useState } from 'react';

export interface ModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const useModal = (): ModalProps => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = (): void => {
    setIsOpen(true);
  };

  const onClose = (): void => {
    setIsOpen(false);
  };

  return { isOpen, onOpen, onClose };
};
