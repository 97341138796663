import {
  FC, ReactElement, ReactNode, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

import colorLogoIcon from 'assets/images/logo-beta.svg';
import logoTextIcon from 'assets/images/logo-text-white-beta.svg';
import logoIcon from 'assets/images/logo-white-beta.svg';
import { DASHBOARD_ROUTE } from 'constants/clientRoutes';
import { ElementSize } from 'constants/enums';

import { LogoImage, LogoWrapper } from './index.styled';

interface LogoProps {
  size?: ElementSize;
  children?: ReactNode;
  withText?: boolean;
  isColor?: boolean;
}

const Logo:FC<LogoProps> = ({
  isColor = false,
  size = ElementSize.small,
  withText = false,
  children,
}): ReactElement => {
  const navigate = useNavigate();
  const appLogo = useMemo(() => {
    if (withText) {
      return logoTextIcon;
    }
    return isColor ? colorLogoIcon : logoIcon;
  }, []);

  const handleRedirect = (): void => {
    navigate(DASHBOARD_ROUTE);
  };

  return (
    <LogoWrapper onClick={handleRedirect}>
      <LogoImage
        src={appLogo}
        withText={withText}
        alt="logoPrimaryAcct"
        size={size}
      />

      {children}
    </LogoWrapper>
  );
};

export default Logo;
