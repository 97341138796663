import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

export const ChangePassword = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'ChangePassword' */ 'modules/change-password/containers/ChangePassword')
    ),
    'ChangePassword',
  ),
);
