import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const TransactionApprovalDetails = lazy(
  () => CustomLazyLoader(
    () => (
      import(
        /* webpackChunkName: 'TransactionApprovalDetails' */
        'modules/transactions-approval-details/containers/TransactionDetails'
      )
    ),
    'TransactionApprovalDetails',
  ),
);

export default TransactionApprovalDetails;
