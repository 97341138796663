/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const UserIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 24 24">
    <path fill={color} d="M12 3C9.803 3 8 4.803 8 7s1.803 4 4 4 4-1.803 4-4-1.803-4-4-4Zm0 2c1.116 0 2 .884 2 2s-.884 2-2 2-2-.884-2-2 .884-2 2-2Zm0 9c-1.745 0-3.813.41-5.55 1.096-.87.343-1.655.75-2.284 1.273C3.537 16.892 3 17.6 3 18.5V21h18v-2.5c0-.9-.537-1.608-1.166-2.13-.629-.524-1.414-.931-2.283-1.274C15.813 14.41 13.745 14 12 14Zm0 2c1.414 0 3.346.374 4.818.955.736.29 1.359.637 1.737.951.377.315.445.529.445.594v.5H5v-.5c0-.065.068-.28.445-.594.378-.314 1-.66 1.737-.95C8.654 16.373 10.586 16 12 16Z" />
  </svg>
);

export default UserIcon;
