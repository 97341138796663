
/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const DollarIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0832 12.3673C14.4952 12.016 13.6768 11.6945 12.6288 11.4021C11.5808 11.1104 10.828 10.7858 10.3712 10.4277C9.91355 10.0711 9.68475 9.59073 9.68475 8.98822C9.68475 8.33913 9.90155 7.83207 10.3352 7.46934C10.768 7.10585 11.3936 6.92487 12.2112 6.92487C12.9944 6.92487 13.6176 7.17 14.0776 7.66102C14.5384 8.15204 14.7688 8.81411 14.7688 9.64723L14.768 9.70909H16.6656L16.6664 9.648C16.6664 8.43993 16.3456 7.48767 15.7024 6.792C15.0592 6.09709 14.1608 5.68091 13.0056 5.54574L13 5.54498V3.6H11.4V5.54804C10.2864 5.67404 9.40475 6.036 8.76235 6.64156C8.10555 7.26087 7.77675 8.05353 7.77675 9.02029C7.77675 9.96949 8.11195 10.7522 8.78235 11.3685C9.45275 11.984 10.52 12.4841 11.984 12.8675C13.036 13.1684 13.7832 13.5044 14.2272 13.8755C14.6696 14.2474 14.892 14.704 14.892 15.2462C14.892 15.8892 14.6336 16.3963 14.1184 16.7674C13.6024 17.1385 12.8944 17.3241 11.9936 17.3241C11.0728 17.3241 10.3608 17.1034 9.85915 16.6612C9.35755 16.2191 9.10715 15.5883 9.10715 14.7689H7.19995C7.19995 15.9358 7.56235 16.8598 8.28715 17.5409C8.99355 18.2045 9.96795 18.5871 11.2 18.6994V20.4H12.8V18.7124L12.82 18.7116C14.0775 18.5993 15.0559 18.2404 15.7535 17.6326C16.4503 17.0255 16.7999 16.2237 16.7999 15.2279C16.7999 14.6032 16.66 14.058 16.3824 13.5914C16.104 13.1264 15.6704 12.7178 15.0832 12.3673Z" fill={color} />
  </svg>
);

export default DollarIcon;
