/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const PlusIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3333 10.6667H13.3333V4.66667C13.3333 4.48986 13.2631 4.32029 13.1381 4.19526C13.013 4.07024 12.8435 4 12.6667 4H11.3333C11.1565 4 10.987 4.07024 10.8619 4.19526C10.7369 4.32029 10.6667 4.48986 10.6667 4.66667V10.6667H4.66667C4.48986 10.6667 4.32029 10.7369 4.19526 10.8619C4.07024 10.987 4 11.1565 4 11.3333V12.6667C4 12.8435 4.07024 13.013 4.19526 13.1381C4.32029 13.2631 4.48986 13.3333 4.66667 13.3333H10.6667V19.3333C10.6667 19.5101 10.7369 19.6797 10.8619 19.8047C10.987 19.9298 11.1565 20 11.3333 20H12.6667C12.8435 20 13.013 19.9298 13.1381 19.8047C13.2631 19.6797 13.3333 19.5101 13.3333 19.3333V13.3333H19.3333C19.5101 13.3333 19.6797 13.2631 19.8047 13.1381C19.9298 13.013 20 12.8435 20 12.6667V11.3333C20 11.1565 19.9298 10.987 19.8047 10.8619C19.6797 10.7369 19.5101 10.6667 19.3333 10.6667Z" fill={color} />
  </svg>
);

export default PlusIcon;
