import { useTheme } from '@mui/material';
import * as d3 from 'd3';
import { get } from 'lodash';
import { FC, useEffect, useRef } from 'react';

interface DonutChartProps {
  data: number[];
  colors: string[];
  width?: number;
  height?: number;
}

const DonutChart: FC<DonutChartProps> = ({
  data,
  colors,
  width = 36,
  height = 36,
}) => {
  const theme = useTheme();
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (svgRef.current) {
      d3.select(svgRef.current).selectAll('*').remove();

      const radius = Math.min(width, height) / 2;

      const svg = d3
        .select(svgRef.current)
        .attr('width', width)
        .attr('height', height)
        .style('background', 'transparent')
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`);

      const pie = d3.pie<number>().value((d) => d)(data);

      const arc = d3
        .arc<d3.PieArcDatum<number>>()
        .innerRadius(radius * 0.6)
        .outerRadius(radius);

      svg
        .selectAll('path')
        .data(pie)
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', (d, i) => get(theme.palette, colors[i]))
        .transition()
        .duration(500)
        .attrTween('d', (d) => {
          const i = d3.interpolate(d.startAngle, d.endAngle);
          return (t) => arc({ ...d, endAngle: i(t) }) || '';
        });
    }
  }, [data, colors, width, height]);

  return <svg ref={svgRef} />;
};

export default DonutChart;
