import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const RulesManagement = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'RulesManagement' */ 'modules/rules-management/containers/Rules'
    ),
    'RulesManagement',
  ),
);

export default RulesManagement;
