import { Box } from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';
import {
  Control, Controller, FieldErrors, UseFormRegister,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import InputField from 'fields/InputField';
import PhoneField from 'fields/PhoneField';

import {
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
} from 'constants/fields';
import { getTranslatedErrors } from 'utils/errorUtils';
import { ContactFormValues } from 'utils/validation/contactFormSchema';

interface AddContactFormProps {
  register: UseFormRegister<ContactFormValues>;
  errors: Partial<FieldErrors<ContactFormValues>>;
  control: Control<ContactFormValues>;
}

const AddContactForm: FC<AddContactFormProps> = ({
  register,
  errors,
  control,
}) => {
  const intl = useIntl();
  const translatedErrors = getTranslatedErrors(errors);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <InputField
        {...FIRST_NAME}
        required
        label={intl.formatMessage({ id: 'label.firstName' })}
        slotProps={{
          htmlInput: register(FIRST_NAME.name),
        }}
        error={!!translatedErrors?.[FIRST_NAME.name]?.message}
        helperText={translatedErrors?.[FIRST_NAME.name]?.message as ReactNode}
      />

      <InputField
        {...LAST_NAME}
        required
        label={intl.formatMessage({ id: 'label.lastName' })}
        slotProps={{
          htmlInput: register(LAST_NAME.name),
        }}
        error={!!translatedErrors?.[LAST_NAME.name]?.message}
        helperText={translatedErrors?.[LAST_NAME.name]?.message as ReactNode}
      />

      <Controller
        name={PHONE_NUMBER.name}
        control={control}
        render={({ field }): ReactElement => (
          <PhoneField
            {...PHONE_NUMBER}
            label={intl.formatMessage({ id: 'label.phoneNumber' })}
            required
            inputProps={register(PHONE_NUMBER.name)}
            phoneNumber={field.value || ''}
            error={!!errors[PHONE_NUMBER.name]?.message}
            helperText={translatedErrors[PHONE_NUMBER.name]?.message as ReactNode}
          />
        )}
      />
    </Box>
  );
};

export default AddContactForm;
