/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const UploadIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4C11.895 4.00022 11.7911 4.02117 11.6941 4.06166C11.5972 4.10214 11.5092 4.16136 11.4352 4.23594L7.33477 8.10779C7.33161 8.11035 7.32849 8.11295 7.32541 8.1156L7.32385 8.11717C7.24894 8.19217 7.20684 8.2939 7.20683 8.39998C7.20683 8.50606 7.24891 8.60781 7.32382 8.68282C7.39873 8.75783 7.50032 8.79998 7.60626 8.79998H10.4023V15.1999C10.4023 15.6415 10.7602 15.9999 11.2011 15.9999H12H12.7989C13.2398 15.9999 13.5977 15.6415 13.5977 15.1999V8.79998H16.3937C16.4997 8.79998 16.6013 8.75783 16.6762 8.68282C16.7511 8.60781 16.7932 8.50606 16.7932 8.39998C16.7932 8.2939 16.7511 8.19217 16.6762 8.11717L16.6512 8.09373L12.5726 4.24219C12.564 4.23365 12.5551 4.22532 12.5461 4.21719C12.3982 4.07795 12.203 4.00029 12 4ZM4.81024 18.3999C4.70438 18.3984 4.59928 18.418 4.50105 18.4575C4.40281 18.4971 4.3134 18.5558 4.23801 18.6302C4.16262 18.7046 4.10276 18.7933 4.0619 18.8911C4.02104 18.9889 4 19.0939 4 19.1999C4 19.3059 4.02104 19.4109 4.0619 19.5087C4.10276 19.6065 4.16262 19.6952 4.23801 19.7696C4.3134 19.8441 4.40281 19.9028 4.50105 19.9423C4.59928 19.9818 4.70438 20.0014 4.81024 19.9999H19.1898C19.2956 20.0014 19.4007 19.9818 19.499 19.9423C19.5972 19.9028 19.6866 19.8441 19.762 19.7696C19.8374 19.6952 19.8972 19.6065 19.9381 19.5087C19.979 19.4109 20 19.3059 20 19.1999C20 19.0939 19.979 18.9889 19.9381 18.8911C19.8972 18.7933 19.8374 18.7046 19.762 18.6302C19.6866 18.5558 19.5972 18.4971 19.499 18.4575C19.4007 18.418 19.2956 18.3984 19.1898 18.3999H4.81024Z" fill={color} />
  </svg>
);

export default UploadIcon;
