import {
  Box, Divider, Skeleton, Typography,
} from '@mui/material';

import CompanyInitial from 'components/CompanyInitial';

import { useAppSelector } from 'hooks/useRedux';
import { currentWorkspaceSelector } from 'store/user/selectors';

const CompanyNameAsTitle = () => {
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const companyName = currentWorkspace?.name;

  return (
    <Box mb={5}>
      <Box display="flex" alignItems="center" px={5} pb={7}>
        {companyName && (
          <CompanyInitial initial={companyName[0]} />
        )}

        {companyName
          ? (
            <Typography variant="h1">
              {companyName}
            </Typography>
          ) : (
            <Skeleton animation="wave" variant="rectangular" height={24} width={300} />
          )}
      </Box>
      <Divider />
    </Box>
  );
};

export default CompanyNameAsTitle;
