import {
  FormLabel, Grid2 as Grid, Tooltip, Typography, Box,
} from '@mui/material';
import { isEqual } from 'lodash';
import {
  Fragment, FC, useEffect, useState, useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import CustomButton from 'components/CustomButton';
import { IconButton } from 'components/GenericModal/index.styled';
import { Root } from 'containers/AdvancedAccountFilter/AccountsFilter/index.styled';
import AccountDropdownField from 'fields/AccountDropdownField';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { fetchBankAccounts } from 'store/bank-accounts/bankAccountsSlice';
import { accountsSelector } from 'store/bank-accounts/selectors';
import { currentWorkspaceSelector } from 'store/user/selectors';

import DeleteIcon from 'assets/icons/DeleteIcon';
import PlusIcon from 'assets/icons/PlusIcon';
import { DefaultType } from 'constants/enums';
import { GenericOption } from 'models/option.interface';
import { formatAccountSearchLabel, formatAcctNumberNameAndType } from 'utils/formatters';
import { capitalize } from 'utils/text';

interface AccountsFilterProps {
  selectedAccounts: GenericOption[];
  setSelectedAccounts: any; // TODO: define the type after we decide if it would be kept on state or react-form
}

const AccountsFilter:FC<AccountsFilterProps> = ({ selectedAccounts, setSelectedAccounts }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { data: bankAccounts } = useAppSelector(accountsSelector, isEqual);
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const filterIds = selectedAccounts?.map((item) => item?.value).filter((id) => id);
  const [accountOptions, setAccountOptions] = useState<GenericOption[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const availableOptions = useMemo(() => (
    accountOptions?.filter(({ value }) => !filterIds.includes(value))
  ), [filterIds]);

  useEffect(() => {
    if (bankAccounts && bankAccounts.length > 0) {
      const generatedOptions = bankAccounts?.map((acc) => ({
        label: formatAccountSearchLabel(acc),
        formattedLabel: formatAcctNumberNameAndType(acc),
        value: acc?.id,
      }));
      generatedOptions.unshift({
        label: intl.formatMessage({ id: 'label.all' }),
        formattedLabel: intl.formatMessage({ id: 'label.all' }),
        value: DefaultType.all,
      });
      setAccountOptions(generatedOptions as GenericOption[]);
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (!bankAccounts && currentWorkspace?.id) {
      dispatch(fetchBankAccounts({ workspaceId: currentWorkspace.id }));
    }
  }, []);

  const handleChangeAutocomplete = (value: GenericOption, accountIndex: number) => {
    if (value.value === DefaultType.all) {
      setIsAllSelected(true);
    } else if (selectedAccounts[accountIndex]?.value === DefaultType.all) {
      setIsAllSelected(false);
    }

    setSelectedAccounts((prevState: any) => {
      prevState.splice(accountIndex, 1, value);
      return [...prevState];
    });
  };

  const handleDelete = (accountIndex: number) => {
    if (selectedAccounts[accountIndex]?.value === DefaultType.all) {
      setIsAllSelected(false);
    }

    setSelectedAccounts((prevState: any) => {
      prevState.splice(accountIndex, 1);
      return [...prevState];
    });
  };

  const addEmptyOption = () => {
    if (!selectedAccounts?.length || selectedAccounts[selectedAccounts.length - 1]) {
      setSelectedAccounts((prevState: any) => ([...prevState, null]));
    }
  };

  const disableAddButton = !availableOptions?.length || isAllSelected;

  return (
    <Root>
      <FormLabel required sx={{ display: 'flex', mb: 2 }}>
        <Typography variant="subtitle1">
          {capitalize(intl.formatMessage({ id: 'label.account' }))}
        </Typography>
      </FormLabel>
      {selectedAccounts?.length > 0 && (
        <Grid container spacing={3} sx={{ mb: 3 }}>
          {selectedAccounts.map((account, index) => (
            <Fragment key={index}>
              <Grid size={{ xs: 11, lg: 11.5 }}>
                <AccountDropdownField
                  disabled={isAllSelected && account?.value !== DefaultType.all}
                  options={availableOptions}
                  onSelect={(value) => handleChangeAutocomplete(value, index)}
                  selectedOption={account}
                  searchPlaceholder="placeholder.searchAccountByName"
                  placeholder="placeholder.selectBankAccount"
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid size={{ xs: 1, lg: 0.5 }}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <Tooltip
                    arrow
                    id="delete-account"
                    title={intl.formatMessage({ id: 'button.delete' })}
                  >
                    <IconButton
                      disabled={isAllSelected && account?.value !== DefaultType.all}
                      color="error"
                      onClick={() => handleDelete(index)}
                      aria-label="Delete Account"
                      id="iconButton-deleteAccount"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      )}

      <CustomButton
        disabled={disableAddButton}
        fullWidth
        icon={<PlusIcon />}
        label=""
        className="addButton"
        id="button-add"
        onClick={addEmptyOption}
      />
    </Root>
  );
};

export default AccountsFilter;
