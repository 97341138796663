import { styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')<{
    hasError?: boolean;
    fullWidth: boolean;
    variant?: string;
  }>(({
    theme, hasError, fullWidth, variant,
  }) => ({
    '& .MuiSelect-icon': {
      right: '10px',

      '& path': {
        fill: theme.palette.text.primary,
      },
    },

    '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root': {
      color: theme.palette.text.primary,
    },

    ...(variant !== 'outlined' ? {
      '& .Mui-focused': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    } : {}),

    '& .MuiFormLabel-root': {
      border: 0,
    },

    ...(hasError ? {
      '& .MuiFormHelperText-root': {
        color: theme.palette.error.main,
        marginTop: theme.spacing(1),
        border: 0,
      },
      '& .MuiOutlinedInput-notchedOutline, .MuiFilledInput-root': {
        borderColor: theme.palette.error.main,
      },
    } : {}),

    width: fullWidth ? '100%' : 'initial',
  }));
