import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getCompanyBeneficiary, getCompanyDetails, getCompanyDocuments, getKybApplication, getKybDetails,
} from 'services/KybService';
import { resetStore } from 'store/actions';

import { ApiStatus, KybSteps } from 'constants/enums';
import { Beneficiary, Company, KybDetails } from 'models/company.interface';
import { Document } from 'models/document.interface';
import { IdentityVerificationApplication } from 'models/identityApplication.interface';
import { handleApiErrors } from 'utils/errorUtils';

export interface KybState {
  activeKybStep: KybSteps;
  companyDetails: {
    data: Company | null;
    status: ApiStatus;
  };
  companyBeneficiary: {
    data: Beneficiary[] | null;
    status: ApiStatus;
  };
  documents: {
    data: Document[];
    status: ApiStatus;
  };
  identityApplication: {
    data: IdentityVerificationApplication;
    status: ApiStatus;
  };
  kybDetails: {
    data: KybDetails;
    status: ApiStatus;
  };
}

const initialState: KybState = {
  activeKybStep: KybSteps.companyDetails,
  companyDetails: {
    data: null,
    status: ApiStatus.idle,
  },
  companyBeneficiary: {
    data: null,
    status: ApiStatus.idle,
  },
  documents: {
    data: [],
    status: ApiStatus.idle,
  },
  identityApplication: {
    data: {} as IdentityVerificationApplication,
    status: ApiStatus.idle,
  },
  kybDetails: {
    data: {} as KybDetails,
    status: ApiStatus.idle,
  },
};

export const fetchCompanyDetails = createAsyncThunk(
  'kyb/fetchCompanyDetails',
  async (customerId: string, thunkAPI) => {
    try {
      const response = await getCompanyDetails(customerId);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchKybDetails = createAsyncThunk(
  'kyb/fetchKybDetails',
  async (customerId: string, thunkAPI) => {
    try {
      const response = await getKybDetails(customerId);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchIdentityApplication = createAsyncThunk(
  'kyb/fetchIdentityApplication',
  async (customerId: string, thunkAPI) => {
    try {
      const response = await getKybApplication(customerId);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchCompanyDocuments = createAsyncThunk(
  'kyb/fetchCompanyDocuments',
  async (customerId: string, thunkAPI) => {
    try {
      const response = await getCompanyDocuments(customerId);
      return response.data || [];
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchCompanyBeneficiary = createAsyncThunk(
  'kyb/fetchCompanyBeneficiary',
  async (customerId: string, thunkAPI) => {
    try {
      const response = await getCompanyBeneficiary(customerId);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const kybSlice = createSlice({
  name: 'kyb',
  initialState,
  reducers: {
    resetState: (state) => ({
      ...initialState,
      identityApplication: state.identityApplication,
    }),
    setActiveKybStep: (state, action: PayloadAction<KybSteps>) => {
      state.activeKybStep = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchCompanyDetails.pending, (state) => {
        state.companyDetails = {
          ...state.companyDetails,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchCompanyDetails.fulfilled, (state, action) => {
        state.companyDetails = {
          ...state.companyDetails,
          status: ApiStatus.idle,
          data: action.payload,
        };
      })
      .addCase(fetchCompanyDetails.rejected, (state) => {
        state.companyDetails = {
          ...state.companyDetails,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchCompanyBeneficiary.pending, (state) => {
        state.companyBeneficiary = {
          ...state.companyBeneficiary,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchCompanyBeneficiary.fulfilled, (state, action) => {
        state.companyBeneficiary = {
          ...state.companyBeneficiary,
          status: ApiStatus.idle,
          data: action.payload,
        };
      })
      .addCase(fetchCompanyBeneficiary.rejected, (state) => {
        state.companyBeneficiary = {
          ...state.companyBeneficiary,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchCompanyDocuments.pending, (state) => {
        state.documents = {
          ...state.documents,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchCompanyDocuments.fulfilled, (state, action) => {
        state.documents = {
          ...state.documents,
          status: ApiStatus.idle,
          data: action.payload,
        };
      })
      .addCase(fetchCompanyDocuments.rejected, (state) => {
        state.documents = {
          ...state.documents,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchIdentityApplication.pending, (state) => {
        state.identityApplication = {
          ...state.identityApplication,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchIdentityApplication.fulfilled, (state, action) => {
        state.identityApplication = {
          ...state.identityApplication,
          status: ApiStatus.idle,
          data: action.payload,
        };
      })
      .addCase(fetchIdentityApplication.rejected, (state) => {
        state.identityApplication = {
          ...state.identityApplication,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchKybDetails.pending, (state) => {
        state.kybDetails = {
          ...state.kybDetails,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchKybDetails.fulfilled, (state, action) => {
        state.kybDetails = {
          ...state.kybDetails,
          status: ApiStatus.idle,
          data: action.payload,
        };
      })
      .addCase(fetchKybDetails.rejected, (state) => {
        state.kybDetails = {
          ...state.kybDetails,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  resetState,
  setActiveKybStep,
} = kybSlice.actions;

export default kybSlice.reducer;
