import { ReactNode } from 'react';

const CustomLazyLoader = (lazyComponent: any, name: string, attemptsLeft = 3): Promise<any> => (
  new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
    );

    lazyComponent()
      .then((component: ReactNode) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');
        resolve(component);
      })
      .catch((error: any) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            if (!hasRefreshed) {
              window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
              window.location.reload();
            } else {
              reject(error);
            }
          } else {
            CustomLazyLoader(lazyComponent, name, attemptsLeft - 1)
              .then(resolve, reject);
          }
        }, 1500);
      });
  })
);

export default CustomLazyLoader;
