import {
  Box, Container, Typography,
} from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';

import CompanyInReview from 'assets/images/company-in-review.svg';
import CompanyRejected from 'assets/images/company-rejected.svg';
import { COMPANY_PROFILE_ROUTE, DASHBOARD_ROUTE } from 'constants/clientRoutes';
import { KybCoverType } from 'constants/enums';

import { CardMedia } from './index.style';

interface KybReviewCoverProps {
  kybCoverType: KybCoverType;
  contentLabel?: string;
  seeDetailsLabel?: string;
  onClose?: () => void;
}

const KybReviewCover: FC<KybReviewCoverProps> = ({
  kybCoverType,
  seeDetailsLabel = 'button.viewCompanyProfile',
  contentLabel = 'label.companyProfileNeedsToBeApprovedMoveMoney',
  onClose,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const title = kybCoverType === KybCoverType.inReview
    ? 'label.companyProfileUnderReview'
    : 'label.yourCompanyProfileIsRejected';

  const newLine = () => <br />;

  const seeApplicationDetails = () => navigate(COMPANY_PROFILE_ROUTE);

  const seeDashboard = () => {
    navigate(DASHBOARD_ROUTE);

    if (onClose) {
      onClose();
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={10} sx={{ mt: 15, mb: 10 }}>
      <CardMedia
        component="img"
        src={kybCoverType === KybCoverType.inReview ? CompanyInReview : CompanyRejected}
        alt="Company Cover"
      />

      <Container sx={{ width: '400px' }}>
        <Typography variant="h2" textAlign="center" mb={3}>
          {intl.formatMessage(
            { id: title },
            { br: newLine },
          )}
        </Typography>
        <Typography mb={10} textAlign="center" color="general.darkGrey1">
          {intl.formatMessage({ id: contentLabel })}
        </Typography>

        <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
          <CustomButton
            variant="text"
            label={intl.formatMessage({ id: 'button.returnToDashboard' })}
            id="button-returnToDashboard"
            onClick={seeDashboard}
          />
          <CustomButton
            label={intl.formatMessage({ id: seeDetailsLabel })}
            id="button-seeDetails"
            onClick={seeApplicationDetails}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default KybReviewCover;
