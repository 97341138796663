import {
  Box, FormLabel, Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import {
  FC, ReactElement, useMemo,
  useState,
} from 'react';
import {
  Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import AutocompleteMultipleField from 'fields/AutocompleteMultipleField';
import AddContact from 'modules/create-rule/containers/AddContact';

import { useAppSelector } from 'hooks/useRedux';
import { contactsSelector } from 'store/contacts/selectors';

import CloseIcon from 'assets/icons/CloseIcon';
import PlusIcon from 'assets/icons/PlusIcon';
import { Chip } from 'assets/themes/styled/AddChip';
import { ALERT_RECIPIENTS } from 'constants/fields';
import { Contact, RuleContact } from 'models/contact.interface';
import { User } from 'models/user.interface';
import { extractInfoFromContact } from 'modules/create-rule/utils';
import { formatPhoneNumber } from 'utils/formatters';
import { RuleFormValues } from 'utils/validation/ruleFormSchema';

interface RecipientsSectionProps {
  onSetValue: UseFormSetValue<RuleFormValues>;
  watch: UseFormWatch<RuleFormValues>;
  control: Control<RuleFormValues>;
  errors: Partial<FieldErrors<RuleFormValues>>;
  isReadOnly: boolean;
}

export const RecipientsSection: FC<RecipientsSectionProps> = ({
  onSetValue,
  watch,
  control,
  errors,
  isReadOnly,
}) => {
  const intl = useIntl();
  const contacts = useAppSelector(contactsSelector);
  const recipients = watch(ALERT_RECIPIENTS.name) as RuleContact[];
  const [addedContacts, setAddedContacts] = useState<RuleContact[]>([]);

  const filteredContacts = useMemo(() => (
    contacts
      ? contacts
        .filter((contact) => contact.phoneNumber)
        .map((contact) => extractInfoFromContact(contact))
      : []
  ), [contacts]);

  const ruleContacts = useMemo(() => (
    addedContacts?.length > 0 ? [...addedContacts, ...filteredContacts] : filteredContacts
  ), [addedContacts, filteredContacts]);

  const handleChangeAutocomplete = (newRecipients: RuleContact[]) => {
    onSetValue(ALERT_RECIPIENTS.name, newRecipients, { shouldValidate: true });
  };

  const handleRemoveRecipient = (recipient: RuleContact) => {
    const filteredRecipients = recipients.filter((r) => r.id !== recipient.id);
    onSetValue(ALERT_RECIPIENTS.name, filteredRecipients, { shouldValidate: true });
  };

  const handleAddContact = (contact: Contact) => {
    const newContacts = [...addedContacts];
    const ruleContact = extractInfoFromContact(contact);
    newContacts.push(ruleContact);
    setAddedContacts(newContacts);
    onSetValue(ALERT_RECIPIENTS.name, [ruleContact, ...recipients], { shouldValidate: true });
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <FormLabel required sx={{ display: 'flex' }}>
          <Typography variant="subtitle1">
            {intl.formatMessage({ id: 'label.to' })}
          </Typography>
        </FormLabel>
        <AddContact onSuccess={handleAddContact} />
      </Box>

      <Controller
        name={ALERT_RECIPIENTS.name}
        control={control}
        render={({ field }): ReactElement => (
          <AutocompleteMultipleField
            {...ALERT_RECIPIENTS}
            filterValues
            isOptionEqualToValue={(option, value) => isEqual(option, value)}
            options={ruleContacts}
            values={field?.value as RuleContact[] || []}
            onChange={handleChangeAutocomplete}
            renderOptionComponent={(user: User) => (
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" px={4} py={3}>
                <Box display="flex" alignItems="center" gap={4}>
                  {user.name && (
                    <Typography variant="body1">
                      {user.name}
                    </Typography>
                  )}
                  <Typography variant="body1" color={user.name ? 'general.darkGrey1' : 'textPrimary'}>
                    {formatPhoneNumber(user.phoneNumber)}
                  </Typography>
                </Box>
                <PlusIcon size={16} />
              </Box>
            )}
            renderTagsComponent={(user: User) => (
              <Chip
                key={user.id}
                label={user.name || formatPhoneNumber(user.phoneNumber)}
                onDelete={() => handleRemoveRecipient(user)}
                deleteIcon={(
                  <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
                    <CloseIcon size={20} />
                  </Box>
                )}
              />
            )}
            getOptionLabel={(user: User) => user.name || formatPhoneNumber(user.phoneNumber) || ''}
            errorMessage={errors[ALERT_RECIPIENTS.name]?.message
              ? intl.formatMessage({ id: errors[ALERT_RECIPIENTS.name]?.message })
              : ''}
            disabled={isReadOnly}
          />
        )}
      />
    </>
  );
};

export default RecipientsSection;
