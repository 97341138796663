import { Grid2 as Grid, Skeleton, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface GridInformationLabelProps {
  label?: any;
  value?: string | number;
  isPlaceholder?: boolean;
  children?: ReactNode[] | ReactNode;
  isLoading?: boolean;
}

const GridInformationLabel: FC<GridInformationLabelProps> = ({
  label,
  value,
  children,
  isPlaceholder = false,
  isLoading = false,
}) => (
  <>
    <Grid size={{ xs: 5, lg: 4 }}>
      <Typography variant="body1" color="textSecondary">
        {label}
      </Typography>
    </Grid>

    <Grid size={{ xs: 6, lg: 7 }}>
      {isLoading
        ? <Skeleton animation="wave" variant="text" height={21} width={200} />
        : (
          <>
            {value && (
              <Typography
                variant="body1"
                fontWeight={600}
                className="text-ellipsis"
                color={isPlaceholder ? 'general.lightGrey6' : 'textPrimary'}
              >
                {value}
              </Typography>
            )}

            {children}
          </>
        )}
    </Grid>
  </>
);

export default GridInformationLabel;
