/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SearchFileIcon = ({ fill = '#111111', size = 12 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.73268 0C1.14442 0 0.666016 0.489273 0.666016 1.09091V9.81818C0.666016 10.4198 1.14442 10.9091 1.73268 10.9091H5.07122C4.95389 10.7373 4.85196 10.5545 4.76289 10.3636H4.76393C4.7618 10.3593 4.75982 10.3547 4.75768 10.3498C4.68942 10.2014 4.63228 10.0477 4.58268 9.88956C4.46695 9.52301 4.39935 9.13364 4.39935 8.72727C4.39935 6.61855 6.07082 4.90909 8.13268 4.90909C8.31402 4.90909 8.49162 4.92713 8.66602 4.95277V4.94957C8.84842 4.97685 9.02655 5.01759 9.19935 5.06996V3.27273L5.99935 0H1.73268ZM5.46602 0.793679L8.39935 3.81818H5.46602V0.793679ZM8.13268 6C6.63935 6 5.46602 7.2 5.46602 8.72727C5.46602 10.2545 6.63935 11.4545 8.13268 11.4545C8.66602 11.4545 9.19977 11.2905 9.62643 11.0178L10.5858 12L11.3327 11.2362L10.3723 10.255C10.6389 9.81861 10.7993 9.27273 10.7993 8.72727C10.7993 7.2 9.62601 6 8.13268 6ZM8.13268 7.09091C9.03935 7.09091 9.73268 7.8 9.73268 8.72727C9.73268 9.65455 9.03935 10.3636 8.13268 10.3636C7.22602 10.3636 6.53268 9.65455 6.53268 8.72727C6.53268 7.8 7.22602 7.09091 8.13268 7.09091Z" fill={fill} />
  </svg>
);

export default SearchFileIcon;
