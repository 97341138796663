import {
  Box, FormLabel, Typography,
} from '@mui/material';
import {
  FC, ReactElement, useMemo,
} from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { GroupButton } from 'components/GroupButton';
import DropdownField from 'fields/DropdownField';
import SearchableDropdown from 'fields/SearchableDropdown';

import { RuleMatch, RuleTimeframeType } from 'constants/enums';
import {
  ALERT_TIME_FROM, ALERT_TIME_TO, ALERT_TIMEFRAME, ALERT_TIMEZONE,
} from 'constants/fields';
import { HOURS_24_OPTIONS } from 'constants/options';
import { getManipulatedTimezones } from 'modules/create-rule/utils';
import { RuleFormValues } from 'utils/validation/ruleFormSchema';

import { Root, FormHelperText } from './index.styled';

interface RuleTimeframeProps {
  ruleType: RuleMatch;
  onSetValue: UseFormSetValue<RuleFormValues>;
  control: Control<RuleFormValues>;
  watch: UseFormWatch<RuleFormValues>;
  translatedErrors: Partial<FieldErrors<RuleFormValues>>;
  register: UseFormRegister<RuleFormValues>;
  isReadOnly: boolean;
  isVisible: boolean;
}

const RuleTimeframe: FC<RuleTimeframeProps> = ({
  ruleType,
  onSetValue,
  control,
  watch,
  translatedErrors,
  register,
  isReadOnly,
  isVisible,
}) => {
  const intl = useIntl();
  const timeframe = watch(ALERT_TIMEFRAME.name);
  const timeframeStart = watch(ALERT_TIME_FROM.name);
  const timezone = watch(ALERT_TIMEZONE.name) as string;
  const isMatch = ruleType === RuleMatch.match;
  const hasError = !!translatedErrors?.[ALERT_TIME_FROM.name]?.message;
  const timezonesAsOptions = useMemo(() => getManipulatedTimezones(), []);

  const handleChangeGroup = () => {
    const isAnyTime = timeframe === RuleTimeframeType.anyTime;
    const newTimeframe = isAnyTime
      ? RuleTimeframeType.customTime
      : RuleTimeframeType.anyTime;
    onSetValue(ALERT_TIMEFRAME.name, newTimeframe);

    if (!isAnyTime) {
      onSetValue(ALERT_TIME_FROM.name, timeframeStart, { shouldValidate: true });
    }
  };

  const matchingConfig = useMemo(() => ([
    {
      label: intl.formatMessage({ id: 'label.anyTime' }),
      onClick: handleChangeGroup,
      id: RuleTimeframeType.anyTime,
      isActive: timeframe === RuleTimeframeType.anyTime,
    },
    {
      label: intl.formatMessage({ id: 'label.customTime' }),
      onClick: handleChangeGroup,
      id: RuleTimeframeType.customTime,
      isActive: timeframe === RuleTimeframeType.customTime,
    },
  ]), [timeframe]);

  const handleChange = ({ target: { name, value } }: any) => {
    onSetValue(name, value);
    onSetValue(ALERT_TIME_FROM.name, timeframeStart, { shouldValidate: true });
  };

  const handleSelectTimezone = (value: string) => {
    onSetValue(ALERT_TIMEZONE.name, value, { shouldValidate: true });
  };

  return (
    <Root sx={{ display: isVisible ? 'initial' : 'none' }}>
      {isMatch && <GroupButton buttonsConfig={matchingConfig} disabled={isReadOnly} />}

      <Box sx={{ display: timeframe === RuleTimeframeType.customTime || !isMatch ? 'initial' : 'none' }}>
        <Box my={3} gap={2} display="flex" alignItems="center">
          <FormLabel required sx={{ whiteSpace: 'nowrap', display: 'flex' }}>
            <Typography variant="body2">
              {intl.formatMessage({ id: 'label.happensBetween' })}
            </Typography>
          </FormLabel>
          <Controller
            name={ALERT_TIME_FROM.name}
            control={control}
            render={({ field }): ReactElement => (
              <DropdownField
                name={ALERT_TIME_FROM.name}
                value={field?.value}
                options={HOURS_24_OPTIONS}
                inputProps={register(ALERT_TIME_FROM.name)}
                id="select-timeframe-from"
                placeholder={intl.formatMessage({ id: 'label.hr' })}
                displayEmpty
                error={hasError}
                disabled={isReadOnly}
                sx={{ minWidth: '155px' }}
              />
            )}
          />
          <Typography variant="body2" textTransform="lowercase">
            {intl.formatMessage({ id: 'label.to' })}
          </Typography>
          <Controller
            name={ALERT_TIME_TO.name}
            control={control}
            render={({ field }): ReactElement => (
              <DropdownField
                name={ALERT_TIME_TO.name}
                inputProps={{
                  ...register(ALERT_TIME_TO.name),
                  onChange: handleChange,
                }}
                value={field?.value}
                options={HOURS_24_OPTIONS}
                id="select-timeframe-to"
                placeholder={intl.formatMessage({ id: 'label.hr' })}
                displayEmpty
                error={hasError}
                disabled={isReadOnly}
                sx={{ minWidth: '155px' }}
              />
            )}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <SearchableDropdown
            fullWidth
            maxWidth="340px"
            options={timezonesAsOptions}
            onSelect={handleSelectTimezone}
            value={timezone}
            placeholder={intl.formatMessage({ id: 'placeholder.timezone' })}
            searchPlaceholder="placeholder.searchByLocation"
          />
        </Box>
      </Box>

      {hasError && (
        <FormHelperText hasError={hasError}>
          {translatedErrors?.[ALERT_TIME_FROM.name]?.message}
        </FormHelperText>
      )}
    </Root>
  );
};

export default RuleTimeframe;
