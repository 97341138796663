/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const BankIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2.5L3 6.5V9H5V17H7V9H9V17H11V9H13V17H15V9H17V17H19V9H21V6.5L12 2.5ZM3 19V21H21V19H3Z" fill={color} />
  </svg>
);

export default BankIcon;
