import { z } from 'zod';

import { AuthorityType } from 'constants/enums';
import { ALLOWED_BANK_ACCOUNTS, EMAIL_ADDRESS, USER_ROLE } from 'constants/fields';
import { REGEX_EMAIL } from 'constants/regex';
import { checkValidPhone } from 'utils/validation/customRules/validations';

export const memberFormSchema = z.object({
  firstName: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    })
    .max(100, {
      message: 'error.userNameMaxLength',
    })
    .nullable(),
  lastName: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    })
    .max(100, {
      message: 'error.userNameMaxLength',
    })
    .nullable(),
  email: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    })
    .regex(REGEX_EMAIL, { message: 'error.invalidEmail' }),
  transactionLimit: z
    .string(),
  phoneNumber: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    })
    .refine(checkValidPhone, {
      message: 'error.phoneNumber',
    })
    .nullable(),
  authority: z
    .enum([
      AuthorityType.userOwner,
      AuthorityType.userAdmin,
      AuthorityType.userManager,
      AuthorityType.userViewer,
      AuthorityType.opsAdmin,
      AuthorityType.opsManager,
      AuthorityType.opsViewer,
    ], {
      required_error: 'error.mandatoryField',
    }),
  amOwner: z
    .boolean(),
  isMe: z
    .boolean(),
  transferOwnerTo: z
    .string(),
  bankAccounts: z
    .array(z.any())
  ,
})
  .refine(
    ({
      amOwner,
      isMe,
      authority,
      transferOwnerTo,
    }) => {
      const isTransferOfOwnership = isMe && amOwner && authority !== AuthorityType.userOwner;

      if (!isTransferOfOwnership) {
        return true;
      }

      return !(isTransferOfOwnership && !transferOwnerTo);
    },
    {
      message: 'error.mandatoryField',
      path: ['transferOwnerTo'],
    },
  )
  .refine(
    ({ authority, bankAccounts }) => {
      if (authority === AuthorityType.userOwner) {
        return true;
      }
      return !!bankAccounts?.length;
    },
    {
      message: 'error.atLeastOneAccount',
      path: ['bankAccounts'],
    },
  );

export const addMemberFormSchema = z.object({
  [EMAIL_ADDRESS.name]: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    })
    .regex(REGEX_EMAIL, { message: 'error.invalidEmail' }),
  [USER_ROLE.name]: z
    .enum([
      AuthorityType.userOwner,
      AuthorityType.userAdmin,
      AuthorityType.userManager,
      AuthorityType.userViewer,
    ], {
      errorMap: () => ({ message: 'error.mandatoryField' }),
    }),
  [ALLOWED_BANK_ACCOUNTS.name]: z
    .array(z.any())
    .min(1, {
      message: 'error.atLeastOneAccount',
    }),
});

export const transferOwnershipSchema = z.object({
  transferOwnerTo: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    }),
});

export type MemberFormValues = z.infer<typeof memberFormSchema>
export type AddMemberFormValues = z.infer<typeof addMemberFormSchema>
export type TransferOwnershipFormValues = z.infer<typeof transferOwnershipSchema>
