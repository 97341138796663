/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const DoubleLeftIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1727 19.8739L12.8768 19.1582C12.9558 19.0763 13 18.9663 13 18.8516C13 18.737 12.9558 18.6269 12.8768 18.545L6.45099 11.9996L12.8768 5.4541C12.9558 5.37222 13 5.26214 13 5.1475C13 5.03285 12.9558 4.92277 12.8768 4.8409L12.1727 4.12521C12.0921 4.04495 11.9838 4 11.8711 4C11.7583 4 11.65 4.04495 11.5694 4.12521L4.12318 11.6938C4.04423 11.7757 4 11.8858 4 12.0004C4 12.1151 4.04423 12.2252 4.12318 12.307L11.5694 19.8757C11.6502 19.9557 11.7586 20.0003 11.8714 20C11.9842 19.9997 12.0924 19.9544 12.1727 19.8739Z" fill={color} />
    <path d="M18.1727 19.8739L18.8768 19.1582C18.9558 19.0763 19 18.9663 19 18.8516C19 18.737 18.9558 18.6269 18.8768 18.545L12.451 11.9996L18.8768 5.4541C18.9558 5.37222 19 5.26214 19 5.1475C19 5.03285 18.9558 4.92277 18.8768 4.8409L18.1727 4.12521C18.0921 4.04495 17.9838 4 17.8711 4C17.7583 4 17.65 4.04495 17.5694 4.12521L10.1232 11.6938C10.0442 11.7757 10 11.8858 10 12.0004C10 12.1151 10.0442 12.2252 10.1232 12.307L17.5694 19.8757C17.6502 19.9557 17.7586 20.0003 17.8714 20C17.9842 19.9997 18.0924 19.9544 18.1727 19.8739Z" fill={color} />
  </svg>
);

export default DoubleLeftIcon;
