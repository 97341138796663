import { Box, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import PrivacyPolicy from 'components/_PrivacyPolicy';
import TermsAndConditions from 'components/_TermsAndConditions';
import GenericModal from 'components/GenericModal';

import { useModal } from 'hooks/useModal';

enum DialogType {
  TOS = 'TOS',
  PP = 'PP',
}

const Footer = () => {
  const intl = useIntl();
  const { isOpen, onOpen, onClose } = useModal();
  const [privacyType, setPrivacyType] = useState<DialogType>(DialogType.PP);

  const modalTitle = useMemo(() => (
    intl.formatMessage({
      id: privacyType === DialogType.TOS
        ? 'modalTitle.termsAndConditions'
        : 'modalTitle.privacyPolicy',
    })
  ), [privacyType]);

  const handleOpenModal = (newPrivacyType: DialogType) => {
    setPrivacyType(newPrivacyType);
    onOpen();
  };

  return (
    <Box pb={5} zIndex={1}>
      <Box display="flex" alignItems="center" gap={4} justifyContent="center" my={3}>
        <Typography
          variant="body3"
          color="common.white"
          className="pointer"
          onClick={() => handleOpenModal(DialogType.PP)}
        >
          {intl.formatMessage({ id: 'label.privacyPolicy' })}
        </Typography>
        <Typography
          variant="body3"
          color="common.white"
          className="pointer"
          onClick={() => handleOpenModal(DialogType.TOS)}
        >
          {intl.formatMessage({ id: 'label.terms' })}
        </Typography>
      </Box>

      <Typography variant="body3" sx={{ opacity: '50%' }} color="common.white">
        © 2023 PrimaryAccount, Inc. All rights reserved.
      </Typography>

      {isOpen && (
        <GenericModal
          id="terms-and-privacy"
          hideCancelButton
          hasActions
          hasTopDivider
          hasBottomDivider
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onClose}
          title={modalTitle}
          confirmLabel={intl.formatMessage({ id: 'button.ok' })}
        >
          {privacyType === DialogType.TOS
            ? <TermsAndConditions />
            : <PrivacyPolicy />}
        </GenericModal>
      )}
    </Box>
  );
};

export default Footer;
