import { useSelector } from 'react-redux';

import { rolePermissionsSelector } from 'store/user/selectors';

import { RolePermissions } from 'constants/permissionEnums';

export const usePermissions = () => {
  const userPermissions: RolePermissions[] = useSelector(rolePermissionsSelector);

  const isAllowed = ({ permission }: { permission: RolePermissions }) => userPermissions?.includes(permission);

  const canLinkAccounts = () => isAllowed({ permission: RolePermissions.LinkAccounts });

  const canSeeMoveMoney = () => (
    isAllowed({ permission: RolePermissions.CreateTransactions })
    // || isAllowed({ permission: RolePermissions.ViewAllTransactions }) // TODO: uncomment this once's we have transaction history
    || isAllowed({ permission: RolePermissions.ViewScheduledTransaction })
  );

  return {
    isAllowed,
    canLinkAccounts,
    canSeeMoveMoney,
  };
};
