/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ErrorIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 12C20 13.5822 19.5308 15.129 18.6518 16.4446C17.7727 17.7602 16.5233 18.7855 15.0615 19.391C13.5997 19.9965 11.9911 20.155 10.4393 19.8463C8.88743 19.5376 7.46197 18.7757 6.34315 17.6569C5.22433 16.538 4.4624 15.1126 4.15372 13.5607C3.84504 12.0089 4.00347 10.4003 4.60897 8.93853C5.21447 7.47672 6.23985 6.22729 7.55544 5.34824C8.87103 4.46919 10.4178 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12ZM12 13.6128C11.7066 13.6128 11.4198 13.6998 11.1759 13.8628C10.932 14.0258 10.7419 14.2575 10.6296 14.5285C10.5173 14.7996 10.4879 15.0978 10.5452 15.3855C10.6024 15.6733 10.7437 15.9376 10.9511 16.145C11.1586 16.3525 11.4229 16.4938 11.7106 16.551C11.9984 16.6082 12.2966 16.5789 12.5676 16.4666C12.8387 16.3543 13.0704 16.1642 13.2333 15.9203C13.3963 15.6763 13.4833 15.3895 13.4833 15.0962C13.4833 14.7028 13.3271 14.3255 13.0489 14.0473C12.7707 13.7691 12.3934 13.6128 12 13.6128ZM10.5912 8.2795L10.8305 12.6667C10.8358 12.7656 10.8788 12.8588 10.9507 12.927C11.0226 12.9953 11.1179 13.0333 11.217 13.0333H12.7837C12.8827 13.0331 12.9778 12.995 13.0496 12.9268C13.1213 12.8586 13.1643 12.7655 13.1695 12.6667L13.4088 8.2795C13.4117 8.22697 13.4038 8.17442 13.3857 8.12504C13.3675 8.07566 13.3395 8.03049 13.3034 7.99229C13.2672 7.9541 13.2236 7.92367 13.1753 7.90288C13.127 7.88208 13.0749 7.87135 13.0223 7.87133H10.9777C10.9251 7.87132 10.873 7.88204 10.8247 7.90283C10.7763 7.92362 10.7328 7.95404 10.6966 7.99224C10.6604 8.03044 10.6324 8.07562 10.6143 8.12501C10.5962 8.1744 10.5883 8.22697 10.5912 8.2795Z" fill={color} />
  </svg>
);

export default ErrorIcon;
