import {
  FormLabel,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  SxProps,
} from '@mui/material';
import { BaseSelectProps } from '@mui/material/Select/Select';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { StyledWrapper } from 'fields/DropdownField/index.styled';

import { SelectChevronIcon } from 'assets/themes/styled/SelectChevronIcon';
import { Option } from 'models/option.interface';
import { capitalizeOneString } from 'utils/text';

interface DropdownFieldProps extends BaseSelectProps {
  options: Array<Option>;
  helperText?: string | ReactNode;
  placeholder?: string;
  emptyMessage?: string;
  value?: any;
  isLoading?: boolean;
  sx?: SxProps;
}

const DropdownField:FC<DropdownFieldProps> = (props) => {
  const {
    name,
    required,
    fullWidth = false,
    label,
    options,
    error,
    helperText,
    placeholder,
    value,
    size = 'small',
    variant = 'filled',
    emptyMessage,
    isLoading = false,
    sx = {},
    ...remainingProps
  } = props;
  const intl = useIntl();
  const translatedEmptyMessage = emptyMessage || intl.formatMessage({ id: 'label.noOptionsAvailable' });

  return (
    <StyledWrapper hasError={error} fullWidth={fullWidth} variant={variant}>
      <FormControl component="div" fullWidth={fullWidth}>
        {label && (
          <FormLabel required={required}>
            <Typography variant="label1" color="textPrimary">
              {label}
            </Typography>
          </FormLabel>
        )}
        <Select
          {...remainingProps}
          variant={variant}
          name={name}
          size={size}
          value={value}
          MenuProps={{ PaperProps: { sx: { maxHeight: 300, mt: 1 } } }}
          IconComponent={SelectChevronIcon}
          sx={{ ...sx, color: !value ? 'general.lightGrey6' : 'initial' }}
        >
          {placeholder && (
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
          )}
          {options.map((option, index: number) => {
            const {
              label: optionLabel,
              requiresIntl = false,
              capitalize = false,
              value: val,
              ...remainingOptionProps
            } = option;
            const formattedLabel = requiresIntl && typeof optionLabel === 'string'
              ? intl.formatMessage({ id: optionLabel })
              : optionLabel;

            return (
              <MenuItem value={val} key={`${index}-${val}`} {...remainingOptionProps}>
                {capitalize && typeof formattedLabel === 'string'
                  ? capitalizeOneString(formattedLabel)
                  : formattedLabel}
              </MenuItem>
            );
          })}
          {!options?.length && !isLoading && (
            <MenuItem disabled value="">
              {translatedEmptyMessage}
            </MenuItem>
          )}
          {isLoading && (
            <MenuItem disabled value="">
              {intl.formatMessage({ id: 'button.loading...' })}
            </MenuItem>
          )}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </StyledWrapper>
  );
};

export default DropdownField;
