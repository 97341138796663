import { styled } from '@mui/material/styles';

export const DocumentWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  borderRadius: '6px',
  border: `1px solid ${theme.palette.border.secondary}`,
  padding: theme.spacing(3, 4),

  '& svg > path': {
    fill: theme.palette.general.darkGrey,
  },
}));
