/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const LogoutIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V5C21 3.895 20.105 3 19 3ZM19.707 12.707L16.407 16.007C16.212 16.202 15.956 16.3 15.7 16.3C15.444 16.3 15.188 16.202 14.993 16.007C14.602 15.616 14.602 14.984 14.993 14.593L16.586 13H9C8.447 13 8 12.553 8 12C8 11.447 8.447 11 9 11H16.586L14.993 9.407C14.602 9.016 14.602 8.384 14.993 7.993C15.384 7.602 16.016 7.602 16.407 7.993L19.707 11.293C20.098 11.684 20.098 12.316 19.707 12.707Z" fill={color} />
  </svg>
);

export default LogoutIcon;
