/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const NoteIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H15L21 15V5C21 3.895 20.105 3 19 3ZM7 7H17V9H7V7ZM12 13H7V11H12V13ZM14 19.5V14H19.5L14 19.5Z" fill={color} />
  </svg>
);

export default NoteIcon;
