import { alpha, styled, Theme } from '@mui/material/styles';
import TableSortLabelMui from '@mui/material/TableSortLabel';

import { TableHeaderColors } from 'constants/enums';

const getHeaderColor = (theme: Theme) => ({
  [TableHeaderColors.general]: alpha(theme.palette.primary.main, 0.1),
  [TableHeaderColors.warning]: alpha(theme.palette.warning.secondary, 0.1),
});

export const TableHead = styled('thead')<{
  isRounded: boolean;
  isFullWidth: boolean;
  tableHeaderColors: TableHeaderColors;
}>(({
  theme, isRounded, tableHeaderColors, isFullWidth,
}) => ({
  '& .MuiTableCell-root': {
    borderBottom: 'none',
    padding: 0,
    fontSize: '14px',
    lineHeight: 1.3,

    '&:last-of-type': {
      '& .innerCell': {
        borderTopRightRadius: isRounded ? '1px' : 0,
        borderBottomRightRadius: isRounded ? '1px' : 0,
        marginRight: isFullWidth ? 0 : theme.spacing(1),
        paddingRight: theme.spacing(isRounded ? 5 : 6),
      },
    },

    '&:first-of-type': {
      '& .innerCell': {
        borderTopLeftRadius: isRounded ? '1px' : 0,
        borderBottomLeftRadius: isRounded ? '1px' : 0,
        marginLeft: isFullWidth ? 0 : theme.spacing(1),
        paddingLeft: theme.spacing(isRounded ? 5 : 6),
      },
    },

    '& .MuiCheckbox-root.MuiCheckbox-root': {
      padding: 0,

      svg: {
        height: '20px',
      },
    },
  },

  '& .innerCell': {
    minHeight: '34px',
    height: isRounded ? 'initial' : '100%',
    backgroundColor: getHeaderColor(theme)?.[tableHeaderColors] || alpha(theme.palette.primary.main, 0.1),
    padding: theme.spacing(isRounded ? '7px' : 2.5, 6),
    margin: isFullWidth ? 0 : theme.spacing(1, 0, 0),
  },

  '& .alignRight': {
    textAlign: 'right',
    paddingRight: theme.spacing(4),
  },
}));

export const TableSortLabel = styled(TableSortLabelMui)(({ theme, active }) => ({
  '& .MuiTableSortLabel-icon': {
    opacity: 1,
    height: '16px',
    width: '16px',
    fill: active ? theme.palette.primary.main : theme.palette.general.lightBlack,
  },
}));
