import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getUserDetails } from 'services/UserService';
import { resetStore } from 'store/actions';

import { ApiStatus } from 'constants/enums';
import { User } from 'models/user.interface';
import { handleApiErrors } from 'utils/errorUtils';

export interface UserState {
  details: {
    data: User|null;
    status: ApiStatus;
  };
}

const initialState: UserState = {
  details: {
    data: null,
    status: ApiStatus.idle,
  },
};

export const fetchUserDetails = createAsyncThunk(
  'userDetails/fetchUserDetails',
  async (
    { userId, workspaceId, showError }: { userId: string; workspaceId: string; showError?: boolean },
    thunkAPI,
  ) => {
    try {
      const { data: member }: { data: User } = await getUserDetails(workspaceId, userId);
      return member || null;
    } catch (e) {
      if (showError) {
        handleApiErrors(e);
      }

      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const userDetailsSlice = createSlice({
  name: 'teamMember',
  initialState,
  reducers: {
    updateTeamMemberDetails: (state, action: PayloadAction<User>) => {
      state.details.data = action.payload;
      return state;
    },
    resetState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchUserDetails.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchUserDetails.fulfilled, (state, action: PayloadAction<User>) => {
        state.details = {
          ...state.details,
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchUserDetails.rejected, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  resetState,
} = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
