/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SendIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 2L2 9.27148L7.4375 14.7109L18 6L9.28906 16.5625L14.7285 22L22 2Z" fill={color} />
  </svg>
);

export default SendIcon;
