import {
  CREATE_RULE_API,
  DELETE_RULE_API,
  GET_RULES_API,
  GET_RULE_API,
  GET_ALERT_HISTORY_API,
  ACTIVATE_RULE_API,
  INACTIVATE_RULE_API,
  ARCHIVE_RULE_API,
  UNARCHIVE_RULE_API,
} from 'constants/apiRoutes';
import { RuleHistoryFilter, RulesManagementFilter } from 'models/filter.interface';
import { CreateRule } from 'models/rule.interface';
import { formatRoute } from 'utils/formatters';

import {
  runDeleteApiRequest,
  runGetApiRequest,
  runPostApiRequest,
} from './Api';

interface RuleHistoryListParams {
  size: number;
  page: number;
  sort: string;
  name: string;
  filter: RuleHistoryFilter;
}

export const getRuleHistory = async (params: RuleHistoryListParams) => {
  const { filter, ...remainingParams } = params;
  return runGetApiRequest(GET_ALERT_HISTORY_API, { ...remainingParams, ...filter });
};

interface RuleListParams {
  size: number;
  page: number;
  sort: string;
  search: string;
  ownerId: string;
  filter: RulesManagementFilter;
}

export const getRules = async (params: RuleListParams) => {
  const { filter, ...remainingParams } = params;
  return runGetApiRequest(GET_RULES_API, { ...remainingParams, ...filter });
};

export const createRule = (data: CreateRule) => (
  runPostApiRequest(CREATE_RULE_API, data)
);

export const activateRule = (ruleId: string, data: { ownerId: string }) => (
  runPostApiRequest(formatRoute(ACTIVATE_RULE_API, { ruleId }), data)
);

export const archiveRule = (ruleId: string) => (
  runPostApiRequest(formatRoute(ARCHIVE_RULE_API, { ruleId }))
);

export const unarchiveRule = (ruleId: string) => (
  runPostApiRequest(formatRoute(UNARCHIVE_RULE_API, { ruleId }))
);

export const inactivateRule = (ruleId: string, data: { ownerId: string }) => (
  runPostApiRequest(formatRoute(INACTIVATE_RULE_API, { ruleId }), data)
);

export const deleteRule = (ruleId: string) => (
  runDeleteApiRequest(formatRoute(DELETE_RULE_API, { ruleId }))
);

export const getRuleDetails = (ruleId: string) => (
  runGetApiRequest(formatRoute(GET_RULE_API, { ruleId }))
);
