/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ACHIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 24 24">
    <path fill={color} fillRule="evenodd" d="M3.5 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h17a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-17Zm9.148 11.072c-.571 0-1.07-.12-1.496-.36a2.455 2.455 0 0 1-.976-1.016c-.224-.437-.336-.944-.336-1.52 0-.576.112-1.083.336-1.52.229-.437.554-.773.976-1.008.426-.24.925-.36 1.496-.36.373 0 .73.059 1.072.176.34.112.62.27.84.472l-.4.888a2.675 2.675 0 0 0-.736-.408 2.229 2.229 0 0 0-.752-.128c-.502 0-.888.16-1.16.48-.267.32-.4.79-.4 1.408 0 .619.133 1.09.4 1.416.272.32.658.48 1.16.48.26 0 .512-.043.752-.128.245-.085.49-.221.736-.408l.4.888a2.331 2.331 0 0 1-.84.48 3.417 3.417 0 0 1-1.072.168ZM8.452 15l-.536-1.248H5.292L4.756 15H3.5l2.592-5.64h1L9.684 15H8.452Zm-.944-2.192-.904-2.112-.904 2.112h1.808ZM20.469 9.36h-1.224v2.28h-2.6V9.36h-1.224V15h1.224v-2.368h2.6V15h1.224V9.36Z" clipRule="evenodd" />
  </svg>
);

export default ACHIcon;
