import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const ManageRecipients = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'ManageRecipients' */
      'modules/recipients/containers/ManageRecipients'
    ),
    'ManageRecipients',
  ),
);

export default ManageRecipients;
