import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import { NAVBAR_HEIGHT } from 'constants/general';

const getBackgroundColor = (theme: any, isOpen = false) => {
  if (isOpen) {
    return theme.palette.general.darkGreen;
  }

  return 'transparent';
};

export const NavElement = styled('div')<{ isActive: boolean; isOpen?: boolean }>(({ theme, isActive, isOpen }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  borderRadius: '4px',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(isActive ? 0.5 : 2),
  padding: theme.spacing(2, 3),
  color: theme.palette.common.white,
  borderBottom: isActive ? `6px solid ${theme.palette.primary.main}` : 'none',
  borderBottomRightRadius: isActive ? 0 : '4px',
  borderBottomLeftRadius: isActive ? 0 : '4px',
  opacity: isActive ? 1 : 0.5,

  backgroundColor: getBackgroundColor(theme, isOpen),

  '&:hover': {
    backgroundColor: theme.palette.general.darkGreen,
    opacity: 1,
  },

  '& svg': {
    '& > path': {
      fill: isActive
        ? theme.palette.common.white
        : theme.palette.border.secondary,
    },
  },

  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1.75, 2.5),

    '& p': {
      display: 'none',
    },

    '& svg': {
      marginRight: 0,
    },
  },
}));

export const NavbarLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  whiteSpace: 'nowrap',
}));

export const ItemRoot = styled('div')(() => ({
  height: `${NAVBAR_HEIGHT}px`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
}));

export const NavbarWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),

  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(2),
  },
}));
