import { FC, ReactElement } from 'react';

import Footer from 'layouts/AuthLayout/Footer';
import { CardMedia, Content, Layout } from 'layouts/AuthLayout/index.styled';
import CustomSuspense from 'routes/CustomSuspense';

import AuthLayoutBackground from 'assets/images/auth-bg.svg';

interface AuthLayoutProps {
  children: ReactElement;
}
const AuthLayout:FC<AuthLayoutProps> = ({ children }): ReactElement => (
  <Layout>
    <Content>
      <CardMedia
        component="img"
        src={AuthLayoutBackground}
        alt="Auth Layout Background"
      />
      <CustomSuspense>
        {children}
      </CustomSuspense>
    </Content>
    <Footer />
  </Layout>
);

export default AuthLayout;
