import { styled } from '@mui/material/styles';

import { ElementSize } from 'constants/enums';

export const LogoWrapper = styled('div')(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}));

export const LogoImage = styled('img')<{ size: ElementSize; withText: boolean }>(({ size, withText }) => {
  if (size === ElementSize.large) {
    return ({
      height: '100px',
      width: withText ? '100%' : '100px',
    });
  }

  if (size === ElementSize.medium) {
    return ({
      height: '60px',
      width: withText ? '100%' : '60px',
    });
  }

  return ({
    height: '46px',
    width: withText ? '100%' : '46px',
  });
});
