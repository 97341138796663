import { Container, Typography } from '@mui/material';
import MuiCardMedia from '@mui/material/CardMedia';
import { alpha, styled } from '@mui/material/styles';

export const Layout = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.main,
  overflow: 'hidden',
  position: 'relative',
}));

export const CardMedia = styled(MuiCardMedia)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  minHeight: '100vh',
  width: '100vw',
  background: `linear-gradient(
      305.93deg, 
      ${alpha(theme.palette.primary.main, 0.2)} -5.5%,
      ${alpha(theme.palette.primary.main, 0.4)} 17.81%,
      ${theme.palette.primary.main} 49.05%,
      ${theme.palette.primary.dark} 85.36%
    )}`,
})) as typeof MuiCardMedia;

export const Navbar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(5, 10),
  borderBottom: `1px solid ${theme.palette.border.secondary}`,
  width: '100%',
  zIndex: 1,
  marginBottom: theme.spacing(12),

  '& .MuiButton-root': {
    color: theme.palette.general.darkGrey1,
  },
}));

export const Content = styled(Container)(({ theme }) => ({
  zIndex: 1,
  color: theme.palette.common.white,

  '& .finishLater': {
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.secondary.secondary,
    },
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  lineHeight: '49.1px',
  fontWeight: 700,
  marginBottom: theme.spacing(3),
}));
