import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  height: '36px',
  width: '36px',

  '&.MuiIconButton-root:hover': {
    '& svg > path': {
      fill: theme.palette.primary.main,
    },
  },

  '& svg': {
    transform: 'rotate(90deg)',

    '& path': {
      fill: theme.palette.general.darkGrey,
    },
  },
}));
