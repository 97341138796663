/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const DashedCloud = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4C11.398 4 10.8073 4.08342 10.2383 4.23242L10.9277 6.12891C11.2777 6.05091 11.635 6 12 6C12.924 6 13.7921 6.2495 14.5371 6.6875L15.4297 4.90234C14.4127 4.33134 13.246 4 12 4ZM8.39844 5.01953C7.56444 5.52253 6.82681 6.18891 6.25781 7.00391C5.19381 7.04291 4.19531 7.34198 3.32031 7.83398L4.68359 9.39062C5.23959 9.14362 5.853 9 6.5 9L7.35938 9.04688L7.66797 8.51367C8.03097 7.88267 8.52366 7.35631 9.09766 6.94531L8.39844 5.01953ZM17.0469 6.14258L16.0625 8.11133C16.4935 8.72233 16.8066 9.42884 16.9316 10.2148L17.0566 11.0098C17.7696 11.0038 18.448 11 18.5 11C18.828 11 19.1404 11.0595 19.4414 11.1445L20.9766 9.60938C20.2866 9.25838 19.5241 9.03391 18.7051 9.00391C18.3821 7.90591 17.8049 6.93358 17.0469 6.14258ZM1.74805 9.07422C0.669047 10.2322 0.00595313 11.7833 0.00195312 13.4863L2.03516 13.1465C2.11216 12.1785 2.49094 11.3035 3.08594 10.6035L1.74805 9.07422ZM22.5781 10.8359L21.1602 12.2539C21.6762 12.8639 22 13.641 22 14.5C22 14.722 21.9736 14.9374 21.9336 15.1484L23.7988 15.9551C23.9268 15.4911 24 15.005 24 14.5C24 13.089 23.4551 11.8099 22.5781 10.8359ZM2.31641 15.127L0.304688 15.4629C0.844688 17.1619 2.06553 18.5541 3.64453 19.3301L4.38672 17.4473C3.44872 16.9423 2.70741 16.129 2.31641 15.127ZM21.0117 16.9277C20.3757 17.5867 19.486 18 18.5 18H18.0195L18.4199 20H18.5C20.313 20 21.9199 19.1139 22.9219 17.7559L21.0117 16.9277ZM6.32617 17.9824L5.5625 19.9238C5.8695 19.9688 6.181 20 6.5 20H10V18H6.5C6.441 18 6.38517 17.9854 6.32617 17.9824ZM12 18V20H16.3809L15.9805 18H12Z" fill={color} />
  </svg>
);

export default DashedCloud;
