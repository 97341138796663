import { lazy } from 'react';

import AddAccount from 'modules/add-bank-account/containers/AddAccount';
import CustomLazyLoader from 'routes/CustomLazyLoader';

const TemporaryBankAccounts = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'TemporaryBankAccounts' */
      'modules/add-bank-account/containers/TemporaryBankAccounts'
    ),
    'TemporaryBankAccounts',
  ),
);

export {
  TemporaryBankAccounts,
  AddAccount,
};
