/* eslint max-len: 0 */

//  TODO: add the right content
const PrivacyPolicy = () => (
  <div>
    <h4>Last Updated: June 6, 2023</h4>

    <p>
      This Privacy Policy includes important information about your personal information, we encourage you to
      read it carefully.
    </p>

    <h3>Welcome</h3>

    <p>
      Rocket Financial delivers embedded finance solutions to businesses of all types and sizes to move money,
      provide banking services (though we are not a bank), and drive/manage the financial requirements of their
      business. Additionally, through our RocketCOMS Platform, we provide business messaging services.
      We care about the security and privacy of the personal information you entrust to us.
    </p>

    <p>
      This Privacy Policy describes the information Rocket Financial Inc (“Rocket Financial”, “RocketFNCL”,
      “RocketKOR”, “RocketCOMS”, “PrimaryAccount” “we”, “our”, “us”) collects about you, how we use and share
      that personal information, and the privacy choices we offer. Personal Information generally means information
      that identifies you, your business, or your devices.
    </p>

    <p>
      This policy applies to information we collect when you pre-register with Rocket Financial, register with Rocket
      Financial, access or use of our websites, mobile applications, products, and services (collectively, the
      “Services”), or when you otherwise interact with us. As used in this Privacy Policy, the term “Site” includes:
      all websites and all devices or applications operated by Rocket Financial that collect personal information from
      you and that link to this Privacy Policy; pages within each such website, device, or application, any equivalent,
      mirror, replacement, substitute, or backup website, device, or application; and pages that are associated with
      each such website, device, or application.
    </p>

    <p>
      If you obtain financial or other services through a Platform that uses our Services, your personal data will be
      subject to additional privacy policies. The Platform you are using will provide you with a privacy policy
      explaining the personal data that it collects and how it uses and shares that personal data. Rocket Financial
      Inc. is not a bank. Therefore, depending on the service/s you obtain, your personal data also will be collected,
      used, and shared with our providers (“Providers”), including as applicable partner bank(s) (“Partner Banks”).
    </p>

    <p>
      If you do not agree with or you are not comfortable with any aspect of this Privacy Policy, you should immediately
      discontinue access or use of our Services.  And finally, please refer to our Terms of Service and Terms of Use for
      information regarding the proper usage of the Services and Site, respectively.
    </p>

    <h3>Principles We Follow When We Collect And Share Your Personal Data</h3>

    <ul>
      <li>
        We safeguard the Personal Information shared with us in accordance with strict standards of security
        and confidentiality.
      </li>
      <li>
        We limit the collection and use of Personal Information to only what is necessary to deliver superior
        service to our customers. Such Personal Information may be used only for the purposes described in this
        Privacy Policy.
      </li>
      <li>
        We retain your Personal Information for only as long as needed to fulfill the intended purpose for which
        it was collected or as may be required by law.
      </li>
      <li>
        We continually maintain proper safeguarding of the Personal Information in our care to protect the privacy of
        each client.
      </li>
      <li>
        We require authorized third-party organizations with whom we share Personal Information to have at least
        the same strict level of security and confidentiality as we do.
      </li>
      <li>We continually assess ourselves to ensure that each client’s privacy rights are being respected.</li>
    </ul>

    <h3>Information We May Collect and How We Collect It</h3>

    <p>
      We may collect your personal data in different ways depending on how you interact with us, one of our client
      platforms, and/or other entities that are necessary to provide Services to you. Below we talk about the personal
      data that we collect and the different ways it may be collected.
    </p>

    <ul>
      <li>
        <strong>
          <i>Registration Information</i>
          :
        </strong>
        {' '}
        When you register for a Service, you may be asked for
        basic registration information, such as an email address, password and/or phone number.
        <br />
      </li>
      <li>
        <strong>
          <i>Identification Information</i>
          :
        </strong>
        {' '}
        You may also be asked to provide identification
        information to confirm your identity, including, but not limited to, your first and last name, zip code,
        address, social security number and date of birth.
        <br />
      </li>
      <li>
        <strong>
          <i>Third-Party Credentials</i>
          :
        </strong>
        {' '}
        You may also enter certain passwords, usernames, account
        numbers, and other account information for third-party sites and Internet services (“Third Party-Sites”).
        <br />
      </li>
      <li>
        <strong>
          <i>Information from Third-Party Sites</i>
          :
        </strong>
        {' '}
        In order to display information to you or to
        fulfill your requests for certain products and services through a Service, we may collect, on your behalf,
        your account and other personal information from Third-Party Sites that you register under your account via
        the Service. We may also collect information about you from mailing list providers, publicly available sources,
        identification verification services and other third parties.&nbsp;
        <br />
      </li>
      <li>
        <strong>
          <i>Information Collected by Cookies and Web Beacons</i>
          :
        </strong>
        {' '}
        We may use various technologies
        to collect information, and this may include sending cookies to your computer or mobile device. Cookies are
        small data files that are stored on your hard drive or in device memory by a website. Among other things,
        cookies support the integrity of our registration process, retain your preferences and account settings, and
        help evaluate and compile aggregated statistics about user activity. We may also collect information using web
        beacons. Web beacons are electronic images that may be used in our Services or emails. We may use web beacons
        to deliver cookies, count visits, understand usage, and determine whether an email has been opened and acted
        upon.
        <br />
      </li>
      <li>
        <strong>
          <i>Technical and Navigational Information</i>
          :
        </strong>
        {' '}
        We may collect your computer browser type,
        Internet protocol address, pages visited, and average time spent on our Site. This information may be used,
        for example, to alert you to software compatibility issues, or it may be analyzed to improve our web design
        and functionality.&nbsp;
        <br />
      </li>
      <li>
        <strong>
          <i>Device ID</i>
          :
        </strong>
        {' '}
        When you use the mobile versions of our Site, we may collect the unique
        device identifier assigned to that device by phone makers, carriers, or makers of the operating system (the
        “Device ID”). Device IDs allow app developers, and others to uniquely identify your device for purposes of
        storing application preferences and other data.&nbsp;
      </li>
    </ul>

    <p>
      If you use one of our clients Platforms, we will receive certain personal information from that Platform.
      The specific information and how it used in process will depend on the service you are receiving, but some key
      examples are below.
    </p>

    <ul>
      <li>
        <i><strong>Identity Verification. </strong></i>
        If it is required to verify your identity when you apply or sign up to receive a service through a Platform,
        we will collect any/all information required to effectively process an Identity check.&nbsp; Information
        required for an Identity check can include, but not be limited to: your name, email address, Social Security
        Number or other tax identification number, address, telephone number, date of birth, gender, IP address, and
        images that may contain of your face, such as your driver’s license or government-issued identification card.
        <br />
      </li>
      <li>
        <i>
          <strong>External Financial Account Information.</strong>
          &nbsp;
        </i>
        We may collect information regarding an external financial account such as the financial institution name,
        account name, account type, account number, routing number, international bank account number (“IBAN”),
        transaction details, and available balance information.
        <br />
      </li>
      <li>
        <i>
          <strong>Recipient or Sender Data.</strong>
          &nbsp;
        </i>
        When you send or request money through a client’s Platform, we may receive name, address, telephone number,
        IP address, date of birth, and financial account information about the recipient or sender of the funds. The
        extent of data required about a recipient or sender may vary depending on the service you are using and the
        specific requirements of that service.
        <br />
      </li>
      <li>
        <strong><i>Digital Information. </i></strong>
        Depending on a service used, we may collect information about your visit to a client Platform’s website or
        mobile application, including IP addresses and associated information. We also may collect your device
        “fingerprint” (e.g., hardware model, operating system and version, unique device identifiers and mobile
        network information).
      </li>
    </ul>

    <h3>How We Use Your Information</h3>

    <p>
      We may use the information you provide about yourself and about your Third-Party Sites to fulfill your requests
      for our products, programs, and Services, to respond to your inquiries about our Services, and to offer you
      other products, programs, or services that we believe may be of interest to you.
    </p>

    <p>
      We may use your information to complete transactions you request, to verify the existence and condition of your
      accounts, or to assist with a transaction. For example, we may use the account information you provide or that
      we collect from Third-Party Sites to confirm your accounts are valid and to access funds from your accounts in
      connection with fulfillment of the Services.
    </p>

    <p>
      We may use your information to improve and personalize the Services. For example, we may use your information
      to pre-fill form fields on the Sites for your convenience.
    </p>

    <h3>How We Share Your Information</h3>

    <p>
      We may share personal information about you with third parties to provide, maintain, and improve our Services
      that you request, such as, but not limited to:
    </p>

    <ul>
      <li>
        Client Platform/s you are using for the purposes of offering you Services and providing customer service to
        you. In this situation, the client Platform will have access to your Personal Data and that Platform’s Privacy
        Policy will apply.
      </li>
      <li>
        Partner Bank(s) to comply with our obligations to our Partner Bank’s, and the Partner Bank’s regulatory
        obligations and “know your customer” requirements. In this situation, the Partner Bank(s) will have access to
        your Personal Data and that Partner Bank(s)’s Privacy Policy will apply.
      </li>
      <li>
        Card networks if you are issued a card by a Partner Bank(s). In this situation, the card network will have
        access to your Personal Data and that card network’s Privacy Policy will apply.
      </li>
      <li>
        Other companies or providers of various types that provide services to us, so our client’s platforms can
        provide services to you. This is a long and changing list, but including but not limited to: identity verification
        service providers, transaction processing providers, fraud prevention service providers, financial service
        providers who enable you to use certain features provided with our Services, card printers, credit bureaus, etc.
      </li>
    </ul>

    <p>
      Additionally, we may share your personal information in a number ways to perform our business and provide
      services to you. As example:
    </p>

    <ul>
      <li>
        For our everyday business purposes— such as to process your transactions, maintain your account(s),
        respond to court orders and legal investigations, or report to credit bureaus
      </li>
      <li>For our marketing purposes— to offer our products and services to you</li>
      <li>For joint marketing with other financial companies</li>
      <li>For our affiliates to market to you</li>
      <li>For non-affiliates to market to you</li>
    </ul>

    <p>
      We may disclose your Personal Information to the following entities:
    </p>

    <ul>
      <li>Our affiliated and subsidiary companies</li>
      <li>Non-affiliated third parties that perform services, such as marketing or market research, on our behalf</li>
      <li>Non-affiliated third parties under contract that service, maintain, or process your transactions and accounts</li>
      <li>
        Non-affiliated third parties as permitted or required by law to defend our rights or enforce a contract,
        or to cooperate with government agencies or regulators, (including for tax purposes), securities exchanges,
        self-regulatory bodies and law enforcement officials (for example, for reporting suspicious activity or
        transactions, or complying with a subpoena)
      </li>
      <li>
        Non-affiliated third parties to protect against actual or potential fraud or unauthorized transactions,
        and to protect the security of our records and systems, unless prohibited by law
      </li>
      <li>
        Non-affiliated third parties at your direction or for which you provide your express consent (for example,
        banking or financial institutions and professional service providers)
      </li>
    </ul>

    <p>
      And finally, we may need to share personal information in connection with, or during the negotiation of, any
      merger, sale of company stock or assets, financing, acquisition, divestiture, or dissolution of all or a portion
      of our business.  To respond to subpoenas, court orders, or legal process.  In order to investigate, prevent,
      defend against, or take other action regarding violations of our Terms of Service, illegal activities, suspected
      fraud, or situations involving potential threats to the legal rights or physical safety of any person or the
      security of our network, Sites or Services.  To respond to claims that any posting or other content violates the
      rights of third parties.  In an emergency, to protect the health and safety of our Sites’, users, employees, or
      the general public.  Or, as otherwise required by law.
    </p>

    <p>
      Our service providers are obligated to keep Personal Information we share with them confidential and use it only
      to provide services specified by Rocket Financial. We require our service providers to have administrative,
      technical, and physical safeguards and to apply them during our cooperation.
    </p>

    <h3>How We Secure Your Information</h3>

    <p>
      To protect your Personal Information from unauthorized access and use, we use security measures that comply with
      federal law. These measures include computer safeguards and secured files and buildings.
    </p>

    <p>
      We maintain physical, electronic, and procedural safeguards designed to protect your Personal Information from
      unauthorized access or intrusion. We regularly adapt these controls to respond to changing requirements and
      advances in technology. We limit access to the Personal Information to those who require it to develop or support
      our products and services, who need such access in connection with providing products or services to you, or for
      other legitimate business purposes.
    </p>

    <p>
      All Personal Information is securely stored on our servers in the United States. We employ the highest in
      industry standards to protect your Personal Information.
    </p>

    <p>
      Passwords and pins are only known by you. No employee, contractor, or Third-Party Site has access to your Rocket
      Financial password/s or pin/s. Additionally, we will never ask for your password or pin through our customer
      service teams. Where employed, Two-factor authentication is provided to ensure your account can only be accessed
      by the device you register with.
    </p>

    <p>
      Although Rocket Financial utilizes the highest reasonable levels of data security in the industry, we can not
      guarantee the security of Personal Information or other information provided to us. By using our services, you
      acknowledge and agree that we make no such guarantee, and that you use our systems at your own risk.
    </p>

    <h3>Compromise of Personal Information</h3>

    <p>
      We regularly review our operational and business practices for compliance with corporate policies and procedures
      governing the security, confidentiality, and quality of our information. However, no method of electronic
      transmission or storage is 100% secure. Therefore, we cannot guarantee absolute security of your Personal
      Information.
    </p>

    <p>
      We shall not be liable for the transfer of any Personal Information resulting from loss or distribution of data,
      the delineation or corruption of storage media, power failures, natural phenomena, riots, acts of vandalism,
      sabotage, terrorism, or any other event beyond our control.
    </p>

    <p>
      In the event that Personal Information is compromised as a result of a breach of security, we will promptly
      notify those whose Personal Information has been compromised, in accordance with the notification procedures
      set forth in this Privacy Policy by email, or as otherwise required by applicable law.
    </p>

    <p>
      You also should protect your Personal Information. Please safeguard your username and password for your Account(s)
      and do not share them with others.
    </p>

    <h3>How Long We Retain Your Information</h3>

    <p>
      Unfortunately, there is no single answer to this as we are required, for compliance or regulatory reasons, to
      retain certain types of data, for certain reasons, for varying periods of time.
    </p>

    <p>
      As such, our policy is that we will maintain the information that is necessary to enable us to provide the
      requested service only for as long as it takes us to provide any such requested service. We may still need to
      keep your Personal Information to ensure that our systems reflect your preferences. We may keep records of any
      transactions you enter into through our services for a minimum of six years from the end of our relationship
      with you. We may keep other information about you to the extent necessary to comply with applicable laws and
      to further legitimate business needs.
    </p>

    <p>
      If you would like to update or delete your personal data, please see the section “How To Update Your Information”.
    </p>

    <h3>Note On Notifications And Communication</h3>

    <p>
      We will provide notifications, whether such notifications are required by law or are for marketing or other
      business-related purposes, to you via email notice, written or hard copy notice, or through conspicuous posting
      of such notice on our Sites, as determined by Rocket Financial in its sole discretion. We reserve the right to
      determine the form and means of providing notifications to you.
    </p>

    <p>
      We may provide our registered customers with summaries of their Account Information through email, SMS and mobile
      notifications. We may also allow users to subscribe to email newsletters, and from time to time, may transmit
      emails promoting Rocket Financial or third-party goods or services.
    </p>

    <p>
      If you no longer wish to receive marketing communications, you can opt out of receiving them by following the
      instructions in the emails or messages. Opting out in this manner will not end transmission of service-related
      emails, such as summaries of Account Information.
    </p>

    <h3>Your Choice Regarding Your Personal Information</h3>

    <p>
      Many of our products require some personal data to operate and provide you with a service. If you decide to
      restrict the use or disclosure of your personal information, this may result in the limitation or discontinuance
      of certain services to you. Likewise, where we need to collect personal data by law or to enter into or carry
      out a contract with you, and you do not provide the data, we will not be able to enter into the contract; or if
      this relates to an existing product you are using, we may have to suspend or cancel it.
    </p>

    <h3>How To Update Your Information</h3>

    <p>
      If you wish to access personal information that you have submitted to us or to request the correction of any
      inaccurate information you have submitted to us, you may correct certain information via your user account.
      Alternatively, you can send an email that includes your contact information to privacy@rocketfncl.com to request
      any corrections to your personal information.
    </p>

    <p>
      You may also email us if you wish to deactivate your account, but even after you deactivate your account, we
      may retain archived copies of information about you for a period of time that is consistent with applicable law.
    </p>

    <p>
      If your information has been entered through a client Platform, you should contact that Platform directly as
      they will likely be able to most expediently assist you.
    </p>

    <p>
      You can ask us to delete your Personal Data at any time and we will comply if possible. However, you should
      understand that this right is not absolute. Even if you make a request for deletion, we may need to retain
      certain information for legal or administrative purposes, such as record keeping, maintenance of opt-out
      requirements, defending or making legal claims, or detecting fraudulent activities. We will retain information
      in accordance with the “How Long Do We Retain Your Personal Data”.
    </p>

    <h3>Minors</h3>

    <p>
      These Sites are not directed at individuals under the age of eighteen (18) or individuals under the age of
      nineteen (19) in the states of Alabama or Nebraska, and we do not knowingly collect Personal Information from
      any individual under the age of eighteen (18) or under the age of nineteen (19) in the states of Alabama or
      Nebraska, at these Sites.
    </p>

    <h3>Links To Or From Other Websites</h3>

    <p>
      Rocket Financial is not responsible for the practices employed by websites linked to or from our Sites, nor the
      information or content contained therein. Please remember that when you use a link to go from one of our Sites
      to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website,
      including those that have a link on our Sites, is subject to that website’s own rules and policies. Please read
      over those rules and policies before proceeding.
    </p>

    <h3>How You Can Limit the Sharing Of Your Personal Data</h3>

    <p>
      Federal law gives you the right to limit only:
    </p>

    <ul>
      <li>Sharing for affiliates’ everyday business purposes—information about your creditworthiness</li>
      <li>Affiliates from using your information to market to you</li>
      <li>Sharing for non-affiliates to market to you</li>
    </ul>

    <p>
      State laws and individual companies may give you additional rights to limit sharing.
    </p>

    <p>
      Please note: When you are no longer our customer, we continue to share your Personal Information as described in this Privacy
      Policy. However, you can contact us at any time to limit our sharing.
    </p>

    <p>
      Please note: We may contact our existing customers by mail, telephone, or email to offer additional
      financial products or services, including products and services offered by nonaffiliates that we believe
      may be of interest to you. You may direct us not to send you such offers.
    </p>

    <p>
      To limit or “opt-out” of sharing your Personal Information with our affiliated companies for marketing purposes,
      information about your creditworthiness and for non-affiliates to market to you, please contact us by
      emailing privacy@rocketfncl.com.
    </p>

    <h3>How We Update Our Privacy Policy</h3>

    <p>
      We reserve the right, at our discretion, to make changes to this Privacy Policy from time to time, so please review
      it frequently. You may review updates to our Terms of Service and Privacy Policy at any time via links on &nbsp;
      <a href="http://www.primaryaccount.com" target="_blank" rel="noopener noreferrer"><span>www.rocketfncl.com</span></a>
      &nbsp; or &nbsp;
      <a href="http://www.rocketkor.com" target="_blank" rel="noopener noreferrer"><span>www.rocketkor.com</span></a>
      &nbsp; or &nbsp;
      <a href="http://www.primaryaccount.com" target="_blank" rel="noopener noreferrer"><span>www.primaryaccount.com</span></a>
      &nbsp; or &nbsp;
      <a href="http://www.rocketcoms.com" target="_blank" rel="noopener noreferrer"><span>www.rocketcoms.com</span></a>
      &nbsp;. You agree to accept
      electronic communications and/or postings of revised versions of this Privacy Policy and agree that such
      electronic communications or postings constitute notice to you of the revised version of this Privacy Policy.
      Changes take effect immediately upon posting.
    </p>

    <h3>Notice to California Residents</h3>

    <p>
      Effective January 1, 2020, the California Consumer Privacy Act (“CCPA”) allows California residents, upon a verifiable
      consumer request and subject to applicable exemptions, to request that we give you access, and (if technically
      feasible) in readily usable form, to the specific pieces and categories of Personal Information that we have
      collected or shared about you. California residents also have the right to submit a request for deletion of
      Personal Information under certain circumstances that Rocket Financial is not otherwise entitled to maintain.
    </p>

    <p>
      A California consumer may, for no fee, make the following verifiable requests to a business collecting their
      Personal Information:
    </p>

    <ul>
      <li>Request to know categories of Personal Information collected and disclosed</li>
      <li>Request to know specific pieces of Personal Information collected</li>
      <li>Request to delete their Personal Information</li>
    </ul>

    <p>
      To submit a request under the CCPA, please contact us by emailing Rocket Financial at devcenter@rocketbnk.com.
      For your protection, we will need to verify your identity using the Personal Information associated with your
      Account before fulfilling your request. Government identification may be required. We will try to comply with
      your request as soon as reasonably practicable and consistent with applicable law, which may take up to 45 days
      following verification of your identity. Please note that the Gramm-Leach-Bliley Act and the CCPA allows us to
      keep certain forms of data for recordkeeping purposes and/or to complete any transactions that you began prior
      to requesting a change or deletion. In addition, there may be certain data that we may not allow you to review
      for legal, security, or other reasons.
    </p>

    <p>
      Rocket Financial will not discriminate against customers exercising their rights as mentioned above. Specifically,
      we will not deny you services, charge you different prices for services or provide you with a different level
      or quality of services.
    </p>

    <h3>Notice to Vermont Residents</h3>

    <p>
      We will not share information we collect about you with non-affiliated companies except as permitted by law,
      including, for example, with your consent or to service your Account. We will not share information about your
      creditworthiness with affiliated and non-affiliated companies except with your authorization or consent, but we
      may share information about our transactions or experiences with you with our affiliates.
    </p>

    <p>
      Rocket Financial will not discriminate against customers exercising their rights as mentioned above. Specifically,
      we will not deny you services, charge you different prices for services or provide you with a different level or
      quality of services.
    </p>

    <h3>Notice to Nevada Residents</h3>

    <p>
      We may contact our existing customers by telephone to offer additional financial products that we believe may be
      of interest to you. You have the right to opt out of these calls. To opt out of these calls, or for more
      information about your opt out rights, please reach us by emailing Rocket Financial at devcenter@rocketbnk.com.
      You are being provided this notice under Nevada state law. In addition to contacting Rocket Financial, Nevada
      residents can contact the Nevada Attorney General for more information about your opt out rights by calling
      702.486.3132, emailing aginfo@ag.nv.gov, or by writing to: Office of the Attorney General, Nevada Department
      of Justice, Bureau of Consumer Protection 100 North Carson Street, Carson City, NV 89701-4717.
    </p>

    <p>
      Rocket Financial will not discriminate against customers exercising their rights as mentioned above.
      Specifically, we will not deny you services, charge you different prices for services or provide you with a
      different level or quality of services.
    </p>

    <h3>Make Sense?</h3>

    <p>
      If you have questions or concerns regarding this statement, or if you have any suggestions, please contact
      privacy@rocketfncl.com
    </p>
  </div>
);

export default PrivacyPolicy;
