import { RootState } from 'store/index';

import { ApiStatus } from 'constants/enums';
import { Contact } from 'models/contact.interface';
import { Pagination } from 'models/pagination.interface';
import { SortOptions } from 'models/sortOptions.interface';

export const searchSelector = (state: RootState): string => state.contacts.search;
export const isLoadingSelector = (state: RootState): boolean => state.contacts?.details?.status === ApiStatus.loading;
export const paginationSelector = (state: RootState): Pagination => state.contacts.pagination;
export const sortOptionsSelector = (state: RootState): SortOptions => state.contacts.sortOptions;
export const contactsSelector = (state: RootState): Contact[] => state.contacts.details.data;
