/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SubcontractIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 4.0001C8 2.90704 8.90694 2.0001 10 2.0001H14C15.0931 2.0001 16 2.90704 16 4.0001V6.0001H21V12H3V6.0001H8V4.0001ZM14 4.0001H10V6.0001H14V4.0001ZM10 9.0001C10 8.4481 9.552 8.0001 9 8.0001C8.448 8.0001 8 8.4481 8 9.0001C8 9.5521 8.448 10.0001 9 10.0001C9.552 10.0001 10 9.5521 10 9.0001ZM16 9.0001C16 8.4481 15.552 8.0001 15 8.0001C14.448 8.0001 14 8.4481 14 9.0001C14 9.5521 14.448 10.0001 15 10.0001C15.552 10.0001 16 9.5521 16 9.0001Z" fill={color} />
    <path d="M3 13.2V19.0001C3 20.1051 3.895 21.0001 5 21.0001H19C20.105 21.0001 21 20.1051 21 19.0001V13.2H3Z" fill={color} />
  </svg>
);

export default SubcontractIcon;
