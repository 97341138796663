import { Divider, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableContainerMui from '@mui/material/TableContainer';

export const TableContainer = styled(TableContainerMui)(({ theme }) => ({
  width: '100%',
  overflowX: 'auto',
  height: 'fit-content',
  backgroundColor: theme.palette.common.white,
}));

export const TablePaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  padding: 0,
  borderRadius: 0,
}));

export const TableDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.general.lightGrey2,
  borderWidth: '1px',
}));
