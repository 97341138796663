import { Box, FormLabel, Typography } from '@mui/material';
import { get } from 'lodash';
import { AddWrapper, TrashIconButton } from 'modules/create-rule/index.styled';
import {
  ChangeEvent, FC, ReactElement, useMemo,
} from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import AmountField from 'fields/AmountField';
import CheckboxField from 'fields/CheckboxField';
import DropdownField from 'fields/DropdownField';
import InputField from 'fields/InputField';

import DeleteIcon from 'assets/icons/DeleteIcon';
import DollarIcon from 'assets/icons/DollarIcon';
import OutlinedPlusIcon from 'assets/icons/OutlinedPlusIcon';
import { CriteriaCondition } from 'constants/enums';
import {
  ALERT_CRITERIA,
  ALERT_CRITERIA_HAS_AMOUNT,
  ALERT_CRITERIA_HAS_DESCRIPTION,
} from 'constants/fields';
import { RULE_CRITERIA_CONDITION_OPTIONS, RULE_TRANSACTION_TYPE_OPTIONS } from 'constants/options';
import { RuleFormValues } from 'utils/validation/ruleFormSchema';

import { InputAdornment, Root } from './index.styled';

interface CriteriaSectionProps {
  register: UseFormRegister<RuleFormValues>;
  onSetValue: UseFormSetValue<RuleFormValues>;
  control: Control<RuleFormValues>;
  errors: Partial<FieldErrors<RuleFormValues>>;
  watch: UseFormWatch<RuleFormValues>;
  onClearErrors: UseFormClearErrors<RuleFormValues>;
  isReadOnly: boolean;
}

const CriteriaSection: FC<CriteriaSectionProps> = ({
  register,
  onSetValue,
  control,
  errors,
  onClearErrors,
  watch,
  isReadOnly,
}) => {
  const intl = useIntl();
  const amountConditionName = `${ALERT_CRITERIA.name}[1].condition`;
  const amountName = `${ALERT_CRITERIA.name}[1].values[0]`;
  const secondaryAmountName = `${ALERT_CRITERIA.name}[1].values[1]`;
  const descriptionName = `${ALERT_CRITERIA.name}[0].values`;
  const typeName = `${ALERT_CRITERIA.name}[2].values[0]`;
  const amountConditionError = get(errors, `${ALERT_CRITERIA.name}[1].condition`)?.message;
  const amountError = get(errors, `${ALERT_CRITERIA.name}[1].values[0]`)?.message;
  const secondaryAmountError = get(errors, `${ALERT_CRITERIA.name}[1].values[1]`)?.message;
  const typeError = get(errors, `${ALERT_CRITERIA.name}[2].values[0]`)?.message;

  const descriptions = watch(`${ALERT_CRITERIA.name}[0].values`) as any;
  const amountCondition = watch(amountConditionName) as any;
  const isConditionBetween = useMemo(() => (
    amountCondition === CriteriaCondition.between
  ), [amountCondition]);

  const handleChangeCheckbox = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
    onSetValue(name, checked, { shouldValidate: true });
  };

  const handleAddDescription = () => {
    onSetValue(descriptionName, [...descriptions, ''], { shouldValidate: true });
  };

  const handleDeleteDescription = (index: number) => {
    const filteredDescriptions = [...descriptions];
    filteredDescriptions.splice(index, 1);
    onSetValue(descriptionName, filteredDescriptions, { shouldValidate: true });
    onClearErrors(`${descriptionName}[${index}]`);
  };

  return (
    <Root>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mb={5}>
        <FormLabel
          required
          sx={{
            whiteSpace: 'nowrap', display: 'flex', mr: 6,
          }}
        >
          <Typography variant="body2" textTransform="lowercase">
            {intl.formatMessage({ id: 'label.type' })}
          </Typography>
        </FormLabel>

        <Controller
          name={typeName}
          control={control}
          render={({ field }): ReactElement => (
            <DropdownField
              name={typeName}
              value={field?.value}
              options={RULE_TRANSACTION_TYPE_OPTIONS}
              inputProps={register(typeName)}
              id="select-criteria-type"
              displayEmpty
              sx={{ width: '375px' }}
              error={!!typeError}
              helperText={typeError ? intl.formatMessage({ id: typeError }) : ''}
              disabled={isReadOnly}
            />
          )}
        />
      </Box>

      <Box display="flex" alignItems="flex-start" justifyContent="space-between" width="100%" mb={5}>
        <Controller
          name={ALERT_CRITERIA_HAS_AMOUNT.name}
          control={control}
          render={({ field }): ReactElement => (
            <CheckboxField
              className="checkboxLabel"
              name={ALERT_CRITERIA_HAS_AMOUNT.name}
              inputProps={{
                ...register(ALERT_CRITERIA_HAS_AMOUNT.name),
                onChange: handleChangeCheckbox,
              }}
              checked={Boolean(field.value)}
              sx={{ pt: 3.5 }}
              label={intl.formatMessage({ id: ALERT_CRITERIA_HAS_AMOUNT.label })}
              disabled={isReadOnly}
            />
          )}
        />

        <Box display="flex" alignItems="flex-start" gap={2}>
          <Controller
            name={amountConditionName}
            control={control}
            render={({ field }): ReactElement => (
              <DropdownField
                name={amountConditionName}
                value={field?.value}
                options={RULE_CRITERIA_CONDITION_OPTIONS}
                inputProps={register(amountConditionName)}
                id="select-criteria-amount-condition"
                placeholder={intl.formatMessage({ id: 'placeholder.selectcomparison' })}
                displayEmpty
                sx={{
                  width: !isConditionBetween ? '184px' : '375px',
                }}
                error={!!amountConditionError}
                helperText={amountConditionError ? intl.formatMessage({ id: amountConditionError }) : ''}
                disabled={isReadOnly}
              />
            )}
          />

          {!isConditionBetween && (
            <Controller
              name={amountName}
              control={control}
              render={({ field }): ReactElement => (
                <AmountField
                  name={amountName}
                  className="amountValue"
                  value={field.value}
                  InputProps={{
                    ...register(amountName),
                    startAdornment: (
                      <InputAdornment position="start">
                        <DollarIcon size={18} />
                      </InputAdornment>
                    ),
                  }}
                  initialValue=""
                  error={!!amountError}
                  helperText={amountError ? intl.formatMessage({ id: amountError }) : ''}
                  id="input-criteria-amount-value"
                  disabled={isReadOnly}
                />
              )}
            />
          )}
        </Box>
      </Box>

      {isConditionBetween && (
        <Box display="flex" alignItems="flex-start" gap={2} justifyContent="flex-end" mb={5}>
          <Controller
            name={amountName}
            control={control}
            render={({ field }): ReactElement => (
              <AmountField
                name={amountName}
                className="amountValue"
                value={field.value}
                InputProps={{
                  ...register(amountName),
                  startAdornment: (
                    <InputAdornment position="start">
                      <DollarIcon size={18} />
                    </InputAdornment>
                  ),
                }}
                initialValue=""
                error={!!amountError}
                helperText={amountError ? intl.formatMessage({ id: amountError }) : ''}
                id="input-criteria-amount-value"
                disabled={isReadOnly}
              />
            )}
          />

          <Controller
            name={secondaryAmountName}
            control={control}
            render={({ field }): ReactElement => (
              <AmountField
                name={secondaryAmountName}
                className="amountValue"
                value={field.value}
                InputProps={{
                  ...register(secondaryAmountName),
                  startAdornment: (
                    <InputAdornment position="start">
                      <DollarIcon size={18} />
                    </InputAdornment>
                  ),
                }}
                initialValue=""
                error={!!secondaryAmountError}
                helperText={secondaryAmountError ? intl.formatMessage({ id: secondaryAmountError }) : ''}
                id="input-criteria-secondary-amount-value"
                disabled={isReadOnly}
              />
            )}
          />
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" width="100%" mb={3}>
        <Controller
          name={ALERT_CRITERIA_HAS_DESCRIPTION.name}
          control={control}
          render={({ field }): ReactElement => (
            <CheckboxField
              name={ALERT_CRITERIA_HAS_DESCRIPTION.name}
              inputProps={{
                ...register(ALERT_CRITERIA_HAS_DESCRIPTION.name),
                onChange: handleChangeCheckbox,
              }}
              checked={Boolean(field.value)}
              sx={{ mt: 3.5 }}
              label={intl.formatMessage({ id: ALERT_CRITERIA_HAS_DESCRIPTION.label })}
              className="descriptionCheckbox"
              disabled={isReadOnly}
            />
          )}
        />

        <Box display="flex" flexDirection="column" gap={3}>
          {descriptions && descriptions.map((description: string, index: number) => {
            const hasMoreDescriptions = descriptions.length > 1;
            const isLast = index + 1 === descriptions?.length;
            const width = hasMoreDescriptions ? 330 : 375;

            return (
              <Box key={index} display="flex" alignItems="center">
                <InputField
                  sx={{ width: `${isLast ? width - 38 : width}px` }}
                  slotProps={{
                    htmlInput: register(`${descriptionName}[${index}]`),
                  }}
                  disabled={isReadOnly}
                  placeholder={intl.formatMessage({ id: 'placeholder.enterdescription' })}
                />

                {hasMoreDescriptions && (
                  <TrashIconButton
                    onClick={() => handleDeleteDescription(index)}
                    aria-label="Delete Description"
                    id={`iconButton-deleteDescription-${index}`}
                    sx={{ ml: 2 }}
                    disabled={isReadOnly}
                  >
                    <DeleteIcon size={22} />
                  </TrashIconButton>
                )}

                {isLast && (
                  <AddWrapper
                    onClick={isReadOnly ? undefined : handleAddDescription}
                    disabled={isReadOnly}
                  >
                    <OutlinedPlusIcon size={22} />
                  </AddWrapper>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Root>
  );
};

export default CriteriaSection;
