import { styled } from '@mui/material/styles';

export const EmptyStateWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: '100%',
  borderRadius: '8px',
  height: '330px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
