import { FC, ReactElement, Suspense } from 'react';

import Loader from 'components/Loader';

interface CustomSuspenseProps {
  children: ReactElement;
}

const CustomSuspense: FC<CustomSuspenseProps> = ({ children }) => (
  <Suspense fallback={<Loader />}>
    {children}
  </Suspense>
);

export default CustomSuspense;
