import { styled } from '@mui/material/styles';

export const BubbleWrapper = styled('span')(() => ({
  display: 'inline-flex',
}));

export const Bubble = styled('span')<{ full?: boolean }>(({ theme, full }) => ({
  backgroundColor: theme.palette.general.lightGrey4,
  borderRadius: '4px',
  padding: theme.spacing(0, 2),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  ...(full ? {} : {
    maxWidth: '220px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
}));

export const MoreBubble = styled(Bubble)(({ theme }) => ({
  backgroundColor: theme.palette.general.lightGrey4,
  borderRadius: '30px',
  padding: theme.spacing(0, 3),
  marginRight: theme.spacing(1),
  fontSize: '12px',
  wordBreak: 'break-all',
  display: 'inline-flex',
}));

export const UnorderList = styled('ul')(({ theme }) => ({
  paddingLeft: theme.spacing(6),
}));
