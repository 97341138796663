import { Box } from '@mui/material';
import { isEqual } from 'lodash';
import {
  FC, ReactNode, SyntheticEvent, useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import CustomButton from 'components/CustomButton';
import FilterHeader from 'containers/AdvancedAccountFilter/FilterHeader';

import { useAppSelector } from 'hooks/useRedux';
import { accountsSelector } from 'store/bank-accounts/selectors';

import ChevronUpIcon from 'assets/icons/ChevronUpIcon';
import { GenericOption } from 'models/option.interface';

import {
  Accordion, AccordionDetails, AccordionSummary,
} from './index.styled';

interface AdvancedAccountFilterProps {
  accounts: GenericOption[];
  children?: ReactNode;
  onSubmit: () => void;
  onCancel: () => void;
  isDirty: boolean;
  isExpanded: boolean;
  onSetIsExpanded: (isExpanded: boolean) => void;
}

const AdvancedAccountFilter: FC<AdvancedAccountFilterProps> = ({
  children,
  onSubmit,
  onCancel,
  accounts,
  isDirty,
  isExpanded,
  onSetIsExpanded,
}) => {
  const intl = useIntl();
  const { data: bankAccounts } = useAppSelector(accountsSelector, isEqual);
  const displayAccounts = useMemo(() => {
    const ids = accounts?.map((acc) => acc?.value).filter((value) => value);
    return bankAccounts?.filter(({ id }) => ids.includes(id)) || [];
  }, [bankAccounts, accounts]);

  const handleChange = (event: SyntheticEvent, isExpended: boolean) => {
    onSetIsExpanded(isExpended);
  };

  return (
    <Accordion
      defaultExpanded
      expanded={isExpanded}
      onChange={handleChange}
      square
      disableGutters
    >
      <AccordionSummary expandIcon={<ChevronUpIcon size={24} />}>
        <FilterHeader accounts={displayAccounts} />
      </AccordionSummary>

      <AccordionDetails expanded={isExpanded}>
        <Box>
          {children}
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Box display="flex" gap={2}>
              <CustomButton
                className="cancel"
                variant="text"
                label={intl.formatMessage({ id: 'button.cancel' })}
                id="button-cancel"
                onClick={onCancel}
                disabled={!isDirty}
              />
              <CustomButton
                label={intl.formatMessage({ id: 'button.saveAndApply' })}
                id="button-save"
                onClick={onSubmit}
                disabled={!isDirty}
              />
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdvancedAccountFilter;
