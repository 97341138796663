/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SettingsIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 24 24">
    <path fill={color} d="m19.795 13.922-1.435-.793a5.953 5.953 0 0 0 0-2.265l1.435-.793a.395.395 0 0 0 .175-.195.375.375 0 0 0 .01-.257 7.96 7.96 0 0 0-1.842-3.052.421.421 0 0 0-.499-.074l-1.435.794a6.433 6.433 0 0 0-2.049-1.133V4.571a.376.376 0 0 0-.089-.242.406.406 0 0 0-.228-.135 8.84 8.84 0 0 0-3.68 0 .407.407 0 0 0-.227.135.376.376 0 0 0-.089.242v1.587A6.636 6.636 0 0 0 7.794 7.29L6.36 6.496a.415.415 0 0 0-.5.075A7.913 7.913 0 0 0 4.02 9.622a.37.37 0 0 0 .01.257c.033.083.095.151.175.195l1.436.793a5.953 5.953 0 0 0 0 2.265l-1.436.793a.395.395 0 0 0-.175.195.374.374 0 0 0-.01.257 7.96 7.96 0 0 0 1.843 3.052.42.42 0 0 0 .499.074l1.435-.794c.603.497 1.298.881 2.049 1.133v1.587c0 .088.031.173.089.242a.407.407 0 0 0 .227.135 8.838 8.838 0 0 0 3.68 0 .407.407 0 0 0 .228-.135.376.376 0 0 0 .088-.242v-1.587a6.64 6.64 0 0 0 2.05-1.133l1.435.794a.416.416 0 0 0 .498-.074 7.913 7.913 0 0 0 1.843-3.052.383.383 0 0 0-.012-.258.403.403 0 0 0-.176-.197Zm-7.797.655a2.782 2.782 0 0 1-1.497-.435 2.608 2.608 0 0 1-.993-1.158 2.478 2.478 0 0 1-.154-1.49c.104-.502.361-.961.738-1.322a2.735 2.735 0 0 1 1.38-.707 2.807 2.807 0 0 1 1.558.147c.492.195.913.526 1.21.95.296.425.454.924.454 1.434a2.53 2.53 0 0 1-.79 1.824 2.76 2.76 0 0 1-1.906.757Z" />
  </svg>
);

export default SettingsIcon;
