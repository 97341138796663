import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& svg path': {
    fill: theme.palette.general.lightBlack,
  },
}));

export const EditButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  '& svg path': {
    fill: theme.palette.primary.main,
  },
}));
