import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const BillingDetails = lazy(
  () => CustomLazyLoader(
    () => import(/* webpackChunkName: 'BillingDetails' */ 'modules/billing/containers/BillingDetails'),
    'BillingDetails',
  ),
);

export default BillingDetails;
