import { SxProps } from '@mui/material';
import { FC } from 'react';

import { ButtonGroupVariant } from 'assets/themes/theme-declarations';

import { Button, ButtonGroup } from './index.styled';

interface GroupButtonProps {
  variant?: ButtonGroupVariant;
  buttonsConfig: {
    label: string;
    onClick: () => void;
    id: string;
    isActive: boolean;
  }[];
  disabled?: boolean;
  sx?: SxProps;
}

export const GroupButton: FC<GroupButtonProps> = ({
  buttonsConfig,
  variant = 'contained',
  sx,
  disabled = false,
}) => (
  <ButtonGroup variant={variant} sx={sx}>
    {buttonsConfig.map(({
      label, onClick, id, isActive,
    }) => (
      <Button onClick={onClick} key={id} className={isActive ? 'activeButton' : ''} disabled={disabled}>
        {label}
      </Button>
    ))}
  </ButtonGroup>
);
