import { Box, CardMedia, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface EmptyStateProps {
  image: string;
  title?: string;
  subtitle?: ReactNode;
  isBold?: boolean;
  children?: ReactNode;
  width?: string;
  height?: string;
}

const EmptyState:FC<EmptyStateProps> = ({
  image,
  title,
  subtitle,
  children,
  isBold,
  width = '70%',
  height = '100px',
}) => (
  <Box display="flex" alignItems="center" flexDirection="column" width="inherit">
    <Box display="flex" alignItems="center" flexDirection="column" width={width}>
      <CardMedia
        component="img"
        alt={title || 'No data'}
        src={image}
        width="100px"
        height={height}
        sx={{ objectFit: 'contain' }}
      />
      {title && (
        <Typography variant="subtitle1" fontWeight={700} mb={3} mt={5} textAlign="center">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant="body2"
          textAlign="center"
          fontWeight={isBold ? 600 : 400}
          color={isBold ? 'textPrimary' : 'textSecondary'}
          mb={2}
          mt={title ? 0 : 5}
        >
          {subtitle}
        </Typography>
      )}
      {children}
    </Box>
  </Box>
);

export default EmptyState;
