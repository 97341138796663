import Ally from 'assets/bankLogos/Ally.svg';
import BankOfAmerica from 'assets/bankLogos/BankOfAmerica.svg';
import BMO from 'assets/bankLogos/BMO.svg';
import CapitalOne from 'assets/bankLogos/CapitalOne.svg';
import ChaseBank from 'assets/bankLogos/ChaseBank.svg';
import Citigroup from 'assets/bankLogos/Citigroup.svg';
import Citizens from 'assets/bankLogos/Citizens.svg';
import FifthThird from 'assets/bankLogos/FifthThird.svg';
import FirstCitizens from 'assets/bankLogos/FirstCitizens.svg';
import Huntington from 'assets/bankLogos/Huntington.svg';
import MT from 'assets/bankLogos/M&T.svg';
import MorganStanley from 'assets/bankLogos/MorganStanley.svg';
import PNC from 'assets/bankLogos/PNC.svg';
import TD from 'assets/bankLogos/TD.svg';
import UsBancorp from 'assets/bankLogos/UsBancorp.svg';
import WellsFargo from 'assets/bankLogos/WellsFargo.svg';
import PrimaryAccountLogo from 'assets/images/logo-beta.svg';

export const BANK_LOGO_BASED_ON_INSTITUTION_CODE: Record<number, string> = {
  2539: Ally,
  2393: BankOfAmerica,
  50020: BMO,
  17501: CapitalOne,
  2: ChaseBank,
  10: Citigroup,
  45: Citizens,
  15168: FifthThird,
  2867: FirstCitizens,
  1324: FirstCitizens,
  20985: FirstCitizens,
  17843: Huntington,
  36: MT,
  2746: MT,
  760: MorganStanley,
  21: PNC,
  20554: TD,
  1833: TD,
  9038: UsBancorp,
  6: WellsFargo,
  999999: PrimaryAccountLogo,
};
