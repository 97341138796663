import { styled } from '@mui/material/styles';

import { variants } from 'assets/themes/variants';

interface InitialWrapperProps {
  size: 'large' | 'medium' | 'small';
  color: 'primary' | 'secondary';
}

const sizes = {
  large: {
    height: '48px',
    width: '48px',
    flex: '0 0 40px',
    ...variants.h1,
  },
  medium: {
    height: '40px',
    width: '40px',
    flex: '0 0 30px',
    ...variants.h2,
  },
  small: {
    height: '28px',
    width: '28px',
    flex: '0 0 30px',
    ...variants.subtitle1,
    lineHeight: '19px',
    fontWeight: 700,
  },
};

const getBackgroundColor = (theme: any) => ({
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.dark,
});

export const InitialWrapper = styled('div')<InitialWrapperProps>(({ theme, color, size }) => ({
  backgroundColor: getBackgroundColor(theme)?.[color] || theme.palette.primary.main,
  borderRadius: '8px',
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(3),
  textTransform: 'uppercase',
  ...(sizes[size] || sizes.large),
}));
