import { alpha, styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  height: '100%',
  marginRight: theme.spacing(7),
  paddingRight: theme.spacing(7),
  borderRight: `1px solid ${theme.palette.primary.main}`,

  '& .addButton, & .addButton:disabled, & .addButton:hover': {
    display: 'flex',
    alignItems: 'center',
    border: `1px dashed ${theme.palette.primary.main}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.18),

    '& .MuiButton-startIcon svg > path': {
      fill: theme.palette.primary.main,
    },
  },
}));
