/* eslint max-len: 0 */
import { CustomIconInterface } from 'models/icon.interface';

const LinkIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 7C3.239 7 1 9.239 1 12C1 14.761 3.239 17 6 17H10V15H6C4.343 15 3 13.657 3 12C3 10.343 4.343 9 6 9H10V7H6ZM14 7V9H18C19.657 9 21 10.343 21 12C21 13.657 19.657 15 18 15H14V17H18C20.761 17 23 14.761 23 12C23 9.239 20.761 7 18 7H14ZM7 11V13H17V11H7Z" fill={color} />
  </svg>
);

export default LinkIcon;
