import MuiMenu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

export const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(1.5),
    minWidth: '170px',
  },
}));

export const LogoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& svg path': {
    fill: theme.palette.error.main,
  },
}));
