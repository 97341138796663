/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ChevronDownIcon = ({ color = 'currentColor', size = 24, className }: CustomIconInterface): ReactElement => (
  <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.09456 9.14346L6.63133 8.59581C6.69274 8.5344 6.7753 8.5 6.86128 8.5C6.94727 8.5 7.02982 8.5344 7.09123 8.59581L12.0003 13.5937L16.9094 8.59581C16.9708 8.5344 17.0534 8.5 17.1394 8.5C17.2254 8.5 17.3079 8.5344 17.3693 8.59581L17.9061 9.14346C17.9663 9.20611 18 9.29034 18 9.37807C18 9.4658 17.9663 9.55003 17.9061 9.61268L12.2296 15.4042C12.1682 15.4656 12.0857 15.5 11.9997 15.5C11.9137 15.5 11.8311 15.4656 11.7697 15.4042L6.09325 9.61268C6.03323 9.54986 5.99976 9.46552 6 9.37779C6.00025 9.29006 6.0342 9.20593 6.09456 9.14346Z" fill={color} />
  </svg>
);

export default ChevronDownIcon;
