import { z } from 'zod';

import {
  COMPANY_NAME, COMPANY_PHONE_NUMBER, WORKSPACE_NAME, WORKSPACE_OPTIONS,
} from 'constants/fields';
import { REGEX_WORKSPACE_NAME } from 'constants/regex';
import { checkValidPhone } from 'utils/validation/customRules/validations';

export const workspaceFormSchema = z.object({
  [WORKSPACE_NAME.name]: z
    .string()
    .regex(REGEX_WORKSPACE_NAME, { message: 'error.workspaceName' })
    .min(1, {
      message: 'error.mandatoryField',
    })
    .min(3, {
      message: 'error.displayNameMinLength',
    })
    .max(50, {
      message: 'error.displayNameMaxLength',
    }),
  [COMPANY_NAME.name]: z
    .string()
    .max(100, {
      message: 'error.companyNameMaxLength',
    })
    .min(1, {
      message: 'error.mandatoryField',
    }),
  [COMPANY_PHONE_NUMBER.name]: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    })
    .refine(checkValidPhone, {
      message: 'error.phoneNumber',
    })
    .nullable(),
});

export type WorkspaceFormValues = z.infer<typeof workspaceFormSchema>

export const workspaceSelectionSchema = z.object({
  [WORKSPACE_OPTIONS.name]: z
    .string()
    .regex(REGEX_WORKSPACE_NAME, { message: 'error.workspaceName' })
    .min(1, {
      message: 'error.mandatoryField',
    }),
});

export type WorkspaceSelectionValues = z.infer<typeof workspaceSelectionSchema>

export const dummyCompanyFormSchema = z.object({
  [COMPANY_NAME.name]: z
    .string()
    .max(100, {
      message: 'error.companyNameMaxLength',
    })
    .min(1, {
      message: 'error.mandatoryField',
    }),
  [COMPANY_PHONE_NUMBER.name]: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    })
    .refine(checkValidPhone, {
      message: 'error.phoneNumber',
    })
    .nullable(),
});

export type DummyCompanyFormValues = z.infer<typeof dummyCompanyFormSchema>
