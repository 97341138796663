import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import {
  Grid2 as Grid, Box, Typography, Skeleton,
} from '@mui/material';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import DateForm from 'components/DateForm';
import AdvancedAccountFilter from 'containers/AdvancedAccountFilter';
import AccountsFilter from 'containers/AdvancedAccountFilter/AccountsFilter';
import CreditTransactions from 'modules/insights-cashflow/containers/CreditTransactions';
import DebitTransactions from 'modules/insights-cashflow/containers/DebitTransactions';
import Summary from 'modules/insights-cashflow/containers/Summary';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { accountsSelector } from 'store/bank-accounts/selectors';
import { fetchCashFlowDetails } from 'store/cash-flow/cashFlowSlice';
import { cashFlowDetailsSelector, isLoadingCashFlowDetailsSelector } from 'store/cash-flow/selectors';

import { DateFormats, DateType, DefaultType } from 'constants/enums';
import { CashFlowSearchQueryParams } from 'models/cashFlow.interface';
import { GenericOption } from 'models/option.interface';
import { formatDate, formatPrice } from 'utils/formatters';
import {
  cashflowSchema,
  CashflowSchema,
} from 'utils/validation/cashflowSchema';

const CashflowAnalysis = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isLoadingCashFlowDetailsSelector);
  const cashFlowDetails = useAppSelector(cashFlowDetailsSelector);
  const { data: bankAccounts } = useAppSelector(accountsSelector, isEqual);
  const [tempAccounts, setTempAccounts] = useState<GenericOption[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<GenericOption[]>([]);
  const [filters, setFilters] = useState<CashFlowSearchQueryParams>({
    interval: DateType.sevenDays,
    bankAccountIds: [], // TODO: we need the top 5 accounts
  });
  const [expanded, setExpanded] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<CashflowSchema>({
    resolver: zodResolver(cashflowSchema),
    mode: 'onSubmit',
    defaultValues: {
      dateRange: {
        type: DateType.sevenDays,
        from: null,
        to: null,
      },
    },
  });

  const isFormDirty = useMemo(() => {
    if (isDirty) {
      return true;
    }

    const newIds = tempAccounts?.map((acc) => acc?.value).filter((value) => value);
    return !isEqual(newIds, filters.bankAccountIds);
  }, [isDirty, tempAccounts, filters.bankAccountIds]);

  useEffect(() => {
    dispatch(fetchCashFlowDetails(filters));
  }, []);

  const onSubmit = (values: CashflowSchema) => {
    let newFilters:CashFlowSearchQueryParams = {
      bankAccountIds: tempAccounts.map(({ value }) => value),
    };

    if (tempAccounts.find(({ value }) => value === DefaultType.all)) {
      newFilters.bankAccountIds = bankAccounts?.map(({ id }) => id) || [];
    }

    if (values.dateRange.type === DateType.custom) {
      newFilters = {
        ...newFilters,
        dateFrom: values.dateRange.from
          ? formatDate(new Date(values.dateRange.from), DateFormats.transactionDate)
          : undefined,
        dateTo: values.dateRange.to
          ? formatDate(new Date(values.dateRange.to), DateFormats.transactionDate)
          : undefined,
      };
    } else {
      newFilters = {
        ...newFilters,
        interval: values.dateRange.type as DateType,
      };
    }

    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
      setSelectedAccounts(tempAccounts);
      reset(values);
    }

    setExpanded(false);
  };

  const onCancel = () => {
    reset({
      dateRange: {
        type: filters.interval || DateType.custom,
        from: filters?.dateFrom ? new Date(filters?.dateFrom).getTime() : null,
        to: filters?.dateTo ? new Date(filters?.dateTo).getTime() : null,
      },
    });
    setTempAccounts(selectedAccounts);
    setExpanded(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <AdvancedAccountFilter
        accounts={selectedAccounts}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onCancel}
        isDirty={isFormDirty}
        isExpanded={expanded}
        onSetIsExpanded={setExpanded}
      >
        <Grid container>
          <Grid size={{ xs: 8 }}>
            <AccountsFilter
              selectedAccounts={tempAccounts}
              setSelectedAccounts={setTempAccounts}
            />
          </Grid>
          <Grid size={{ xs: 4 }}>
            <DateForm
              <CashflowSchema>
              register={register}
              watch={watch}
              control={control}
              errors={errors}
              required
            />
          </Grid>
        </Grid>
      </AdvancedAccountFilter>

      <Summary filters={filters} />

      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h2">
          {intl.formatMessage({ id: 'label.consolidatedStartingAccountBalance' })}
        </Typography>
        {isLoading ? (
          <Skeleton animation="wave" variant="rectangular" height={40} width={100} />
        ) : (
          <Typography variant="h2">
            {formatPrice({ price: cashFlowDetails?.startBalance || 0 })}
          </Typography>
        )}
      </Box>

      <DebitTransactions filters={filters} />

      <CreditTransactions filters={filters} />

      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h2">
          {intl.formatMessage({ id: 'label.consolidatedEndingAccountBalance' })}
        </Typography>
        {isLoading ? (
          <Skeleton animation="wave" variant="rectangular" height={40} width={100} />
        ) : (
          <Typography variant="h2">
            {formatPrice({ price: cashFlowDetails?.endBalance || 0 })}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CashflowAnalysis;
