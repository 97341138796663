import { z } from 'zod';

import {
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
} from 'constants/fields';
import { checkValidPhone } from 'utils/validation/customRules/validations';

export const contactFormSchema = z.object({
  [FIRST_NAME.name]: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    })
    .max(100, {
      message: 'error.userNameMaxLength',
    })
    .nullable(),
  [LAST_NAME.name]: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    })
    .max(100, {
      message: 'error.userNameMaxLength',
    })
    .nullable(),
  [PHONE_NUMBER.name]: z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    })
    .refine(checkValidPhone, {
      message: 'error.phoneNumber',
    })
    .nullable(),
});

export type ContactFormValues = z.infer<typeof contactFormSchema>
