import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import TransactionAccordion from 'modules/insights-cashflow/components/TransactionAccordion';
import Transactions from 'modules/insights-cashflow/components/Transactions';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  fetchDebitTransaction,
  setCashFlowDebitTransactionPagination,
  setCashFlowDebitTransactionSort,
} from 'store/cash-flow/cashFlowSlice';
import {
  cashFlowDebitPaginationSelector,
  cashFlowDebitSortOptionsSelector,
  cashFlowDebitTransactionsSelector,
  isLoadingCashFlowDebitSelector,
  totalDebitValueSelector,
} from 'store/cash-flow/selectors';

import { CashFlowSearchType } from 'constants/enums';
import { DEFAULT_PAGINATION_SIZE } from 'constants/general';
import { formatPrice } from 'utils/formatters';
import { getRequestSortOptions } from 'utils/sort';

const DebitTransactions = ({ filters }: any) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(cashFlowDebitTransactionsSelector, isEqual);
  const isLoading = useAppSelector(isLoadingCashFlowDebitSelector, isEqual);
  const sortOptions = useAppSelector(cashFlowDebitSortOptionsSelector, isEqual);
  const pagination = useAppSelector(cashFlowDebitPaginationSelector, isEqual);
  const totalDebitValue = useAppSelector(totalDebitValueSelector, isEqual);

  useEffect(() => {
    dispatch(fetchDebitTransaction({
      page: pagination.page - 1,
      size: DEFAULT_PAGINATION_SIZE,
      sort: `${sortOptions.name},${sortOptions.type}`,
      type: CashFlowSearchType.credit,
      ...filters,
    }));
  }, [sortOptions, filters]);

  const handlePagination = (newPage: number) => {
    dispatch(setCashFlowDebitTransactionPagination({ page: newPage }));
    dispatch(fetchDebitTransaction({
      page: newPage - 1,
      size: DEFAULT_PAGINATION_SIZE,
      sort: `${sortOptions.name},${sortOptions.type}`,
      type: CashFlowSearchType.credit,
      ...filters,
    }));
  };

  const handleSort = (columnName: string) => {
    const newSortOption = getRequestSortOptions(columnName, sortOptions);
    dispatch(setCashFlowDebitTransactionSort(newSortOption));
  };

  return (
    <TransactionAccordion
      title={intl.formatMessage({ id: 'label.debits' })}
      totalAmount={formatPrice({ price: totalDebitValue })}
    >
      <Transactions
        transactions={transactions}
        sortOptions={sortOptions}
        pagination={pagination}
        isLoading={isLoading}
        onPage={handlePagination}
        onSort={handleSort}
      />
    </TransactionAccordion>
  );
};

export default DebitTransactions;
