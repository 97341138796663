import { forwardRef, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import InputField from 'fields/InputField';

const NumberFormatCustom = forwardRef((props: any, ref: any) => {
  const {
    onChange, name, initialValue = '0.00', ...other
  } = props;

  const preventNegativeNumbers = (e: any) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    if (!other?.value) {
      setInputValue(initialValue);
    } else if (other.value && other.value !== inputValue) {
      setInputValue(other.value);
    }
  }, [other?.value]);

  const handleValueChange = (values: any) => {
    const { value: val } = values; // `value` is the raw value without formatting
    const formattedValue = formatValue(val);

    setInputValue(formattedValue);
    onChange({
      target: {
        name,
        value: formattedValue,
      },
    });
  };

  const formatValue = (val: string) => {
    if (!val) return '0.00';
    const numericValue = val.replace(/\D/g, '');
    const number = parseFloat(numericValue) / 100;
    return number.toFixed(2);
  };

  return (
    <NumericFormat
      {...other}
      name={name}
      getInputRef={ref}
      value={inputValue}
      thousandSeparator=","
      decimalSeparator="."
      fixedDecimalScale
      onKeyDown={preventNegativeNumbers}
      onValueChange={handleValueChange} // Use onValueChange instead of onChange
      valueIsNumericString
    />
  );
});

const AmountField = ({
  InputProps,
  inputProps,
  isAllowed,
  initialValue,
  ...remainingProps
}: any) => (
  <InputField
    {...remainingProps}
    InputProps={{
      ...InputProps,
      inputProps: {
        ...inputProps,
        initialValue,
        isAllowed,
      },
      inputComponent: NumberFormatCustom,
    }}
  />
);

export default AmountField;
