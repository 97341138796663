/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const FilterIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.2492 4H4.75087C4.08468 4 3.74853 4.80831 4.22056 5.28034L10 11.0607V17.5C10 17.7447 10.1194 17.9741 10.3199 18.1144L12.8199 19.8638C13.3131 20.2091 14 19.8592 14 19.2494V11.0607L19.7796 5.28034C20.2506 4.80925 19.9168 4 19.2492 4Z" fill={color} />
  </svg>
);

export default FilterIcon;
