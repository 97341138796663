/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const CompanyIcon = ({ color = 'currenColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 3.5V21.5H12H22V7.5H12V3.5H2ZM4 5.5H6V7.5H4V5.5ZM8 5.5H10V7.5H8V5.5ZM4 9.5H6V11.5H4V9.5ZM8 9.5H10V11.5H8V9.5ZM12 9.5H20V19.5H12V17.5H14V15.5H12V13.5H14V11.5H12V9.5ZM16 11.5V13.5H18V11.5H16ZM4 13.5H6V15.5H4V13.5ZM8 13.5H10V15.5H8V13.5ZM16 15.5V17.5H18V15.5H16ZM4 17.5H6V19.5H4V17.5ZM8 17.5H10V19.5H8V17.5Z" fill={color} />
  </svg>
);

export default CompanyIcon;
