/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const OutlineCheckIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size}>
    <path fill={color} d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
  </svg>
);

export default OutlineCheckIcon;
