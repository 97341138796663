import { Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import CardType from 'components/CardType';

import { NOT_APPLICABLE } from 'constants/general';

import { InstitutionWrapper } from './index.styled';

interface RecipientAccountDetailsProps {
  isDebit: boolean;
  accountNumberTail: string;
  accountName?: string;
  accountTypeLabel?: string;
}

const RecipientAccountDetails: FC<RecipientAccountDetailsProps> = ({
  isDebit,
  accountTypeLabel,
  accountNumberTail,
  accountName,
}) => {
  const intl = useIntl();

  return (
    <InstitutionWrapper>
      <Typography variant="body1" className="text-ellipsis">
        {isDebit || !accountName
          ? `*${accountNumberTail}`
          : `${accountName} *${accountNumberTail}`}
      </Typography>

      {isDebit ? (
        <CardType label={intl.formatMessage({ id: 'label.debit' })} />
      ) : (
        <Typography variant="label2" fontWeight={400} color="textSecondary">
          {accountTypeLabel ? intl.formatMessage({ id: accountTypeLabel }) : NOT_APPLICABLE}
        </Typography>
      )}
    </InstitutionWrapper>
  );
};

export default RecipientAccountDetails;
