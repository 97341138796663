import { Box } from '@mui/material';
import { FC } from 'react';

import { ApproveBox, RejectBox } from 'modules/transaction-approval/components/Actions/index.styled';

import { usePermissions } from 'hooks/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { selectedTransactionsSelector } from 'store/transaction-approval/selectors';
import { removeSelectedTransaction, setSelectedTransaction } from 'store/transaction-approval/transactionApprovalSlice';
import { userSelector } from 'store/user/selectors';

import CloseIcon from 'assets/icons/CloseIcon';
import OutlineCheckIcon from 'assets/icons/OutlineCheckIcon';
import { TransactionApprovalStatus } from 'constants/enums';
import { RolePermissions } from 'constants/permissionEnums';
import { TransactionApproval } from 'models/transactionApproval.interface';

interface ActionsProps {
  transactionApproval: TransactionApproval;
}

const Actions:FC<ActionsProps> = ({ transactionApproval }) => {
  const loggedUser = useAppSelector(userSelector);
  const selectedTransactions = useAppSelector(selectedTransactionsSelector);
  const dispatch = useAppDispatch();
  const { isAllowed } = usePermissions();
  const canReview = isAllowed({ permission: RolePermissions.ReviewTransactionApproval });
  const canReject = canReview && (loggedUser?.id === transactionApproval.createdByUserId
    || Boolean(loggedUser?.transactionLimit && transactionApproval.amount <= loggedUser?.transactionLimit));
  const canApprove = canReview
    && Boolean(loggedUser?.transactionLimit && transactionApproval.amount <= loggedUser?.transactionLimit);

  const handleReject = () => {
    if (selectedTransactions?.[transactionApproval.id]?.status === TransactionApprovalStatus.rejected) {
      dispatch(removeSelectedTransaction(transactionApproval.id));
    } else {
      dispatch(setSelectedTransaction({
        transaction: transactionApproval,
        status: TransactionApprovalStatus.rejected,
      }));
    }
  };

  const handleApproved = () => {
    if (selectedTransactions?.[transactionApproval.id]?.status === TransactionApprovalStatus.approved) {
      dispatch(removeSelectedTransaction(transactionApproval.id));
    } else {
      dispatch(setSelectedTransaction({
        transaction: transactionApproval,
        status: TransactionApprovalStatus.approved,
      }));
    }
  };

  return (
    <Box display="flex" gap={2}>
      {canApprove && (
        <ApproveBox
          role="presentation"
          onClick={handleApproved}
          selected={selectedTransactions?.[transactionApproval.id]?.status === TransactionApprovalStatus.approved}
        >
          <OutlineCheckIcon size={12} />
        </ApproveBox>
      )}
      {canReject && (
        <RejectBox
          role="presentation"
          onClick={handleReject}
          selected={selectedTransactions?.[transactionApproval.id]?.status === TransactionApprovalStatus.rejected}
        >
          <CloseIcon size={12} />
        </RejectBox>
      )}
    </Box>
  );
};

export default Actions;
