/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const CloseIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.1283 16.2426L13.8857 12L18.1283 7.75736C18.2533 7.63234 18.3236 7.46277 18.3236 7.28596C18.3236 7.10915 18.2533 6.93958 18.1283 6.81455L17.1855 5.87174C17.0605 5.74672 16.8909 5.67648 16.7141 5.67648C16.5373 5.67648 16.3677 5.74672 16.2427 5.87174L12 10.1144L7.75739 5.87174C7.63237 5.74672 7.4628 5.67648 7.28599 5.67648C7.10918 5.67648 6.93961 5.74672 6.81458 5.87174L5.87177 6.81455C5.74675 6.93958 5.67651 7.10915 5.67651 7.28596C5.67651 7.46277 5.74675 7.63234 5.87177 7.75736L10.1144 12L5.87177 16.2426C5.74675 16.3677 5.67651 16.5372 5.67651 16.714C5.67651 16.8909 5.74675 17.0604 5.87177 17.1855L6.81458 18.1283C6.93961 18.2533 7.10918 18.3235 7.28599 18.3235C7.4628 18.3235 7.63237 18.2533 7.75739 18.1283L12 13.8856L16.2427 18.1283C16.3677 18.2533 16.5373 18.3235 16.7141 18.3235C16.8909 18.3235 17.0605 18.2533 17.1855 18.1283L18.1283 17.1855C18.2533 17.0604 18.3236 16.8909 18.3236 16.7141C18.3236 16.5372 18.2533 16.3677 18.1283 16.2426Z" fill={color} />
  </svg>
);

export default CloseIcon;
