import DefaultBankPicture from 'assets/images/default-bank-picture.svg';
import { BANK_ACCOUNT_TYPES_AS_LABELS } from 'constants/labels';
import { BANK_LOGO_BASED_ON_INSTITUTION_CODE } from 'constants/logos';
import { BankAccount, Institution } from 'models/bankAccount.interface';

export const getBankType = (account: BankAccount): string|null => {
  if (account && account?.type && account?.type in BANK_ACCOUNT_TYPES_AS_LABELS) {
    return BANK_ACCOUNT_TYPES_AS_LABELS[account.type];
  }

  return null;
};

export const getTypesAsOptions = (accounts: BankAccount[]) => {
  const bankTypeOptions: string[] = [];

  accounts.forEach((account) => {
    if (account?.type && !bankTypeOptions.includes(account?.type)) {
      bankTypeOptions.push(account.type);
    }
  });

  return bankTypeOptions;
};

export const getInstitutionsAsOptions = (accounts: BankAccount[]) => {
  const institutions:Institution[] = [];

  accounts.forEach((account) => {
    const hasInstitution = institutions.some((institution) => account?.institution?.name === institution?.name);

    if (account?.institution?.name && !hasInstitution) {
      institutions.push(account?.institution);
    }
  });

  return institutions;
};

export const getBankLogo = (institution?: Institution): string => {
  const { code, smallLogoUrl } = institution || {};

  if (smallLogoUrl) {
    return smallLogoUrl;
  }

  if (code && BANK_LOGO_BASED_ON_INSTITUTION_CODE[+code]) {
    return BANK_LOGO_BASED_ON_INSTITUTION_CODE[+code];
  }

  return DefaultBankPicture;
};
