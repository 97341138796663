/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ArrowDownIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9993 4L10.9993 16.1695L5.40993 10.5798L4 11.9994L12 20L20 11.9994L18.5901 10.5894L12.9996 16.1695L12.9996 4L10.9993 4Z" fill={color} />
  </svg>
);

export default ArrowDownIcon;
