import { Box, Collapse } from '@mui/material';
import { Bubble, BubbleWrapper } from 'modules/rules-management/index.styled';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useIntl } from 'react-intl';

import BanksIdentifiers from 'modules/rules-management/components/BanksIdentifiers';
import Memos from 'modules/rules-management/components/Memos';
import { ExpandMore, StyledTypography } from 'modules/rules-management/containers/RuleInfo/index.styled';

import { useAppSelector } from 'hooks/useRedux';
import { accountsSelector } from 'store/bank-accounts/selectors';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import { CriteriaCondition, TransactionType } from 'constants/enums';
import { RuleDetails } from 'models/rule.interface';

interface RuleInfoProps {
  rule: RuleDetails;
}

const RuleInfo:FC<RuleInfoProps> = ({ rule }) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const { data: bankAccounts } = useAppSelector(accountsSelector);
  const amount = useMemo(() => rule.criteria?.find(({ key }) => key === 'amount'), [rule]);
  const type = useMemo(() => rule.criteria?.find(({ key }) => key === 'trnType'), [rule]);
  const memo = useMemo(() => rule.criteria?.find(({ key }) => key === 'memo'), [rule]);
  const renderIdentifiers = useCallback(() => (
    rule.identifiers
      ? <BanksIdentifiers identifiers={rule.identifiers} />
      : null
  ), [bankAccounts]);
  const renderMemo = useCallback(() => (memo ? <Memos memos={memo.values} /> : null), [memo]);
  const renderChunk = (chunk: any) => <BubbleWrapper><Bubble>{chunk}</Bubble></BubbleWrapper>;

  const handleSeeMore = () => {
    setExpanded(!expanded);
  };

  const [min, max] = useMemo(() => (
    amount && amount.condition === CriteriaCondition.between
      ? amount.values[0]?.split(':')
      : []
  ), [amount]);

  return (
    <Box display="flex" gap={1}>
      <div>
        <ExpandMore
          expand={expanded}
          onClick={handleSeeMore}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ChevronDownIcon />
        </ExpandMore>
      </div>

      <div>
        <StyledTypography
          role="presentation"
          full={expanded}
          onClick={handleSeeMore}
        >
          {intl.formatMessage(
            { id: 'label.ruleIdentifiersDescription' },
            { identifiers: renderIdentifiers },
          )}
          {memo && (
            intl.formatMessage(
              { id: 'label.ruleIncludesDescription' },
              { includes: renderMemo },
            )
          )}
          {amount && (
            intl.formatMessage(
              { id: 'label.ruleAmountDescription' },
              {
                amount: amount.values[0],
                condition: amount.condition,
                min,
                max,
                p: renderChunk,
              },
            )
          )}
          {type && (
            intl.formatMessage(
              { id: 'label.ruleTypeDescription' },
              {
                type: intl.formatMessage({
                  id: type.values[0] === TransactionType.debit
                    ? 'label.debitWithdrawal'
                    : 'label.creditWithdrawal',
                }),
                p: renderChunk,
              },
            )
          )}
        </StyledTypography>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div>
            {rule.message && (
              <StyledTypography full>
                {intl.formatMessage({ id: 'label.sendMessage' })}
                &nbsp;
                <BubbleWrapper><Bubble full>{rule.message}</Bubble></BubbleWrapper>
              </StyledTypography>
            )}
            {rule.recipients && rule.recipients?.length > 0 && (
              <StyledTypography full>
                {intl.formatMessage({ id: 'label.to' })}
                &nbsp;
                {rule.recipients.map(({ id, name }) => (
                  <BubbleWrapper key={id || name}><Bubble full>{name}</Bubble></BubbleWrapper>
                ))}
              </StyledTypography>
            )}
          </div>
        </Collapse>
      </div>
    </Box>
  );
};

export default RuleInfo;
