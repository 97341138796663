import {
  FormControl, FormLabel, TextField, TextFieldProps, Tooltip, Typography, Box,
} from '@mui/material';
import { FC } from 'react';

import InfoIcon from 'assets/icons/InfoIcon';

interface InputFieldProps {
  tooltip?: string;
}

const InputField:FC<TextFieldProps & InputFieldProps> = ({
  name,
  required,
  fullWidth,
  label,
  tooltip,
  size = 'small',
  variant = 'filled',
  sx = {},
  ...remainingProps
}) => (
  <FormControl component="div" fullWidth={fullWidth} sx={sx}>
    {label && (
    <Box display="flex" alignItems="center" gap={1}>
      <FormLabel required={required}>
        <Typography variant="label1" color="textPrimary">
          {label}
        </Typography>
      </FormLabel>
      {tooltip && (
      <Tooltip
        arrow
        title={tooltip}
        placement="top"
      >
        <Box display="flex" alignItems="center"><InfoIcon size={16} /></Box>
      </Tooltip>
      )}
    </Box>
    )}
    <TextField
      {...remainingProps}
      variant={variant}
      size={size}
      name={name}
    />
  </FormControl>
);

export default InputField;
