import axios from 'axios';

export const axiosInstance = axios.create();
export const axiosInstanceFB = axios.create();

export const runGetApiRequest = async (endpoint: string, params: any = {}) => (
  axiosInstance.get(
    endpoint,
    { params },
  )
);

export const runGetBlobApiRequest = async (endpoint: string, params: any = {}) => (
  axiosInstance.get(
    endpoint,
    { params, responseType: 'blob' },
  )
);

export const runPostApiRequest = async (endpoint: string, data?: any, params: any = {}) => (
  axiosInstance.post(
    endpoint,
    data,
    { params },
  )
);

export const runUploadApiRequest = async (endpoint: string, data?: any, params: any = {}) => (
  axiosInstance.post(
    endpoint,
    data,
    {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
);

export const runUpdateFileRequest = async (endpoint: string, data?: any, params: any = {}) => (
  axiosInstance.put(
    endpoint,
    data,
    {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
);

export const runUpdateApiRequest = async (endpoint: string, data: any, params: any = {}) => (
  axiosInstance.put(
    endpoint,
    data,
    { params },
  )
);

export const runPatchApiRequest = async (endpoint: string, data: any, params: any = {}) => (
  axiosInstance.patch(
    endpoint,
    data,
    { params },
  )
);

export const runDeleteApiRequest = async (endpoint: string, params: any = {}) => (
  axiosInstance.delete(
    endpoint,
    { params },
  )
);

export const runGetApiRequestFB = async (endpoint: string, params: any = {}) => (
  axiosInstanceFB.get(
    endpoint,
    { params },
  )
);

export const runPostApiRequestFB = async (endpoint: string, data?: any, params: any = {}) => (
  axiosInstanceFB.post(
    endpoint,
    data,
    { params },
  )
);

export const runDeleteApiRequestFB = async (endpoint: string, params: any = {}) => (
  axiosInstanceFB.delete(
    endpoint,
    { params },
  )
);
