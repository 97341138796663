import {
  Box, ListItemIcon, ListItemText, MenuItem, useTheme,
} from '@mui/material';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CreateRule from 'modules/create-rule';

import { useModal } from 'hooks/useModal';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { archiveTransaction } from 'services/TransactionService';
import { fetchAccountTransactions } from 'store/bank-account-details/bankAccountDetailsSlice';
import {
  accountDetailsSelector,
  paginationSelector, searchSelector,
  transactionsFilterSelector,
  transactionsSortSelector,
} from 'store/bank-account-details/selectors';
import { currentWorkspaceSelector } from 'store/user/selectors';

import ArchiveIcon from 'assets/icons/ArchiveIcon';
import MoreIcon from 'assets/icons/MoreIcon';
import NotificationIcon from 'assets/icons/NotificationIcon';
import UnarchiveIcon from 'assets/icons/UnarchiveIcon';
import { GENERAL_TOAST_OPTIONS, TRANSACTION_HISTORY_PAGINATION_SIZE } from 'constants/general';
import { Transaction } from 'models/transaction.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { extractTransactionInfoForRule } from 'utils/formatters';
import { formatTransactionFilters } from 'utils/transactions';

import { IconButton, Menu } from './index.styled';

interface TransactionActionMenuProps {
  transaction: Transaction;
}

const TransactionActionMenu: FC<TransactionActionMenuProps> = ({ transaction }) => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { accountId = '' } = useParams();
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const workspaceId = currentWorkspace?.id || '';
  const { data: account } = useAppSelector(accountDetailsSelector);
  const filters = useAppSelector(transactionsFilterSelector);
  const sortOptions = useAppSelector(transactionsSortSelector);
  const search = useAppSelector(searchSelector);
  const { page } = useAppSelector(paginationSelector);
  const IconComponent = transaction?.archived ? UnarchiveIcon : ArchiveIcon;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenRule = () => {
    onOpen();
    handleMenuClose();
  };

  const handleArchiveTransaction = async () => {
    setIsSubmitting(true);
    try {
      await archiveTransaction(transaction?.id, { archived: !transaction?.archived });
      getTransactions();
      handleMenuClose();

      const successMessage = !transaction?.archived ? 'success.transactionArchived' : 'success.transactionUnarchived';
      toast.success(intl.formatMessage({ id: successMessage }), GENERAL_TOAST_OPTIONS);
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getTransactions = () => {
    const formattedFilters = formatTransactionFilters(filters);
    const apiInfo = {
      params: { workspaceId },
      queryParams: {
        'bank-account-id': accountId,
        page: page - 1,
        size: TRANSACTION_HISTORY_PAGINATION_SIZE,
        ...(sortOptions ? { sort: `${sortOptions.name},${sortOptions.type}` } : {}),
        ...(search ? {
          field: 'memo',
          sign: 'ILIKE',
          value: search,
        } : {}),
        ...formattedFilters,
      },
    };

    dispatch(fetchAccountTransactions(apiInfo));
  };

  return (
    <>
      <IconButton onClick={handleClick} aria-label="Transaction menu" id="iconButton-transactionMenu">
        <Box display="flex" alignItems="center">
          <MoreIcon />
        </Box>
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!transaction?.archived && (
          <MenuItem
            id="menuItem-createRule"
            onClick={handleOpenRule}
          >
            <ListItemIcon>
              <NotificationIcon color={theme.palette.general.darkGrey} />
            </ListItemIcon>
            <ListItemText>
              {intl.formatMessage({ id: 'button.createAlert' })}
            </ListItemText>
          </MenuItem>
        )}

        <MenuItem
          id="menuItem-archiveTransaction"
          onClick={handleArchiveTransaction}
          disabled={isSubmitting}
        >
          <ListItemIcon>
            <IconComponent color={theme.palette.general.darkGrey} />
          </ListItemIcon>
          <ListItemText>
            {intl.formatMessage({
              id: transaction?.archived
                ? 'button.unarchiveTransaction'
                : 'button.archiveTransaction',
            })}
          </ListItemText>
        </MenuItem>
      </Menu>

      {isOpen && account && (
        <CreateRule
          isOpen={isOpen}
          onClose={onClose}
          transaction={extractTransactionInfoForRule(transaction)}
          bankAccount={account}
        />
      )}
    </>
  );
};

export default TransactionActionMenu;
