/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const StarFilledIcon = ({ stroke = '#757575', size = 24, fill = 'none' }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6133 9.97619H19.5263L15.6218 12.9703C15.2929 13.2225 15.1537 13.6514 15.2717 14.0487L16.6828 18.7962L12.5629 15.9906C12.2233 15.7593 11.7767 15.7593 11.4371 15.9906L7.31712 18.7963L8.72805 14.0486C8.84634 13.6506 8.70636 13.2209 8.37633 12.9689L4.47395 9.98948L9.38888 9.97708C9.81332 9.97601 10.1909 9.70711 10.3306 9.30633L12 4.51863L13.6691 9.30543C13.8091 9.70709 14.188 9.97619 14.6133 9.97619Z" fill={fill} stroke={stroke} />
  </svg>
);

export default StarFilledIcon;
