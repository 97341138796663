/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const RoundedArrowUpIcon = ({ size = 40 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={size} height={size} rx="14" fill="#24605D" />
    <path
      d="M30 20C30 14.477 25.523 10 20 10C14.477 10 10 14.477 10 20C10 25.523 14.477 30 20 30C25.523 30 30 25.523 30 20ZM19 25V18.414L16.707 20.707L15.293 19.293L20 14.586L24.707 19.293L23.293 20.707L21 18.414V25H19Z"
      fill="#FF9898"
    />
  </svg>
);

export default RoundedArrowUpIcon;
