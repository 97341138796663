/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const TeamIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M5 11C5.39556 11 5.78224 10.8827 6.11114 10.6629C6.44004 10.4432 6.69638 10.1308 6.84776 9.76537C6.99913 9.39991 7.03874 8.99778 6.96157 8.60982C6.8844 8.22186 6.69392 7.86549 6.41421 7.58579C6.13451 7.30608 5.77814 7.1156 5.39018 7.03843C5.00222 6.96126 4.60009 7.00087 4.23463 7.15224C3.86918 7.30362 3.55682 7.55996 3.33706 7.88886C3.1173 8.21776 3 8.60444 3 9C3.0005 9.53028 3.21137 10.0387 3.58633 10.4137C3.9613 10.7886 4.46972 10.9995 5 11ZM19 11C19.3956 11 19.7822 10.8827 20.1111 10.6629C20.44 10.4432 20.6964 10.1308 20.8478 9.76537C20.9991 9.39991 21.0387 8.99778 20.9616 8.60982C20.8844 8.22186 20.6939 7.86549 20.4142 7.58579C20.1345 7.30608 19.7781 7.1156 19.3902 7.03843C19.0022 6.96126 18.6001 7.00087 18.2346 7.15224C17.8692 7.30362 17.5568 7.55996 17.3371 7.88886C17.1173 8.21776 17 8.60444 17 9C17.0005 9.53028 17.2114 10.0387 17.5863 10.4137C17.9613 10.7886 18.4697 10.9995 19 11ZM20 12H18C17.4718 11.9994 16.9649 12.2085 16.5906 12.5812C17.2236 12.9258 17.7662 13.415 18.174 14.0092C18.5819 14.6033 18.8435 15.2855 18.9375 16H21C21.2652 16 21.5196 15.8946 21.7071 15.7071C21.8946 15.5196 22 15.2652 22 15V14C21.9995 13.4697 21.7886 12.9613 21.4137 12.5863C21.0387 12.2114 20.5303 12.0005 20 12ZM7.40938 12.5812C7.03511 12.2085 6.52823 11.9994 6 12H4C3.46972 12.0005 2.9613 12.2114 2.58633 12.5863C2.21137 12.9613 2.0005 13.4697 2 14L2 15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8946 2.73478 16 3 16H5.05938C5.15465 15.2855 5.41711 14.6035 5.82543 14.0095C6.23376 13.4155 6.77643 12.9262 7.40938 12.5812Z" fill={color} />
    <path d="M12 12C12.6922 12 13.3689 11.7947 13.9445 11.4101C14.5201 11.0256 14.9687 10.4789 15.2336 9.83939C15.4985 9.19985 15.5678 8.49612 15.4327 7.81719C15.2977 7.13825 14.9644 6.51461 14.4749 6.02513C13.9854 5.53564 13.3617 5.2023 12.6828 5.06725C12.0039 4.9322 11.3001 5.00152 10.6606 5.26642C10.0211 5.53133 9.47444 5.97993 9.08986 6.55551C8.70527 7.13108 8.5 7.80777 8.5 8.5C8.49978 8.95969 8.59016 9.41491 8.76597 9.83965C8.94179 10.2644 9.19959 10.6503 9.52464 10.9754C9.84969 11.3004 10.2356 11.5582 10.6603 11.734C11.0851 11.9098 11.5403 12.0002 12 12ZM14.4 13H14.1406C13.475 13.3289 12.7425 13.5 12 13.5C11.2575 13.5 10.525 13.3289 9.85937 13H9.6C8.64529 13.0002 7.72974 13.3796 7.05466 14.0547C6.37958 14.7297 6.00022 15.6453 6 16.6V17.5C6 17.8978 6.15803 18.2794 6.43934 18.5607C6.72064 18.842 7.10217 19 7.5 19H16.5C16.8978 19 17.2794 18.842 17.5607 18.5607C17.842 18.2794 18 17.8978 18 17.5V16.6C17.9998 15.6453 17.6204 14.7297 16.9453 14.0547C16.2703 13.3796 15.3547 13.0002 14.4 13Z" fill={color} />
  </svg>
);

export default TeamIcon;
