import { Box } from '@mui/material';
import { FC, ReactNode, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { UserContext } from 'context/UserContext';
import AuthLayout from 'layouts/AuthLayout';
import Header from 'layouts/CustomerLayout/Header';
import { Layout } from 'layouts/CustomerLayout/index.styled';

import { SIGN_IN_ROUTE } from 'constants/clientRoutes';

interface CustomerLayoutProps {
  children?: ReactNode;
  allowUnauth?: boolean;
  width?: string;
  hasHeader?: boolean;
}

const CustomerLayout:FC<CustomerLayoutProps> = ({
  children,
  allowUnauth = false,
  width = '440px',
  hasHeader = false,
}) => {
  const { fbUser } = useContext(UserContext);

  if (!allowUnauth && (!fbUser || !fbUser.emailVerified)) {
    return <Navigate to={SIGN_IN_ROUTE} replace />;
  }

  return (
    <AuthLayout>
      <Layout width={width}>
        <Box m={3}>
          {hasHeader && <Header />}
          <Outlet />
          {children}
        </Box>
      </Layout>
    </AuthLayout>
  );
};

export default CustomerLayout;
