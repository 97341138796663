import { useEffect, useState } from 'react';

import { Margin } from 'models/margin.interface';

const SHORT_DATE_LABEL_WIDTH = 50;

export const useChartResize = (margin: Margin, id: string, defaultHeight = 180) => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      setWidth(event[0].contentBoxSize[0].inlineSize - margin.left - margin.right);
      setHeight(defaultHeight - margin.top - margin.bottom);
    });

    if (document.getElementById(id)) {
      resizeObserver.observe(document.getElementById(id) as Element);
    }
  }, []);

  return {
    width,
    height,
  };
};

export const useChartXDateTicks = (values: string[], width: number) => {
  const [ticks, setTicks] = useState<string[]|null>(null);

  useEffect(() => {
    const totalTicks = values?.length;
    const widthOfTicks = totalTicks * SHORT_DATE_LABEL_WIDTH;

    if (widthOfTicks > width) {
      const allowedTicks = Math.floor(width / SHORT_DATE_LABEL_WIDTH);
      let divider = 0;

      while (Math.floor(totalTicks / divider) > allowedTicks) {
        divider += 2;
      }

      if (allowedTicks && divider) {
        const filteredTicks = values.filter((item, index) => item && index % divider === 0);
        setTicks(filteredTicks);
      }

      return;
    }

    setTicks(values);
  }, [values, width]);

  return ticks;
};
