import { InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SearchAdornment = styled(InputAdornment)(({ theme }) => ({
  '& svg path': {
    fill: theme.palette.general.darkGrey1,
  },
}));

export const CloseAdornment = styled(InputAdornment)(({ theme }) => ({
  '& svg path': {
    fill: theme.palette.primary.main,
  },
}));
