import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

export const Signin = lazy(
  () => CustomLazyLoader(
    () => import(/* webpackChunkName: 'Signin' */ 'modules/signin/containers/Signin'),
    'Signin',
  ),
);
