import { Box } from '@mui/material';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const Button = styled(
  MuiButton,
  { shouldForwardProp: (prop) => prop !== 'isActive' },
)<{ isActive: boolean }>(({ isActive, theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 3.5),
  backgroundColor: isActive ? theme.palette.common.white : theme.palette.general.lightGrey8,
  border: `1px solid ${isActive ? theme.palette.primary.main : theme.palette.general.lightGrey8}`,
  transition: 'none',

  '& .MuiButton-endIcon svg path': {
    fill: theme.palette.text.primary,
  },

  '&:hover': {
    backgroundColor: theme.palette.general.lightGrey8,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const DropdownWrapper = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== 'hasError' },
)<{ hasError: boolean }>(({ theme, hasError }) => ({
  '& .Mui-disabled': {
    backgroundColor: theme.palette.general.lightGrey9,
    opacity: '1 !important',

    '& .MuiTypography-root': {
      color: theme.palette.text.disabled,
    },

    '& .MuiButton-endIcon svg path': {
      fill: theme.palette.text.disabled,
    },
  },

  ...(hasError
    ? {
      '& .MuiFormHelperText-root': {
        color: theme.palette.error.main,
      },

      '& .MuiButton-root': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    } : {}),
}));
