/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const PlusBoxIcon = ({ color = '#BABABA', size = 18 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 18 18">
    <path fill={color} d="M16.2.2H1.8A1.6 1.6 0 0 0 .2 1.8v14.4a1.6 1.6 0 0 0 1.6 1.6h14.4a1.6 1.6 0 0 0 1.6-1.6V1.8A1.6 1.6 0 0 0 16.2.2Zm-2.4 9.6h-4v4a.8.8 0 1 1-1.6 0v-4h-4a.8.8 0 1 1 0-1.6h4v-4a.8.8 0 1 1 1.6 0v4h4a.8.8 0 1 1 0 1.6Z" />
  </svg>
);

export default PlusBoxIcon;
