import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import AddContactForm from 'components/AddContactForm';
import GenericModal from 'components/GenericModal';

import { Contact } from 'models/contact.interface';
import { contactFormSchema, ContactFormValues } from 'utils/validation/contactFormSchema';

interface AddContactDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: ContactFormValues) => void;
  contact?: Contact;
}

const AddContactDialog: FC<AddContactDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  contact,
}) => {
  const intl = useIntl();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ContactFormValues>({
    resolver: zodResolver(contactFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      firstName: contact?.firstName || '',
      lastName: contact?.lastName || '',
      phoneNumber: contact?.phoneNumber || '',
    },
  });

  return (
    <GenericModal
      id="modal-add-contact"
      hideCloseButton
      hasActions
      isOpen={isOpen}
      onClose={onClose}
      disabled={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
      title={intl.formatMessage({ id: contact ? 'modalTitle.editContact' : 'modalTitle.addContact' })}
      confirmLabel={intl.formatMessage({ id: contact ? 'button.save' : 'button.add' })}
    >
      <AddContactForm register={register} errors={errors} control={control} />
    </GenericModal>
  );
};

export default AddContactDialog;
