import { IconButton } from '@mui/material';

import { CloseWrapper } from 'components/CloseToast/index.styled';

import CloseIcon from 'assets/icons/CloseIcon';

const CloseToast = () => (
  <CloseWrapper>
    <IconButton aria-label="close toast" id="iconButton-closeToast">
      <CloseIcon size={18} />
    </IconButton>
  </CloseWrapper>
);

export default CloseToast;
