/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ArrowLeftIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 10.9993H7.8305L13.4202 5.40993L12.0006 4L4 12L12.0006 20L13.4106 18.5901L7.8305 12.9996H20V10.9993Z" fill={color} />
  </svg>
);

export default ArrowLeftIcon;
