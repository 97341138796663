import {
  Box, FormLabel, Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import { AddWrapper, TrashIconButton } from 'modules/create-rule/index.styled';
import {
  FC, useEffect, useMemo, useState,
} from 'react';
import {
  UseFormSetValue, UseFormWatch, UseFormClearErrors,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import SearchableMenu from 'containers/SearchableMenu';
import NestedDropdown from 'fields/NestedDropdownField';

import { useAppSelector } from 'hooks/useRedux';
import { accountsSelector } from 'store/bank-accounts/selectors';

import DeleteIcon from 'assets/icons/DeleteIcon';
import OutlinedPlusIcon from 'assets/icons/OutlinedPlusIcon';
import { CriteriaAccount, DefaultType } from 'constants/enums';
import { ALERT_IDENTIFIERS } from 'constants/fields';
import { GenericOption } from 'models/option.interface';
import { RuleIdentifier } from 'models/rule.interface';
import { getAccountsAsOptions, hasAllOptionsChecked, hasIdentifierDisabled } from 'modules/create-rule/utils';
import { RuleFormValues } from 'utils/validation/ruleFormSchema';

interface IdentifiersSectionProps {
  onSetValue: UseFormSetValue<RuleFormValues>;
  onClearErrors: UseFormClearErrors<RuleFormValues>;
  watch: UseFormWatch<RuleFormValues>;
  isReadOnly: boolean;
}

const IdentifiersSection: FC<IdentifiersSectionProps> = ({
  onSetValue,
  watch,
  onClearErrors,
  isReadOnly,
}) => {
  const intl = useIntl();
  const [accountOptions, setAccountOptions] = useState<any[]>([]);
  const { data: bankAccounts } = useAppSelector(accountsSelector, isEqual);
  const identifiers = watch(ALERT_IDENTIFIERS.name) as RuleIdentifier[];

  const { hasAllBankAccounts } = useMemo(() => hasAllOptionsChecked(identifiers), [identifiers]);
  const isAddDisabled = hasAllBankAccounts || isReadOnly;

  useEffect(() => {
    if (bankAccounts && bankAccounts.length > 0) {
      setAccountOptions(getAccountsAsOptions(bankAccounts, []));
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (bankAccounts) {
      setAccountOptions(getAccountsAsOptions(bankAccounts, identifiers));
    }
  }, [identifiers]);

  const handleSelectOption = (option: GenericOption, index: number) => {
    const newIdentifiers = [...identifiers];
    newIdentifiers[index] = {
      formattedLabel: option.formattedLabel,
      label: option.label,
      parentValue: option.value?.bankCode ? CriteriaAccount.bank : CriteriaAccount.bankAccount,
      isAllOption: [option.value?.bankCode, option.value?.bankAccountId].includes(DefaultType.all),
      ...option.value,
    };

    onSetValue(ALERT_IDENTIFIERS.name, newIdentifiers, { shouldValidate: true });
    onClearErrors(`${ALERT_IDENTIFIERS.name}[${index}]`);
  };

  const handleAddAccount = () => {
    onSetValue(
      ALERT_IDENTIFIERS.name,
      [...identifiers, { bankCode: '', bankAccountId: '' }],
    );
  };

  const handleDeleteAccount = (index: number) => {
    const filteredIdentifiers = [...identifiers];
    filteredIdentifiers.splice(index, 1);
    onSetValue(ALERT_IDENTIFIERS.name, filteredIdentifiers, { shouldValidate: true });
    onClearErrors(`${ALERT_IDENTIFIERS.name}[${index}]`);
  };

  return (
    <>
      <Typography variant="subtitle1" mt={5} mb={3}>
        {intl.formatMessage({ id: 'label.withTheFollowingCriteria' })}
      </Typography>

      <Box display="flex" justifyContent="space-between">
        <FormLabel
          required
          sx={{
            whiteSpace: 'nowrap', display: 'flex', mt: 3.5, mr: 6,
          }}
        >
          <Typography variant="body2">
            {intl.formatMessage({ id: 'label.account' })}
          </Typography>
        </FormLabel>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="space-between"
          gap={3}
          width="375px"
        >
          {identifiers && identifiers.map((identifier, index) => {
            const isDisabled = hasIdentifierDisabled(identifier, hasAllBankAccounts);
            const hasMoreAccounts = identifiers.length > 1;
            const isLast = index + 1 === identifiers?.length;
            const width = hasMoreAccounts ? 330 : 375;

            return (
              <Box
                display="flex"
                alignItems="center"
                key={`account-${identifier.bankCode}-${identifier.bankAccountId}-${index}`}
              >
                <NestedDropdown
                  fullWidth
                  sx={{ width: `${isLast ? width - 38 : width}px` }}
                  options={accountOptions}
                  onSelect={(option: GenericOption) => handleSelectOption(option, index)}
                  selectedOption={identifier}
                  disabled={isDisabled || isReadOnly}
                  placeholder={intl.formatMessage({ id: 'label.selectBankOrAccount' })}
                  SublistComponent={SearchableMenu}
                />

                {hasMoreAccounts && (
                  <TrashIconButton
                    onClick={() => handleDeleteAccount(index)}
                    aria-label="Delete Account"
                    id={`iconButton-deleteAccount-${index}`}
                    sx={{ ml: 2 }}
                    disabled={isReadOnly}
                  >
                    <DeleteIcon size={22} />
                  </TrashIconButton>
                )}

                {isLast && (
                  <AddWrapper
                    onClick={isAddDisabled ? undefined : handleAddAccount}
                    disabled={isAddDisabled}
                  >
                    <OutlinedPlusIcon size={22} />
                  </AddWrapper>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default IdentifiersSection;
