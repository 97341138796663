/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ActivityIcon = ({ color = '#BABABA', size = 20 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 22 20">
    <path fill={color} d="M2 0v12H0v3c0 1.654 1.346 3 3 3h8v-2H3c-.551 0-1-.449-1-1v-1h11l-2-2H4V2h13v4h2V0H2Zm4 4v2h2V4H6Zm4 0v2h5V4h-5ZM6 8v2h2V8H6Zm7 0v2h1v1.914L16.086 14 14 16.086V18h-1v2h9v-2h-1v-1.914L18.914 14 21 11.914V10h1V8h-9Zm3 2h3v1.086l-1.5 1.5-1.5-1.5V10Zm1.5 5.414 1.5 1.5V18h-3v-1.086l1.5-1.5Z" />
  </svg>
);

export default ActivityIcon;
