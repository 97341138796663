import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

import { ExtendedSwitchProps } from 'models/switch.interface';

export const getSwitchSizes = (size: 'small' | 'medium') => {
  if (size === 'small') {
    return {
      mainWidth: 42,
      mainHeight: 20,
      thumbWidth: 16,
      thumbHeight: 16,
    };
  }

  return {
    mainWidth: 50,
    mainHeight: 28,
    thumbWidth: 24,
    thumbHeight: 24,
  };
};

export const IOSSwitch = styled(
  (props: ExtendedSwitchProps) => (<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />),
  { shouldForwardProp: (props) => props !== 'checkmark' && props !== 'size' },
)<{ checkmark?: boolean; size: 'small' | 'medium' }>(({ theme, checkmark, size }) => {
  const {
    mainWidth, mainHeight, thumbWidth, thumbHeight,
  } = getSwitchSizes(size);

  return {
    width: mainWidth,
    height: mainHeight,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        color: theme.palette.common.white,
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: checkmark ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 -960 960 960"><path fill="${encodeURIComponent(
            '#24605D',
          )}" d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>')` : 'none',
        },
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette.primary.main,
        border: `6px solid ${theme.palette.common.white}`,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.common.white,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: thumbWidth,
      height: thumbHeight,
      boxShadow: 'none',
      backgroundColor: theme.palette.common.white,

      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.general.lightGrey11,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  };
});
