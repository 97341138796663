import {
  Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason,
} from '@mui/material';
import { isEqual } from 'lodash';
import {
  FC, memo, ReactNode, SyntheticEvent, useEffect, useState,
} from 'react';

import InputField from 'fields/InputField';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import CloseIcon from 'assets/icons/CloseIcon';

import { AutocompleteWrapper, PaperComponent } from './index.styled';

interface AutocompleteMultipleFieldProps {
  className?: string;
  id: string;
  name?: string;
  disabled?: boolean;
  isSuccess?: boolean;
  options: any[];
  onChange: (values: any[], selectedValue?: any) => any;
  values: any[];
  errorMessage?: string | null;
  placeholder?: string;
  renderOptionComponent: (option: any) => ReactNode;
  isOptionEqualToValue: (option: any, value: any) => boolean;
  renderTagsComponent: (option: any) => ReactNode;
  getOptionLabel: (option: any) => string;
  filterValues?: boolean;
}

const AutocompleteMultipleField: FC<AutocompleteMultipleFieldProps> = ({
  disabled,
  options,
  onChange,
  name,
  values = [],
  errorMessage,
  placeholder = '',
  renderOptionComponent,
  renderTagsComponent,
  isOptionEqualToValue,
  getOptionLabel,
  id,
  filterValues = false,
}) => {
  const [filteredValues, setFilteredValues] = useState<any[]>([]);

  useEffect(() => {
    if (filterValues) {
      setFilteredValues(options.filter((o) => values.some((value) => isEqual(value, o))));
    } else {
      setFilteredValues(values);
    }
  }, [options, values]);

  const handleChange = (
    e: SyntheticEvent,
    opts: any[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => {
    onChange(opts, details?.option);
  };

  return (
    <AutocompleteWrapper>
      <Autocomplete
        multiple
        disabled={disabled}
        disableCloseOnSelect
        options={options}
        disableClearable
        value={filteredValues}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={handleChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        renderOption={({ key, ...remainingProps }, option) => (
          <li key={key} {...remainingProps}>
            {renderOptionComponent(option)}
          </li>
        )}
        PaperComponent={PaperComponent}
        clearIcon={<CloseIcon size={18} />}
        popupIcon={<ChevronDownIcon size={20} />}
        renderInput={({ inputProps, ...params }) => (
          <InputField
            {...params}
            name={name}
            placeholder={placeholder}
            slotProps={{
              htmlInput: inputProps,
            }}
            variant="outlined"
            helperText={errorMessage}
            error={!!errorMessage}
          />
        )}
        renderTags={(tags) => tags.map((option) => renderTagsComponent(option))}
        id={id}
      />
    </AutocompleteWrapper>
  );
};

export default memo(AutocompleteMultipleField);
