/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ReceiveMoney = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.85541 11.0001C7.5594 11.0037 7.26563 11.0531 6.98408 11.1466L3 12.1004V19.3105L6.91338 18.0049L14.893 20.8624C15.2089 20.975 15.5443 21.0193 15.8778 20.9923C16.2114 20.9654 16.5358 20.8679 16.8306 20.706L24 16.7892L22.8459 14.2719L15.8025 17.573L15.4357 17.0883L15.728 16.4316C15.8339 16.19 15.8919 15.9294 15.8986 15.6647C15.9054 15.4001 15.8607 15.1367 15.7672 14.8899C15.6737 14.643 15.5333 14.4176 15.354 14.2266C15.1748 14.0356 14.9603 13.883 14.7229 13.7774L8.99236 11.2151H8.97325C8.62924 11.0742 8.26227 11.0012 7.89172 11.0001C7.87962 11 7.86752 11 7.85541 11.0001Z" fill={color} />
    <path d="M18.5876 7.76055C18.1895 7.51138 17.6353 7.28334 16.9258 7.07588C16.2162 6.86896 15.7065 6.63875 15.3972 6.38471C15.0873 6.13175 14.9324 5.79105 14.9324 5.36367C14.9324 4.90325 15.0792 4.54359 15.3728 4.2863C15.6658 4.02846 16.0894 3.90009 16.643 3.90009C17.1733 3.90009 17.5953 4.07396 17.9067 4.42225C18.2187 4.77055 18.3747 5.24017 18.3747 5.83113L18.3742 5.875H19.659L19.6595 5.83167C19.6595 4.97475 19.4423 4.2993 19.0068 3.80584C18.5713 3.31292 17.963 3.01771 17.1809 2.92184L17.1771 2.9213V1.54167H16.0938V2.92346C15.3398 3.01284 14.7428 3.26959 14.3079 3.69913C13.8632 4.13842 13.6405 4.70067 13.6405 5.38642C13.6405 6.05971 13.8675 6.61492 14.3214 7.05205C14.7753 7.48863 15.4979 7.84342 16.4892 8.11534C17.2015 8.32875 17.7074 8.56709 18.008 8.83034C18.3075 9.09413 18.4581 9.41805 18.4581 9.80263C18.4581 10.2587 18.2832 10.6184 17.9343 10.8816C17.585 11.1449 17.1056 11.2765 16.4957 11.2765C15.8722 11.2765 15.3901 11.12 15.0505 10.8063C14.7109 10.4927 14.5413 10.0453 14.5413 9.46409H13.25C13.25 10.2918 13.4954 10.9472 13.9861 11.4303C14.4644 11.901 15.1242 12.1724 15.9583 12.252V13.4583H17.0417V12.2613L17.0552 12.2607C17.9067 12.1811 18.5692 11.9265 19.0415 11.4953C19.5133 11.0647 19.75 10.496 19.75 9.78963C19.75 9.34655 19.6552 8.9598 19.4673 8.62884C19.2788 8.29896 18.9852 8.00917 18.5876 7.76055Z" fill={color} />
  </svg>
);

export default ReceiveMoney;
