/* eslint max-len: 0 */
import { CustomIconInterface } from 'models/icon.interface';

const CreateAccountIcon = ({ color = 'currentColor', stroke = 'currentColor', size = 70 }: CustomIconInterface) => (
  <svg width={size} height={size} viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.8095 5.45958C30.535 5.32716 30.215 5.32716 29.9405 5.45958L8.06553 16.0117C7.71976 16.1785 7.5 16.5285 7.5 16.9124V22.181C7.5 22.7333 7.94772 23.181 8.5 23.181H11.5833C12.1356 23.181 12.5833 23.6287 12.5833 24.181V44.25C12.5833 44.8023 13.031 45.25 13.5833 45.25H16.6667C17.219 45.25 17.6667 44.8023 17.6667 44.25V24.181C17.6667 23.6287 18.1144 23.181 18.6667 23.181H21.75C22.3023 23.181 22.75 23.6287 22.75 24.181V44.25C22.75 44.8023 23.1977 45.25 23.75 45.25H26.8333C27.3856 45.25 27.8333 44.8023 27.8333 44.25V24.181C27.8333 23.6287 28.281 23.181 28.8333 23.181H31.9167C32.469 23.181 32.9167 23.6287 32.9167 24.181V44.25C32.9167 44.8023 33.3644 45.25 33.9167 45.25H37C37.5523 45.25 38 44.8023 38 44.25V24.181C38 23.6287 38.4477 23.181 39 23.181H42.0833C42.6356 23.181 43.0833 23.6287 43.0833 24.181V44.25C43.0833 44.8023 43.5311 45.25 44.0833 45.25H47.1667C47.719 45.25 48.1667 44.8023 48.1667 44.25V24.181C48.1667 23.6287 48.6144 23.181 49.1667 23.181H52.25C52.8023 23.181 53.25 22.7333 53.25 22.181V16.9124C53.25 16.5285 53.0302 16.1785 52.6845 16.0117L30.8095 5.45958Z"
      fill={color}
    />
    <path
      d="M53.6667 54.8796H7.08333C5.24238 54.8796 3.75 53.3872 3.75 51.5463C3.75 49.7053 5.24239 48.213 7.08334 48.213H53.6667C55.5076 48.213 57 49.7053 57 51.5463C57 53.3872 55.5076 54.8796 53.6667 54.8796Z"
      fill={color}
    />
    <path
      d="M51 35.6C43.0476 35.6 36.6 42.0476 36.6 50C36.6 57.9524 43.0476 64.4 51 64.4C58.9524 64.4 65.4 57.9524 65.4 50C65.4 42.0476 58.9524 35.6 51 35.6ZM57 51.2H52.2V56C52.2 56.6636 51.6624 57.2 51 57.2C50.3376 57.2 49.8 56.6636 49.8 56V51.2H45C44.3376 51.2 43.8 50.6636 43.8 50C43.8 49.3364 44.3376 48.8 45 48.8H49.8V44C49.8 43.3364 50.3376 42.8 51 42.8C51.6624 42.8 52.2 43.3364 52.2 44V48.8H57C57.6624 48.8 58.2 49.3364 58.2 50C58.2 50.6636 57.6624 51.2 57 51.2Z"
      fill="white"
    />
    <path
      d="M57 51.2H52.2V56C52.2 56.6636 51.6624 57.2 51 57.2C50.3376 57.2 49.8 56.6636 49.8 56V51.2H45C44.3376 51.2 43.8 50.6636 43.8 50C43.8 49.3364 44.3376 48.8 45 48.8H49.8V44C49.8 43.3364 50.3376 42.8 51 42.8C51.6624 42.8 52.2 43.3364 52.2 44V48.8H57C57.6624 48.8 58.2 49.3364 58.2 50C58.2 50.6636 57.6624 51.2 57 51.2Z"
      fill="white"
    />
    <path
      d="M37.6 50C37.6 42.5999 43.5999 36.6 51 36.6C58.4001 36.6 64.4 42.5999 64.4 50C64.4 57.4001 58.4001 63.4 51 63.4C43.5999 63.4 37.6 57.4001 37.6 50ZM53.2 56V52.2H57C58.214 52.2 59.2 51.2165 59.2 50C59.2 48.7834 58.214 47.8 57 47.8H53.2V44C53.2 42.7834 52.214 41.8 51 41.8C49.7859 41.8 48.8 42.7834 48.8 44V47.8H45C43.7859 47.8 42.8 48.7834 42.8 50C42.8 51.2165 43.7859 52.2 45 52.2H48.8V56C48.8 57.2165 49.7859 58.2 51 58.2C52.214 58.2 53.2 57.2165 53.2 56Z"
      fill="#A7BFBE"
      stroke={stroke}
      strokeWidth="2"
      id="circle"
    />
  </svg>

);

export default CreateAccountIcon;
