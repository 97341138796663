import { Grid2 as Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const VolumeWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.general.darkGreen5,
  padding: theme.spacing(9.5, 6),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: '14px',
  height: 'fit-content',
  color: theme.palette.text.primary,
  display: 'flex',
}));

export const ValueWrapper = styled(
  Grid,
  { shouldForwardProp: (props) => props !== 'hideBorder' },
)<{ hideBorder?: boolean }>(({ theme, hideBorder = false }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  borderLeft: 0,

  [theme.breakpoints.down('lg')]: {
    borderLeft: hideBorder ? 0 : `1px solid ${theme.palette.border.secondary}`,
    borderTop: 0,
  },
}));
