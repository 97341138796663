import MuiCheckbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')<{ hasError?: boolean }>(({ theme, hasError }) => ({
  margin: 0,
  display: 'flex',
  alignItems: 'center',

  '& .MuiFormHelperText-root': {
    ...(hasError ? { color: theme.palette.error.main } : {}),
  },

  '& .MuiFormControlLabel-root': {
    margin: 0,

    '& .MuiButtonBase-root': {
      padding: 0,
      marginRight: theme.spacing(2),
    },
  },

  '& .MuiFormControlLabel-asterisk': {
    color: theme.palette.error.main,
  },
}));

export const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
  padding: theme.spacing(2),

  '& svg': {
    height: '16px',
    width: '16px',
  },
}));
