import { Tooltip, Typography, Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from 'hooks/useRedux';
import { reasonsSelector } from 'store/transaction-approval/selectors';

import { NOT_APPLICABLE } from 'constants/general';

interface ReasonStatusProps {
  paymentReasonId: string;
  note?: string;
}

const ReasonStatus:FC<ReasonStatusProps> = ({ paymentReasonId, note }) => {
  const intl = useIntl();
  const reasons = useAppSelector(reasonsSelector);
  const selectedReason = useMemo(() => {
    const r = reasons.find(({ id }) => id === paymentReasonId);
    return r?.reason || paymentReasonId;
  }, [reasons, paymentReasonId]);

  const renderContent = () => (
    <Box>
      <Typography fontWeight={600}>
        {selectedReason || NOT_APPLICABLE}
      </Typography>
      <Typography component="div" variant="body3" className="text-ellipsis" sx={{ maxWidth: '200px' }}>
        {note || NOT_APPLICABLE}
      </Typography>
    </Box>
  );

  return (
    note ? (
      <Tooltip
        arrow
        title={`${intl.formatMessage({ id: 'label.memo' })}: ${note}`}
        className="pointer"
        placement="top"
      >
        {renderContent()}
      </Tooltip>
    ) : (
      renderContent()
    )
  );
};

export default ReasonStatus;
