
export const variants = {
  h1: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '33px',
  },
  h2: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '27px',
  },
  subtitle1: { // "title1" in design
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '23px',
  },
  body1: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '21px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  body3: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '17px',
  },
  label1: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  label2: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '17px',
  },
  label3: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '15px',
  },
};
