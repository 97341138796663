import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';

import {
  getAccountDetailsActivityWidget,
  getCustomerBankAccountDetails,
  getGroupedBankTransactions,
  getInflowOutflowWidget,
  resyncBankAccount,
} from 'services/BankAccountService';
import { getAccountTopUpFunding } from 'services/TopUpFundingService';
import { resetStore } from 'store/actions';
import { RootState } from 'store/index';

import {
  ApiStatus, BalanceTrend, DefaultType, SortOrder,
} from 'constants/enums';
import { TRANSACTION_HISTORY_PAGINATION_SIZE } from 'constants/general';
import { BankAccount } from 'models/bankAccount.interface';
import { DetailsActivityChartInfo, InflowOutflowData } from 'models/charts.interface';
import { Pagination } from 'models/pagination.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { TopUpFunding } from 'models/topUpFunding.interface';
import { Transaction } from 'models/transaction.interface';
import { isInternalAccount } from 'utils/bankAccount';
import { handleApiErrors } from 'utils/errorUtils';
import { formatQueryParamsAsSearchParams, formatTransactionFilters } from 'utils/transactions';
import { TransactionFilterSchema } from 'utils/validation/transactionFormSchema';

export interface BankAccountState {
  details: {
    data: BankAccount|null;
    status: ApiStatus;
  };
  transactions: {
    data: Transaction[];
    status: ApiStatus;
    sortOptions: SortOptions;
  };
  activityChart: {
    data: DetailsActivityChartInfo;
    status: ApiStatus;
  };
  inflowOutflowCharts: {
    data: InflowOutflowData;
    status: ApiStatus;
  };
  chartFilters: {
    trend: BalanceTrend;
  };
  search: string;
  requiresRelink: boolean;
  pagination: Pagination;
  transactionsFilters: TransactionFilterSchema;
  archived: boolean;
  topUpFunding: {
    data: TopUpFunding | null;
    status: ApiStatus;
    isDialogOpen: boolean;
  };
}

const initialState: BankAccountState = {
  details: {
    data: null,
    status: ApiStatus.idle,
  },
  transactions: {
    data: [],
    status: ApiStatus.idle,
    sortOptions: {
      name: 'postedDate',
      type: SortOrder.desc,
    },
  },
  activityChart: {
    data: {
      inflow: [],
      outflow: [],
      balances: [],
      thirtyDaysMovingAverage: [],
      sixtyDaysMovingAverage: [],
      ninetyDaysMovingAverage: [],
      totalInflow: null,
      totalOutflow: null,
    },
    status: ApiStatus.loading,
  },
  inflowOutflowCharts: {
    data: {
      inflowCurrent: [],
      inflowVariationPercentage: null,
      inflowPrevious: [],
      inflowSumCurrent: null,
      inflowSumPrevious: null,
      outflowCurrent: [],
      outflowVariationPercentage: null,
      outflowPrevious: [],
      outflowSumCurrent: null,
      outflowSumPrevious: null,
    },
    status: ApiStatus.loading,
  },
  chartFilters: {
    trend: BalanceTrend.LastFourWeeks,
  },
  requiresRelink: false,
  pagination: {
    totalPages: 0,
    totalElements: 0,
    page: 1,
  },
  transactionsFilters: {
    types: {
      [DefaultType.all]: true,
      inflow: false,
      outflow: false,
    },
    dateRange: {
      from: null,
      to: null,
    },
    amountRange: {
      min: null,
      max: null,
    },
  },
  archived: false,
  search: '',
  topUpFunding: {
    data: null,
    status: ApiStatus.idle,
    isDialogOpen: false,
  },
};

export const fetchBankAccountDetails = createAsyncThunk(
  'bankAccount/fetchBankAccountDetails',
  async ({ accountId, workspaceId }: { accountId: string; workspaceId: string }, thunkAPI) => {
    try {
      const response = await getCustomerBankAccountDetails(workspaceId, accountId);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchTopUpFunding = createAsyncThunk(
  'bankAccount/fetchTopUpFunding',
  async ({ accountId }: { accountId: string }, thunkAPI) => {
    try {
      const response = await getAccountTopUpFunding(accountId);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const resyncAccount = createAsyncThunk(
  'bankAccount/resyncAccount',
  async ({ accountId, workspaceId }: { accountId: string; workspaceId: string }, thunkAPI) => {
    try {
      const response = await resyncBankAccount(workspaceId, accountId);
      const state = thunkAPI.getState() as RootState;
      const accountState = state.bankAccountDetails;
      const formattedFilters = formatTransactionFilters(accountState.transactionsFilters);
      const { sortOptions } = accountState.transactions;
      const storeSearch = accountState.search;
      const transactionsQueryParams = {
        'bank-account-id': accountId,
        page: accountState.pagination.page - 1,
        size: TRANSACTION_HISTORY_PAGINATION_SIZE,
        ...(sortOptions ? { sort: `${sortOptions.name},${sortOptions.type}` } : {}),
        ...(storeSearch ? {
          field: 'memo',
          sign: 'ILIKE',
          value: storeSearch,
        } : {}),
        ...formattedFilters,
      };
      const chartsQueryParams = {
        'bank-account-id': accountId,
        period: accountState.chartFilters.trend,
      };

      await Promise.all([
        thunkAPI.dispatch(fetchAccountTransactions({ params: { workspaceId }, queryParams: transactionsQueryParams })),
        thunkAPI.dispatch(fetchActivityChartInfo({ params: { workspaceId }, queryParams: chartsQueryParams })),
        thunkAPI.dispatch(fetchInflowOutflowChartInfo({
          params: { workspaceId },
          queryParams: { 'bank-account-id': accountId },
        })),
      ]);

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchAccountTransactions = createAsyncThunk(
  'bankAccount/fetchAccountTransactions',
  async ({ params, queryParams } : {
    params: { workspaceId: string };
    queryParams: { 'bank-account-id': string; page: number; size: number; sort?: string; search?: string };
    }, thunkAPI) => {
    try {
      const response = await getGroupedBankTransactions(formatQueryParamsAsSearchParams(queryParams), params);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchInflowOutflowChartInfo = createAsyncThunk(
  'bankAccount/fetchInflowOutflowChartInfo',
  async ({ params, queryParams } : {
    params: { workspaceId: string };
    queryParams: { 'bank-account-id': string };
  }, thunkAPI) => {
    try {
      const response = await getInflowOutflowWidget(queryParams, params);

      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchActivityChartInfo = createAsyncThunk(
  'bankAccount/fetchActivityChartInfo',
  async ({ params, queryParams } : {
    params: { workspaceId: string };
    queryParams: { 'bank-account-id': string; period?: BalanceTrend };
  }, thunkAPI) => {
    try {
      const response = await getAccountDetailsActivityWidget(queryParams, params);

      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const bankAccountSlice = createSlice({
  name: 'bankAccount',
  initialState,
  reducers: {
    handleUpdatePagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
      return state;
    },
    setIsDialogOpenTopUp: (state, action: PayloadAction<boolean>) => {
      state.topUpFunding = {
        ...state.topUpFunding,
        isDialogOpen: action.payload,
      };
      return state;
    },
    setTopUpFunding: (state, action: PayloadAction<TopUpFunding>) => {
      state.topUpFunding = {
        ...state.topUpFunding,
        data: action.payload,
      };
      return state;
    },
    handleUpdateTransactionsFilters: (
      state,
      action: PayloadAction<TransactionFilterSchema>,
    ) => {
      state.transactionsFilters = action.payload;
      return state;
    },
    handleArchive: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.archived = action.payload;
      return state;
    },
    handleUpdateChartFilters: (state, action: PayloadAction<{ trend: BalanceTrend }>) => {
      state.chartFilters = action.payload;

      ReactGA.gtag('event', `chart_date_filter_${action.payload.trend}`, {
        label: action.payload.trend,
        value: 1,
      });

      return state;
    },
    handleUpdateAccount: (state, action: PayloadAction<BankAccount>) => {
      state.details = {
        ...state.details,
        data: action.payload,
      };
      return state;
    },
    handleSearchTransactions: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      return state;
    },
    handleSortTransactions: (state, action: PayloadAction<SortOptions>) => {
      state.transactions.sortOptions = action.payload;
      return state;
    },
    resetTopUpFunding: (state) => {
      state.topUpFunding = initialState.topUpFunding;
      return state;
    },
    resetAccountStore: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchBankAccountDetails.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchBankAccountDetails.fulfilled, (state, action) => {
        const account = action.payload;

        state.details = {
          data: account,
          status: ApiStatus.idle,
        };
        state.requiresRelink = !isInternalAccount(account.category) && account.lastUpdateFailed;
      })
      .addCase(fetchBankAccountDetails.rejected, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchAccountTransactions.pending, (state) => {
        state.transactions = {
          ...state.transactions,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchAccountTransactions.fulfilled, (state, action) => {
        const {
          content, totalPages, totalElements, pageable,
        } = action.payload || {};
        const page = pageable?.pageNumber || 0;

        state.transactions = {
          ...state.transactions,
          data: content,
          status: ApiStatus.idle,
        };
        state.pagination = {
          totalPages,
          totalElements,
          page: page + 1,
        };
      })
      .addCase(fetchAccountTransactions.rejected, (state) => {
        state.transactions = {
          ...state.transactions,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchActivityChartInfo.pending, (state) => {
        state.activityChart = {
          ...state.activityChart,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchActivityChartInfo.fulfilled, (state, action) => {
        state.activityChart = {
          ...state.activityChart,
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchActivityChartInfo.rejected, (state) => {
        state.activityChart = {
          ...state.activityChart,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchInflowOutflowChartInfo.pending, (state) => {
        state.inflowOutflowCharts = {
          ...state.inflowOutflowCharts,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchInflowOutflowChartInfo.fulfilled, (state, action) => {
        state.inflowOutflowCharts = {
          ...state.inflowOutflowCharts,
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchInflowOutflowChartInfo.rejected, (state) => {
        state.inflowOutflowCharts = {
          ...state.inflowOutflowCharts,
          status: ApiStatus.idle,
        };
      })

      .addCase(resyncAccount.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(resyncAccount.fulfilled, (state, action) => {
        const account = action.payload;

        state.details = {
          data: account,
          status: ApiStatus.idle,
        };
        state.requiresRelink = !isInternalAccount(account.category) && account.lastUpdateFailed;
      })
      .addCase(resyncAccount.rejected, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchTopUpFunding.pending, (state) => {
        state.topUpFunding = {
          ...state.topUpFunding,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchTopUpFunding.fulfilled, (state, action) => {
        state.topUpFunding = {
          ...state.topUpFunding,
          data: action.payload || null,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchTopUpFunding.rejected, (state) => {
        state.topUpFunding = {
          ...state.topUpFunding,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  resetAccountStore,
  resetTopUpFunding,
  handleUpdatePagination,
  setIsDialogOpenTopUp,
  setTopUpFunding,
  handleUpdateTransactionsFilters,
  handleUpdateChartFilters,
  handleSearchTransactions,
  handleArchive,
  handleSortTransactions,
  handleUpdateAccount,
} = bankAccountSlice.actions;

export default bankAccountSlice.reducer;
