import { Workspace } from 'models/workspace.interface';

export const getWorkspacesAsOptions = (workspaces: Workspace[]) => (
  workspaces.map(({ id, displayName }) => ({
    label: displayName,
    value: id,
  }))
);

export const formatWorkspaceName = (name: string) => name.trim().replace(/\s+/g, '-').toLowerCase();
