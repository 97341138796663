import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const TransactionDetails = lazy(
  () => CustomLazyLoader(
    () => (
      import(
        /* webpackChunkName: 'TransactionDetails' */
        'modules/transactions-details/containers/TransactionDetails'
      )
    ),
    'TransactionDetails',
  ),
);

export default TransactionDetails;
