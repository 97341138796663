import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const SubscriptionFlow = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'PaymentAgreement' */ 'modules/choose-subscription/containers/SubscriptionFlow')
    ),
    'SubscriptionFlow',
  ),
);

export default SubscriptionFlow;
