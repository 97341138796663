/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const CheckIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 12C20 13.5822 19.5308 15.129 18.6518 16.4446C17.7727 17.7602 16.5233 18.7855 15.0615 19.391C13.5997 19.9965 11.9911 20.155 10.4393 19.8463C8.88743 19.5376 7.46197 18.7757 6.34315 17.6569C5.22433 16.538 4.4624 15.1126 4.15372 13.5607C3.84504 12.0089 4.00347 10.4003 4.60897 8.93853C5.21447 7.47672 6.23985 6.22729 7.55544 5.34824C8.87103 4.46919 10.4178 4 12 4C13.0506 4 14.0909 4.20693 15.0615 4.60896C16.0321 5.011 16.914 5.60028 17.6569 6.34315C18.3997 7.08601 18.989 7.96793 19.391 8.93853C19.7931 9.90914 20 10.9494 20 12ZM11.0748 16.236L17.01 10.3004C17.058 10.2525 17.096 10.1956 17.122 10.1329C17.1479 10.0703 17.1613 10.0032 17.1613 9.9354C17.1613 9.8676 17.1479 9.80048 17.122 9.73785C17.096 9.67522 17.058 9.61832 17.01 9.5704L16.28 8.84C16.2321 8.79204 16.1752 8.754 16.1126 8.72805C16.0499 8.70209 15.9828 8.68873 15.915 8.68873C15.8472 8.68873 15.7801 8.70209 15.7175 8.72805C15.6548 8.754 15.5979 8.79204 15.55 8.84L10.71 13.68L8.45 11.42C8.40208 11.372 8.34518 11.334 8.28255 11.308C8.21993 11.2821 8.1528 11.2687 8.085 11.2687C8.01721 11.2687 7.95008 11.2821 7.88745 11.308C7.82482 11.334 7.76792 11.372 7.72 11.42L6.99 12.15C6.94205 12.1979 6.90401 12.2548 6.87805 12.3174C6.8521 12.3801 6.83874 12.4472 6.83874 12.515C6.83874 12.5828 6.8521 12.6499 6.87805 12.7125C6.90401 12.7752 6.94205 12.8321 6.99 12.88L10.3448 16.2348C10.3927 16.2828 10.4496 16.3208 10.5123 16.3467C10.5749 16.3727 10.642 16.3861 10.7098 16.3861C10.7776 16.3861 10.8447 16.3727 10.9074 16.3467C10.97 16.3208 11.0269 16.2828 11.0748 16.2348V16.236Z" fill={color} />
  </svg>
);

export default CheckIcon;
