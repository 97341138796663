import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { alpha, styled } from '@mui/material/styles';

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: 0,
  padding: 0,
  border: `1px solid ${theme.palette.primary.main}`,

  '&:before': {
    height: 0,
  },
}));

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  '& svg path': {
    fill: theme.palette.general.lightBlack,
  },

  backgroundColor: alpha(theme.palette.primary.main, 0.17),
  padding: theme.spacing(5, 6),
  minHeight: 0,
  alignItems: 'center',

  '& .MuiAccordionSummary-content': {
    margin: 0,
    cursor: 'pointer',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5),

    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(2.5),
    },
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(180deg)',
    display: 'initial',
    height: '24px',
  },

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
}));

export const AccordionDetails = styled(
  MuiAccordionDetails,
  { shouldForwardProp: (props) => props !== 'expanded' },
)<{ expanded: boolean }>(({ theme, expanded }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.26),
  padding: theme.spacing(5, 6),
  ...(expanded ? { borderTop: `1px solid ${theme.palette.primary.main}` } : {}),

  '& .cancel:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
}));
