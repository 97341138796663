import { Box, Typography } from '@mui/material';
import { get } from 'lodash';
import { FC, ReactElement, ReactNode } from 'react';
import {
  Control, Controller, FieldErrors, UseFormRegister, UseFormWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import AmountField from 'fields/AmountField';
import DropdownField from 'fields/DropdownField';

import DollarIcon from 'assets/icons/DollarIcon';
import { AmountComparison } from 'constants/enums';
import {
  AMOUNT_CONDITION, AMOUNT_RANGE_MAX, AMOUNT_RANGE_MIN, TRANSACTION_AMOUNT_RANGE,
} from 'constants/fields';
import { AMOUNT_CONDITION_OPTIONS } from 'constants/options';
import { getTranslatedErrors } from 'utils/errorUtils';
import { capitalizeOneString } from 'utils/text';
import { AdvancedTransactionFilterSchema } from 'utils/validation/transactionFormSchema';

import { InputAdornment } from './index.styled';

interface AmountFormProps {
  register: UseFormRegister<AdvancedTransactionFilterSchema>;
  errors: Partial<FieldErrors<AdvancedTransactionFilterSchema>>;
  control: Control<AdvancedTransactionFilterSchema>;
  watch: UseFormWatch<AdvancedTransactionFilterSchema>;
}

const AmountForm: FC<AmountFormProps> = ({
  register,
  control,
  watch,
  errors,
}) => {
  const intl = useIntl();
  const translatedErrors = getTranslatedErrors(errors);

  const amountCondition = watch('amountCondition');
  const isBetween = amountCondition === AmountComparison.between;
  const rootError = get(errors, TRANSACTION_AMOUNT_RANGE.name)?.message as string;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="subtitle1">
        {intl.formatMessage({ id: 'label.amount' })}
      </Typography>

      <Controller
        name="amountCondition"
        control={control}
        render={({ field }): ReactElement => (
          <DropdownField
            {...AMOUNT_CONDITION}
            value={field?.value}
            options={AMOUNT_CONDITION_OPTIONS}
            inputProps={register('amountCondition')}
            id="select-amount-comparison"
            placeholder={capitalizeOneString(intl.formatMessage({ id: 'placeholder.selectcomparison' }))}
            error={!!translatedErrors?.amountCondition?.message}
            helperText={translatedErrors?.amountCondition?.message as ReactNode}
          />
        )}
      />

      <Box display="flex" alignItems="flex-start" gap={2}>
        <Controller
          name="amountRange.min"
          control={control}
          render={({ field }): ReactElement => (
            <AmountField
              {...AMOUNT_RANGE_MIN}
              value={field.value}
              InputProps={{
                ...register('amountRange.min'),
                startAdornment: (
                  <InputAdornment position="start">
                    <DollarIcon size={18} />
                  </InputAdornment>
                ),
              }}
              error={!!translatedErrors?.amountRange?.min?.message}
              helperText={translatedErrors?.amountRange?.min?.message as ReactNode}
            />
          )}
        />

        {isBetween && (
          <Controller
            name="amountRange.max"
            control={control}
            render={({ field }): ReactElement => (
              <AmountField
                {...AMOUNT_RANGE_MAX}
                value={field.value}
                InputProps={{
                  ...register('amountRange.max'),
                  startAdornment: (
                    <InputAdornment position="start">
                      <DollarIcon size={18} />
                    </InputAdornment>
                  ),
                }}
                error={!!translatedErrors?.amountRange?.max?.message}
                helperText={translatedErrors?.amountRange?.max?.message as ReactNode}
              />
            )}
          />
        )}
      </Box>

      {rootError && (
        <Typography variant="label1" color="general.darkRed1">
          {intl.formatMessage({ id: rootError })}
        </Typography>
      )}
    </Box>
  );
};

export default AmountForm;
