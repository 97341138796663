import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/index';
import { customerWorkspaceSelector } from 'store/user/selectors';

import {
  ApiStatus, ApplicationOutcome, IdentityApplicationStatus, KybStatus, MappedKybStatus,
} from 'constants/enums';
import { Beneficiary, Company, KybDetails } from 'models/company.interface';
import { Document } from 'models/document.interface';
import { IdentityVerificationApplication } from 'models/identityApplication.interface';

export const companyDetailsSelector = (state: RootState): Company | null => state.kyb.companyDetails.data;
export const isLoadingCompanyDetailsSelector = (state: RootState): boolean => (
  state.kyb.companyDetails.status === ApiStatus.loading
);

export const companyBeneficiarySelector = (state: RootState): Beneficiary[] | null => state.kyb.companyBeneficiary.data;
export const isLoadingCompanyBeneficiarySelector = (state: RootState): boolean => (
  state.kyb.companyBeneficiary.status === ApiStatus.loading
);
export const documentsSelector = (state: RootState): Document[] => state.kyb.documents.data;
export const isLoadingDocumentsSelector = (state: RootState):boolean => (
  state.kyb.documents.status === ApiStatus.loading
);

export const identityApplicationSelector = (state: RootState): IdentityVerificationApplication => (
  state.kyb?.identityApplication?.data
);

export const kybDetailsSelector = (state: RootState): KybDetails => state.kyb.kybDetails.data;
export const isLoadingKybDetailsSelector = (state: RootState): boolean => (
  state.kyb.kybDetails.status === ApiStatus.loading
);

export const mappedKybStatusSelector = createSelector(
  identityApplicationSelector,
  customerWorkspaceSelector,
  (identityApplication, company) => {
    const { latestStatus, outcome } = identityApplication || {};
    const { kybStatus } = company || {};
    const isApplicationRejected = latestStatus
      && [IdentityApplicationStatus.error, IdentityApplicationStatus.awaitingInformation].includes(latestStatus);
    const statusBasedOnOutcome = outcome === ApplicationOutcome.denied
      ? MappedKybStatus.rejected
      : MappedKybStatus.accepted;

    if (kybStatus !== KybStatus.submitted) {
      if (kybStatus === KybStatus.pending) {
        return MappedKybStatus.draft;
      }

      if (kybStatus === KybStatus.inProgress) {
        return MappedKybStatus.inProgress;
      }

      if (kybStatus === KybStatus.completed) {
        return statusBasedOnOutcome;
      }
    }

    if (isApplicationRejected) {
      return MappedKybStatus.rejected;
    }

    if (latestStatus === IdentityApplicationStatus.completed) {
      return statusBasedOnOutcome;
    }

    return MappedKybStatus.inReview;
  },
);
