import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

export const CreateTransaction = lazy(
  () => CustomLazyLoader(
    () => import(/* webpackChunkName: 'CreateTransaction' */ 'modules/create-transaction/containers/CreateTransaction'),
    'CreateTransaction',
  ),
);
