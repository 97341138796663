import { Box, FormLabel, Typography } from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';
import {
  Control, Controller, FieldErrors, UseFormRegister, UseFormWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import DateForm from 'components/DateForm';
import DropdownField from 'fields/DropdownField';
import TextareaField from 'fields/TextareaField';
import AmountForm from 'modules/insights-advanced-search/components/AmountForm';

import { KEYWORDS, TRANSACTION_TYPE } from 'constants/fields';
import { MAX_ALERT_MESSAGE } from 'constants/general';
import { FILTER_TRANSACTION_TYPE_OPTIONS } from 'constants/options';
import { getTranslatedErrors } from 'utils/errorUtils';
import {
  AdvancedTransactionFilterSchema,
} from 'utils/validation/transactionFormSchema';

interface FilterContentProps {
  control: Control<AdvancedTransactionFilterSchema>;
  watch: UseFormWatch<AdvancedTransactionFilterSchema>;
  register: UseFormRegister<AdvancedTransactionFilterSchema>;
  errors: Partial<FieldErrors<AdvancedTransactionFilterSchema>>;
}

const FilterContent: FC<FilterContentProps> = ({
  register,
  watch,
  control,
  errors,
}) => {
  const intl = useIntl();
  const translatedErrors = getTranslatedErrors(errors);

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <DateForm
        <AdvancedTransactionFilterSchema>
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        required
      />

      <Box display="flex" flexDirection="column" gap={3}>
        <FormLabel required sx={{ display: 'flex' }}>
          <Typography variant="subtitle1">
            {intl.formatMessage({ id: 'label.transactionType' })}
          </Typography>
        </FormLabel>

        <Controller
          name="transactionType"
          control={control}
          render={({ field }): ReactElement => (
            <DropdownField
              {...TRANSACTION_TYPE}
              value={field?.value}
              options={FILTER_TRANSACTION_TYPE_OPTIONS}
              inputProps={register('transactionType')}
              error={!!translatedErrors?.transactionType?.message}
              helperText={translatedErrors?.transactionType?.message as ReactNode}
            />
          )}
        />
      </Box>

      <AmountForm
        register={register}
        watch={watch}
        control={control}
        errors={errors}
      />

      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="subtitle1">
          {intl.formatMessage({ id: 'label.keywordInDescription' })}
        </Typography>
        <Controller
          name="keywords"
          control={control}
          render={({ field }): ReactElement => (
            <TextareaField
              {...KEYWORDS}
              inputProps={register('keywords')}
              value={field.value}
              error={!!translatedErrors?.keywords?.message}
              helperText={translatedErrors?.keywords?.message as ReactNode}
              count={field.value ? field.value.toString().length : 0}
              maxCount={MAX_ALERT_MESSAGE}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default FilterContent;
