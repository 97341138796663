import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

export const JoinCustomer = lazy(
  () => CustomLazyLoader(() => import(
    /* webpackChunkName: 'JoinCustomer' */
    'modules/accept-invitation/containers/JoinCustomer'
  ), 'JoinCustomer'),
);
