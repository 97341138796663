/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ChevronRightIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.14346 6.09456L8.59581 6.63133C8.5344 6.69274 8.5 6.7753 8.5 6.86128C8.5 6.94727 8.5344 7.02982 8.59581 7.09123L13.5937 12.0003L8.59581 16.9094C8.5344 16.9708 8.5 17.0534 8.5 17.1394C8.5 17.2254 8.5344 17.3079 8.59581 17.3693L9.14346 17.9061C9.20611 17.9663 9.29034 18 9.37807 18C9.4658 18 9.55003 17.9663 9.61268 17.9061L15.4042 12.2296C15.4656 12.1682 15.5 12.0857 15.5 11.9997C15.5 11.9137 15.4656 11.8311 15.4042 11.7697L9.61268 6.09325C9.54986 6.03323 9.46552 5.99976 9.37779 6C9.29006 6.00025 9.20593 6.0342 9.14346 6.09456Z" fill={color} />
  </svg>
);

export default ChevronRightIcon;
