import {
  Box, ListItemIcon, ListItemText, MenuItem, Menu,
} from '@mui/material';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import CreateRule from 'modules/create-rule';

import { useModal } from 'hooks/useModal';
import { usePermissions } from 'hooks/usePermissions';
import { useAppDispatch } from 'hooks/useRedux';
import { resetTransactionsStore } from 'store/transactions-search/transactionsSearchSlice';

import BankCardIcon from 'assets/icons/BankCardIcon';
import MoreIcon from 'assets/icons/MoreIcon';
import NotificationIcon from 'assets/icons/NotificationIcon';
import { BANK_ACCOUNTS_DETAILS_ROUTE } from 'constants/clientRoutes';
import { FaAccountType } from 'constants/enums';
import { RolePermissions } from 'constants/permissionEnums';
import { TransactionSearch } from 'models/transaction.interface';
import { convertTransactionFaToAccount } from 'utils/createTransaction';
import { extractTransactionInfoForRule, formatRoute } from 'utils/formatters';

import { IconButton } from './index.styled';

interface TransactionActionMenuProps {
  transaction: TransactionSearch;
}

const TransactionActionMenu: FC<TransactionActionMenuProps> = ({ transaction }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { isAllowed } = usePermissions();
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const canCreateRules = isAllowed({ permission: RolePermissions.CreateAlertRules });

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenRule = () => {
    onOpen();
    handleMenuClose();
  };

  const handleRedirectAccount = () => {
    dispatch(resetTransactionsStore());
    navigate(formatRoute(BANK_ACCOUNTS_DETAILS_ROUTE, { accountId: transaction?.transactionFa?.id }));
  };

  return (
    <>
      <IconButton onClick={handleClick} aria-label="Transaction menu" id="iconButton-transactionMenu">
        <Box display="flex" alignItems="center" sx={{ transform: 'rotate(90deg)' }}>
          <MoreIcon />
        </Box>
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {transaction?.transactionFa?.type === FaAccountType.bankAccount && (
          <MenuItem
            id="menuItem-accountDetails"
            onClick={handleRedirectAccount}
          >
            <ListItemIcon sx={{ color: 'text.secondary' }}>
              <BankCardIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: 'text.primary' }}>
              {intl.formatMessage({ id: 'label.seeAccountDetails' })}
            </ListItemText>
          </MenuItem>
        )}
        {canCreateRules && !transaction?.archived && (
          <MenuItem
            id="menuItem-createRule"
            onClick={handleOpenRule}
          >
            <ListItemIcon sx={{ color: 'text.secondary' }}>
              <NotificationIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: 'text.primary' }}>
              {intl.formatMessage({ id: 'button.createAlert' })}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>

      {isOpen && (
        <CreateRule
          isOpen={isOpen}
          onClose={onClose}
          transaction={extractTransactionInfoForRule(transaction)}
          bankAccount={convertTransactionFaToAccount(transaction?.transactionFa)}
        />
      )}
    </>
  );
};

export default TransactionActionMenu;
