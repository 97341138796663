/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const RoundedArrowDownIcon = ({ size = 40 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={size} height={size} rx="14" fill="#24605D" />
    <path
      d="M30 20C30 14.486 25.514 10 20 10C14.486 10 10 14.486 10 20C10 25.514 14.486 30 20 30C25.514 30 30 25.514 30 20ZM16.043 21.457L17.457 20.043L19 21.586V15H21V21.586L22.543 20.043L23.957 21.457L20 25.414L16.043 21.457Z"
      fill="#B4F89D"
    />
  </svg>

);

export default RoundedArrowDownIcon;
