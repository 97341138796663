import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const RulesHistory = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'RulesHistory' */ 'modules/rules-history/containers/RulesHistory'
    ),
    'RulesHistory',
  ),
);

export default RulesHistory;
