/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const LinXRequestIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 2C2.89 2 2 2.9 2 4V19.0195C2 20.1295 2.89 21 4 21H18C19.11 21 20 20.11 20 19V4C20 2.9 19.1393 2 18.0293 2H4ZM4 8H18V14H15V16L12 14H4V8ZM6 10V12H8V10H6ZM10 10V12H12V10H10ZM14 10V12H16V10H14Z" fill={color} />
  </svg>
);

export default LinXRequestIcon;
