import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

import InputField from 'fields/InputField';

const NumberFormatCustom = forwardRef((props: any, ref: any) => {
  const preventNegativeNumbers = (e: any) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  return (
    <NumericFormat
      {...props}
      getInputRef={ref}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
      onKeyDown={preventNegativeNumbers}
    />
  );
});

const NumberFormatField = ({
  InputProps,
  inputProps,
  isAllowed,
  ...remainingProps
}: any) => (
  <InputField
    {...remainingProps}
    InputProps={{
      ...InputProps,
      inputProps: {
        ...inputProps,
        isAllowed,
      },
      inputComponent: NumberFormatCustom,
    }}
  />
);

export default NumberFormatField;
