/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const DepositArrowIcon = ({ color = '#43CA21', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 12C22 6.486 17.514 2 12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12ZM8.043 13.457L9.457 12.043L11 13.586L11 7L13 7L13 13.586L14.543 12.043L15.957 13.457L12 17.414L8.043 13.457Z" fill={color} />
  </svg>
);

export default DepositArrowIcon;
