import MuiCardMedia from '@mui/material/CardMedia';
import { alpha, styled } from '@mui/material/styles';

export const Layout = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.main,
  overflow: 'hidden',
  position: 'relative',
}));

export const Content = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
}));

export const CardMedia = styled(MuiCardMedia)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  minHeight: '100vh',
  width: '100vw',
  background: `linear-gradient(
      305.93deg, 
      ${alpha(theme.palette.primary.main, 0.2)} -5.5%,
      ${alpha(theme.palette.primary.main, 0.4)} 17.81%,
      ${theme.palette.primary.main} 49.05%,
      ${theme.palette.primary.dark} 85.36%
    )}`,
})) as typeof MuiCardMedia;
