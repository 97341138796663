import {
  runGetApiRequest, runPostApiRequest, runUpdateApiRequest, runUpdateFileRequest, runUploadApiRequest,
} from 'services/Api';

import {
  CREATE_BENEFICIARY,
  CREATE_COMPANY_DOCUMENT,
  GET_COMPANY_BENEFICIARY,
  GET_COMPANY_BENEFICIARY_DETAILS,
  GET_COMPANY_DETAILS,
  GET_COMPANY_DOCUMENT,
  GET_COMPANY_DOCUMENTS,
  GET_KYB_APPLICATION,
  GET_KYB_DETAILS,
  SAVE_KYB_COMPANY_DETAILS,
  SUBMIT_KYB_APPLICATION,
  UPDATE_BENEFICIARY,
  UPDATE_COMPANY_DOCUMENT,
} from 'constants/apiRoutes';
import { Beneficiary } from 'models/company.interface';
import { CompanyUploadDocument } from 'models/document.interface';
import { formatRoute } from 'utils/formatters';

export const getCompanyBeneficiary = async (customerId: string) => {
  const endpoint = formatRoute(GET_COMPANY_BENEFICIARY, { customerId });
  return runGetApiRequest(endpoint);
};

export const getKybDetails = async (customerId: string) => {
  const endpoint = formatRoute(GET_KYB_DETAILS, { customerId });
  return runGetApiRequest(endpoint);
};

export const getCompanyBeneficiaryDetails = async (customerId: string, beneficiaryId: string) => {
  const endpoint = formatRoute(GET_COMPANY_BENEFICIARY_DETAILS, { customerId, beneficiaryId });
  return runGetApiRequest(endpoint);
};

export const getCompanyDetails = async (customerId: string) => {
  const endpoint = formatRoute(GET_COMPANY_DETAILS, { customerId });
  return runGetApiRequest(endpoint);
};

export const getCompanyDocuments = async (customerId: string, queryParams: Record<string, string> = {}) => {
  const endpoint = formatRoute(GET_COMPANY_DOCUMENTS, { customerId });
  return runGetApiRequest(endpoint, queryParams);
};

export const getKybApplication = async (customerId: string) => {
  const endpoint = formatRoute(GET_KYB_APPLICATION, { customerId });
  return runGetApiRequest(endpoint);
};

export const saveCompanyDetails = async (customerId: string, payload: any) => {
  const endpoint = formatRoute(SAVE_KYB_COMPANY_DETAILS, { customerId });
  return runPostApiRequest(endpoint, payload);
};

export const createBeneficiary = async (customerId: string, payload: Partial<Beneficiary>) => {
  const endpoint = formatRoute(CREATE_BENEFICIARY, { customerId });
  return runPostApiRequest(endpoint, payload);
};

export const updateBeneficiary = async (customerId: string, beneficiaryId: string, payload: Partial<Beneficiary>) => {
  const endpoint = formatRoute(UPDATE_BENEFICIARY, { customerId, beneficiaryId });
  return runUpdateApiRequest(endpoint, payload);
};

export const createCompanyDocument = async (
  customerId: string,
  payload: { file: File; dto: Partial<CompanyUploadDocument> },
) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('dto', new Blob(
    [JSON.stringify(payload.dto)],
    {
      type: 'application/json',
    },
  ));

  const endpoint = formatRoute(CREATE_COMPANY_DOCUMENT, { customerId });
  return runUploadApiRequest(endpoint, formData);
};

export const updateCompanyDocument = async (
  customerId: string,
  documentId: string,
  payload: Partial<CompanyUploadDocument>,
) => {
  const formData = new FormData();
  formData.append('dto', new Blob(
    [JSON.stringify(payload)],
    {
      type: 'application/json',
    },
  ));

  const endpoint = formatRoute(UPDATE_COMPANY_DOCUMENT, { customerId, documentId });
  return runUpdateFileRequest(endpoint, formData);
};

export const createBeneficiaryIdentificationDocument = async (
  customerId: string,
  payload: { file: File; dto: any },
) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('dto', new Blob(
    [JSON.stringify(payload.dto)],
    {
      type: 'application/json',
    },
  ));

  const endpoint = formatRoute(CREATE_COMPANY_DOCUMENT, { customerId });
  return runUploadApiRequest(endpoint, formData);
};

export const updateBeneficiaryIdentificationDocument = async (
  customerId: string,
  documentId: string,
  payload: any,
) => {
  const formData = new FormData();
  formData.append('dto', new Blob(
    [JSON.stringify(payload)],
    {
      type: 'application/json',
    },
  ));

  const endpoint = formatRoute(UPDATE_COMPANY_DOCUMENT, { customerId, documentId });
  return runUpdateFileRequest(endpoint, formData);
};

export const getDocumentDetails = async (customerId: string, documentId: string) => (
  runGetApiRequest(formatRoute(GET_COMPANY_DOCUMENT, { customerId, documentId }))
);

export const submitKybApplication = async (customerId: string) => (
  runPostApiRequest(formatRoute(SUBMIT_KYB_APPLICATION, { customerId }))
);
