import { differenceInMinutes } from 'date-fns';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useLocalStorage from 'hooks/useLocalStorage';
import { useAppDispatch } from 'hooks/useRedux';
import { sendEmailConfirmation } from 'services/UserService';
import { resetStore } from 'store/actions';
import { resetUserState } from 'store/user/userSlice';

import { SIGN_IN_ROUTE } from 'constants/clientRoutes';
import { LocalStorageKeys } from 'constants/enums';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { auth } from 'utils/firebase';

export const useSendEmailVerification = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lastSentAt, setLastSentAt] = useLocalStorage(LocalStorageKeys.lastVerificationEmailDate);

  const sendEmailVerification = async () => {
    try {
      setIsSubmitting(true);
      const user = auth.currentUser;

      if (user?.email && (!lastSentAt || differenceInMinutes(new Date(), new Date(lastSentAt)) >= 1)) {
        await sendEmailConfirmation(user?.email);
        setLastSentAt(new Date().getTime());
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    sendEmailVerification,
  };
};

export const useLogout = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const logout = async () => {
    try {
      setIsSubmitting(true);
      await auth.signOut();
      navigate(SIGN_IN_ROUTE);
      dispatch(resetStore());
      dispatch(resetUserState());
    } catch (e) {
      toast.error(intl.formatMessage({ id: 'error.internalServer' }), GENERAL_TOAST_OPTIONS);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    logout,
  };
};
