/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const BillingIcon = ({ color = 'currentColor', height = 22, width = 20 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <path
      fill={color}
      d="M14.4 1.4 12.8.6l-1.6.8L9.6.6 8 1.4 6.4.6l-1.6.8L3.2.6l-1.6.8L0 .6v19.2a1.6 1.6 0 0 0 1.6 1.6h12.8a1.6 1.6 0 0 0 1.6-1.6V.6l-1.6.8Zm-5.6 16H4a.8.8 0 0 1 0-1.6h4.8a.8.8 0 0 1 0 1.6Zm-5.6-4a.8.8 0 0 1 .8-.8h3.2a.8.8 0 0 1 0 1.6H4a.8.8 0 0 1-.8-.8ZM8.8 11H4a.8.8 0 0 1 0-1.6h4.8a.8.8 0 0 1 0 1.6Zm3.2 6.4a.8.8 0 1 1 0-1.6.8.8 0 0 1 0 1.6Zm0-3.2a.8.8 0 1 1 0-1.6.8.8 0 0 1 0 1.6Zm0-3.2a.8.8 0 1 1 0-1.6.8.8 0 0 1 0 1.6Zm0-4.8H4a.8.8 0 0 1 0-1.6h8a.8.8 0 0 1 0 1.6Z"
    />
  </svg>
);

export default BillingIcon;
