import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const AccountDetails = lazy(
  () => CustomLazyLoader(
    () => import(/* webpackChunkName: 'AccountDetails' */ 'modules/bank-account-details/containers/AccountDetails'),
    'AccountDetails',
  ),
);

export default AccountDetails;
