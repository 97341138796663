import { styled } from '@mui/material/styles';

export const SettingsRoot = styled('div')<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  flexDirection: 'column',

  '& .MuiIconButton-root': {
    height: '40px',
    width: '40px',
    flex: 1,
    backgroundColor: isActive ? theme.palette.general.darkGreen2 : 'transparent',

    '&:hover': {
      backgroundColor: theme.palette.general.darkGreen,
    },
  },
}));
