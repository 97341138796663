import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTransactionSearch } from 'services/TransactionService';
import { resetStore } from 'store/actions';

import { ApiStatus, DefaultType, SortOrder } from 'constants/enums';
import { Pagination } from 'models/pagination.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { TransactionSearch } from 'models/transaction.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { TransactionFilterSchema } from 'utils/validation/transactionFormSchema';

export interface TransactionSearchState {
  details: {
    data: TransactionSearch[];
    status: ApiStatus;
  };
  search: string;
  sortOptions: SortOptions;
  pagination: Pagination;
  filters: TransactionFilterSchema;
  archived: boolean;
}

const initialState: TransactionSearchState = {
  details: {
    data: [],
    status: ApiStatus.idle,
  },
  sortOptions: {
    name: 'createdAt',
    type: SortOrder.desc,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
    page: 1,
  },
  search: '',
  filters: {
    types: {
      [DefaultType.all]: true,
      inflow: false,
      outflow: false,
    },
    dateRange: {
      from: null,
      to: null,
    },
    amountRange: {
      min: null,
      max: null,
    },
    banks: { [DefaultType.all]: true },
  },
  archived: false,
};

export const fetchTransactions = createAsyncThunk(
  'transactionsSearch/fetchTransactions',
  async (queryParams: { page?: number; sort?: string; search?: string; dashboard?: boolean }, thunkAPI) => {
    try {
      const response = await getTransactionSearch(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const transactionsSearchSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
      return state;
    },
    setSort: (state, action: PayloadAction<SortOptions>) => {
      state.sortOptions = action.payload;
      return state;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      state.pagination.page = 1;
      return state;
    },
    setFilters: (state, action: PayloadAction<TransactionFilterSchema>) => {
      state.filters = action.payload;
      state.pagination.page = 1;
      return state;
    },
    setArchived: (state, action: PayloadAction<boolean>) => {
      state.archived = action.payload;
      return state;
    },
    resetTransactions: (state) => ({
      ...state,
      details: initialState.details,
      pagination: initialState.pagination,
    }),
    resetTransactionsStore: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchTransactions.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        const {
          totalElements, totalPages, content,
        } = action.payload;

        state.details = {
          ...state.details,
          data: content,
          status: ApiStatus.idle,
        };
        state.pagination = {
          ...state.pagination,
          totalPages,
          totalElements,
        };
      })
      .addCase(fetchTransactions.rejected, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  setPagination,
  setSort,
  setSearch,
  setFilters,
  setArchived,
  resetTransactions,
  resetTransactionsStore,
} = transactionsSearchSlice.actions;

export default transactionsSearchSlice.reducer;
