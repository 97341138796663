import { Dialog } from '@mui/material';
import { isEqual } from 'lodash';
import {
  FC, ReactNode, useEffect, useState,
} from 'react';

import KybCover from 'components/KybCover';
import KybReviewCover from 'components/KybReviewCover';

import { useAppSelector } from 'hooks/useRedux';
import { mappedKybStatusSelector } from 'store/kyb/selectors';

import { KybCoverType, MappedKybStatus } from 'constants/enums';

import { Paper } from './index.styled';

interface KybRestrictionWrapperProps {
  children: ReactNode | ReactNode[];
  isVisible?: boolean;
  onClose?: () => void;
  skipLabel?: string;
  contentLabel?: string;
  contentReviewLabel?: string;
  contentRejectedLabel?: string;
}

const KybRestrictionWrapper: FC<KybRestrictionWrapperProps> = ({
  children,
  isVisible = false,
  onClose,
  skipLabel = 'button.returnToDashboard',
  contentLabel = 'label.pleaseFillOutLegalDetailsMoveMoney',
  contentReviewLabel = 'label.companyProfileNeedsToBeApproved.review.moveMoney',
  contentRejectedLabel = 'label.companyProfileNeedsToBeApproved.rejected.moveMoney',
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const mappedKybStatus = useAppSelector(mappedKybStatusSelector, isEqual);
  const isRejected = mappedKybStatus === MappedKybStatus.rejected;
  const isInProgress = [MappedKybStatus.draft, MappedKybStatus.inProgress].includes(mappedKybStatus);

  useEffect(() => {
    if (mappedKybStatus !== MappedKybStatus.accepted) {
      setIsOpen(true);
    }
  }, [mappedKybStatus]);

  return (
    <>
      {isOpen && isVisible && (
        <Dialog
          open={isOpen}
          maxWidth={false}
          PaperComponent={Paper}
        >
          {isInProgress ? (
            <KybCover
              onClose={onClose}
              skipLabel={skipLabel}
              contentLabel={contentLabel}
            />
          ) : (
            <KybReviewCover
              onClose={onClose}
              kybCoverType={isRejected ? KybCoverType.rejected : KybCoverType.inReview}
              contentLabel={isRejected
                ? contentRejectedLabel
                : contentReviewLabel}
              seeDetailsLabel={isRejected
                ? 'button.viewRejectedDetails'
                : 'button.viewCompanyProfile'}
            />
          )}
        </Dialog>
      )}
      {children}
    </>
  );
};

export default KybRestrictionWrapper;
