import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCustomerDetails } from 'services/CustomersService';
import { getWorkspaceInvitations, getWorkspaceMembers } from 'services/UsersService';
import { resetStore } from 'store/actions';

import { ApiStatus, DefaultType, InvitationStatus } from 'constants/enums';
import { Customer } from 'models/customer.interface';
import { UserAndInvitationFilter } from 'models/filter.interface';
import { InvitationDetails } from 'models/invitation.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { User } from 'models/user.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { filterSupportUsers } from 'utils/users';

export interface CustomersState {
  user: {
    data: Customer|null;
    status: ApiStatus;
  };
  users: {
    data: User[] | null;
    status: ApiStatus;
    sortOptions: SortOptions | null;
  };
  invitations: {
    data: InvitationDetails[] | null;
    status: ApiStatus;
    sortOptions: SortOptions | null;
  };
  search: string;
  filter: UserAndInvitationFilter;
}

const initialState: CustomersState = {
  user: {
    data: null,
    status: ApiStatus.idle,
  },
  users: {
    data: null,
    status: ApiStatus.idle,
    sortOptions: null,
  },
  invitations: {
    data: null,
    status: ApiStatus.loading,
    sortOptions: null,
  },
  search: '',
  filter: {
    role: DefaultType.all,
    statuses: {
      [DefaultType.all]: true,
      [InvitationStatus.accepted]: false,
      [InvitationStatus.new]: false,
      [InvitationStatus.visited]: false,
    },
  },
};

export const fetchCustomerDetails = createAsyncThunk(
  'customerDetails/fetchCustomerDetails',
  async (customerId: string, thunkAPI) => {
    try {
      const { data } = await getCustomerDetails(customerId);
      return data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchInvitations = createAsyncThunk(
  'customerDetails/fetchInvitations',
  async ({ params } : { params: { workspaceId: string } }, thunkAPI) => {
    try {
      const response = await getWorkspaceInvitations(params);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchUsers = createAsyncThunk(
  'customerDetails/fetchUsers',
  async ({ params } : { params: { workspaceId: string } }, thunkAPI) => {
    try {
      const response = await getWorkspaceMembers(params);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const customerDetailsSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetState: () => initialState,
    updateInvitationsSortOptions: (state, action: PayloadAction<SortOptions>) => {
      state.invitations.sortOptions = action.payload;
      return state;
    },
    updateUsersSortOptions: (state, action: PayloadAction<SortOptions>) => {
      state.users.sortOptions = action.payload;
      return state;
    },
    updateFilter: (state, action: PayloadAction<UserAndInvitationFilter>) => {
      state.filter = action.payload;
      return state;
    },
    updateSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchUsers.pending, (state) => {
        state.users = {
          ...state.users,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const { content } = action.payload || {};

        state.users = {
          ...state.users,
          status: ApiStatus.idle,
          data: filterSupportUsers(content),
        };
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.users = {
          ...state.users,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchCustomerDetails.pending, (state) => {
        state.user = {
          ...state.user,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchCustomerDetails.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          status: ApiStatus.idle,
          data: action.payload || null,
        };
      })
      .addCase(fetchCustomerDetails.rejected, (state) => {
        state.user = {
          ...state.user,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchInvitations.pending, (state) => {
        state.invitations = {
          ...state.invitations,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchInvitations.fulfilled, (state, action) => {
        const { content } = action.payload || {};

        state.invitations = {
          ...state.invitations,
          status: ApiStatus.idle,
          data: content,
        };
      })
      .addCase(fetchInvitations.rejected, (state) => {
        state.invitations = {
          ...state.invitations,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  resetState,
  updateInvitationsSortOptions,
  updateUsersSortOptions,
  updateFilter,
  updateSearch,
} = customerDetailsSlice.actions;

export default customerDetailsSlice.reducer;
