/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const TransferIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 3V6H2V8H18V11L22 7L18 3ZM6 13L2 17L6 21V18H22V16H6V13Z" fill={color} />
  </svg>
);

export default TransferIcon;
