import { Typography } from '@mui/material';
import { FC } from 'react';

import { TypeWrapper } from 'components/CardType/index.styled';

interface CardTypeProps {
  label: string;
}

const CardType:FC<CardTypeProps> = ({ label }) => (
  <TypeWrapper>
    <Typography variant="label2" color="textSecondary">
      {label}
    </Typography>
  </TypeWrapper>
);

export default CardType;
