import customerPalette from 'assets/themes/customerPalette';

const operatorPalette = {
  ...customerPalette,
  primary: {
    main: '#111111',
    secondary: '#1D1D1D',
    dark: '#122F2E',
  },
  general: {
    ...customerPalette.general,
    lightGrey7: '#e7eded',
  },
};

export default operatorPalette;
