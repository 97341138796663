
const MuiTab = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      marginRight: theme.spacing(2),
      color: theme.palette.general.black,
      minHeight: '22px',
      margin: theme.spacing(0, 6, 0, 0),
      '& svg': {
        marginRight: theme.spacing(2),
      },

      '&.Mui-selected': {
        color: theme.palette.common.black,
        fontWeight: 700,
        '& svg > path': {
          fill: theme.palette.common.black,
        },
      },
    }),
  },
};

export default MuiTab;
