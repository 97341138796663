/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const RefreshIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4C7.5888 4 4 7.5888 4 12C4 16.4112 7.5888 20 12 20C16.4112 20 20 16.4112 20 12H18.4C18.4 15.5288 15.5288 18.4 12 18.4C8.4712 18.4 5.6 15.5288 5.6 12C5.6 8.4712 8.4712 5.6 12 5.6C13.7648 5.6 15.3622 6.32038 16.5203 7.47969L14.4 9.6H20V4L17.6484 6.35156C16.2014 4.90101 14.2058 4 12 4Z" fill={color} />
  </svg>
);

export default RefreshIcon;
