import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const UserProfile = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'UserProfile' */ 'modules/user-profile/containers/UserProfile')
    ),
    'UserProfile',
  ),
);

export default UserProfile;
