/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const TransactionsIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7679 7.21305L14.5573 2.22265C14.4052 2.08445 14.1971 2 13.981 2L5.6008 2.03839C4.71236 2.03839 4 2.72169 4 3.5739V20.4645C4 21.309 4.71236 22 5.6008 22L18.3992 21.9616C19.2796 21.9616 20 21.2706 20 20.4261V7.75816C20 7.55086 19.912 7.35892 19.7679 7.21305ZM12.7884 16.2879V17.3628H11.1876V16.2956C11.0835 16.2726 10.9875 16.2495 10.8995 16.2188C10.2431 16.0115 9.81091 15.6123 9.67484 15.0518C9.64282 14.9367 9.62681 14.8138 9.62681 14.691H10.9955C11.0195 15.0134 11.2997 15.2438 11.7399 15.3052C11.8279 15.3282 11.932 15.3359 12.036 15.3359C12.3002 15.3359 12.5243 15.2668 12.6843 15.1593C12.7644 15.1056 12.8284 15.0441 12.8684 14.9674C12.9085 14.8983 12.9325 14.8138 12.9325 14.7294C12.9325 14.6526 12.9165 14.5835 12.8844 14.5298C12.8524 14.4683 12.8044 14.4146 12.7324 14.3685C12.6603 14.3225 12.5723 14.2841 12.4522 14.2457C12.3402 14.2073 12.2041 14.1766 12.044 14.1459L11.988 14.1382L11.2997 14C10.9315 13.9309 10.6273 13.8234 10.3872 13.6622C9.95498 13.3935 9.73887 12.9712 9.73887 12.4261C9.73887 11.9271 9.93897 11.5125 10.2831 11.2207C10.3152 11.1823 10.3472 11.1593 10.3872 11.1363C10.6033 10.9674 10.8754 10.8445 11.1876 10.7678V9.68522H12.7884V10.7754C12.9645 10.8215 13.1326 10.8829 13.2846 10.9597C13.4287 11.0288 13.5648 11.1209 13.6848 11.2207C13.941 11.428 14.1171 11.7044 14.1971 12.0192C14.2211 12.1267 14.2371 12.2418 14.2371 12.357H12.9165C12.8844 11.9808 12.5083 11.7121 12.004 11.7121H11.988C11.4917 11.7198 11.1636 11.9501 11.1636 12.3033C11.1636 12.5873 11.4037 12.7639 11.988 12.8714C11.996 12.8791 12.004 12.8791 12.012 12.8791L12.7404 13.0173C13.8529 13.2246 14.3492 13.7083 14.3492 14.5681C14.3492 15.4664 13.7729 16.0729 12.7884 16.2879ZM14.3972 8.14203C14.1731 8.14203 13.981 8.05758 13.8289 7.91939C13.6768 7.78887 13.5808 7.58925 13.5808 7.37428V3.45873L18.4712 8.14203H14.3972Z" fill={color} />
  </svg>
);

export default TransactionsIcon;
