import enUSMessages from 'languages/console-strings-en-US.json';

const AppLocale = {
  'en-US': {
    messages: enUSMessages,
    locale: 'en-US',
  },
};

export default AppLocale;
