import {
  CashFlowSearchType, DateFormats, DateType, DefaultType,
} from 'constants/enums';
import { formatDate } from 'utils/formatters';
import { AdvancedTransactionFilterSchema } from 'utils/validation/transactionFormSchema';

export const formatFiltersForServer = (filters: AdvancedTransactionFilterSchema) => ({
  interval: filters?.dateRange?.type as DateType,
  ...(filters?.transactionType !== DefaultType.all
    ? { type: filters?.transactionType as CashFlowSearchType }
    : {}),
  ...(filters?.dateRange?.type === DateType.custom
    ? {
      dateFrom: filters?.dateRange?.from
        ? formatDate(new Date(filters.dateRange.from), DateFormats.transactionDate)
        : undefined,
      dateTo: filters?.dateRange?.to
        ? formatDate(new Date(filters.dateRange.to), DateFormats.transactionDate)
        : undefined,
    } : {}),
});
