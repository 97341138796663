/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const LocationIcon = ({ color = '#BABABA', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9998 1.99922C8.13376 1.99922 4.99976 5.13322 4.99976 8.99922C4.99976 13.9992 11.9998 21.9992 11.9998 21.9992C11.9998 21.9992 18.9998 13.9992 18.9998 8.99922C18.9998 5.13322 15.8658 1.99922 11.9998 1.99922ZM11.9998 11.4992C10.6188 11.4992 9.49976 10.3802 9.49976 8.99922C9.49976 7.61822 10.6188 6.49922 11.9998 6.49922C13.3808 6.49922 14.4998 7.61822 14.4998 8.99922C14.4998 10.3802 13.3808 11.4992 11.9998 11.4992Z" fill={color} />
  </svg>
);

export default LocationIcon;
