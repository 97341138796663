import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const TeamManagement = lazy(
  () => CustomLazyLoader(
    () => import(/* webpackChunkName: 'TeamManagement' */ 'modules/team-management/containers/TeamManagement'),
    'TeamManagement',
  ),
);

export default TeamManagement;
